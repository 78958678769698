import React from 'react';

import cn from 'classnames';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

import { math } from '@app/lib';

type PaginationPropTypes = {
  canPreviousPage: boolean;
  canNextPage: boolean;
  totalPageCount: number;
  currentPage: number;
  onPageChange: (page: number) => void;
};

const NUMBER_OF_VISIBLE_PAGES = 5;

function generatePages(min: number, max: number) {
  const result = [];
  for (let i = min; i <= max; i++) {
    result.push(i.toString());
  }
  return result;
}

const Pagination: React.FC<PaginationPropTypes> = ({ canPreviousPage, canNextPage, totalPageCount, currentPage, onPageChange }) => {
  let minPage = 1;
  let maxPage = 1;

  const numberOfVisiblePagesToEitherSide = Math.floor(NUMBER_OF_VISIBLE_PAGES / 2);
  currentPage = math.clamp(currentPage, 1, totalPageCount);

  if (totalPageCount <= NUMBER_OF_VISIBLE_PAGES) {
    minPage = 1;
    maxPage = totalPageCount;
  } else {
    if (currentPage - numberOfVisiblePagesToEitherSide < 1) {
      minPage = 1;
      maxPage = currentPage + numberOfVisiblePagesToEitherSide + (numberOfVisiblePagesToEitherSide - currentPage + 1);
    } else if (currentPage + numberOfVisiblePagesToEitherSide > totalPageCount) {
      const remainder = totalPageCount - currentPage + 1;
      minPage = currentPage - (NUMBER_OF_VISIBLE_PAGES - remainder);
      maxPage = totalPageCount;
    } else {
      minPage = currentPage - numberOfVisiblePagesToEitherSide;
      maxPage = currentPage + numberOfVisiblePagesToEitherSide;
    }
  }

  return (
    <nav className="flex flex-row gap-3" aria-label="Pagination">
      <button
        disabled={!canPreviousPage}
        onClick={() => onPageChange(currentPage - 1)}
        className="flex items-center justify-center w-9 h-9"
      >
        <ChevronLeftIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
      </button>

      {generatePages(minPage, maxPage).map((page: string, index: number) => (
        <button
          key={index}
          className={cn(
            'flex items-center justify-center rounded-2 w-9 h-9 rounded-1.6 border border-gray-300 text-3.5 font-semibold text-gray-500',
            {
              'bg-gray-300': parseInt(page) === currentPage,
            }
          )}
          onClick={() => onPageChange(parseInt(page))}
        >
          {page}
        </button>
      ))}

      <button disabled={!canNextPage} onClick={() => onPageChange(currentPage + 1)} className="flex items-center justify-center w-9 h-9">
        <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
      </button>
    </nav>
  );
};

export default Pagination;

/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BranchUserTokenSuccessResponse } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { V2OtpCheckRequestInput } from '../model';
// @ts-ignore
import { V2OtpSendRequestInput } from '../model';
// @ts-ignore
import { V2OtpSendSuccessResponse } from '../model';
/**
 * OTPBranchV2Api - axios parameter creator
 * @export
 */
export const OTPBranchV2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * OTP will be checked for given phone number for branch users
         * @summary Check OTP for given phone number for branch users
         * @param {V2OtpCheckRequestInput} v2OtpCheckRequestInput Check OTP request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkOtpToBranchUserV2: async (v2OtpCheckRequestInput: V2OtpCheckRequestInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'v2OtpCheckRequestInput' is not null or undefined
            assertParamExists('checkOtpToBranchUserV2', 'v2OtpCheckRequestInput', v2OtpCheckRequestInput)
            const localVarPath = `/v2/branch_account/otp/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v2OtpCheckRequestInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * OTP will be sent to given phone number for branch users
         * @summary Send OTP to given phone number for branch users
         * @param {V2OtpSendRequestInput} v2OtpSendRequestInput Send OTP request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendOtpToBranchUserV2: async (v2OtpSendRequestInput: V2OtpSendRequestInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'v2OtpSendRequestInput' is not null or undefined
            assertParamExists('sendOtpToBranchUserV2', 'v2OtpSendRequestInput', v2OtpSendRequestInput)
            const localVarPath = `/v2/branch_account/otp/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v2OtpSendRequestInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OTPBranchV2Api - functional programming interface
 * @export
 */
export const OTPBranchV2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OTPBranchV2ApiAxiosParamCreator(configuration)
    return {
        /**
         * OTP will be checked for given phone number for branch users
         * @summary Check OTP for given phone number for branch users
         * @param {V2OtpCheckRequestInput} v2OtpCheckRequestInput Check OTP request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkOtpToBranchUserV2(v2OtpCheckRequestInput: V2OtpCheckRequestInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchUserTokenSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkOtpToBranchUserV2(v2OtpCheckRequestInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * OTP will be sent to given phone number for branch users
         * @summary Send OTP to given phone number for branch users
         * @param {V2OtpSendRequestInput} v2OtpSendRequestInput Send OTP request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendOtpToBranchUserV2(v2OtpSendRequestInput: V2OtpSendRequestInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2OtpSendSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendOtpToBranchUserV2(v2OtpSendRequestInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OTPBranchV2Api - factory interface
 * @export
 */
export const OTPBranchV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OTPBranchV2ApiFp(configuration)
    return {
        /**
         * OTP will be checked for given phone number for branch users
         * @summary Check OTP for given phone number for branch users
         * @param {V2OtpCheckRequestInput} v2OtpCheckRequestInput Check OTP request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkOtpToBranchUserV2(v2OtpCheckRequestInput: V2OtpCheckRequestInput, options?: any): AxiosPromise<BranchUserTokenSuccessResponse> {
            return localVarFp.checkOtpToBranchUserV2(v2OtpCheckRequestInput, options).then((request) => request(axios, basePath));
        },
        /**
         * OTP will be sent to given phone number for branch users
         * @summary Send OTP to given phone number for branch users
         * @param {V2OtpSendRequestInput} v2OtpSendRequestInput Send OTP request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendOtpToBranchUserV2(v2OtpSendRequestInput: V2OtpSendRequestInput, options?: any): AxiosPromise<V2OtpSendSuccessResponse> {
            return localVarFp.sendOtpToBranchUserV2(v2OtpSendRequestInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OTPBranchV2Api - interface
 * @export
 * @interface OTPBranchV2Api
 */
export interface OTPBranchV2ApiInterface {
    /**
     * OTP will be checked for given phone number for branch users
     * @summary Check OTP for given phone number for branch users
     * @param {V2OtpCheckRequestInput} v2OtpCheckRequestInput Check OTP request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OTPBranchV2ApiInterface
     */
    checkOtpToBranchUserV2(v2OtpCheckRequestInput: V2OtpCheckRequestInput, options?: any): AxiosPromise<BranchUserTokenSuccessResponse>;

    /**
     * OTP will be sent to given phone number for branch users
     * @summary Send OTP to given phone number for branch users
     * @param {V2OtpSendRequestInput} v2OtpSendRequestInput Send OTP request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OTPBranchV2ApiInterface
     */
    sendOtpToBranchUserV2(v2OtpSendRequestInput: V2OtpSendRequestInput, options?: any): AxiosPromise<V2OtpSendSuccessResponse>;

}

/**
 * OTPBranchV2Api - object-oriented interface
 * @export
 * @class OTPBranchV2Api
 * @extends {BaseAPI}
 */
export class OTPBranchV2Api extends BaseAPI implements OTPBranchV2ApiInterface {
    /**
     * OTP will be checked for given phone number for branch users
     * @summary Check OTP for given phone number for branch users
     * @param {V2OtpCheckRequestInput} v2OtpCheckRequestInput Check OTP request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OTPBranchV2Api
     */
    public checkOtpToBranchUserV2(v2OtpCheckRequestInput: V2OtpCheckRequestInput, options?: any) {
        return OTPBranchV2ApiFp(this.configuration).checkOtpToBranchUserV2(v2OtpCheckRequestInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * OTP will be sent to given phone number for branch users
     * @summary Send OTP to given phone number for branch users
     * @param {V2OtpSendRequestInput} v2OtpSendRequestInput Send OTP request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OTPBranchV2Api
     */
    public sendOtpToBranchUserV2(v2OtpSendRequestInput: V2OtpSendRequestInput, options?: any) {
        return OTPBranchV2ApiFp(this.configuration).sendOtpToBranchUserV2(v2OtpSendRequestInput, options).then((request) => request(this.axios, this.basePath));
    }
}

import React, { useState, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { Form } from '@app/components';
import { api, useLocale, useDebounce } from '@app/hooks';
import type {
  FacebookCitiesListResponseCities as FacebookCityType,
  GeoTargetsResponseKeywords as GeoTargetsResponseKeywordsType,
} from '@app/api';

type ItemType = {
  key: string;
  name: string;
  region: string;
  country_name: string;
  target_type: string;
  country_code: string;
};

type LocationFormPropTypes = {
  defaultValue?: ItemType[] | any;
  isGooglePlatform: boolean;
};
const DEBOUNCE_MS = 200;

const CityInput: React.FC<LocationFormPropTypes> = ({ defaultValue, isGooglePlatform }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.CityInput',
  };
  const { t } = useLocale();
  const [query, setQuery] = useState<string>('');
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const { debouncedValue: debouncedQuery, isLoading: isDebounceLoading } = useDebounce(query, DEBOUNCE_MS);
  const {
    data: facebookCities,
    refetch,
    isLoading: isFacebookCitiesLoading,
  } = api.useListBranchFacebookCities({
    enabled: false,
    params: {
      query: query,
    },
  });
  const {
    data: googleCities,
    refetch: googleRefetch,
    isLoading: isGoogleCitiesLoading,
  } = api.useListBranchGoogleCities({
    enabled: false,
    params: {
      query: query,
    },
  });

  useEffect(() => {
    if (isGooglePlatform) {
      googleRefetch();
      return;
    }
    refetch();
  }, [debouncedQuery, refetch, googleRefetch]);

  function handleCityOptions() {
    if (isGooglePlatform) {
      return googleCities?.geo_targets?.map((city: GeoTargetsResponseKeywordsType) => ({
        name: city.name,
        region: city.target_type,
        country_name: city.country_code,
        value: String(city.id),
      }));
    }
    return facebookCities?.cities?.map((city: FacebookCityType) => ({
      name: city.name,
      region: city.region,
      country_name: city.country_name,
      value: city.id,
    }));
  }

  return (
    <Form.Select
      automaticHandleValue={false}
      onInputChange={setQuery}
      isMulti
      isSearchable
      isClearable
      isLoading={isFacebookCitiesLoading || isDebounceLoading || isGoogleCitiesLoading}
      name="cities"
      label={t('form.cities.label', SCOPE_OPTIONS)}
      rules={{
        required: { value: true, message: t('form.cities.errors.required', SCOPE_OPTIONS) },
      }}
      control={control}
      options={handleCityOptions()}
      defaultValue={defaultValue?.map((city: ItemType) => ({
        name: city.name as string,
        region: city.region as string,
        value: city.key as string,
        country_name: city.country_name,
      }))}
      getOptionLabels={(option: ItemType) => (option.region ? `${option.name} - ${option.region}` : option.name)}
      filterOptions={(options: ItemType) => {
        return options;
      }}
      error={errors.cities}
    />
  );
};

export default CityInput;

import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { TokenBranchUserApi } from '@app/api';
import type { BranchUserTokenInput } from '@app/api';
import { useAxios } from '@app/hooks';

export function branchUserLogin(branchUserTokenInput: BranchUserTokenInput, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new TokenBranchUserApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.branchUserLogin(branchUserTokenInput).then((res) => res.data);
}

export default function useBranchUserLogin(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation((values: BranchUserTokenInput) => branchUserLogin(values, axiosInstance), props);
}

import dynamic from 'next/dynamic';

import BalanceCard from './BalanceCard';

const AllowGmUpdateCampaignCard = dynamic(() => import('./AllowGmUpdateCampaignCard'), { ssr: false });
const BranchCard = dynamic(() => import('./BranchCard'), { ssr: false });
const IntegrationCard = dynamic(() => import('./IntegrationCard'), { ssr: false });
const PaymentMethodsCard = dynamic(() => import('./PaymentMethodsCard'), { ssr: false });
const ProfileCard = dynamic(() => import('./ProfileCard'), { ssr: false });
const DisconnectConfirmModal = dynamic(() => import('./DisconnectConfirmModal'), { ssr: false });

export default {
  AllowGmUpdateCampaignCard,
  BalanceCard,
  BranchCard,
  IntegrationCard,
  ProfileCard,
  PaymentMethodsCard,
  DisconnectConfirmModal,
};

import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16.835" height="14.017" viewBox="0 0 16.835 14.017" {...props}>
    <g id="Group_4231" data-name="Group 4231" transform="translate(-543.111 -451.999)">
      <path
        id="Path_1821"
        data-name="Path 1821"
        d="M554.871,462.976A5,5,0,0,1,551.848,464h-.009a.369.369,0,0,0-.083.009,5,5,0,0,1,.076-10h0a5.019,5.019,0,0,1,3.035,1.028.414.414,0,0,0,.566-.6,7.01,7.01,0,1,0-5.312,11.577c.116,0,.232,0,.348-.009a7.035,7.035,0,0,0,4.964-2.433.414.414,0,0,0-.566-.6Z"
        transform="translate(0)"
        fill="currentColor"
      />
      <path
        id="Path_1822"
        data-name="Path 1822"
        d="M558.015,458.575l.728-1.041a.413.413,0,0,0-.451-.634l-1.223.347-.769-1.016a.414.414,0,0,0-.744.239l-.034,1.267-1.193.409a.414.414,0,0,0-.006.781l1.19.425v1.271a.416.416,0,0,0,.278.393.44.44,0,0,0,.136.022.413.413,0,0,0,.325-.158l.791-1.009,1.218.368a.414.414,0,0,0,.461-.63Z"
        transform="translate(1.128 0.419)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default SvgComponent;

import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { ContactFormBranchAccountApi } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function showContactForm(branchId: string, contactFormId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new ContactFormBranchAccountApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.showContactFormForBranch(branchId, contactFormId).then((res) => res.data);
}

export default function useShowContactForm({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useQuery(
    ['useShowContactForm', branchId, params.contactFormId],
    () => showContactForm(branchId, params.contactFormId, axiosInstance),
    props
  );
}

import { memo } from 'react';

import { useGTM, useHotjar } from '@app/hooks';

const ThirdPartyAnalytics = () => {
  useHotjar();
  useGTM();

  return null;
};

export default memo(ThirdPartyAnalytics);

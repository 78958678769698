import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      d="M12.5 10.207l-4.5 4.5V16h1.293l4.5-4.5-1.293-1.293zm.707-.707l1.293 1.293L15.793 9.5 14.5 8.207 13.207 9.5zM12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-1a9 9 0 100-18 9 9 0 000 18zm2.854-13.854l2 2a.5.5 0 010 .708l-7 7A.5.5 0 019.5 17h-2a.5.5 0 01-.5-.5v-2a.5.5 0 01.146-.354l7-7a.5.5 0 01.708 0z"
    ></path>
  </svg>
);

export default SvgComponent;

import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { WalletBranchV2Api } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function getUserTransactions(branchId: string, axiosInstance: AxiosInstance, page: number, perPage: number) {
  const api = new WalletBranchV2Api(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);
  return api.getWalletTransactions(branchId, page, perPage);
}

export default function useGetUserTransactions(page: number, perPage: number) {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();
  return useQuery(['userTransactions', branchId, page, perPage], () => getUserTransactions(branchId, axiosInstance, page, perPage));
}

import React from 'react';

import { useFormContext } from 'react-hook-form';

import { useLocale } from '@app/hooks';
import { Box, Form } from '@app/components';

const TEXT_AREA_MAX_LENGTH = 2200;

type DescriptionPropTypes = {
  id: string;
  name: string;
  rows?: number;
};

const Description: React.FC<DescriptionPropTypes> = ({ id, name, rows }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.SocialMediaManagement.Description',
  };
  const { t } = useLocale();
  const {
    watch,
    register,
    formState: { errors },
  } = useFormContext();
  const descWatch = watch(name);
  const descriptionLength = !descWatch || descWatch === '' ? 0 : descWatch.length;

  return (
    <Box className="bg-white px-5">
      <Form.Textarea
        id={id}
        {...register(name, {
          required: {
            value: true,
            message: t('errors.required', SCOPE_OPTIONS),
          },
        })}
        className="mt-1"
        rows={rows}
        resizeOff={true}
        maxLength={TEXT_AREA_MAX_LENGTH}
        error={errors[name]}
        length={descriptionLength}
        label={t('label', SCOPE_OPTIONS)}
      />
    </Box>
  );
};

export default Description;

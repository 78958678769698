import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 17 17"
    xmlSpace="preserve"
    fill="currentColor"
    {...props}
  >
    <path
      d="M16.7,0.2C16.6,0.1,16.4,0,16.1,0c-0.2,0-0.4,0.1-0.6,0.2l-1.6,1.6c-2.4-1.8-5.7-1.6-7.8,0.5
	L5.4,3.1L5.4,3.2v0C5.1,3.5,5.1,4,5.4,4.3l0.9,0.9c-0.1,0-0.2,0.1-0.3,0.2L5.2,6.3L4.3,5.4C4,5.1,3.5,5.1,3.2,5.4L2.4,6.1
	c-2.1,2.1-2.3,5.4-0.5,7.8l-1.6,1.6l-0.1,0.1c-0.3,0.3-0.3,0.8,0.1,1.1C0.4,16.9,0.6,17,0.8,17c0.2,0,0.4-0.1,0.6-0.3L3,15.1
	c2.4,1.8,5.7,1.6,7.8-0.5l0.7-0.7c0.3-0.3,0.3-0.8,0-1.1l-0.9-0.9l0.9-0.9l0,0c0.1-0.1,0.1-0.2,0.2-0.3l0.9,0.9
	c0.3,0.3,0.8,0.3,1.1,0l0.7-0.7c2.1-2.1,2.3-5.4,0.5-7.8l1.6-1.6l0,0C17,1.1,17,0.5,16.7,0.2C16.7,0.2,16.7,0.2,16.7,0.2L16.7,0.2z
	 M9.7,13.4c-1.6,1.7-4.4,1.8-6.1,0.2c-1.7-1.6-1.8-4.4-0.2-6.1c0.1-0.1,0.1-0.1,0.2-0.2l0.2-0.2l6.1,6.1L9.7,13.4z M10.4,9.8
	l-0.9,0.9L6.3,7.4l0.9-0.9l0,0c0.1-0.1,0.1-0.2,0.2-0.3l3.3,3.3C10.6,9.6,10.5,9.7,10.4,9.8L10.4,9.8z M13.4,9.7l-0.2,0.2L7.1,3.7
	l0.2-0.2C9,1.9,11.8,2,13.4,3.8C15,5.4,15,8,13.4,9.7L13.4,9.7z"
    />
  </svg>
);

export default SvgComponent;

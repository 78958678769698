import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="Group_8"
    data-name="Group 8"
    xmlns="http://www.w3.org/2000/svg"
    width="31.737"
    height="22.63"
    viewBox="0 0 31.737 22.63"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_13" data-name="Rectangle 13" width="31.737" height="22.63" fill="currentColor" />
      </clipPath>
    </defs>
    <g id="Group_7" data-name="Group 7" clipPath="url(#clip-path)">
      <path
        id="Path_6"
        data-name="Path 6"
        d="M0,10.117A10.491,10.491,0,0,1,2.238,6.926a19.035,19.035,0,0,1,3.379-2.99c.066-.046.126-.1.213-.17-.609-.6-1.21-1.183-1.793-1.781A1.158,1.158,0,0,1,3.97.432,1.174,1.174,0,0,1,5.453.15a1.808,1.808,0,0,1,.359.291Q14.072,8.7,22.328,16.955q1.785,1.785,3.572,3.568a1.21,1.21,0,0,1,.4,1.2,1.167,1.167,0,0,1-1.977.552c-.9-.882-1.787-1.776-2.671-2.673a.363.363,0,0,0-.43-.1A17.141,17.141,0,0,1,6.76,17.78,19.8,19.8,0,0,1,.419,11.744,6.182,6.182,0,0,1,0,10.861v-.744M9.791,7.748a6.672,6.672,0,0,0,2,8.028,6.507,6.507,0,0,0,6.79.749c-.567-.564-1.126-1.128-1.7-1.68a.472.472,0,0,0-.295-.09,9.549,9.549,0,0,1-1.507-.03,4.292,4.292,0,0,1-3.493-4.889c.012-.1.026-.253-.032-.313-.572-.592-1.158-1.17-1.764-1.774"
        transform="translate(0 0)"
        fill="currentColor"
      />
      <path
        id="Path_7"
        data-name="Path 7"
        d="M180.583,26.743c-1.281-1.282-2.54-2.549-3.809-3.8-.157-.156-.071-.27-.021-.41a6.677,6.677,0,0,0-8.629-8.7.448.448,0,0,1-.58-.123c-.768-.793-1.557-1.567-2.337-2.349-.071-.071-.139-.147-.27-.287.79-.2,1.515-.435,2.258-.568,6.558-1.179,12.03.928,16.619,5.588a25.882,25.882,0,0,1,2.164,2.662,2.055,2.055,0,0,1,.017,2.561,20.574,20.574,0,0,1-5.205,5.29c-.058.041-.119.079-.206.136"
        transform="translate(-154.695 -9.553)"
        fill="currentColor"
      />
      <path
        id="Path_8"
        data-name="Path 8"
        d="M247.442,104.071l-5.016-5.018c.871-.424,2.747.1,3.763,1.042a4.183,4.183,0,0,1,1.253,3.976"
        transform="translate(-227.376 -92.764)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default SvgComponent;

function dataToInput(targeting) {
  let cities = [];

  if (targeting.geo_locations?.cities) {
    const handledItems = targeting.geo_locations?.cities.map((city) => ({
      ...city,
    }));
    cities = [...cities, ...handledItems];
  }

  return cities;
}

export default {
  dataToInput,
};

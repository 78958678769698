import React from 'react';

import cn from 'classnames';

import { Icons } from '@app/components';
import { WIZARD_STEPS } from '@app/constants';

type WizartItemType = { label: string; value: string };
type WizardPropTypes = {
  items: WizartItemType[];
  selected: null | string;
  onClick?: (key: string) => void;
  className?: string;
  boxClassName?: string;
};

const Wizard: React.FC<WizardPropTypes> = ({ items, selected, onClick }) => {
  const selectedIndex = items.findIndex((item) => item.value === selected);

  return (
    <div className={cn('flex items-center rounded-3 overflow-hidden mb-8 w-full')}>
      {items.map((item: WizartItemType, index: number) => (
        <React.Fragment key={index}>
          <div className={cn('w-[12%] bg-gray-500 h-0.5', { 'bg-blue-500': item.value === selected || index < selectedIndex })}></div>
          <div className={cn('flex items-center', { 'w-[76%]': item.value !== WIZARD_STEPS.OBJECTIVE })}>
            {item.value === WIZARD_STEPS.OBJECTIVE ||
              (WIZARD_STEPS.SUMMARY && (
                <div
                  className={cn('w-[100%] bg-gray-500 h-0.5', { 'bg-blue-500': item.value === selected || index < selectedIndex })}
                ></div>
              ))}
            <div className="flex flex-col items-center">
              <button
                onClick={() => onClick && onClick(item.value)}
                key={item.value}
                className={cn('flex flex-col items-center  overflow-hidden  transition-all outline-none', {
                  'text-blue-300': item.value === selected,
                })}
              >
                <span
                  className={cn('w-8.5 h-8 p-1 text-center flex items-center justify-center rounded-1.5 text-3 transition-all', {
                    'bg-blue-300 text-white border-[2px] border-transparent': item.value === selected,
                    'text-gray-500 border border-gray-500': index > selectedIndex,
                    'text-blue-600 border-[2px] border-blue-600': index < selectedIndex,
                  })}
                >
                  {index < selectedIndex ? (
                    <Icons.Checkmark className="w-4 h-4 text-blue-600" />
                  ) : (
                    <span className="text-4">{index + 1}</span>
                  )}
                </span>
              </button>
              <span
                className={cn('absolute top-[2.5rem] text-sm z-9 font-semibold', {
                  'text-blue-300': item.value === selected || index < selectedIndex,
                  'text-gray-500': item.value !== selected,
                })}
              >
                {item.label}
              </span>
            </div>
          </div>

          {item.value === WIZARD_STEPS.SUMMARY && (
            <div className={cn('w-[12%] bg-gray-500 h-0.5', { 'bg-blue-500': item.value === selected })}></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Wizard;

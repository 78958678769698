import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { MediaLibraryBranchApi } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function getBranchMediaLibraries(
  branchId: string,
  page?: number,
  perPage?: number,
  query?: object,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new MediaLibraryBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);
  return api.listMediaLibraryOfBranch(branchId, page, perPage, query).then((res) => res.data);
}

export default function useGetBranchMediaLibraries({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useQuery(
    ['useGetBranchMediaLibraries', branchId, params.page, params.perPage, params.query],
    () => getBranchMediaLibraries(branchId, params.page, params.perPage, params.query, axiosInstance),
    props
  );
}

import React, { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { useAuth, useLocale } from '@app/hooks';
import { Box, Form, Location } from '@app/components';
import { GEO_LOCATION_TYPE } from '@app/constants';
import type { Campaign as CampaignType, BranchCampaign as BranchCampaignType } from '@app/api';
import { cities, google as googleLib } from '@app/lib';
import CityInput from './CityInput';

type LocationFormPropTypes = {
  campaign: Partial<CampaignType> & Partial<BranchCampaignType>;
  radius?: number;
};

const FB_LOCATION_MIN = 1;
const FB_LOCATION_MAX = 80;
const SMART_LOCATION_MIN = 5;
const SMART_LOCATION_MAX = 65;

const LocationForm: React.FC<LocationFormPropTypes> = ({ campaign, radius }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.LocationForm',
  };
  const { t } = useLocale();
  const {
    unregister,
    formState: { errors },
    watch,
    control,
    setValue,
    register,
  } = useFormContext();
  const geoLocationTypeWatch = watch('geoLocationType');
  const radiusWatch = watch('radius') ?? radius;
  const defaultRange = campaign.targeting?.default_location_range ?? '';
  const { branch } = useAuth();
  const isGooglePlatform = googleLib.isPlatformGoogle(campaign?.template);
  const cityDefaultValue = getDefaultCity();
  function getDefaultCity() {
    if (campaign.targeting?.geo_locations?.cities) {
      return cities.dataToInput(campaign.targeting);
    }

    if (isGooglePlatform) {
      return branch?.default_google_city ? [branch?.default_google_city] : [];
    }

    return branch?.default_facebook_city ? [branch?.default_facebook_city] : [];
  }

  function getLocation() {
    if (!branch.default_custom_location?.latitude || !branch.default_custom_location?.longitude) {
      return;
    }

    return {
      lat: branch.default_custom_location?.latitude,
      lng: branch.default_custom_location?.longitude,
    };
  }

  const LOCATION_VALUES = [
    {
      label: t('form.geoLocationType.options.range', SCOPE_OPTIONS),
      value: GEO_LOCATION_TYPE.RANGE,
    },
    {
      label: t('form.geoLocationType.options.city', SCOPE_OPTIONS),
      value: GEO_LOCATION_TYPE.CITY,
    },
  ];

  useEffect(() => {
    if (campaign?.geo_location_type === GEO_LOCATION_TYPE.FREE) {
      if (campaign?.targeting?.geo_locations) {
        if (Object.prototype.hasOwnProperty.call(campaign?.targeting?.geo_locations, 'cities')) {
          setValue('geoLocationType', GEO_LOCATION_TYPE.CITY);
          return;
        }

        if (Object.prototype.hasOwnProperty.call(campaign?.targeting?.geo_locations, 'custom_locations')) {
          setValue('geoLocationType', GEO_LOCATION_TYPE.RANGE);
          return;
        }
      }

      setValue('geoLocationType', GEO_LOCATION_TYPE.RANGE);
      return;
    }

    if (campaign?.geo_location_type === GEO_LOCATION_TYPE.RANGE) {
      setValue('geoLocationType', GEO_LOCATION_TYPE.RANGE);
      return;
    }

    if (campaign?.geo_location_type === GEO_LOCATION_TYPE.CITY) {
      setValue('geoLocationType', GEO_LOCATION_TYPE.CITY);
      return;
    }
  }, [campaign?.geo_location_type]);

  useEffect(() => {
    if (geoLocationTypeWatch === GEO_LOCATION_TYPE.CITY) {
      unregister('radius');
    }

    if (geoLocationTypeWatch === GEO_LOCATION_TYPE.RANGE) {
      unregister('cities');
    }
  }, [geoLocationTypeWatch]);

  useEffect(() => {
    if (defaultRange && !radiusWatch) {
      setValue('radius', defaultRange);
    }
  }, [defaultRange]);

  return (
    <Box className="bg-white px-5 py-4 mt-5">
      {campaign.geo_location_type === GEO_LOCATION_TYPE.FREE && !isGooglePlatform && (
        <Form.Select
          name="geoLocationType"
          label={t('form.geoLocationType.label', SCOPE_OPTIONS)}
          rules={{
            required: { value: true, message: t('form.geoLocationType.errors.required', SCOPE_OPTIONS) },
          }}
          className="mb-4"
          control={control}
          options={LOCATION_VALUES}
          error={errors.geoLocationType}
        />
      )}
      {geoLocationTypeWatch === GEO_LOCATION_TYPE.RANGE && (
        <>
          <Form.Range
            label={t('form.radius.label', SCOPE_OPTIONS)}
            id="radius"
            defaultValue={radius}
            control={control}
            minValue={isGooglePlatform ? SMART_LOCATION_MIN : FB_LOCATION_MIN}
            maxValue={isGooglePlatform ? SMART_LOCATION_MAX : FB_LOCATION_MAX}
            error={errors.radius}
            {...register('radius', {
              onChange: (e) => {
                const value = e.target.value;

                setValue('radius', value);
              },
            })}
          />
          <Location.LoadScript>
            <Location.Circle radius={radiusWatch} location={getLocation()} />
          </Location.LoadScript>
        </>
      )}
      {geoLocationTypeWatch === GEO_LOCATION_TYPE.CITY && <CityInput defaultValue={cityDefaultValue} isGooglePlatform={isGooglePlatform} />}
    </Box>
  );
};

export default LocationForm;

import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18.872" height="18.872" viewBox="0 0 18.872 18.872" {...props}>
    <defs>
      <clipPath id="clip-path">
        <path fill="#bebebe" d="M0 0H18.872V18.872H0z" data-name="Rectangle 1887"></path>
      </clipPath>
    </defs>
    <g data-name="Group 4561">
      <g fill="#bebebe" clipPath="url(#clip-path)" data-name="Group 4561">
        <path
          d="M12.037 1.965h4.55a.319.319 0 01.319.318v4.551a.983.983 0 101.965 0V2.283A2.3 2.3 0 0016.587 0h-4.55a.983.983 0 000 1.965"
          data-name="Path 2849"
        ></path>
        <path
          d="M.982 7.816v-.25.25a.985.985 0 00.981-.981V2.283a.318.318 0 01.318-.318h4.553a.983.983 0 100-1.965H2.283A2.285 2.285 0 000 2.283v4.552a.982.982 0 00.982.981"
          data-name="Path 2850"
        ></path>
        <path
          d="M6.834 16.906H2.281a.317.317 0 01-.316-.317v-4.554a.983.983 0 10-1.965 0v4.552a2.288 2.288 0 002.283 2.285h4.551a.983.983 0 000-1.966"
          data-name="Path 2851"
        ></path>
        <path
          d="M17.89 11.054v.25-.25a.983.983 0 00-.982.981v4.552a.318.318 0 01-.318.318h-4.554a.982.982 0 100 1.964h4.554a2.282 2.282 0 002.282-2.281v-4.553a.982.982 0 00-.982-.981"
          data-name="Path 2852"
        ></path>
        <path
          d="M12.441 8.331l-4.9-2.452a1.236 1.236 0 00-1.792 1.105v4.9a1.234 1.234 0 001.237 1.236 1.222 1.222 0 00.551-.131l4.9-2.452a1.235 1.235 0 000-2.21"
          data-name="Path 2853"
        ></path>
      </g>
    </g>
  </svg>
);

export default SvgComponent;

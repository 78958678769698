/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ContactForm } from '../model';
// @ts-ignore
import { PunditResponse } from '../model';
/**
 * ContactFormBranchAccountApi - axios parameter creator
 * @export
 */
export const ContactFormBranchAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List contact forms for the Branch
         * @summary List contact forms for the Branch
         * @param {string} branchId The id of the branch
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContactFormsForBranch: async (branchId: string, page?: number, perPage?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('listContactFormsForBranch', 'branchId', branchId)
            const localVarPath = `/v2/branch_account/branches/{branch_id}/mobile/contact_forms`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Show contact form for the branch
         * @summary Show contact form for the branch
         * @param {string} branchId The id of the branch
         * @param {string} contactFormId The id of the contact form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showContactFormForBranch: async (branchId: string, contactFormId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('showContactFormForBranch', 'branchId', branchId)
            // verify required parameter 'contactFormId' is not null or undefined
            assertParamExists('showContactFormForBranch', 'contactFormId', contactFormId)
            const localVarPath = `/v2/branch_account/branches/{branch_id}/mobile/contact_forms/{contact_form_id}`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)))
                .replace(`{${"contact_form_id"}}`, encodeURIComponent(String(contactFormId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactFormBranchAccountApi - functional programming interface
 * @export
 */
export const ContactFormBranchAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactFormBranchAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * List contact forms for the Branch
         * @summary List contact forms for the Branch
         * @param {string} branchId The id of the branch
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listContactFormsForBranch(branchId: string, page?: number, perPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContactForm>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listContactFormsForBranch(branchId, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Show contact form for the branch
         * @summary Show contact form for the branch
         * @param {string} branchId The id of the branch
         * @param {string} contactFormId The id of the contact form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showContactFormForBranch(branchId: string, contactFormId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showContactFormForBranch(branchId, contactFormId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactFormBranchAccountApi - factory interface
 * @export
 */
export const ContactFormBranchAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactFormBranchAccountApiFp(configuration)
    return {
        /**
         * List contact forms for the Branch
         * @summary List contact forms for the Branch
         * @param {string} branchId The id of the branch
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContactFormsForBranch(branchId: string, page?: number, perPage?: number, options?: any): AxiosPromise<Array<ContactForm>> {
            return localVarFp.listContactFormsForBranch(branchId, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Show contact form for the branch
         * @summary Show contact form for the branch
         * @param {string} branchId The id of the branch
         * @param {string} contactFormId The id of the contact form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showContactFormForBranch(branchId: string, contactFormId: string, options?: any): AxiosPromise<ContactForm> {
            return localVarFp.showContactFormForBranch(branchId, contactFormId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactFormBranchAccountApi - interface
 * @export
 * @interface ContactFormBranchAccountApi
 */
export interface ContactFormBranchAccountApiInterface {
    /**
     * List contact forms for the Branch
     * @summary List contact forms for the Branch
     * @param {string} branchId The id of the branch
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactFormBranchAccountApiInterface
     */
    listContactFormsForBranch(branchId: string, page?: number, perPage?: number, options?: any): AxiosPromise<Array<ContactForm>>;

    /**
     * Show contact form for the branch
     * @summary Show contact form for the branch
     * @param {string} branchId The id of the branch
     * @param {string} contactFormId The id of the contact form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactFormBranchAccountApiInterface
     */
    showContactFormForBranch(branchId: string, contactFormId: string, options?: any): AxiosPromise<ContactForm>;

}

/**
 * ContactFormBranchAccountApi - object-oriented interface
 * @export
 * @class ContactFormBranchAccountApi
 * @extends {BaseAPI}
 */
export class ContactFormBranchAccountApi extends BaseAPI implements ContactFormBranchAccountApiInterface {
    /**
     * List contact forms for the Branch
     * @summary List contact forms for the Branch
     * @param {string} branchId The id of the branch
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactFormBranchAccountApi
     */
    public listContactFormsForBranch(branchId: string, page?: number, perPage?: number, options?: any) {
        return ContactFormBranchAccountApiFp(this.configuration).listContactFormsForBranch(branchId, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Show contact form for the branch
     * @summary Show contact form for the branch
     * @param {string} branchId The id of the branch
     * @param {string} contactFormId The id of the contact form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactFormBranchAccountApi
     */
    public showContactFormForBranch(branchId: string, contactFormId: string, options?: any) {
        return ContactFormBranchAccountApiFp(this.configuration).showContactFormForBranch(branchId, contactFormId, options).then((request) => request(this.axios, this.basePath));
    }
}

import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import type { AccountResponse as AccountType, Branch as BranchType } from '@app/api';
import { api, useAuth, useCheckAccountAlert, useLocale, useToast } from '@app/hooks';

const CheckFacebookAccount: React.FC = ({ children }) => {
  const router = useRouter();
  const { branchId, branch, storeBranch } = useAuth();
  const [branchUpdated, setBranchUpdated] = useState(false);

  if (!branchId) {
    return <>{children}</>;
  }

  const { showPaymentAlert, showSpendAlert } = useCheckAccountAlert();
  const toast = useToast();
  const { t } = useLocale();
  const SCOPE_OPTIONS = {
    scope: 'components.CheckCompanyInformation',
  };
  const { refetch: refetchBranch } = api.useShowBranchUserBranches({
    enabled: !!branchId,
    params: {
      branchId: branchId,
    },
    onSuccess: (data: BranchType) => {
      if (JSON.stringify(data) === JSON.stringify(branch)) {
        return;
      }

      storeBranch(data);
      setBranchUpdated(true);
    },
  });

  api.useGetBranchFacebookAccount({
    id: [router?.asPath],
    enabled: !!branchId,
    automaticToast: false,
    cacheTime: 0,
    staleTime: 0,
    params: {
      branchId: branchId,
    },
    onSuccess: (account: AccountType) => {
      if (branch?.is_onboarded !== true) {
        return;
      }

      if (!router.pathname.includes('/campaigns/create/[id]')) {
        return;
      }

      if (account?.funding_source) {
        return;
      }

      setTimeout(() => {
        router.push('/settings/payment-methods');
      }, 100);
    },
  });

  useEffect(() => {
    refetchBranch();
  }, [router?.asPath]);

  useEffect(() => {
    if (showPaymentAlert) {
      toast.error(t('messages.paymentAlert', SCOPE_OPTIONS));

      return;
    }

    if (showSpendAlert) {
      toast.error(t('messages.spendLimit', SCOPE_OPTIONS));

      return;
    }
  }, [showPaymentAlert, showSpendAlert]);

  useEffect(() => {
    if (!branchUpdated) {
      return;
    }

    if (!branch.company?.integration_redirect) {
      return;
    }

    if (router.pathname.includes('/settings/integration')) {
      return;
    }

    if (!branch?.facebook_integration || !branch?.facebook_integration?.id) {
      router.push('/settings/integration');
    }

    setBranchUpdated(false);
  }, [branchUpdated]);

  return <>{children}</>;
};

export default CheckFacebookAccount;

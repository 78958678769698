import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27.039" height="38.624" viewBox="0 0 27.039 38.624" {...props}>
    <g id="Group_2996" data-name="Group 2996" transform="translate(371 124)">
      <path
        id="Path_1012"
        data-name="Path 1012"
        d="M0,19.3V4.952A4.787,4.787,0,0,1,1.677,1.183,4.81,4.81,0,0,1,4.957,0C8.211,0,11.465,0,14.72,0a6.994,6.994,0,0,1,6,3.115A6.631,6.631,0,0,1,21.9,7.876a1.618,1.618,0,0,1-1.79,1.541c-2.664,0-5.329,0-7.993,0a7.221,7.221,0,1,0-.05,14.441c.84.016,1.681,0,2.521,0a2.544,2.544,0,1,1-.017,5.083c-2.369-.016-4.738,0-7.108,0a2.577,2.577,0,0,0-2.142.962A2.406,2.406,0,0,0,6.365,33.6a2.787,2.787,0,0,0,.97.17c2.45,0,4.9.009,7.349,0a7.252,7.252,0,0,0,7.054-5.214,7.107,7.107,0,0,0-1.986-7.491A7.137,7.137,0,0,0,14.6,19.036c-.822,0-1.645,0-2.467,0a2.384,2.384,0,0,1-2.413-1.82,2.358,2.358,0,0,1,2.032-2.932,21.758,21.758,0,0,1,5.4.184,11.988,11.988,0,0,1,8.711,6.76,11.643,11.643,0,0,1,1.085,6.651,11.933,11.933,0,0,1-3.829,7.5,11.955,11.955,0,0,1-6.5,3.118,12.346,12.346,0,0,1-1.871.129c-3.254,0-6.509.007-9.763-.006A4.852,4.852,0,0,1,0,33.648Q0,26.474,0,19.3"
        transform="translate(-371 -124)"
        fill="#3067f4"
      />
    </g>
  </svg>
);

export default SvgComponent;

import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';

import { useAuth } from '@app/hooks';
import { ENVIRONMENT_TYPES } from '@app/constants';

function useHotjar() {
  const { token } = useAuth();

  useEffect(() => {
    if (process.env.NODE_ENV === ENVIRONMENT_TYPES.PRODUCTION) {
      hotjar.initialize(Number(process.env.NEXT_PUBLIC_HOTJAR_HJID), Number(process.env.NEXT_PUBLIC_HOTJAR_HJSV));
    }
  }, []);

  useEffect(() => {
    if (token?.user?.id && typeof window !== 'undefined') {
      // initialized function copied from
      // https://github.com/abdalla/react-hotjar/blob/master/index.js
      if (typeof (window as any).hj === 'function') {
        hotjar.identify('USER_ID', { userProperty: token?.user?.id });
      }
    }
  }, [typeof window, token]);

  return useHotjar;
}

export default useHotjar;

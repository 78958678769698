import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { CampaignsBranchApi } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function cancelGoogleCampaignByBranchCampaignId(
  branchId: string,
  campaignId: string,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new CampaignsBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.cancelGoogleCampaignByBranchCampaignId(branchId, campaignId).then((res) => res.data);
}

export default function useCancelGoogleCampaignByBranchCampaignId(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useMutation((campaignId: string) => cancelGoogleCampaignByBranchCampaignId(branchId, campaignId, axiosInstance), props);
}

import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { IntegrationsBranchApi } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function deleteBranchIntegration(branchId: string, integrationId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new IntegrationsBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.deleteBranchIntegration(branchId, integrationId).then((res) => res.data);
}

export default function useDeleteBranchIntegration(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useMutation((integrationId: string) => deleteBranchIntegration(branchId, integrationId, axiosInstance), props);
}

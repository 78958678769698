import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { CampaignsBranchApi } from '@app/api';
import type { GoogleBranchCampaignCreateInput } from '@app/api';

import { useAxios } from '@app/hooks';

export function createGoogleBranchCampaign(
  branchId: string,
  googleBranchCampaignCreateInput: GoogleBranchCampaignCreateInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new CampaignsBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.createGoogleBranchCampaign(branchId, googleBranchCampaignCreateInput).then((res) => res.data);
}

export default function useCreateGoogleBranchCampaign(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { branchId: string; googleBranchCampaignCreateInput: GoogleBranchCampaignCreateInput }) =>
      createGoogleBranchCampaign(values.branchId, values.googleBranchCampaignCreateInput, axiosInstance),
    props
  );
}

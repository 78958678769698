import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 23.496 20.479" {...props}>
    <defs>
      <clipPath>
        <rect data-name="Rectangle 1622" width="23.496" height="20.479" transform="translate(0 0)" fill="currentColor" />
      </clipPath>
    </defs>
    <g data-name="Group 3109" transform="translate(0 0)">
      <g data-name="Group 3108">
        <path
          data-name="Path 1717"
          d="M21.375,2.12a7.239,7.239,0,0,0-9.686-.5A7.294,7.294,0,0,0,2.105,2.2,7.267,7.267,0,0,0,1.984,12.3a.887.887,0,0,0,.069.079l7,7a3.748,3.748,0,0,0,5.3,0l7.027-7.027a7.237,7.237,0,0,0,0-10.236M14.507,16.871l-1.335,1.335a2.133,2.133,0,0,1-2.943,0L8.9,16.871ZM11.745,5.27A2.085,2.085,0,1,1,9.661,7.355,2.087,2.087,0,0,1,11.745,5.27m3.75,2.085a3.749,3.749,0,1,0-6.445,2.6,6.8,6.8,0,0,0-3.656,3.413L3.238,11.21a5.571,5.571,0,0,1,7.879-7.878.817.817,0,0,0,.557.235.875.875,0,0,0,.651-.279,5.574,5.574,0,0,1,9.506,3.951,5.51,5.51,0,0,1-1.608,3.918,1.148,1.148,0,0,0-.083.081l-2.073,2.073A6.793,6.793,0,0,0,14.44,9.959a3.731,3.731,0,0,0,1.055-2.6M6.83,14.236A5.273,5.273,0,0,1,11.745,11.1a5.273,5.273,0,0,1,4.916,3.133.622.622,0,0,1,.007.474l-.43.431a.878.878,0,0,1-.325.066H7.578a.834.834,0,0,1-.526-.18l-.081-.081a.545.545,0,0,1-.081-.092.647.647,0,0,1-.06-.618m-.148.757Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default SvgComponent;

import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { CampaignsBranchApi } from '@app/api';
import type { BranchCampaignCreateInput } from '@app/api';

import { useAxios } from '@app/hooks';

export function createBranchCampaign(
  branchId: string,
  branchCampaignCreateInput: BranchCampaignCreateInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new CampaignsBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.createBranchCampaign(branchId, branchCampaignCreateInput).then((res) => res.data);
}

export default function useCreateBranchCampaign(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { branchId: string; branchCampaignCreateInput: BranchCampaignCreateInput }) =>
      createBranchCampaign(values.branchId, values.branchCampaignCreateInput, axiosInstance),
    props
  );
}

import { PUBLISHER_PLATFORMS } from '@app/constants';
import { array as arrayLib } from '@app/lib';

function selectRegisterName(platform: string) {
  if (platform === PUBLISHER_PLATFORMS.FACEBOOK) {
    return 'targeting_platforms.facebook_page_ids';
  }

  if (platform === PUBLISHER_PLATFORMS.INSTAGRAM) {
    return 'targeting_platforms.instagram_page_ids';
  }

  return '';
}

function handleTargetingPlatforms(instagramIds: Array<string>, facebookIds: Array<string>) {
  const isEmptyInstagram = arrayLib.isEmpty(instagramIds);
  const isEmptyFacebook = arrayLib.isEmpty(facebookIds);

  if (!isEmptyInstagram && !isEmptyFacebook) {
    return ['INSTAGRAM', 'FACEBOOK'];
  }

  if (isEmptyInstagram) {
    if (isEmptyFacebook) {
      return [];
    }
    return ['FACEBOOK'];
  }

  return ['INSTAGRAM'];
}

function handleInstagramFormData(instagramPages: []) {
  const instagram_page_ids = instagramPages?.reduce((group: any, currentValue: any) => {
    const businessAccountId = currentValue.instagram_business_account_id;
    group[businessAccountId] = group[businessAccountId] ?? [];
    group[businessAccountId].push(currentValue.id);
    return group;
  }, {} as Record<string, any[]>);

  return Object.entries(instagram_page_ids)?.map(([key, value]) => {
    return {
      page_ids: value,
      instagram_business_id: key,
    };
  });
}

export default { selectRegisterName, handleTargetingPlatforms, handleInstagramFormData };

import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    x="0"
    y="0"
    enableBackground="new 0 0 13.8 21"
    version="1.1"
    viewBox="0 0 13.8 21"
    xmlSpace="preserve"
    {...props}
  >
    <style></style>
    <g id="Group_4565">
      <g id="Group_4553">
        <path
          id="Path_2840"
          d="M11.1 0H2.8C1.2 0 0 1.2 0 2.8v15.5C0 19.8 1.2 21 2.8 21h8.3c1.5 0 2.7-1.2 2.8-2.8V2.8C13.8 1.2 12.6 0 11.1 0m1.1 18.3c0 .6-.5 1.1-1.2 1.1H2.8c-.6 0-1.1-.5-1.1-1.1V2.8c0-.6.5-1.1 1.1-1.1h8.3c.6 0 1.1.5 1.1 1.1v15.5z"
          className="st1"
        ></path>
        <path id="Path_2841" d="M6.9 16.3c-.5 0-.9.4-.9.9s.4.9.9.9.9-.4.9-.9-.4-.9-.9-.9" className="st1"></path>
      </g>
    </g>
  </svg>
);

export default SvgComponent;

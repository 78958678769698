import { useEffect } from 'react';
import { useRouter } from 'next/router';

function useDetectWindowChange(warningMessage: string, isDirty: boolean) {
  const router = useRouter();

  useEffect(() => {
    const handleWindowClose = (e: BeforeUnloadEvent) => {
      if (!isDirty) return;
      e.preventDefault();
      return (e.returnValue = warningMessage);
    };
    const handleBrowseAway = () => {
      if (!isDirty) return;
      if (window.confirm(warningMessage)) return;
      router.events.emit('routeChangeError');
      throw 'routeChange aborted.';
    };
    window.addEventListener('beforeunload', handleWindowClose);
    router.events.on('routeChangeStart', handleBrowseAway);
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
      router.events.off('routeChangeStart', handleBrowseAway);
    };
  }, [isDirty]);
}

export default useDetectWindowChange;

import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Group 3107"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 22.25 22.25"
    {...props}
  >
    <defs>
      <clipPath>
        <rect data-name="Rectangle 1621" width="22.25" height="22.25" fill="currentColor" />
      </clipPath>
    </defs>
    <g data-name="Group 3106">
      <path
        data-name="Path 1714"
        d="M19.925,4.4h-2.2a.859.859,0,1,0,0,1.717h2.2a.608.608,0,0,1,.608.608v9.533a.609.609,0,0,1-.608.609h-.733a.859.859,0,0,0-.859.858V19.32l-2.2-2.2a.851.851,0,0,0-.607-.252H8.192a.609.609,0,0,1-.609-.609.859.859,0,0,0-1.717,0,2.328,2.328,0,0,0,2.326,2.326h6.977L18.584,22a.863.863,0,0,0,.607.252.833.833,0,0,0,.329-.066.853.853,0,0,0,.53-.793v-2.81a2.329,2.329,0,0,0,2.2-2.323V6.725A2.328,2.328,0,0,0,19.925,4.4"
        fill="currentColor"
      />
      <path
        data-name="Path 1715"
        d="M14.059,14.184a2.328,2.328,0,0,0,2.325-2.325V2.325A2.328,2.328,0,0,0,14.059,0H2.326A2.328,2.328,0,0,0,0,2.325v9.534a2.329,2.329,0,0,0,2.2,2.322v2.811a.855.855,0,0,0,.528.793.842.842,0,0,0,.33.066.864.864,0,0,0,.606-.251l3.416-3.416Zm-11-1.717H2.326a.609.609,0,0,1-.609-.608V2.325a.609.609,0,0,1,.609-.608H14.059a.608.608,0,0,1,.608.608v9.534a.608.608,0,0,1-.608.608H6.726a.847.847,0,0,0-.607.252l-2.2,2.2v-1.6a.859.859,0,0,0-.858-.858"
        fill="currentColor"
      />
      <path
        data-name="Path 1716"
        d="M4.4,10.391a.859.859,0,0,0,.858.859,6.331,6.331,0,0,0,3.615-1.144,5.071,5.071,0,0,1,.355.644.857.857,0,0,0,.778.5h0a.857.857,0,0,0,.78-1.214,6.986,6.986,0,0,0-.627-1.093,7.569,7.569,0,0,0,1.821-4.967.858.858,0,1,0-1.716,0c0,.02,0,.038,0,.058H8.334c.015-.34.017-.681.017-.975a.859.859,0,0,0-1.717,0c0,.379-.007.691-.021.975H5.258a.858.858,0,0,0,0,1.716H6.274a4.279,4.279,0,0,1-.81,1.21A.859.859,0,0,0,6.1,8.4a2.886,2.886,0,0,1,1.559.448,4.6,4.6,0,0,1-2.4.686.858.858,0,0,0-.858.858m3.2-3.455A5.146,5.146,0,0,0,8.1,5.75H10A5.772,5.772,0,0,1,8.974,7.691a4.889,4.889,0,0,0-1.369-.755"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default SvgComponent;

import Router from 'next/router';

import { Icons } from '@app/components';
import { campaign as campaignLib, date, google as googleLib } from '@app/lib';
import type { BranchCampaignInsightResponse } from '@app/api';
import { useLocale } from '@app/hooks';

type CampaignInfoPropTypes = {
  campaign: BranchCampaignInsightResponse;
};

const CampaignInfo: React.FC<CampaignInfoPropTypes> = ({ campaign }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.CampaignInfo',
  };
  const { t } = useLocale();
  const foundedCampaignType = campaignLib.getCampaignType(campaign?.campaign);

  function handleEdit() {
    if (campaign) {
      const isGooglePlatform = googleLib.isPlatformGoogle(campaign?.campaign?.parent_campaign?.template);

      Router.push({
        pathname: isGooglePlatform ? '/campaigns/edit/google/[id]' : '/campaigns/edit/[id]',
        query: { id: campaign.campaign.id },
      });
    }
  }

  return (
    <div className="bg-white flex justify-center flex-col p-5 shadow-sm rounded-lg w-full">
      <div className="font-semibold text-md text-blue-500 mb-2">
        <span>{t('labels.campaignInfo', SCOPE_OPTIONS)}</span>
      </div>
      <div className="flex flex-col py-3.5 text-md">
        <div className="text-black-800 flex items-center space-x-2.5">
          <span>
            <Icons.InstagramVisit className="w-5 h-5" />
          </span>
          <span>{t('labels.campaignTarget', SCOPE_OPTIONS)}</span>
        </div>

        <span className="text-gray-500 pl-7.5 capitalize">{t(`campaignTypes.${foundedCampaignType}`)}</span>
      </div>
      <div className="flex flex-col py-3.5 text-md">
        <div className="text-black-800 flex items-center space-x-2.5">
          <span>
            <Icons.Date className="w-5 h-5" />
          </span>
          <span>{t('labels.startDate', SCOPE_OPTIONS)}</span>
        </div>

        <span className="text-gray-500 pl-7.5">
          {campaign?.campaign.start_time ? date.format(campaign?.campaign.start_time, 'dd/MM/yyyy / HH:mm') : '-'}
        </span>
      </div>
      <div className="flex flex-col py-3.5 text-md">
        <div className="text-black-800 flex items-center space-x-2.5">
          <span>
            <Icons.Date className="w-5 h-5" />
          </span>
          <span>{t('labels.endDate', SCOPE_OPTIONS)}</span>
        </div>
        <span className="text-gray-500 pl-7.5">
          {campaign?.campaign.stop_time ? date.format(campaign?.campaign.stop_time, 'dd/MM/yyyy / HH:mm') : '-'}
        </span>
      </div>
      <div className="flex items-center py-4 space-x-4">
        <button
          className="text-black-800 flex items-center border border-black-800 py-2 px-4.5 rounded-lg hover:bg-yellow-500 hover:text-white hover:border-yellow-600"
          onClick={handleEdit}
        >
          <span className="pr-1.5">
            <Icons.Pencil />
          </span>
          <span>{t('actions.update', SCOPE_OPTIONS)}</span>
        </button>
      </div>
    </div>
  );
};

export default CampaignInfo;

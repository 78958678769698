import React from 'react';

import { branch as branchLib } from '@app/lib';
import { INTEGRATION_TYPES } from '@app/constants';
import { useAuth, useLocale } from '@app/hooks';
import { Icons, Integration } from '@app/components';

const Banner: React.FC = () => {
  const SCOPE_OPTIONS = {
    scope: 'components.Integration.Banner',
  };
  const { t } = useLocale();
  const { branch } = useAuth();

  const bannerDataList = [
    // {
    //   Icon: <Icons.Google className="w-8 h-8" />,
    //   integrationType: INTEGRATION_TYPES.GOOGLE,
    //   description: t('labels.googleDesc', SCOPE_OPTIONS),
    // },
    {
      Icon: <Icons.FacebookIcon className="w-8 h-8 text-blue-500" />,
      integrationType: INTEGRATION_TYPES.FACEBOOK,
      description: t('labels.facebookDesc', SCOPE_OPTIONS),
    },
    {
      Icon: <Icons.DangerCircle className="w-8 h-8 text-[#D85858]" />,
      integrationType: INTEGRATION_TYPES.ALL,
      description: <span dangerouslySetInnerHTML={{ __html: t('labels.allDesc', SCOPE_OPTIONS) }}></span>,
    },
  ];

  function handleIntegration() {
    const facebookIntegration = branchLib.integration(branch);
    const googleIntegration = branchLib.googleIntegration(branch);

    if (!facebookIntegration && googleIntegration) {
      return bannerDataList?.find((item) => item.integrationType === INTEGRATION_TYPES.FACEBOOK);
    }

    // if (!googleIntegration && facebookIntegration) {
    //   return bannerDataList?.find((item) => item.integrationType === INTEGRATION_TYPES.GOOGLE);
    // }

    if (!facebookIntegration && !googleIntegration) {
      return bannerDataList?.find((item) => item.integrationType === INTEGRATION_TYPES.ALL);
    }

    if (!facebookIntegration) {
      return bannerDataList?.find((item) => item.integrationType === INTEGRATION_TYPES.FACEBOOK);
    }

    return;
  }

  const bannerData = handleIntegration();

  if (!bannerData) return null;
  return (
    <div className="px-6 h-16 bg-[#FCECEC] flex flex-row items-center space-x-2">
      {bannerData?.Icon}
      <div className="flex justify-between items-center w-full">
        <div className="flex items-center">
          <span className="text-4">{bannerData?.description}</span>
        </div>
        <div className="min-w-47">
          <Integration.Select integration={bannerData?.integrationType} />
        </div>
      </div>
    </div>
  );
};

export default Banner;

/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BranchUserPasswordErrorResponse } from '../model';
// @ts-ignore
import { BranchUserPasswordSuccessResponse } from '../model';
// @ts-ignore
import { BranchUserResetPasswordInput } from '../model';
// @ts-ignore
import { BranchUserUpdatePasswordInput } from '../model';
/**
 * PasswordBranchUserApi - axios parameter creator
 * @export
 */
export const PasswordBranchUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Send an email about resetting password
         * @summary Send an email about resetting password
         * @param {BranchUserResetPasswordInput} branchUserResetPasswordInput Send an email about resetting password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        branchUserResetPassword: async (branchUserResetPasswordInput: BranchUserResetPasswordInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchUserResetPasswordInput' is not null or undefined
            assertParamExists('branchUserResetPassword', 'branchUserResetPasswordInput', branchUserResetPasswordInput)
            const localVarPath = `/branch_user/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(branchUserResetPasswordInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update password of branch user
         * @summary Update password
         * @param {BranchUserUpdatePasswordInput} branchUserUpdatePasswordInput Send an email about resetting password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        branchUserUpdatePassword: async (branchUserUpdatePasswordInput: BranchUserUpdatePasswordInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchUserUpdatePasswordInput' is not null or undefined
            assertParamExists('branchUserUpdatePassword', 'branchUserUpdatePasswordInput', branchUserUpdatePasswordInput)
            const localVarPath = `/branch_user/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(branchUserUpdatePasswordInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PasswordBranchUserApi - functional programming interface
 * @export
 */
export const PasswordBranchUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PasswordBranchUserApiAxiosParamCreator(configuration)
    return {
        /**
         * Send an email about resetting password
         * @summary Send an email about resetting password
         * @param {BranchUserResetPasswordInput} branchUserResetPasswordInput Send an email about resetting password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async branchUserResetPassword(branchUserResetPasswordInput: BranchUserResetPasswordInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchUserPasswordSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.branchUserResetPassword(branchUserResetPasswordInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update password of branch user
         * @summary Update password
         * @param {BranchUserUpdatePasswordInput} branchUserUpdatePasswordInput Send an email about resetting password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async branchUserUpdatePassword(branchUserUpdatePasswordInput: BranchUserUpdatePasswordInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchUserPasswordSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.branchUserUpdatePassword(branchUserUpdatePasswordInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PasswordBranchUserApi - factory interface
 * @export
 */
export const PasswordBranchUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PasswordBranchUserApiFp(configuration)
    return {
        /**
         * Send an email about resetting password
         * @summary Send an email about resetting password
         * @param {BranchUserResetPasswordInput} branchUserResetPasswordInput Send an email about resetting password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        branchUserResetPassword(branchUserResetPasswordInput: BranchUserResetPasswordInput, options?: any): AxiosPromise<BranchUserPasswordSuccessResponse> {
            return localVarFp.branchUserResetPassword(branchUserResetPasswordInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Update password of branch user
         * @summary Update password
         * @param {BranchUserUpdatePasswordInput} branchUserUpdatePasswordInput Send an email about resetting password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        branchUserUpdatePassword(branchUserUpdatePasswordInput: BranchUserUpdatePasswordInput, options?: any): AxiosPromise<BranchUserPasswordSuccessResponse> {
            return localVarFp.branchUserUpdatePassword(branchUserUpdatePasswordInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PasswordBranchUserApi - interface
 * @export
 * @interface PasswordBranchUserApi
 */
export interface PasswordBranchUserApiInterface {
    /**
     * Send an email about resetting password
     * @summary Send an email about resetting password
     * @param {BranchUserResetPasswordInput} branchUserResetPasswordInput Send an email about resetting password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordBranchUserApiInterface
     */
    branchUserResetPassword(branchUserResetPasswordInput: BranchUserResetPasswordInput, options?: any): AxiosPromise<BranchUserPasswordSuccessResponse>;

    /**
     * Update password of branch user
     * @summary Update password
     * @param {BranchUserUpdatePasswordInput} branchUserUpdatePasswordInput Send an email about resetting password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordBranchUserApiInterface
     */
    branchUserUpdatePassword(branchUserUpdatePasswordInput: BranchUserUpdatePasswordInput, options?: any): AxiosPromise<BranchUserPasswordSuccessResponse>;

}

/**
 * PasswordBranchUserApi - object-oriented interface
 * @export
 * @class PasswordBranchUserApi
 * @extends {BaseAPI}
 */
export class PasswordBranchUserApi extends BaseAPI implements PasswordBranchUserApiInterface {
    /**
     * Send an email about resetting password
     * @summary Send an email about resetting password
     * @param {BranchUserResetPasswordInput} branchUserResetPasswordInput Send an email about resetting password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordBranchUserApi
     */
    public branchUserResetPassword(branchUserResetPasswordInput: BranchUserResetPasswordInput, options?: any) {
        return PasswordBranchUserApiFp(this.configuration).branchUserResetPassword(branchUserResetPasswordInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update password of branch user
     * @summary Update password
     * @param {BranchUserUpdatePasswordInput} branchUserUpdatePasswordInput Send an email about resetting password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordBranchUserApi
     */
    public branchUserUpdatePassword(branchUserUpdatePasswordInput: BranchUserUpdatePasswordInput, options?: any) {
        return PasswordBranchUserApiFp(this.configuration).branchUserUpdatePassword(branchUserUpdatePasswordInput, options).then((request) => request(this.axios, this.basePath));
    }
}

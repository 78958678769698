import React, { useState } from 'react';
import Router from 'next/router';

import { useForm } from 'react-hook-form';
import OTPInput from 'react-otp-input';
import cn from 'classnames';
import Countdown, { zeroPad } from 'react-countdown';

import Button from 'components/Button';
import Form from 'components/Form';
import { api, useAuth, useLocale } from '@app/hooks';
import { phoneNumber } from '@app/lib';
import Icons from 'components/Icons';

const LoginWithOtp: React.FC = () => {
  const SCOPE_OPTIONS = {
    scope: 'pages.login',
  };
  const [errors, setErrors] = useState<Array<string>>([]);
  const { t, locale, locales } = useLocale();
  const [step, setStep] = useState(1);
  const [response, setResponse] = useState<any>({});
  const [otp, setOtp] = useState('');
  const { storeToken } = useAuth();
  const { control, handleSubmit, getValues } = useForm({ reValidateMode: 'onChange' });

  const { mutate: sendOtp, isLoading: sendLoading } = api.useSendOtpToBranchUserV2({
    onSuccess: (data: any) => {
      setResponse(data);
      setStep(2);
    },
    onError: (data: any) => {
      setErrors(data.response.data.errors || []);
      return;
    },
  });

  const { mutate: checkOtp, isLoading: checkLoading } = api.useCheckOtpToBranchUserV2({
    onSuccess: (data: any) => {
      storeToken(data);

      Router.push('/gateway');
    },
    onError: (data: any) => {
      setErrors(data.response.data.errors || []);
      return;
    },
  });

  function handleSendOtp(data: { [x: string]: string | number }) {
    setErrors([]);
    sendOtp({
      phoneNumber: data.phoneNumber as string,
    });
  }

  function handleCheckOtp() {
    setErrors([]);
    checkOtp({
      phoneNumber: response.msisdn,
      reference: response.reference,
      code: otp,
    });
  }

  const start = React.useMemo(() => {
    if (response.timeout) {
      return Date.now() + response.timeout * 1000;
    } else {
      return Date.now();
    }
  }, [response]);

  return (
    <>
      {step === 1 ? (
        <form onSubmit={handleSubmit(handleSendOtp)}>
          <div className="text-center mb-10">
            <h2 className="text-3xl font-semibold text-gray-900">{t('labels.title', SCOPE_OPTIONS)}</h2>
            <p className="text-sm text-gray-500 mt-1">{t('labels.description', SCOPE_OPTIONS)}</p>
          </div>
          {errors.map((error: string, index: number) => (
            <div
              key={index}
              className="flex items-center justify-between gap-4 bg-[#E53030] border border-red-400 p-2 rounded-2 mb-4 px-4 py-4"
            >
              <div className="w-6 h-6 bg-white flex-shrink-0 rotate-45 rounded-1 flex justify-center items-center">
                <span className="text-[#E53030] -rotate-45">!</span>
              </div>
              <p className="text-3.5 text-white text-bold text-left w-full">
                <span>{error}</span>
              </p>
            </div>
          ))}
          <Form.PhoneInput
            onlyCountries={locales.map((locale) => locale.key)}
            country={locale}
            id="phoneNumber"
            name="phoneNumber"
            control={control}
          />
          <Button
            disabled={sendLoading}
            type="submit"
            theme="blue"
            className="mt-8 disabled:opacity-50 disabled:cursor-not-allowed"
            label={t('form.submit', SCOPE_OPTIONS)}
          />
        </form>
      ) : (
        <div className="flex flex-col gap-6">
          <button className="w-10 p-2 bg-gray-300 flex items-center justify-center rounded-1" onClick={() => setStep(1)}>
            <Icons.ChevronLeft width={25} />
          </button>
          <form onSubmit={handleSubmit(handleCheckOtp)}>
            <div className="text-left mb-10">
              <h2 className="text-3xl font-semibold text-gray-900 mb-5">{t('labels.fewSteps', SCOPE_OPTIONS)}</h2>
              <div className="flex gap-2 items-center">
                <div className="w-14">
                  <img alt="" className="block w-full" src="/phone.png" />
                </div>
                <p className="text-sm text-gray-500 mt-1">
                  {t('labels.enterCode', { ...SCOPE_OPTIONS, number: phoneNumber.maskPhoneNumber(getValues('phoneNumber')) })}
                </p>
              </div>
            </div>
            {errors.map((error: string, index: number) => (
              <div
                key={index}
                className="flex items-center justify-between gap-4 bg-[#E53030] border border-red-400 p-2 rounded-2 mb-4 px-4 py-4"
              >
                <div className="w-6 h-6 bg-white flex-shrink-0 rotate-45 rounded-1 flex justify-center items-center">
                  <span className="text-[#E53030] -rotate-45">!</span>
                </div>
                <p className="text-3.5 text-white text-bold text-left w-full">
                  <span>{error}</span>
                </p>
              </div>
            ))}
            <div className="flex items-center justify-between mb-2">
              <span className="text-gray-600">{t('labels.verificationCode', SCOPE_OPTIONS)}</span>
              <Countdown date={start} autoStart renderer={renderer} />
            </div>
            <OTPInput
              containerStyle="gap-2"
              inputStyle="h-16 bg-[#EEEEEE] focus:bg-white border-transparent border-1 rounded-2 outline-none focus:border-blue-300"
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderInput={(props) => <input {...props} />}
            />
            <Button
              disabled={checkLoading}
              type="submit"
              theme="blue"
              className="mt-8 disabled:opacity-50 disabled:cursor-not-allowed"
              label={t('form.submit', SCOPE_OPTIONS)}
            />
          </form>
          <button
            disabled={sendLoading}
            className="w-full text-center disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() => handleSendOtp({ phoneNumber: getValues('phoneNumber') })}
          >
            {t('labels.resendCode', SCOPE_OPTIONS)}
          </button>
        </div>
      )}
    </>
  );
};

const renderer = ({ minutes, seconds, completed }: any) => {
  return (
    <span className={cn('font-bold', { 'text-[#3067F4]': !completed, 'text-red-600': completed })}>
      {zeroPad(minutes)}:{zeroPad(seconds)}
    </span>
  );
};

export default LoginWithOtp;

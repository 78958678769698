import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17.76" height="17.759" viewBox="0 0 17.76 17.759" {...props}>
    <g id="Group_4451" data-name="Group 4451" transform="translate(-998 -458.824)">
      <path
        id="Path_1505"
        data-name="Path 1505"
        d="M8.879,0A8.879,8.879,0,1,0,17.76,8.879,8.89,8.89,0,0,0,8.879,0m7.252,8.879A7.252,7.252,0,1,1,8.879,1.627a7.259,7.259,0,0,1,7.252,7.252"
        transform="translate(998 458.824)"
        fill="currentColor"
      />
      <path
        id="Path_1506"
        data-name="Path 1506"
        d="M10.53,8.665a5.4,5.4,0,0,0-1.383-.624,2.913,2.913,0,0,1-1.033-.5.7.7,0,0,1-.225-.562A.8.8,0,0,1,8.1,6.362a1,1,0,0,1,.705-.215.8.8,0,0,1,.666.293,1.447,1.447,0,0,1,.27.932v.4h1.783v-.4a3.035,3.035,0,0,0-.591-1.952,2.383,2.383,0,0,0-1.425-.827V3.371H7.884V4.6a2.621,2.621,0,0,0-1.165.711A2.4,2.4,0,0,0,6.1,7a2.335,2.335,0,0,0,.633,1.672,4.134,4.134,0,0,0,1.835,1,2.7,2.7,0,0,1,1.018.51.689.689,0,0,1,.225.529.718.718,0,0,1-.26.6,1.322,1.322,0,0,1-.855.241,1.123,1.123,0,0,1-.831-.279,1.155,1.155,0,0,1-.277-.847v-.4H5.8v.4a2.714,2.714,0,0,0,.673,1.921,2.633,2.633,0,0,0,1.413.771v1.061H9.513v-1.06a2.687,2.687,0,0,0,1.423-.7A2.325,2.325,0,0,0,11.6,10.7a2.474,2.474,0,0,0-.263-1.158,2.418,2.418,0,0,0-.805-.876"
        transform="translate(998.181 458.93)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default SvgComponent;

import React from 'react';

import cn from 'classnames';

import { useLocale } from '@app/hooks';
import { Icons, LoaderSkeleton } from '@app/components';
import { SCREEN_MODES } from '@app/constants';

type InstagramPropTypes = {
  accountName: string;
  description: string;
  image: string;
  screen: string;
};

const Instagram: React.FC<InstagramPropTypes> = ({ accountName, image, description, screen }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.SocialMediaManagement.PostPreview.Instagram',
  };
  const { t } = useLocale();

  return (
    <div className="bg-white select-none p-2 w-full">
      <div className="flex flex-row h-12">
        <div className="flex w-10 rounded-full justify-center border-none self-center aspect-square">
          <Icons.InstagramIcon className="w-8 h-8 " />
        </div>

        <span className="flex flex-row justify-between w-full">
          <div className="flex flex-col ml-2 align-center self-center">
            <span className="font-bold text-3.5 text-gray-900 capitalize">{accountName}</span>
            {screen === SCREEN_MODES.MOBILE && (
              <span className="leading-none text-gray-500 text-2.5 capitalize">{t('labels.sponsor', SCOPE_OPTIONS)}</span>
            )}
          </div>
        </span>
      </div>

      <div className="flex my-1 justify-center">
        {image ? (
          <img src={image} alt="feed_image" className={cn({ 'object-cover': screen === SCREEN_MODES.MOBILE })} height="100%" />
        ) : (
          <LoaderSkeleton.SkeletonLoaderFeed />
        )}
      </div>

      <div className="pb-3 px-2 mt-3 flex flex-row justify-between w-full">
        <div className="flex flex-row">
          <div className="mr-3">
            <span className="leading-none text-gray-700 text-3.5 font-medium ">
              <Icons.InstagramLike className="w-5 h-5" />
            </span>
          </div>
          <div className="mr-3">
            <span className="leading-none text-gray-700 text-3.5 ">
              <Icons.InstagramComment className="w-5 h-5" />
            </span>
          </div>
          <div>
            <span className="leading-none text-gray-700 text-3.5">
              <Icons.InstagramShare className="w-5 h-5" />
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <Icons.InstagramSave className="w-5 h-5" />
        </div>
      </div>
      <div className="flex flex-row items-center justify-between py-2 border-gray-300 h-auto">
        <div className="h-auto line-clamp-2 text-black-900 text-3">{description}</div>
      </div>
    </div>
  );
};

export default Instagram;

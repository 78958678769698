import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" fill="none" height="48" viewBox="0 0 48 48" {...props}>
    <path
      fill="currentColor"
      d="M36 5c-3.848 0-7 3.152-7 7 0 .586.199 1.11.336 1.654l-11.99 5.998C16.059 18.074 14.182 17 12 17c-3.848 0-7 3.152-7 7s3.152 7 7 7c2.182 0 4.06-1.074 5.346-2.652l11.99 5.998C29.199 34.89 29 35.414 29 36c0 3.848 3.152 7 7 7s7-3.152 7-7-3.152-7-7-7c-2.182 0-4.06 1.074-5.346 2.652l-11.99-5.998C18.801 25.11 19 24.586 19 24c0-.586-.199-1.11-.336-1.654l11.99-5.998C31.941 17.926 33.818 19 36 19c3.848 0 7-3.152 7-7s-3.152-7-7-7zm0 3c2.227 0 4 1.773 4 4 0 2.227-1.773 4-4 4-2.227 0-4-1.773-4-4 0-2.227 1.773-4 4-4zM12 20c2.227 0 4 1.773 4 4 0 2.227-1.773 4-4 4-2.227 0-4-1.773-4-4 0-2.227 1.773-4 4-4zm24 12c2.227 0 4 1.773 4 4 0 2.227-1.773 4-4 4-2.227 0-4-1.773-4-4 0-2.227 1.773-4 4-4z"
    ></path>
  </svg>
);

export default SvgComponent;

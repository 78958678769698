import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { CampaignsBranchApi } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function showBranchCampaigns(branchId: string, campaignId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new CampaignsBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.showBranchCampaigns(branchId, campaignId).then((res) => res.data);
}

export default function useShowBranchCampaigns({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useQuery(
    ['useShowBranchCampaigns', branchId, params.campaignId],
    () => showBranchCampaigns(branchId, params.campaignId, axiosInstance),
    props
  );
}

import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { WalletBranchV2Api } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function getUserWallet(branchId: string, hasCentricPayment: boolean | undefined, axiosInstance: AxiosInstance) {
  if (!hasCentricPayment) {
    return;
  }
  const api = new WalletBranchV2Api(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);
  return api.getBranchWallet(branchId);
}

export default function useGetUserWallet() {
  const { api: axiosInstance } = useAxios();
  const { branchId, branch } = useAuth();
  const hasCentricPayment = branch?.company?.feature_flags?.has_centric_payment_feature;
  return useQuery(['wallet', branchId], () => getUserWallet(branchId, hasCentricPayment, axiosInstance));
}

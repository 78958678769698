import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="Group_6"
    data-name="Group 6"
    xmlns="http://www.w3.org/2000/svg"
    width="32.828"
    height="24.597"
    viewBox="0 0 32.828 24.597"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_12" data-name="Rectangle 12" width="32.828" height="24.597" fill="currentColor" />
      </clipPath>
    </defs>
    <g id="Group_5" data-name="Group 5" clipPath="url(#clip-path)">
      <path
        id="Path_4"
        data-name="Path 4"
        d="M0,122.475V107.411a.883.883,0,0,1,.143.063l13.322,8.884a4.943,4.943,0,0,0,5.868.018q6.584-4.384,13.163-8.774c.094-.062.19-.121.326-.207v.261q0,7.179,0,14.358a3.285,3.285,0,0,1-.1.791,3.023,3.023,0,0,1-3.089,2.287q-6.554,0-13.108,0-6.666,0-13.332,0a3.16,3.16,0,0,1-2.274-.861A3.394,3.394,0,0,1,0,122.475"
        transform="translate(0 -100.496)"
        fill="currentColor"
      />
      <path
        id="Path_5"
        data-name="Path 5"
        d="M0,2.62c.053-.183.1-.368.159-.549A3.034,3.034,0,0,1,3.066,0C3.7,0,4.326,0,4.956,0Q17.279,0,29.6,0a3.1,3.1,0,0,1,2.435,1.007,2.911,2.911,0,0,1-.508,4.422c-1.995,1.357-4.011,2.682-6.018,4.021l-7.168,4.779a3.054,3.054,0,0,1-3.859,0C10.274,11.422,6.071,8.6,1.846,5.819A3.712,3.712,0,0,1,0,3.453V2.62"
        transform="translate(0 0)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default SvgComponent;

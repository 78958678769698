import { ANALYTICS_CONTENT, ANALYTICS_TOTAL_CONTENT } from '@app/constants';

function percent(value: number, key?: string, locale?: string) {
  if (key === ANALYTICS_TOTAL_CONTENT.UP.IMPRESSION.KEY || key === ANALYTICS_TOTAL_CONTENT.UP.SPEND.KEY) {
    return Intl.NumberFormat(locale).format(value);
  }

  return Intl.NumberFormat(locale, { style: 'percent' }).format(value);
}

function number(
  value: number,
  options: { isCompact?: boolean; precision?: number; locale?: string } = { isCompact: false, precision: 2, locale: 'tr' }
) {
  return new Intl.NumberFormat(options.locale, {
    notation: options.isCompact ? 'compact' : 'standard',
    compactDisplay: 'short',
    maximumFractionDigits: options.precision,
  }).format(value);
}

function formatedNumberToNumber(value: string) {
  return value.replaceAll(',', '').replaceAll('.', '');
}

function money(
  value: number | string,
  currency: string,
  options: { isCompact?: boolean; precision?: number; locale?: string } = { isCompact: false, precision: 2, locale: 'en' }
) {
  return new Intl.NumberFormat(options.locale, {
    notation: options.isCompact ? 'compact' : 'standard',
    compactDisplay: 'short',
    style: 'currency',
    currency: currency,
    maximumFractionDigits: options.precision,
  }).format(value as number);
}

function data(value: string | number | undefined, currency = 'TRY', key?: string, locale = 'tr') {
  if (Number.isNaN(value)) {
    return '-';
  }

  const handledCount = Math.trunc(Number(value) * 100) / 100;

  if (key === ANALYTICS_CONTENT.COST.KEY || key === ANALYTICS_CONTENT.CPC.KEY) {
    return money(handledCount, currency, { isCompact: handledCount > 1000, precision: handledCount < 0 ? 3 : 2, locale: locale });
  }

  if (handledCount > 999) {
    return number(handledCount, { isCompact: true, precision: 0, locale: locale });
  }

  return Number(handledCount.toFixed(5));
}

function summary(value: string | number | undefined, currency = 'TRY', key?: string) {
  if (Number.isNaN(value)) {
    return '-';
  }

  const handledCount = Number(value);

  if (key === ANALYTICS_CONTENT.COST.KEY || key === ANALYTICS_CONTENT.CPC.KEY) {
    return money(handledCount, currency, { isCompact: handledCount > 1000, precision: handledCount < 0 ? 3 : 2 });
  }

  if (handledCount > 1000) {
    return number(handledCount, { isCompact: true, precision: 6 });
  }

  return Number(handledCount.toFixed(2));
}

export default { percent, number, money, formatedNumberToNumber, data, summary };

import parsePhoneNumber from 'libphonenumber-js';

function format(phoneNumberString: string): string {
  const phoneNumberText = `+${phoneNumberString.replaceAll('+', '')}`;
  const phoneNumber = parsePhoneNumber(phoneNumberText);

  if (phoneNumber?.isValid()) {
    const formatedPhoneNumber = phoneNumber.formatInternational();

    return formatedPhoneNumber;
  }

  return phoneNumberText;
}

function isValid(phoneNumberString: string) {
  const phoneNumber = parsePhoneNumber(phoneNumberString);

  if (phoneNumber?.isValid()) {
    return true;
  }

  return false;
}

function national(phoneNumberString: string): string | null {
  const phoneNumber = parsePhoneNumber(phoneNumberString);

  if (phoneNumber?.isValid()) {
    return phoneNumber.formatNational();
  }

  return null;
}

function uri(phoneNumberString: string) {
  const phoneNumber = parsePhoneNumber(phoneNumberString);

  if (phoneNumber?.isValid()) {
    return phoneNumber.getURI();
  }

  return null;
}

function maskPhoneNumber(phoneNumberString: string) {
  if (phoneNumberString === '' || phoneNumberString === null || typeof phoneNumberString === 'undefined') {
    return '';
  }

  const phoneNumber = parsePhoneNumber(phoneNumberString);
  const countryCode = phoneNumber?.countryCallingCode as string;
  const length = phoneNumber?.number.length as number;
  const lastTwoDigits = phoneNumber?.number.slice(-2);
  const maskedPart = '*'.repeat(length - 2 - countryCode?.length - 1);

  return '+' + countryCode + maskedPart + lastTwoDigits;
}

export default {
  format,
  isValid,
  uri,
  national,
  maskPhoneNumber,
};

import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { OmniauthCallbackBranchApi } from '@app/api';
import type { FacebookCreateInput } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function callFacebookOAuthCallbackForBranch(
  branchId: string,
  facebookCreateInput: FacebookCreateInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new OmniauthCallbackBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.callFacebookOAuthCallbackForBranch(branchId, facebookCreateInput).then((res) => res.data);
}

export default function useCallFacebookOAuthCallbackForBranch(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useMutation((values: FacebookCreateInput) => callFacebookOAuthCallbackForBranch(branchId, values, axiosInstance), props);
}

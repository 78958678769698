import dynamic from 'next/dynamic';

const AdAccount = dynamic(() => import('./AdAccount'), {
  ssr: false,
});
import Content from './Content';
const PaymentMethod = dynamic(() => import('./PaymentMethod'), {
  ssr: false,
});
import ProfileInfo from './ProfileInfo';
import Questions from './Questions';
import SelectFacebookPage from './SelectFacebookPage';
import SelectInstagramPage from './SelectInstagramPage';
import Sidebar from './Sidebar';
import Video from './Video';

export default {
  AdAccount,
  Content,
  PaymentMethod,
  ProfileInfo,
  Questions,
  SelectFacebookPage,
  SelectInstagramPage,
  Sidebar,
  Video,
};

import React from 'react';

import { useFormContext } from 'react-hook-form';

import { GOOGLE_PREVIEW_TYPES, GOOGLE_TEXT_TYPE } from '@app/constants';

import Search from './Search';
import Maps from './Maps';
import { GoogleLocationsResponseStorefrontAddress } from 'api/model';

type ContentPropsType = {
  placementType: typeof GOOGLE_PREVIEW_TYPES[keyof typeof GOOGLE_PREVIEW_TYPES];
};

const Content: React.FC<ContentPropsType> = ({ placementType }) => {
  const { getValues, watch } = useFormContext();

  const headlines = getValues(`creative.${String(GOOGLE_TEXT_TYPE.HEADLINE).toLowerCase()}_0`) || [];
  const descriptions = getValues(`creative.${String(GOOGLE_TEXT_TYPE.DESCRIPTION).toLowerCase()}_0`) || [];
  const headline = headlines instanceof Array ? headlines.slice(0, 3).join(' | ') : headlines;
  const description = descriptions instanceof Array ? descriptions.slice(0, 3).join('. ') : descriptions;
  const latlng = watch(`creative.business_location`);
  const address = watch(`creative.business_location`)?.storefrontAddress;

  const fullAddress = handleFullAddress(address);

  function handleFullAddress(addressObject: GoogleLocationsResponseStorefrontAddress) {
    if (!addressObject) {
      return;
    }
    return `${addressObject?.addressLines} ${addressObject?.postalCode} ${addressObject?.administrativeArea} ${addressObject?.locality}`.trim();
  }

  return (
    <div className="bg-white h-full pt-3">
      <div className="flex justify-center h-full px-6">
        {placementType === GOOGLE_PREVIEW_TYPES.SEARCH && <Search headline={headline} description={description} />}
        {placementType === GOOGLE_PREVIEW_TYPES.MAPS && <Maps latlng={latlng} fullAddress={fullAddress} />}
      </div>
    </div>
  );
};

export default Content;

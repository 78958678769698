import React from 'react';

import cn from 'classnames';

type ButtonPropTypes = {
  id?: string;
  type?: 'submit' | 'button';
  theme: 'blue' | 'green' | 'yellow' | 'gray' | 'red' | 'link' | 'outline';
  label?: string;
  className?: string;
  labelClassName?: string;
  iconClassName?: string;
  onClick?: () => void;
  disabled?: boolean;
  IconLeft?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  IconRight?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  block?: boolean;
  size?: 'xs' | 'sm' | 'md';
  href?: string;
  element?: 'div' | 'span' | 'button' | 'a';
};

const Button: React.FC<ButtonPropTypes> = ({
  className,
  theme,
  label,
  disabled = false,
  IconLeft,
  block = true,
  IconRight,
  size = 'md',
  href,
  element,
  ...props
}) => {
  const ElementTag = getElementTag();

  function getElementTag() {
    if (element) {
      return element;
    }

    if (href) {
      return 'a';
    }

    return 'button';
  }

  return (
    <ElementTag
      href={href}
      className={cn(
        'group relative flex justify-center items-center border border-transparent transition-colors focus:outline-none leading-none',
        {
          'text-white bg-red-400 hover:bg-red-500': 'red' === theme,
          'text-white bg-blue-300 hover:bg-blue-400': 'blue' === theme,
          'text-blue-300 hover:text-blue-400 underline': 'outline' === theme,
          'text-white bg-green-500 hover:bg-green-400': 'green' === theme,
          'text-white bg-yellow-500 hover:bg-yellow-600': 'yellow' === theme,
          'text-white bg-gray-500 hover:bg-gray-600': 'gray' === theme,
          'text-gray-500 hover:text-gray-700 underline': 'link' === theme,
          'w-full': block === true,
          'inline-flex': block === false,
          'px-4 py-1.5 text-3.5 font-semibold rounded-2': size === 'xs',
          'px-4 py-2.5 text-3.5 font-semibold rounded-3': size === 'sm',
          'px-4 py-3.5 text-3.5 font-semibold rounded-3': size === 'md',
        },
        className
      )}
      disabled={disabled}
      {...props}
    >
      {IconLeft && (
        <span className="inset-y-0 flex items-center pr-2.5">
          <IconLeft className="h-5 w-5" aria-hidden="true" />
        </span>
      )}
      <span className="inline">{label}</span>
      {IconRight && (
        <span className="inset-y-0 flex items-center pl-2.5">
          <IconRight className="h-4 w-4 text-white" aria-hidden="true" />
        </span>
      )}
    </ElementTag>
  );
};

export default Button;

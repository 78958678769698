import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
    <circle cx="12" cy="12" r="10" stroke="#1C274C" strokeWidth="1.5"></circle>
    <path
      stroke="#1C274C"
      strokeLinecap="round"
      strokeWidth="1.5"
      d="M10.125 8.875a1.875 1.875 0 112.828 1.615c-.475.281-.953.708-.953 1.26V13"
    ></path>
    <circle cx="12" cy="16" r="1" fill="#1C274C"></circle>
  </svg>
);

export default SvgComponent;

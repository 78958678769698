import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { FacebookBranchApi } from '@app/api';
import type { FacebookGeneratePreviewInput } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function generateBranchFacebookPreview(
  branchId: string,
  facebookGeneratePreviewInput: FacebookGeneratePreviewInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new FacebookBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.generateBranchFacebookPreview(branchId, facebookGeneratePreviewInput).then((res) => res.data);
}

export default function useGenerateBranchFacebookPreview({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios({
    automaticToast: false,
  });
  const { branchId } = useAuth();

  return useQuery(
    ['useGenerateBranchFacebookPreview', branchId, params.creative],
    () => generateBranchFacebookPreview(branchId, { creative: params.creative }, axiosInstance),
    props
  );
}

import React from 'react';

import DatePicker from 'react-datepicker';
import { enUS, tr } from 'date-fns/locale';

import { useLocale } from '@app/hooks';
import CustomDateInput from './CustomDateInput';

type DateFilterPropTypes = {
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
};

const DateFilter: React.FC<DateFilterPropTypes> = ({ startDate, endDate, setStartDate, setEndDate }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.DateFilter',
  };
  const { t, locale } = useLocale();

  function getLocale() {
    if (locale === 'en') {
      return enUS;
    }
    if (locale === 'tr') {
      return tr;
    }

    return enUS;
  }

  return (
    <>
      <DatePicker
        dateFormat="d MMM yyyy"
        locale={getLocale()}
        selected={startDate}
        onChange={(date) => setStartDate(date as Date)}
        maxDate={endDate}
        isClearable
        withPortal={true}
        customInput={<CustomDateInput placeholderText={t('placeholders.startDate', SCOPE_OPTIONS)} />}
      />
      <DatePicker
        dateFormat="d MMM yyyy"
        locale={getLocale()}
        selected={endDate}
        onChange={(date) => setEndDate(date as Date)}
        minDate={startDate}
        isClearable
        withPortal={true}
        customInput={<CustomDateInput placeholderText={t('placeholders.endDate', SCOPE_OPTIONS)} />}
      />
    </>
  );
};

export default DateFilter;

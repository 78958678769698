import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { FacebookAccountBranchApi } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function getBranchFacebookPromotePages(branchId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new FacebookAccountBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.getBranchFacebookPromotePages(branchId).then((res) => res.data);
}

export default function useGetBranchFacebookPromotePages(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useQuery(['useGetBranchFacebookPromotePages'], () => getBranchFacebookPromotePages(branchId, axiosInstance), props);
}

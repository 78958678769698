import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { BranchesBranchUserApi } from '@app/api';
import type { BranchAccountBranchUpdateInput } from '@app/api';

import { useAxios } from '@app/hooks';

export function toggleAllowGmUpdateCampaigns(
  branchId: string,
  branchAccountBranchUpdateInput: BranchAccountBranchUpdateInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new BranchesBranchUserApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.toggleAllowGmUpdateCampaigns(branchId, branchAccountBranchUpdateInput).then((res) => res.data);
}

export default function useToggleAllowGmUpdateCampaigns(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { branchId: string; branch: BranchAccountBranchUpdateInput }) =>
      toggleAllowGmUpdateCampaigns(values.branchId, { branch: values.branch }, axiosInstance),
    props
  );
}

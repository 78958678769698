import * as React from 'react';

const Cancel = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="Group_3004"
    data-name="Group 3004"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="14.948"
    height="14.953"
    viewBox="0 0 14.948 14.953"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_1582" data-name="Rectangle 1582" width="14.948" height="14.953" fill="currentColor" />
      </clipPath>
    </defs>
    <g id="Group_3005" data-name="Group 3005">
      <path
        id="Path_1541"
        data-name="Path 1541"
        d="M12.762,2.187a7.479,7.479,0,1,0,0,10.576,7.486,7.486,0,0,0,0-10.576M10.736,2.9,2.9,10.736A5.62,5.62,0,0,1,10.736,2.9m1.314,1.314a5.62,5.62,0,0,1-7.839,7.839Z"
        transform="translate(0 0)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default Cancel;

import React, { useState } from 'react';

import { api, useAuth, useLocale, useToast } from '@app/hooks';
import { Facebook, Modal } from '@app/components';
import type { Branch as BranchType } from '@app/api';

const LoginModal = () => {
  const SCOPE_OPTIONS = {
    scope: 'components.LoginModal',
  };
  const { t } = useLocale();
  const toast = useToast();
  const { branchId, branch, storeBranch } = useAuth();
  const [openModal, setOpenModal] = useState<boolean>(branch?.force_open_facebook_login_modal ?? false);

  const { refetch } = api.useShowBranchUserBranches({
    enabled: false,
    params: {
      branchId: branchId,
    },
    onSuccess: (data: BranchType) => {
      storeBranch(data);
    },
  });

  const { mutate: markFacebookModalAsFalseMutate } = api.useMarkFacebookModalAsFalse({
    onSuccess: (data: { force_open_facebook_login_modal: boolean }) => {
      setOpenModal(data.force_open_facebook_login_modal);
      refetch();
    },
  });

  const { mutate: facebookExchangeTokenMutate } = api.useCallFacebookExchangeTokenForBranch({
    onSuccess: () => {
      markFacebookModalAsFalseMutate();
    },
  });

  function responseFacebook(response: FacebookLoginResponse) {
    if (response !== null) {
      const findAccount = response?.adaccounts?.data?.find((item) => item.id === branch.facebook_integration?.ad_account_id);
      if (findAccount) {
        if (
          findAccount.name === branch.facebook_integration?.ad_account_name &&
          findAccount.id === branch.facebook_integration.ad_account_id &&
          findAccount.currency === branch.facebook_integration.ad_account_currency
        ) {
          facebookExchangeTokenMutate({
            facebook: {
              access_token: response.accessToken,
            },
          });
        }
      } else {
        toast.error(t('messages.wrongAccount', SCOPE_OPTIONS));
      }
    }
  }

  return (
    <Modal isOpen={openModal} onClose={() => setOpenModal(openModal)}>
      <div className="grid grid-cols-1 relative h-90 px-5 py-5">
        <div className="items-center justify-center py-8 px-4 space-y-6 bg-gray-200 rounded-3">
          <div className="flex justify-center items-center mt-3">
            <span className="text-5.5 font-semibold text-black-800">{t('labels.title', SCOPE_OPTIONS)}</span>
          </div>
          <div className="flex justify-center items-center">
            <span className="text-3.5 text-slate-500 text-center w-122">{t('labels.description', SCOPE_OPTIONS)}</span>
          </div>
          <div className="flex justify-center pt-3">
            <Facebook.Login callback={responseFacebook} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal;

import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="#fff"
      strokeWidth="1.5"
      d="M2 11.538C2 7.912 2 6.1 3.172 4.973 4.343 3.846 6.229 3.846 10 3.846h4c3.771 0 5.657 0 6.828 1.127C22 6.099 22 7.913 22 11.538c0 3.627 0 5.44-1.172 6.566-1.171 1.127-3.057 1.127-6.828 1.127h-4c-3.771 0-5.657 0-6.828-1.127C2 16.978 2 15.164 2 11.538z"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth="1.5"
      d="M6 7.692l2.159 1.73C9.996 10.894 10.914 11.63 12 11.63c1.086 0 2.005-.736 3.841-2.208L18 7.692"
    ></path>
  </svg>
);

export default SvgComponent;

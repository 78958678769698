import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={76.254} height={92.013} fill="currentColor" {...props}>
    <defs>
      <clipPath id="a">
        <path data-name="Rectangle 1657" fill="#e6e6e6" d="M0 0h76.254v92.013H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 3326">
      <g data-name="Group 3281" clipPath="url(#a)" fill="#e6e6e6">
        <path
          data-name="Path 1768"
          d="M18.429.001A18.45 18.45 0 0 0 0 18.435V73.58a18.45 18.45 0 0 0 18.434 18.431h39.389a18.45 18.45 0 0 0 18.429-18.434l-.028-43.34a2.653 2.653 0 0 0-.824-1.946L45.858.72a2.889 2.889 0 0 0-1.8-.681l-1.286.02V.001Zm28.28 8.818L66.8 27.575H55.851a9.143 9.143 0 0 1-9.142-9.142Zm-1.1 19.854a14.4 14.4 0 0 0 10.241 4.25h15.056v40.659a13.088 13.088 0 0 1-13.083 13.081H18.429A13.092 13.092 0 0 1 5.348 73.582v-55.15A13.1 13.1 0 0 1 18.429 5.347h22.932v13.085a14.423 14.423 0 0 0 4.252 10.241"
        />
        <path
          data-name="Path 1769"
          d="M52.527 49.284a2.683 2.683 0 0 0-.694-2.582L40.016 34.885a2.749 2.749 0 0 0-3.781 0L24.42 46.703a2.676 2.676 0 0 0 0 3.784 2.745 2.745 0 0 0 3.779 0l7.257-7.257v17.181a2.674 2.674 0 1 0 5.348 0V43.23l7.252 7.257a2.677 2.677 0 0 0 4.475-1.2"
        />
        <path data-name="Path 1770" d="M51.916 70.91H24.339a2.673 2.673 0 0 0 0 5.346h27.577a2.673 2.673 0 1 0 0-5.346" />
      </g>
    </g>
  </svg>
);

export default SvgComponent;

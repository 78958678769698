import React from 'react';

import { branch as branchLib } from '@app/lib';

import { useRouter } from 'next/router';
import cn from 'classnames';

import { Icons } from '@app/components';
import type { BranchCompany } from '@app/api';

type LogoPropTypes = {
  miniSidebar?: boolean;
  company: BranchCompany;
};

const Logo: React.FC<LogoPropTypes> = ({ miniSidebar, company }) => {
  const router = useRouter();
  const logo = branchLib.logo(company);

  function handleLogoPress() {
    router.push('/');
  }

  return (
    <button type="button" onClick={handleLogoPress} className="border-0.5 border-blue-300 rounded-full">
      {logo?.thumbnailUrl ? (
        <img
          src={logo?.thumbnailUrl}
          className={cn('rounded-full', {
            'w-12 h-12': !miniSidebar,
            'w-14 h-14': miniSidebar,
          })}
          alt={company.name}
        />
      ) : (
        <div className="flex items-center justify-center transition-all object-cover rounded-full w-12 h-12">
          <Icons.Logo />
        </div>
      )}
    </button>
  );
};

export default Logo;

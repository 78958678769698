/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface V2SocialMediaPostsListResponseTargetingPlatforms
 */
export interface V2SocialMediaPostsListResponseTargetingPlatforms {
    /**
     * 
     * @type {string}
     * @memberof V2SocialMediaPostsListResponseTargetingPlatforms
     */
    platform?: V2SocialMediaPostsListResponseTargetingPlatformsPlatformEnum;
    /**
     * 
     * @type {string}
     * @memberof V2SocialMediaPostsListResponseTargetingPlatforms
     */
    media_type?: V2SocialMediaPostsListResponseTargetingPlatformsMediaTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof V2SocialMediaPostsListResponseTargetingPlatforms
     */
    object_url?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum V2SocialMediaPostsListResponseTargetingPlatformsPlatformEnum {
    Facebook = 'FACEBOOK',
    Instagram = 'INSTAGRAM'
}
/**
    * @export
    * @enum {string}
    */
export enum V2SocialMediaPostsListResponseTargetingPlatformsMediaTypeEnum {
    Feed = 'FEED',
    Story = 'STORY'
}




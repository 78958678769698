import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { FacebookAccountBranchApi } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function getBranchFacebookAccounts(branchId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new FacebookAccountBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.getBranchFacebookAccounts(branchId).then((res) => res.data);
}

export default function useGetBranchFacebookAccounts(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useQuery(['useGetBranchFacebookAccounts', branchId], () => getBranchFacebookAccounts(branchId, axiosInstance), props);
}

import React, { useState, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { Button, Form, Icons } from '@app/components';
import { api, useLocale, useDebounce } from '@app/hooks';
import { array } from '@app/lib';

import Browse from './Browse';

const DEBOUNCE_MS = 200;

type ItemType = {
  id: string;
  name: string;
  type: string;
  label: string;
  value: number;
};

type InterestInputPropTypes = {
  defaultValue?: ItemType[];
};

const InterestInput: React.FC<InterestInputPropTypes> = ({ defaultValue }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.InterestInput',
  };
  const { t } = useLocale();
  const [isBrowseOpen, setIsBrowseOpen] = useState(false);
  const [query, setQuery] = useState<string>('');
  const {
    formState: { errors },
    control,
    setValue,
    watch,
  } = useFormContext();
  const interestsWatch = watch('interests');
  const { debouncedValue: debouncedQuery } = useDebounce(query, DEBOUNCE_MS);
  const { data, refetch } = api.useListBranchFacebookInterests({
    enabled: false,
    params: {
      query: query,
    },
  });

  useEffect(() => {
    refetch();
  }, [debouncedQuery, refetch]);

  function handleDelete(interest: any) {
    const filteredInterest = interestsWatch.filter((item: ItemType) => item.value !== interest);
    setValue('interests', filteredInterest);
  }

  return (
    <div className="flex flex-col w-full mt-4">
      <div className="flex items-center justify-between mb-3.5">
        <label className="block text-3.5 font-semibold text-black-800">{t('form.interests.label', SCOPE_OPTIONS)}</label>

        <div className="flex flex-row items-center gap-4">
          {errors && (
            <div className="flex items-center">
              <span className="inline text-red-400 text-3 whitespace-nowrap">{errors.message}</span>
            </div>
          )}
        </div>
      </div>

      {interestsWatch && !array.isEmpty(interestsWatch) && (
        <div className="flex items-center flex-wrap gap-1.5 ">
          {interestsWatch.map((interest: ItemType, index: number) => (
            <div className="bg-gray-200 flex justify-between p-2 space-x-2 rounded-2" key={index}>
              <span key={index}>{interest.label}</span>
              <button onClick={() => handleDelete(interest.value)} key={index}>
                <Icons.XMark className="w-4 h-4" />
              </button>
            </div>
          ))}
        </div>
      )}
      <Form.Select
        right={
          <Button
            label={t('actions.browse', SCOPE_OPTIONS)}
            theme="outline"
            size="xs"
            onClick={() => setIsBrowseOpen(true)}
            disabled={isBrowseOpen}
            block={false}
            className="py-1 text-4 rounded-1 font-medium top-8 right-6 z-1"
          />
        }
        isMulti
        automaticHandleValue={false}
        onInputChange={setQuery}
        isSearchable
        isClearable={false}
        name="interests"
        placeholder={t('form.interests.placeholder', SCOPE_OPTIONS)}
        control={control}
        options={data?.interests
          ?.filter(function (interest: ItemType) {
            return (
              interestsWatch?.filter(function (item: { value: string }) {
                return item.value == interest.id;
              }).length == 0
            );
          })
          .map((interest: ItemType) => ({
            label: interest.name,
            value: interest.id,
            type: interest.type,
          }))}
        defaultValue={defaultValue?.map((interest: ItemType) => ({
          label: interest.name as string,
          value: interest.id as string,
          type: interest.type,
        }))}
        error={errors.interests}
        filterOptions={(options: ItemType) => {
          return options;
        }}
        controlShouldRenderValue={false}
        prefix
      />
      <Browse
        value={interestsWatch}
        onChange={(value) => setValue('interests', value, { shouldTouch: true, shouldValidate: true })}
        isOpen={isBrowseOpen}
        onClose={() => setIsBrowseOpen(false)}
      />
    </div>
  );
};

export default InterestInput;

import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22.39" height="20.966" viewBox="0 0 22.39 20.966" fill="currentColor" {...props}>
    <path
      id="Path_2659"
      data-name="Path 2659"
      d="M715.7,252.593h-4.342v-2.838a2.525,2.525,0,0,0-2.518-2.518H705.2a2.525,2.525,0,0,0-2.516,2.518v5.951h-4.343a2.522,2.522,0,0,0-2.516,2.517v7.463a2.521,2.521,0,0,0,2.516,2.517H715.7a2.524,2.524,0,0,0,2.516-2.517V255.112A2.523,2.523,0,0,0,715.7,252.593Zm.7,13.1a.7.7,0,0,1-.7.7h-4.342V254.406H715.7a.705.705,0,0,1,.7.706ZM704.5,249.755a.705.705,0,0,1,.7-.7h3.637a.707.707,0,0,1,.706.7v16.637H704.5Zm-6.859,8.47a.705.705,0,0,1,.7-.7h4.343v8.871h-4.343a.7.7,0,0,1-.7-.7Z"
      transform="translate(-695.827 -247.237)"
      fill="currentColor"
    />
  </svg>
);

export default SvgComponent;

import { useState, useEffect } from 'react';

import cn from 'classnames';
import Head from 'next/head';

import { Breadcrumb, Loader, Sidebar } from '@app/components';
import { useAuth } from '@app/hooks';
import Salesiq from 'components/Salesiq';
import Cookies from 'js-cookie';

type LayoutPropTypes = {
  isLoading?: boolean;
  containerClassName?: string;
  title: string;
  containerType?: 'boxed' | 'headerBoxed' | 'full';
  right?: JSX.Element;
  breadcrumbs?: string[];
};

const Layout: React.FC<LayoutPropTypes> = ({ children, containerClassName, isLoading, title, breadcrumbs }) => {
  const { isRefreshTokenLoading } = useAuth();
  const [isMiniSidebar, setIsMiniSidebar] = useState(false);
  const [messagingApplicationDataState, setMessagingApplicationDataState] = useState<any>({});
  const [branchDataState, setBranchDataState] = useState<any>({});

  useEffect(() => {
    const messagingApplicationData = localStorage.getItem('messagingApplication') || '{}';
    const branchData = Cookies.get('branchsight_branch_web_branch');

    setMessagingApplicationDataState(JSON.parse(messagingApplicationData));
    setBranchDataState(JSON.parse(branchData as string));
  }, []);

  return (
    <div className="min-h-screen flex flex-col">
      <Head>{title && <title>{`${title} | Branchsight`}</title>}</Head>

      {messagingApplicationDataState && (
        <Salesiq
          widgetCode={messagingApplicationDataState?.widget_id}
          domain={messagingApplicationDataState?.domain_url}
          language={messagingApplicationDataState?.language_code}
          branchData={branchDataState}
        />
      )}

      <Sidebar
        onCollapseClick={setIsMiniSidebar}
        miniSidebar={isMiniSidebar}
        className={cn({
          '!w-20': isMiniSidebar,
          '!w-80': !isMiniSidebar,
        })}
      />

      <Breadcrumb
        className={cn('transition-all sticky top-0 z-99', {
          'ml-20': isMiniSidebar,
          'ml-80': !isMiniSidebar,
        })}
        items={breadcrumbs ?? [title]}
      />

      <main
        className={cn('pl-6 pr-12 mt-4.5 transition-all overflow pb-6', containerClassName, {
          'ml-20': isMiniSidebar,
          'ml-80': !isMiniSidebar,
        })}
      >
        {children}
      </main>
      {(isLoading || isRefreshTokenLoading) && <Loader />}
    </div>
  );
};

export default Layout;

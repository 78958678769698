import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { InvitationsBranchUserApi } from '@app/api';
import { useAxios } from '@app/hooks';

export function branchUserGetInvitationDetails(token: string, clientId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new InvitationsBranchUserApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.branchUserGetInvitationDetails(token, clientId).then((res) => res.data);
}

export default function useBranchUserGetInvitationDetails({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useQuery(
    ['useBranchUserGetInvitationDetails', params.token],
    () => branchUserGetInvitationDetails(params.token, params.clientId, axiosInstance),
    props
  );
}

import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    fill="#3166F4"
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    zoomAndPan="magnify"
    viewBox="0 0 375 374.9999"
    height="25"
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
    {...props}
  >
    <defs>
      <path
        id="pathAttribute"
        d="M 7.09375 7.09375 L 367.84375 7.09375 L 367.84375 367.84375 L 7.09375 367.84375 Z M 7.09375 7.09375 "
        fill="currentColor"
        strokeWidth="0"
        stroke="currentColor"
      ></path>
    </defs>
    <g>
      <path
        id="pathAttribute"
        d="M 187.46875 7.09375 C 87.851562 7.09375 7.09375 87.851562 7.09375 187.46875 C 7.09375 287.085938 87.851562 367.84375 187.46875 367.84375 C 287.085938 367.84375 367.84375 287.085938 367.84375 187.46875 C 367.84375 87.851562 287.085938 7.09375 187.46875 7.09375 "
        fillOpacity="1"
        fillRule="nonzero"
        fill="currentColor"
        strokeWidth="0"
        stroke="currentColor"
      ></path>
    </g>
    <g id="inner-icon" transform="translate(85, 75)">
      <svg width="199.8" height="199.8" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="IconChangeColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z"
          id="mainIconPathAttribute"
          fill="#ffffff"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14Z"
          id="mainIconPathAttribute"
          fill="#ffffff"
        ></path>
      </svg>
    </g>
  </svg>
);

export default SvgComponent;

import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { CampaignsBranchApi, ImageAttachmentsBranchApi, ImageAttachmentsBranchV2Api } from '@app/api';
import type { BranchCampaignCreateInput, CustomVariableCampaignImageInput } from '@app/api';

import { useAxios } from '@app/hooks';

export function createCustomVariableCampaignImage(
  branchId: string,
  imageInput: CustomVariableCampaignImageInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new ImageAttachmentsBranchV2Api(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.createCustomVariableCampaignImage(branchId, imageInput).then((res) => res.data);
}

export default function useCreateCustomVariableCampaignImage(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { branchId: string; imageInput: CustomVariableCampaignImageInput }) =>
      createCustomVariableCampaignImage(values.branchId, values.imageInput, axiosInstance),
    props
  );
}

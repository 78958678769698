import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';
import type { UpdateLanguageProfileInput } from '@app/api';
import { ProfileApi } from '@app/api';
import { useAxios } from '@app/hooks';

export function updateLanguageProfile(language: UpdateLanguageProfileInput, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new ProfileApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.updateLanguageProfile(language).then((res) => res.data);
}

export default function useUpdateLanguageProfile(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation((values: any) => updateLanguageProfile(values, axiosInstance), props);
}

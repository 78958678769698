/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BranchAccountCampaignLeadListResponse } from '../model';
// @ts-ignore
import { BranchAccountCampaignListResponse } from '../model';
// @ts-ignore
import { BranchCampaign } from '../model';
// @ts-ignore
import { BranchCampaignCreateErrorResponse } from '../model';
// @ts-ignore
import { BranchCampaignCreateInput } from '../model';
// @ts-ignore
import { BranchCampaignInsightResponse } from '../model';
// @ts-ignore
import { BranchCampaignUpdateInput } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { FacebookAdPreviewErrorResponse } from '../model';
// @ts-ignore
import { FacebookAdPreviewSuccessResponse } from '../model';
// @ts-ignore
import { GoogleBranchCampaignCreateInput } from '../model';
// @ts-ignore
import { GoogleBranchCampaignUpdateInput } from '../model';
// @ts-ignore
import { SearchTermInsightResponse } from '../model';
/**
 * CampaignsBranchApi - axios parameter creator
 * @export
 */
export const CampaignsBranchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Cancel campaign and sync on facebook by branch campaign id if campaign is pending
         * @summary Cancel campaign and sync on facebook by branch campaign id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelFacebookCampaignByBranchCampaignId: async (branchId: string, campaignId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('cancelFacebookCampaignByBranchCampaignId', 'branchId', branchId)
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('cancelFacebookCampaignByBranchCampaignId', 'campaignId', campaignId)
            const localVarPath = `/branch_account/branches/{branch_id}/campaigns/facebook/{campaign_id}/cancel`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)))
                .replace(`{${"campaign_id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cancel campaign and sync on google by branch campaign id if campaign is pending
         * @summary Cancel campaign and sync on google by branch campaign id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelGoogleCampaignByBranchCampaignId: async (branchId: string, campaignId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('cancelGoogleCampaignByBranchCampaignId', 'branchId', branchId)
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('cancelGoogleCampaignByBranchCampaignId', 'campaignId', campaignId)
            const localVarPath = `/branch_account/branches/{branch_id}/campaigns/google/{campaign_id}/cancel`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)))
                .replace(`{${"campaign_id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create campaign for branch
         * @summary Create campaign for branch
         * @param {string} branchId The id of the branch
         * @param {BranchCampaignCreateInput} branchCampaignCreateInput Create a campaign to branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBranchCampaign: async (branchId: string, branchCampaignCreateInput: BranchCampaignCreateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('createBranchCampaign', 'branchId', branchId)
            // verify required parameter 'branchCampaignCreateInput' is not null or undefined
            assertParamExists('createBranchCampaign', 'branchCampaignCreateInput', branchCampaignCreateInput)
            const localVarPath = `/branch_account/branches/{branch_id}/campaigns/facebook`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(branchCampaignCreateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create google campaign for branch
         * @summary Create google campaign for branch
         * @param {string} branchId The id of the branch
         * @param {GoogleBranchCampaignCreateInput} googleBranchCampaignCreateInput Create a google campaign to branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGoogleBranchCampaign: async (branchId: string, googleBranchCampaignCreateInput: GoogleBranchCampaignCreateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('createGoogleBranchCampaign', 'branchId', branchId)
            // verify required parameter 'googleBranchCampaignCreateInput' is not null or undefined
            assertParamExists('createGoogleBranchCampaign', 'googleBranchCampaignCreateInput', googleBranchCampaignCreateInput)
            const localVarPath = `/branch_account/branches/{branch_id}/campaigns/google`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(googleBranchCampaignCreateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate facebook ad preview by branch campaign id
         * @summary Generate facebook ad preview by branch campaign id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {'AUDIENCE_NETWORK_INSTREAM_VIDEO' | 'AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE' | 'AUDIENCE_NETWORK_OUTSTREAM_VIDEO' | 'AUDIENCE_NETWORK_REWARDED_VIDEO' | 'BIZ_DISCO_FEED_MOBILE' | 'DESKTOP_FEED_STANDARD' | 'FACEBOOK_REELS_BANNER' | 'FACEBOOK_REELS_MOBILE' | 'FACEBOOK_REELS_STICKER' | 'FACEBOOK_STORY_MOBILE' | 'FACEBOOK_STORY_STICKER_MOBILE' | 'INSTAGRAM_EXPLORE_CONTEXTUAL' | 'INSTAGRAM_EXPLORE_IMMERSIVE' | 'INSTAGRAM_FEED_WEB' | 'INSTAGRAM_FEED_WEB_M_SITE' | 'INSTAGRAM_REELS' | 'INSTAGRAM_REELS_OVERLAY' | 'INSTAGRAM_SHOP' | 'INSTAGRAM_STANDARD' | 'INSTAGRAM_STORY' | 'INSTANT_ARTICLE_RECIRCULATION_AD' | 'INSTANT_ARTICLE_STANDARD' | 'INSTREAM_BANNER_DESKTOP' | 'INSTREAM_BANNER_MOBILE' | 'INSTREAM_VIDEO_DESKTOP' | 'INSTREAM_VIDEO_IMAGE' | 'INSTREAM_VIDEO_MOBILE' | 'JOB_BROWSER_DESKTOP' | 'JOB_BROWSER_MOBILE' | 'MARKETPLACE_MOBILE' | 'MESSENGER_MOBILE_INBOX_MEDIA' | 'MESSENGER_MOBILE_STORY_MEDIA' | 'MOBILE_BANNER' | 'MOBILE_FEED_BASIC' | 'MOBILE_FEED_STANDARD' | 'MOBILE_FULLWIDTH' | 'MOBILE_INTERSTITIAL' | 'MOBILE_MEDIUM_RECTANGLE' | 'MOBILE_NATIVE' | 'RIGHT_COLUMN_STANDARD' | 'SUGGESTED_VIDEO_DESKTOP' | 'SUGGESTED_VIDEO_MOBILE' | 'WATCH_FEED_HOME' | 'WATCH_FEED_MOBILE'} adFormat Ad format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateFacebookAdPreviewByBranchCampaignId: async (branchId: string, campaignId: string, adFormat: 'AUDIENCE_NETWORK_INSTREAM_VIDEO' | 'AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE' | 'AUDIENCE_NETWORK_OUTSTREAM_VIDEO' | 'AUDIENCE_NETWORK_REWARDED_VIDEO' | 'BIZ_DISCO_FEED_MOBILE' | 'DESKTOP_FEED_STANDARD' | 'FACEBOOK_REELS_BANNER' | 'FACEBOOK_REELS_MOBILE' | 'FACEBOOK_REELS_STICKER' | 'FACEBOOK_STORY_MOBILE' | 'FACEBOOK_STORY_STICKER_MOBILE' | 'INSTAGRAM_EXPLORE_CONTEXTUAL' | 'INSTAGRAM_EXPLORE_IMMERSIVE' | 'INSTAGRAM_FEED_WEB' | 'INSTAGRAM_FEED_WEB_M_SITE' | 'INSTAGRAM_REELS' | 'INSTAGRAM_REELS_OVERLAY' | 'INSTAGRAM_SHOP' | 'INSTAGRAM_STANDARD' | 'INSTAGRAM_STORY' | 'INSTANT_ARTICLE_RECIRCULATION_AD' | 'INSTANT_ARTICLE_STANDARD' | 'INSTREAM_BANNER_DESKTOP' | 'INSTREAM_BANNER_MOBILE' | 'INSTREAM_VIDEO_DESKTOP' | 'INSTREAM_VIDEO_IMAGE' | 'INSTREAM_VIDEO_MOBILE' | 'JOB_BROWSER_DESKTOP' | 'JOB_BROWSER_MOBILE' | 'MARKETPLACE_MOBILE' | 'MESSENGER_MOBILE_INBOX_MEDIA' | 'MESSENGER_MOBILE_STORY_MEDIA' | 'MOBILE_BANNER' | 'MOBILE_FEED_BASIC' | 'MOBILE_FEED_STANDARD' | 'MOBILE_FULLWIDTH' | 'MOBILE_INTERSTITIAL' | 'MOBILE_MEDIUM_RECTANGLE' | 'MOBILE_NATIVE' | 'RIGHT_COLUMN_STANDARD' | 'SUGGESTED_VIDEO_DESKTOP' | 'SUGGESTED_VIDEO_MOBILE' | 'WATCH_FEED_HOME' | 'WATCH_FEED_MOBILE', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('generateFacebookAdPreviewByBranchCampaignId', 'branchId', branchId)
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('generateFacebookAdPreviewByBranchCampaignId', 'campaignId', campaignId)
            // verify required parameter 'adFormat' is not null or undefined
            assertParamExists('generateFacebookAdPreviewByBranchCampaignId', 'adFormat', adFormat)
            const localVarPath = `/branch_account/branches/{branch_id}/campaigns/facebook/{campaign_id}/ad_preview`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)))
                .replace(`{${"campaign_id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (adFormat !== undefined) {
                localVarQueryParameter['ad_format'] = adFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return campaigns of branch
         * @summary Return campaigns of branch
         * @param {string} branchId The id of the branch
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBranchCampaigns: async (branchId: string, page?: number, perPage?: number, query?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('listBranchCampaigns', 'branchId', branchId)
            const localVarPath = `/branch_account/branches/{branch_id}/campaigns`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return leads of branch
         * @summary Return leads of branch
         * @param {string} campaignId The id of the campaign
         * @param {string} branchId The id of the branch
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFacebookBranchCampaignLeadsOfBranchCampaign: async (campaignId: string, branchId: string, page?: number, perPage?: number, query?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('listFacebookBranchCampaignLeadsOfBranchCampaign', 'campaignId', campaignId)
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('listFacebookBranchCampaignLeadsOfBranchCampaign', 'branchId', branchId)
            const localVarPath = `/branch_account/branches/{branch_id}/campaigns/facebook/{campaign_id}/leads`
                .replace(`{${"campaign_id"}}`, encodeURIComponent(String(campaignId)))
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Pause campaign and sync on facebook by branch campaign id if campaign is running
         * @summary Pause campaign and sync on facebook by branch campaign id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseFacebookCampaignByBranchCampaignId: async (branchId: string, campaignId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('pauseFacebookCampaignByBranchCampaignId', 'branchId', branchId)
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('pauseFacebookCampaignByBranchCampaignId', 'campaignId', campaignId)
            const localVarPath = `/branch_account/branches/{branch_id}/campaigns/facebook/{campaign_id}/pause`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)))
                .replace(`{${"campaign_id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * pause campaign and sync on google by branch campaign id if campaign is running
         * @summary Pause campaign and sync on google by branch campaign id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseGoogleCampaignByBranchCampaignId: async (branchId: string, campaignId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('pauseGoogleCampaignByBranchCampaignId', 'branchId', branchId)
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('pauseGoogleCampaignByBranchCampaignId', 'campaignId', campaignId)
            const localVarPath = `/branch_account/branches/{branch_id}/campaigns/google/{campaign_id}/pause`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)))
                .replace(`{${"campaign_id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Run campaign and sync on facebook by branch campaign id if campaign is paused or canceled 
         * @summary Run campaign and sync on facebook by branch campaign id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runFacebookCampaignByBranchCampaignId: async (branchId: string, campaignId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('runFacebookCampaignByBranchCampaignId', 'branchId', branchId)
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('runFacebookCampaignByBranchCampaignId', 'campaignId', campaignId)
            const localVarPath = `/branch_account/branches/{branch_id}/campaigns/facebook/{campaign_id}/run`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)))
                .replace(`{${"campaign_id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Run campaign and sync on facebook by branch campaign id if campaign is paused
         * @summary Run campaign and sync on google by branch campaign id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runGoogleCampaignByBranchCampaignId: async (branchId: string, campaignId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('runGoogleCampaignByBranchCampaignId', 'branchId', branchId)
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('runGoogleCampaignByBranchCampaignId', 'campaignId', campaignId)
            const localVarPath = `/branch_account/branches/{branch_id}/campaigns/google/{campaign_id}/run`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)))
                .replace(`{${"campaign_id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return campaign insights with by id
         * @summary Return campaign insights with by id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {string} startDate Start date of the date range for the insights
         * @param {string} endDate End date of the date range for the insights
         * @param {'day' | 'month' | 'week'} [granularity] Granularity of insights (default&#x3D;day if empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showBranchCampaignInsights: async (branchId: string, campaignId: string, startDate: string, endDate: string, granularity?: 'day' | 'month' | 'week', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('showBranchCampaignInsights', 'branchId', branchId)
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('showBranchCampaignInsights', 'campaignId', campaignId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('showBranchCampaignInsights', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('showBranchCampaignInsights', 'endDate', endDate)
            const localVarPath = `/branch_account/branches/{branch_id}/campaigns/{campaign_id}/insights`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)))
                .replace(`{${"campaign_id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (granularity !== undefined) {
                localVarQueryParameter['granularity'] = granularity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return search term campaign insights with by id
         * @summary Return search term campaign insights with by id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {string} startDate Start date of the date range for the insights
         * @param {string} endDate End date of the date range for the insights
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showBranchCampaignSearchTermInsights: async (branchId: string, campaignId: string, startDate: string, endDate: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('showBranchCampaignSearchTermInsights', 'branchId', branchId)
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('showBranchCampaignSearchTermInsights', 'campaignId', campaignId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('showBranchCampaignSearchTermInsights', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('showBranchCampaignSearchTermInsights', 'endDate', endDate)
            const localVarPath = `/branch_account/branches/{branch_id}/campaigns/{campaign_id}/search_term_insights`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)))
                .replace(`{${"campaign_id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return campaigns of branch
         * @summary Return campaigns of branch
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showBranchCampaigns: async (branchId: string, campaignId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('showBranchCampaigns', 'branchId', branchId)
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('showBranchCampaigns', 'campaignId', campaignId)
            const localVarPath = `/branch_account/branches/{branch_id}/campaigns/{campaign_id}`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)))
                .replace(`{${"campaign_id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update campaigns of branch
         * @summary Update campaigns of branch
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {BranchCampaignUpdateInput} branchCampaignUpdateInput Update a campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBranchCampaigns: async (branchId: string, campaignId: string, branchCampaignUpdateInput: BranchCampaignUpdateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('updateBranchCampaigns', 'branchId', branchId)
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('updateBranchCampaigns', 'campaignId', campaignId)
            // verify required parameter 'branchCampaignUpdateInput' is not null or undefined
            assertParamExists('updateBranchCampaigns', 'branchCampaignUpdateInput', branchCampaignUpdateInput)
            const localVarPath = `/branch_account/branches/{branch_id}/campaigns/facebook/{campaign_id}`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)))
                .replace(`{${"campaign_id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(branchCampaignUpdateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update campaigns of branch
         * @summary Update campaigns of branch
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {GoogleBranchCampaignUpdateInput} googleBranchCampaignUpdateInput Update a campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGoogleBranchCampaigns: async (branchId: string, campaignId: string, googleBranchCampaignUpdateInput: GoogleBranchCampaignUpdateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('updateGoogleBranchCampaigns', 'branchId', branchId)
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('updateGoogleBranchCampaigns', 'campaignId', campaignId)
            // verify required parameter 'googleBranchCampaignUpdateInput' is not null or undefined
            assertParamExists('updateGoogleBranchCampaigns', 'googleBranchCampaignUpdateInput', googleBranchCampaignUpdateInput)
            const localVarPath = `/branch_account/branches/{branch_id}/campaigns/google/{campaign_id}`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)))
                .replace(`{${"campaign_id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(googleBranchCampaignUpdateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignsBranchApi - functional programming interface
 * @export
 */
export const CampaignsBranchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignsBranchApiAxiosParamCreator(configuration)
    return {
        /**
         * Cancel campaign and sync on facebook by branch campaign id if campaign is pending
         * @summary Cancel campaign and sync on facebook by branch campaign id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelFacebookCampaignByBranchCampaignId(branchId: string, campaignId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchCampaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelFacebookCampaignByBranchCampaignId(branchId, campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Cancel campaign and sync on google by branch campaign id if campaign is pending
         * @summary Cancel campaign and sync on google by branch campaign id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelGoogleCampaignByBranchCampaignId(branchId: string, campaignId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchCampaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelGoogleCampaignByBranchCampaignId(branchId, campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create campaign for branch
         * @summary Create campaign for branch
         * @param {string} branchId The id of the branch
         * @param {BranchCampaignCreateInput} branchCampaignCreateInput Create a campaign to branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBranchCampaign(branchId: string, branchCampaignCreateInput: BranchCampaignCreateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchCampaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBranchCampaign(branchId, branchCampaignCreateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create google campaign for branch
         * @summary Create google campaign for branch
         * @param {string} branchId The id of the branch
         * @param {GoogleBranchCampaignCreateInput} googleBranchCampaignCreateInput Create a google campaign to branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGoogleBranchCampaign(branchId: string, googleBranchCampaignCreateInput: GoogleBranchCampaignCreateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchCampaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGoogleBranchCampaign(branchId, googleBranchCampaignCreateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generate facebook ad preview by branch campaign id
         * @summary Generate facebook ad preview by branch campaign id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {'AUDIENCE_NETWORK_INSTREAM_VIDEO' | 'AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE' | 'AUDIENCE_NETWORK_OUTSTREAM_VIDEO' | 'AUDIENCE_NETWORK_REWARDED_VIDEO' | 'BIZ_DISCO_FEED_MOBILE' | 'DESKTOP_FEED_STANDARD' | 'FACEBOOK_REELS_BANNER' | 'FACEBOOK_REELS_MOBILE' | 'FACEBOOK_REELS_STICKER' | 'FACEBOOK_STORY_MOBILE' | 'FACEBOOK_STORY_STICKER_MOBILE' | 'INSTAGRAM_EXPLORE_CONTEXTUAL' | 'INSTAGRAM_EXPLORE_IMMERSIVE' | 'INSTAGRAM_FEED_WEB' | 'INSTAGRAM_FEED_WEB_M_SITE' | 'INSTAGRAM_REELS' | 'INSTAGRAM_REELS_OVERLAY' | 'INSTAGRAM_SHOP' | 'INSTAGRAM_STANDARD' | 'INSTAGRAM_STORY' | 'INSTANT_ARTICLE_RECIRCULATION_AD' | 'INSTANT_ARTICLE_STANDARD' | 'INSTREAM_BANNER_DESKTOP' | 'INSTREAM_BANNER_MOBILE' | 'INSTREAM_VIDEO_DESKTOP' | 'INSTREAM_VIDEO_IMAGE' | 'INSTREAM_VIDEO_MOBILE' | 'JOB_BROWSER_DESKTOP' | 'JOB_BROWSER_MOBILE' | 'MARKETPLACE_MOBILE' | 'MESSENGER_MOBILE_INBOX_MEDIA' | 'MESSENGER_MOBILE_STORY_MEDIA' | 'MOBILE_BANNER' | 'MOBILE_FEED_BASIC' | 'MOBILE_FEED_STANDARD' | 'MOBILE_FULLWIDTH' | 'MOBILE_INTERSTITIAL' | 'MOBILE_MEDIUM_RECTANGLE' | 'MOBILE_NATIVE' | 'RIGHT_COLUMN_STANDARD' | 'SUGGESTED_VIDEO_DESKTOP' | 'SUGGESTED_VIDEO_MOBILE' | 'WATCH_FEED_HOME' | 'WATCH_FEED_MOBILE'} adFormat Ad format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateFacebookAdPreviewByBranchCampaignId(branchId: string, campaignId: string, adFormat: 'AUDIENCE_NETWORK_INSTREAM_VIDEO' | 'AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE' | 'AUDIENCE_NETWORK_OUTSTREAM_VIDEO' | 'AUDIENCE_NETWORK_REWARDED_VIDEO' | 'BIZ_DISCO_FEED_MOBILE' | 'DESKTOP_FEED_STANDARD' | 'FACEBOOK_REELS_BANNER' | 'FACEBOOK_REELS_MOBILE' | 'FACEBOOK_REELS_STICKER' | 'FACEBOOK_STORY_MOBILE' | 'FACEBOOK_STORY_STICKER_MOBILE' | 'INSTAGRAM_EXPLORE_CONTEXTUAL' | 'INSTAGRAM_EXPLORE_IMMERSIVE' | 'INSTAGRAM_FEED_WEB' | 'INSTAGRAM_FEED_WEB_M_SITE' | 'INSTAGRAM_REELS' | 'INSTAGRAM_REELS_OVERLAY' | 'INSTAGRAM_SHOP' | 'INSTAGRAM_STANDARD' | 'INSTAGRAM_STORY' | 'INSTANT_ARTICLE_RECIRCULATION_AD' | 'INSTANT_ARTICLE_STANDARD' | 'INSTREAM_BANNER_DESKTOP' | 'INSTREAM_BANNER_MOBILE' | 'INSTREAM_VIDEO_DESKTOP' | 'INSTREAM_VIDEO_IMAGE' | 'INSTREAM_VIDEO_MOBILE' | 'JOB_BROWSER_DESKTOP' | 'JOB_BROWSER_MOBILE' | 'MARKETPLACE_MOBILE' | 'MESSENGER_MOBILE_INBOX_MEDIA' | 'MESSENGER_MOBILE_STORY_MEDIA' | 'MOBILE_BANNER' | 'MOBILE_FEED_BASIC' | 'MOBILE_FEED_STANDARD' | 'MOBILE_FULLWIDTH' | 'MOBILE_INTERSTITIAL' | 'MOBILE_MEDIUM_RECTANGLE' | 'MOBILE_NATIVE' | 'RIGHT_COLUMN_STANDARD' | 'SUGGESTED_VIDEO_DESKTOP' | 'SUGGESTED_VIDEO_MOBILE' | 'WATCH_FEED_HOME' | 'WATCH_FEED_MOBILE', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookAdPreviewSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateFacebookAdPreviewByBranchCampaignId(branchId, campaignId, adFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return campaigns of branch
         * @summary Return campaigns of branch
         * @param {string} branchId The id of the branch
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBranchCampaigns(branchId: string, page?: number, perPage?: number, query?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchAccountCampaignListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBranchCampaigns(branchId, page, perPage, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return leads of branch
         * @summary Return leads of branch
         * @param {string} campaignId The id of the campaign
         * @param {string} branchId The id of the branch
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFacebookBranchCampaignLeadsOfBranchCampaign(campaignId: string, branchId: string, page?: number, perPage?: number, query?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchAccountCampaignLeadListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFacebookBranchCampaignLeadsOfBranchCampaign(campaignId, branchId, page, perPage, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Pause campaign and sync on facebook by branch campaign id if campaign is running
         * @summary Pause campaign and sync on facebook by branch campaign id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pauseFacebookCampaignByBranchCampaignId(branchId: string, campaignId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchCampaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pauseFacebookCampaignByBranchCampaignId(branchId, campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * pause campaign and sync on google by branch campaign id if campaign is running
         * @summary Pause campaign and sync on google by branch campaign id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pauseGoogleCampaignByBranchCampaignId(branchId: string, campaignId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchCampaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pauseGoogleCampaignByBranchCampaignId(branchId, campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Run campaign and sync on facebook by branch campaign id if campaign is paused or canceled 
         * @summary Run campaign and sync on facebook by branch campaign id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runFacebookCampaignByBranchCampaignId(branchId: string, campaignId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchCampaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runFacebookCampaignByBranchCampaignId(branchId, campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Run campaign and sync on facebook by branch campaign id if campaign is paused
         * @summary Run campaign and sync on google by branch campaign id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runGoogleCampaignByBranchCampaignId(branchId: string, campaignId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchCampaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runGoogleCampaignByBranchCampaignId(branchId, campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return campaign insights with by id
         * @summary Return campaign insights with by id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {string} startDate Start date of the date range for the insights
         * @param {string} endDate End date of the date range for the insights
         * @param {'day' | 'month' | 'week'} [granularity] Granularity of insights (default&#x3D;day if empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showBranchCampaignInsights(branchId: string, campaignId: string, startDate: string, endDate: string, granularity?: 'day' | 'month' | 'week', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchCampaignInsightResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showBranchCampaignInsights(branchId, campaignId, startDate, endDate, granularity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return search term campaign insights with by id
         * @summary Return search term campaign insights with by id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {string} startDate Start date of the date range for the insights
         * @param {string} endDate End date of the date range for the insights
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showBranchCampaignSearchTermInsights(branchId: string, campaignId: string, startDate: string, endDate: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchTermInsightResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showBranchCampaignSearchTermInsights(branchId, campaignId, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return campaigns of branch
         * @summary Return campaigns of branch
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showBranchCampaigns(branchId: string, campaignId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchCampaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showBranchCampaigns(branchId, campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update campaigns of branch
         * @summary Update campaigns of branch
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {BranchCampaignUpdateInput} branchCampaignUpdateInput Update a campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBranchCampaigns(branchId: string, campaignId: string, branchCampaignUpdateInput: BranchCampaignUpdateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchCampaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBranchCampaigns(branchId, campaignId, branchCampaignUpdateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update campaigns of branch
         * @summary Update campaigns of branch
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {GoogleBranchCampaignUpdateInput} googleBranchCampaignUpdateInput Update a campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGoogleBranchCampaigns(branchId: string, campaignId: string, googleBranchCampaignUpdateInput: GoogleBranchCampaignUpdateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchCampaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGoogleBranchCampaigns(branchId, campaignId, googleBranchCampaignUpdateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CampaignsBranchApi - factory interface
 * @export
 */
export const CampaignsBranchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignsBranchApiFp(configuration)
    return {
        /**
         * Cancel campaign and sync on facebook by branch campaign id if campaign is pending
         * @summary Cancel campaign and sync on facebook by branch campaign id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelFacebookCampaignByBranchCampaignId(branchId: string, campaignId: string, options?: any): AxiosPromise<BranchCampaign> {
            return localVarFp.cancelFacebookCampaignByBranchCampaignId(branchId, campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * Cancel campaign and sync on google by branch campaign id if campaign is pending
         * @summary Cancel campaign and sync on google by branch campaign id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelGoogleCampaignByBranchCampaignId(branchId: string, campaignId: string, options?: any): AxiosPromise<BranchCampaign> {
            return localVarFp.cancelGoogleCampaignByBranchCampaignId(branchId, campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create campaign for branch
         * @summary Create campaign for branch
         * @param {string} branchId The id of the branch
         * @param {BranchCampaignCreateInput} branchCampaignCreateInput Create a campaign to branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBranchCampaign(branchId: string, branchCampaignCreateInput: BranchCampaignCreateInput, options?: any): AxiosPromise<BranchCampaign> {
            return localVarFp.createBranchCampaign(branchId, branchCampaignCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Create google campaign for branch
         * @summary Create google campaign for branch
         * @param {string} branchId The id of the branch
         * @param {GoogleBranchCampaignCreateInput} googleBranchCampaignCreateInput Create a google campaign to branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGoogleBranchCampaign(branchId: string, googleBranchCampaignCreateInput: GoogleBranchCampaignCreateInput, options?: any): AxiosPromise<BranchCampaign> {
            return localVarFp.createGoogleBranchCampaign(branchId, googleBranchCampaignCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate facebook ad preview by branch campaign id
         * @summary Generate facebook ad preview by branch campaign id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {'AUDIENCE_NETWORK_INSTREAM_VIDEO' | 'AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE' | 'AUDIENCE_NETWORK_OUTSTREAM_VIDEO' | 'AUDIENCE_NETWORK_REWARDED_VIDEO' | 'BIZ_DISCO_FEED_MOBILE' | 'DESKTOP_FEED_STANDARD' | 'FACEBOOK_REELS_BANNER' | 'FACEBOOK_REELS_MOBILE' | 'FACEBOOK_REELS_STICKER' | 'FACEBOOK_STORY_MOBILE' | 'FACEBOOK_STORY_STICKER_MOBILE' | 'INSTAGRAM_EXPLORE_CONTEXTUAL' | 'INSTAGRAM_EXPLORE_IMMERSIVE' | 'INSTAGRAM_FEED_WEB' | 'INSTAGRAM_FEED_WEB_M_SITE' | 'INSTAGRAM_REELS' | 'INSTAGRAM_REELS_OVERLAY' | 'INSTAGRAM_SHOP' | 'INSTAGRAM_STANDARD' | 'INSTAGRAM_STORY' | 'INSTANT_ARTICLE_RECIRCULATION_AD' | 'INSTANT_ARTICLE_STANDARD' | 'INSTREAM_BANNER_DESKTOP' | 'INSTREAM_BANNER_MOBILE' | 'INSTREAM_VIDEO_DESKTOP' | 'INSTREAM_VIDEO_IMAGE' | 'INSTREAM_VIDEO_MOBILE' | 'JOB_BROWSER_DESKTOP' | 'JOB_BROWSER_MOBILE' | 'MARKETPLACE_MOBILE' | 'MESSENGER_MOBILE_INBOX_MEDIA' | 'MESSENGER_MOBILE_STORY_MEDIA' | 'MOBILE_BANNER' | 'MOBILE_FEED_BASIC' | 'MOBILE_FEED_STANDARD' | 'MOBILE_FULLWIDTH' | 'MOBILE_INTERSTITIAL' | 'MOBILE_MEDIUM_RECTANGLE' | 'MOBILE_NATIVE' | 'RIGHT_COLUMN_STANDARD' | 'SUGGESTED_VIDEO_DESKTOP' | 'SUGGESTED_VIDEO_MOBILE' | 'WATCH_FEED_HOME' | 'WATCH_FEED_MOBILE'} adFormat Ad format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateFacebookAdPreviewByBranchCampaignId(branchId: string, campaignId: string, adFormat: 'AUDIENCE_NETWORK_INSTREAM_VIDEO' | 'AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE' | 'AUDIENCE_NETWORK_OUTSTREAM_VIDEO' | 'AUDIENCE_NETWORK_REWARDED_VIDEO' | 'BIZ_DISCO_FEED_MOBILE' | 'DESKTOP_FEED_STANDARD' | 'FACEBOOK_REELS_BANNER' | 'FACEBOOK_REELS_MOBILE' | 'FACEBOOK_REELS_STICKER' | 'FACEBOOK_STORY_MOBILE' | 'FACEBOOK_STORY_STICKER_MOBILE' | 'INSTAGRAM_EXPLORE_CONTEXTUAL' | 'INSTAGRAM_EXPLORE_IMMERSIVE' | 'INSTAGRAM_FEED_WEB' | 'INSTAGRAM_FEED_WEB_M_SITE' | 'INSTAGRAM_REELS' | 'INSTAGRAM_REELS_OVERLAY' | 'INSTAGRAM_SHOP' | 'INSTAGRAM_STANDARD' | 'INSTAGRAM_STORY' | 'INSTANT_ARTICLE_RECIRCULATION_AD' | 'INSTANT_ARTICLE_STANDARD' | 'INSTREAM_BANNER_DESKTOP' | 'INSTREAM_BANNER_MOBILE' | 'INSTREAM_VIDEO_DESKTOP' | 'INSTREAM_VIDEO_IMAGE' | 'INSTREAM_VIDEO_MOBILE' | 'JOB_BROWSER_DESKTOP' | 'JOB_BROWSER_MOBILE' | 'MARKETPLACE_MOBILE' | 'MESSENGER_MOBILE_INBOX_MEDIA' | 'MESSENGER_MOBILE_STORY_MEDIA' | 'MOBILE_BANNER' | 'MOBILE_FEED_BASIC' | 'MOBILE_FEED_STANDARD' | 'MOBILE_FULLWIDTH' | 'MOBILE_INTERSTITIAL' | 'MOBILE_MEDIUM_RECTANGLE' | 'MOBILE_NATIVE' | 'RIGHT_COLUMN_STANDARD' | 'SUGGESTED_VIDEO_DESKTOP' | 'SUGGESTED_VIDEO_MOBILE' | 'WATCH_FEED_HOME' | 'WATCH_FEED_MOBILE', options?: any): AxiosPromise<FacebookAdPreviewSuccessResponse> {
            return localVarFp.generateFacebookAdPreviewByBranchCampaignId(branchId, campaignId, adFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Return campaigns of branch
         * @summary Return campaigns of branch
         * @param {string} branchId The id of the branch
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBranchCampaigns(branchId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<BranchAccountCampaignListResponse> {
            return localVarFp.listBranchCampaigns(branchId, page, perPage, query, options).then((request) => request(axios, basePath));
        },
        /**
         * Return leads of branch
         * @summary Return leads of branch
         * @param {string} campaignId The id of the campaign
         * @param {string} branchId The id of the branch
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFacebookBranchCampaignLeadsOfBranchCampaign(campaignId: string, branchId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<BranchAccountCampaignLeadListResponse> {
            return localVarFp.listFacebookBranchCampaignLeadsOfBranchCampaign(campaignId, branchId, page, perPage, query, options).then((request) => request(axios, basePath));
        },
        /**
         * Pause campaign and sync on facebook by branch campaign id if campaign is running
         * @summary Pause campaign and sync on facebook by branch campaign id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseFacebookCampaignByBranchCampaignId(branchId: string, campaignId: string, options?: any): AxiosPromise<BranchCampaign> {
            return localVarFp.pauseFacebookCampaignByBranchCampaignId(branchId, campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * pause campaign and sync on google by branch campaign id if campaign is running
         * @summary Pause campaign and sync on google by branch campaign id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseGoogleCampaignByBranchCampaignId(branchId: string, campaignId: string, options?: any): AxiosPromise<BranchCampaign> {
            return localVarFp.pauseGoogleCampaignByBranchCampaignId(branchId, campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * Run campaign and sync on facebook by branch campaign id if campaign is paused or canceled 
         * @summary Run campaign and sync on facebook by branch campaign id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runFacebookCampaignByBranchCampaignId(branchId: string, campaignId: string, options?: any): AxiosPromise<BranchCampaign> {
            return localVarFp.runFacebookCampaignByBranchCampaignId(branchId, campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * Run campaign and sync on facebook by branch campaign id if campaign is paused
         * @summary Run campaign and sync on google by branch campaign id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runGoogleCampaignByBranchCampaignId(branchId: string, campaignId: string, options?: any): AxiosPromise<BranchCampaign> {
            return localVarFp.runGoogleCampaignByBranchCampaignId(branchId, campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return campaign insights with by id
         * @summary Return campaign insights with by id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {string} startDate Start date of the date range for the insights
         * @param {string} endDate End date of the date range for the insights
         * @param {'day' | 'month' | 'week'} [granularity] Granularity of insights (default&#x3D;day if empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showBranchCampaignInsights(branchId: string, campaignId: string, startDate: string, endDate: string, granularity?: 'day' | 'month' | 'week', options?: any): AxiosPromise<BranchCampaignInsightResponse> {
            return localVarFp.showBranchCampaignInsights(branchId, campaignId, startDate, endDate, granularity, options).then((request) => request(axios, basePath));
        },
        /**
         * Return search term campaign insights with by id
         * @summary Return search term campaign insights with by id
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {string} startDate Start date of the date range for the insights
         * @param {string} endDate End date of the date range for the insights
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showBranchCampaignSearchTermInsights(branchId: string, campaignId: string, startDate: string, endDate: string, options?: any): AxiosPromise<SearchTermInsightResponse> {
            return localVarFp.showBranchCampaignSearchTermInsights(branchId, campaignId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Return campaigns of branch
         * @summary Return campaigns of branch
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showBranchCampaigns(branchId: string, campaignId: string, options?: any): AxiosPromise<BranchCampaign> {
            return localVarFp.showBranchCampaigns(branchId, campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update campaigns of branch
         * @summary Update campaigns of branch
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {BranchCampaignUpdateInput} branchCampaignUpdateInput Update a campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBranchCampaigns(branchId: string, campaignId: string, branchCampaignUpdateInput: BranchCampaignUpdateInput, options?: any): AxiosPromise<BranchCampaign> {
            return localVarFp.updateBranchCampaigns(branchId, campaignId, branchCampaignUpdateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Update campaigns of branch
         * @summary Update campaigns of branch
         * @param {string} branchId The id of the branch
         * @param {string} campaignId The id of the campaign
         * @param {GoogleBranchCampaignUpdateInput} googleBranchCampaignUpdateInput Update a campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGoogleBranchCampaigns(branchId: string, campaignId: string, googleBranchCampaignUpdateInput: GoogleBranchCampaignUpdateInput, options?: any): AxiosPromise<BranchCampaign> {
            return localVarFp.updateGoogleBranchCampaigns(branchId, campaignId, googleBranchCampaignUpdateInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampaignsBranchApi - interface
 * @export
 * @interface CampaignsBranchApi
 */
export interface CampaignsBranchApiInterface {
    /**
     * Cancel campaign and sync on facebook by branch campaign id if campaign is pending
     * @summary Cancel campaign and sync on facebook by branch campaign id
     * @param {string} branchId The id of the branch
     * @param {string} campaignId The id of the campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApiInterface
     */
    cancelFacebookCampaignByBranchCampaignId(branchId: string, campaignId: string, options?: any): AxiosPromise<BranchCampaign>;

    /**
     * Cancel campaign and sync on google by branch campaign id if campaign is pending
     * @summary Cancel campaign and sync on google by branch campaign id
     * @param {string} branchId The id of the branch
     * @param {string} campaignId The id of the campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApiInterface
     */
    cancelGoogleCampaignByBranchCampaignId(branchId: string, campaignId: string, options?: any): AxiosPromise<BranchCampaign>;

    /**
     * Create campaign for branch
     * @summary Create campaign for branch
     * @param {string} branchId The id of the branch
     * @param {BranchCampaignCreateInput} branchCampaignCreateInput Create a campaign to branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApiInterface
     */
    createBranchCampaign(branchId: string, branchCampaignCreateInput: BranchCampaignCreateInput, options?: any): AxiosPromise<BranchCampaign>;

    /**
     * Create google campaign for branch
     * @summary Create google campaign for branch
     * @param {string} branchId The id of the branch
     * @param {GoogleBranchCampaignCreateInput} googleBranchCampaignCreateInput Create a google campaign to branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApiInterface
     */
    createGoogleBranchCampaign(branchId: string, googleBranchCampaignCreateInput: GoogleBranchCampaignCreateInput, options?: any): AxiosPromise<BranchCampaign>;

    /**
     * Generate facebook ad preview by branch campaign id
     * @summary Generate facebook ad preview by branch campaign id
     * @param {string} branchId The id of the branch
     * @param {string} campaignId The id of the campaign
     * @param {'AUDIENCE_NETWORK_INSTREAM_VIDEO' | 'AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE' | 'AUDIENCE_NETWORK_OUTSTREAM_VIDEO' | 'AUDIENCE_NETWORK_REWARDED_VIDEO' | 'BIZ_DISCO_FEED_MOBILE' | 'DESKTOP_FEED_STANDARD' | 'FACEBOOK_REELS_BANNER' | 'FACEBOOK_REELS_MOBILE' | 'FACEBOOK_REELS_STICKER' | 'FACEBOOK_STORY_MOBILE' | 'FACEBOOK_STORY_STICKER_MOBILE' | 'INSTAGRAM_EXPLORE_CONTEXTUAL' | 'INSTAGRAM_EXPLORE_IMMERSIVE' | 'INSTAGRAM_FEED_WEB' | 'INSTAGRAM_FEED_WEB_M_SITE' | 'INSTAGRAM_REELS' | 'INSTAGRAM_REELS_OVERLAY' | 'INSTAGRAM_SHOP' | 'INSTAGRAM_STANDARD' | 'INSTAGRAM_STORY' | 'INSTANT_ARTICLE_RECIRCULATION_AD' | 'INSTANT_ARTICLE_STANDARD' | 'INSTREAM_BANNER_DESKTOP' | 'INSTREAM_BANNER_MOBILE' | 'INSTREAM_VIDEO_DESKTOP' | 'INSTREAM_VIDEO_IMAGE' | 'INSTREAM_VIDEO_MOBILE' | 'JOB_BROWSER_DESKTOP' | 'JOB_BROWSER_MOBILE' | 'MARKETPLACE_MOBILE' | 'MESSENGER_MOBILE_INBOX_MEDIA' | 'MESSENGER_MOBILE_STORY_MEDIA' | 'MOBILE_BANNER' | 'MOBILE_FEED_BASIC' | 'MOBILE_FEED_STANDARD' | 'MOBILE_FULLWIDTH' | 'MOBILE_INTERSTITIAL' | 'MOBILE_MEDIUM_RECTANGLE' | 'MOBILE_NATIVE' | 'RIGHT_COLUMN_STANDARD' | 'SUGGESTED_VIDEO_DESKTOP' | 'SUGGESTED_VIDEO_MOBILE' | 'WATCH_FEED_HOME' | 'WATCH_FEED_MOBILE'} adFormat Ad format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApiInterface
     */
    generateFacebookAdPreviewByBranchCampaignId(branchId: string, campaignId: string, adFormat: 'AUDIENCE_NETWORK_INSTREAM_VIDEO' | 'AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE' | 'AUDIENCE_NETWORK_OUTSTREAM_VIDEO' | 'AUDIENCE_NETWORK_REWARDED_VIDEO' | 'BIZ_DISCO_FEED_MOBILE' | 'DESKTOP_FEED_STANDARD' | 'FACEBOOK_REELS_BANNER' | 'FACEBOOK_REELS_MOBILE' | 'FACEBOOK_REELS_STICKER' | 'FACEBOOK_STORY_MOBILE' | 'FACEBOOK_STORY_STICKER_MOBILE' | 'INSTAGRAM_EXPLORE_CONTEXTUAL' | 'INSTAGRAM_EXPLORE_IMMERSIVE' | 'INSTAGRAM_FEED_WEB' | 'INSTAGRAM_FEED_WEB_M_SITE' | 'INSTAGRAM_REELS' | 'INSTAGRAM_REELS_OVERLAY' | 'INSTAGRAM_SHOP' | 'INSTAGRAM_STANDARD' | 'INSTAGRAM_STORY' | 'INSTANT_ARTICLE_RECIRCULATION_AD' | 'INSTANT_ARTICLE_STANDARD' | 'INSTREAM_BANNER_DESKTOP' | 'INSTREAM_BANNER_MOBILE' | 'INSTREAM_VIDEO_DESKTOP' | 'INSTREAM_VIDEO_IMAGE' | 'INSTREAM_VIDEO_MOBILE' | 'JOB_BROWSER_DESKTOP' | 'JOB_BROWSER_MOBILE' | 'MARKETPLACE_MOBILE' | 'MESSENGER_MOBILE_INBOX_MEDIA' | 'MESSENGER_MOBILE_STORY_MEDIA' | 'MOBILE_BANNER' | 'MOBILE_FEED_BASIC' | 'MOBILE_FEED_STANDARD' | 'MOBILE_FULLWIDTH' | 'MOBILE_INTERSTITIAL' | 'MOBILE_MEDIUM_RECTANGLE' | 'MOBILE_NATIVE' | 'RIGHT_COLUMN_STANDARD' | 'SUGGESTED_VIDEO_DESKTOP' | 'SUGGESTED_VIDEO_MOBILE' | 'WATCH_FEED_HOME' | 'WATCH_FEED_MOBILE', options?: any): AxiosPromise<FacebookAdPreviewSuccessResponse>;

    /**
     * Return campaigns of branch
     * @summary Return campaigns of branch
     * @param {string} branchId The id of the branch
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApiInterface
     */
    listBranchCampaigns(branchId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<BranchAccountCampaignListResponse>;

    /**
     * Return leads of branch
     * @summary Return leads of branch
     * @param {string} campaignId The id of the campaign
     * @param {string} branchId The id of the branch
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApiInterface
     */
    listFacebookBranchCampaignLeadsOfBranchCampaign(campaignId: string, branchId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<BranchAccountCampaignLeadListResponse>;

    /**
     * Pause campaign and sync on facebook by branch campaign id if campaign is running
     * @summary Pause campaign and sync on facebook by branch campaign id
     * @param {string} branchId The id of the branch
     * @param {string} campaignId The id of the campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApiInterface
     */
    pauseFacebookCampaignByBranchCampaignId(branchId: string, campaignId: string, options?: any): AxiosPromise<BranchCampaign>;

    /**
     * pause campaign and sync on google by branch campaign id if campaign is running
     * @summary Pause campaign and sync on google by branch campaign id
     * @param {string} branchId The id of the branch
     * @param {string} campaignId The id of the campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApiInterface
     */
    pauseGoogleCampaignByBranchCampaignId(branchId: string, campaignId: string, options?: any): AxiosPromise<BranchCampaign>;

    /**
     * Run campaign and sync on facebook by branch campaign id if campaign is paused or canceled 
     * @summary Run campaign and sync on facebook by branch campaign id
     * @param {string} branchId The id of the branch
     * @param {string} campaignId The id of the campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApiInterface
     */
    runFacebookCampaignByBranchCampaignId(branchId: string, campaignId: string, options?: any): AxiosPromise<BranchCampaign>;

    /**
     * Run campaign and sync on facebook by branch campaign id if campaign is paused
     * @summary Run campaign and sync on google by branch campaign id
     * @param {string} branchId The id of the branch
     * @param {string} campaignId The id of the campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApiInterface
     */
    runGoogleCampaignByBranchCampaignId(branchId: string, campaignId: string, options?: any): AxiosPromise<BranchCampaign>;

    /**
     * Return campaign insights with by id
     * @summary Return campaign insights with by id
     * @param {string} branchId The id of the branch
     * @param {string} campaignId The id of the campaign
     * @param {string} startDate Start date of the date range for the insights
     * @param {string} endDate End date of the date range for the insights
     * @param {'day' | 'month' | 'week'} [granularity] Granularity of insights (default&#x3D;day if empty)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApiInterface
     */
    showBranchCampaignInsights(branchId: string, campaignId: string, startDate: string, endDate: string, granularity?: 'day' | 'month' | 'week', options?: any): AxiosPromise<BranchCampaignInsightResponse>;

    /**
     * Return search term campaign insights with by id
     * @summary Return search term campaign insights with by id
     * @param {string} branchId The id of the branch
     * @param {string} campaignId The id of the campaign
     * @param {string} startDate Start date of the date range for the insights
     * @param {string} endDate End date of the date range for the insights
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApiInterface
     */
    showBranchCampaignSearchTermInsights(branchId: string, campaignId: string, startDate: string, endDate: string, options?: any): AxiosPromise<SearchTermInsightResponse>;

    /**
     * Return campaigns of branch
     * @summary Return campaigns of branch
     * @param {string} branchId The id of the branch
     * @param {string} campaignId The id of the campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApiInterface
     */
    showBranchCampaigns(branchId: string, campaignId: string, options?: any): AxiosPromise<BranchCampaign>;

    /**
     * Update campaigns of branch
     * @summary Update campaigns of branch
     * @param {string} branchId The id of the branch
     * @param {string} campaignId The id of the campaign
     * @param {BranchCampaignUpdateInput} branchCampaignUpdateInput Update a campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApiInterface
     */
    updateBranchCampaigns(branchId: string, campaignId: string, branchCampaignUpdateInput: BranchCampaignUpdateInput, options?: any): AxiosPromise<BranchCampaign>;

    /**
     * Update campaigns of branch
     * @summary Update campaigns of branch
     * @param {string} branchId The id of the branch
     * @param {string} campaignId The id of the campaign
     * @param {GoogleBranchCampaignUpdateInput} googleBranchCampaignUpdateInput Update a campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApiInterface
     */
    updateGoogleBranchCampaigns(branchId: string, campaignId: string, googleBranchCampaignUpdateInput: GoogleBranchCampaignUpdateInput, options?: any): AxiosPromise<BranchCampaign>;

}

/**
 * CampaignsBranchApi - object-oriented interface
 * @export
 * @class CampaignsBranchApi
 * @extends {BaseAPI}
 */
export class CampaignsBranchApi extends BaseAPI implements CampaignsBranchApiInterface {
    /**
     * Cancel campaign and sync on facebook by branch campaign id if campaign is pending
     * @summary Cancel campaign and sync on facebook by branch campaign id
     * @param {string} branchId The id of the branch
     * @param {string} campaignId The id of the campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApi
     */
    public cancelFacebookCampaignByBranchCampaignId(branchId: string, campaignId: string, options?: any) {
        return CampaignsBranchApiFp(this.configuration).cancelFacebookCampaignByBranchCampaignId(branchId, campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cancel campaign and sync on google by branch campaign id if campaign is pending
     * @summary Cancel campaign and sync on google by branch campaign id
     * @param {string} branchId The id of the branch
     * @param {string} campaignId The id of the campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApi
     */
    public cancelGoogleCampaignByBranchCampaignId(branchId: string, campaignId: string, options?: any) {
        return CampaignsBranchApiFp(this.configuration).cancelGoogleCampaignByBranchCampaignId(branchId, campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create campaign for branch
     * @summary Create campaign for branch
     * @param {string} branchId The id of the branch
     * @param {BranchCampaignCreateInput} branchCampaignCreateInput Create a campaign to branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApi
     */
    public createBranchCampaign(branchId: string, branchCampaignCreateInput: BranchCampaignCreateInput, options?: any) {
        return CampaignsBranchApiFp(this.configuration).createBranchCampaign(branchId, branchCampaignCreateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create google campaign for branch
     * @summary Create google campaign for branch
     * @param {string} branchId The id of the branch
     * @param {GoogleBranchCampaignCreateInput} googleBranchCampaignCreateInput Create a google campaign to branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApi
     */
    public createGoogleBranchCampaign(branchId: string, googleBranchCampaignCreateInput: GoogleBranchCampaignCreateInput, options?: any) {
        return CampaignsBranchApiFp(this.configuration).createGoogleBranchCampaign(branchId, googleBranchCampaignCreateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate facebook ad preview by branch campaign id
     * @summary Generate facebook ad preview by branch campaign id
     * @param {string} branchId The id of the branch
     * @param {string} campaignId The id of the campaign
     * @param {'AUDIENCE_NETWORK_INSTREAM_VIDEO' | 'AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE' | 'AUDIENCE_NETWORK_OUTSTREAM_VIDEO' | 'AUDIENCE_NETWORK_REWARDED_VIDEO' | 'BIZ_DISCO_FEED_MOBILE' | 'DESKTOP_FEED_STANDARD' | 'FACEBOOK_REELS_BANNER' | 'FACEBOOK_REELS_MOBILE' | 'FACEBOOK_REELS_STICKER' | 'FACEBOOK_STORY_MOBILE' | 'FACEBOOK_STORY_STICKER_MOBILE' | 'INSTAGRAM_EXPLORE_CONTEXTUAL' | 'INSTAGRAM_EXPLORE_IMMERSIVE' | 'INSTAGRAM_FEED_WEB' | 'INSTAGRAM_FEED_WEB_M_SITE' | 'INSTAGRAM_REELS' | 'INSTAGRAM_REELS_OVERLAY' | 'INSTAGRAM_SHOP' | 'INSTAGRAM_STANDARD' | 'INSTAGRAM_STORY' | 'INSTANT_ARTICLE_RECIRCULATION_AD' | 'INSTANT_ARTICLE_STANDARD' | 'INSTREAM_BANNER_DESKTOP' | 'INSTREAM_BANNER_MOBILE' | 'INSTREAM_VIDEO_DESKTOP' | 'INSTREAM_VIDEO_IMAGE' | 'INSTREAM_VIDEO_MOBILE' | 'JOB_BROWSER_DESKTOP' | 'JOB_BROWSER_MOBILE' | 'MARKETPLACE_MOBILE' | 'MESSENGER_MOBILE_INBOX_MEDIA' | 'MESSENGER_MOBILE_STORY_MEDIA' | 'MOBILE_BANNER' | 'MOBILE_FEED_BASIC' | 'MOBILE_FEED_STANDARD' | 'MOBILE_FULLWIDTH' | 'MOBILE_INTERSTITIAL' | 'MOBILE_MEDIUM_RECTANGLE' | 'MOBILE_NATIVE' | 'RIGHT_COLUMN_STANDARD' | 'SUGGESTED_VIDEO_DESKTOP' | 'SUGGESTED_VIDEO_MOBILE' | 'WATCH_FEED_HOME' | 'WATCH_FEED_MOBILE'} adFormat Ad format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApi
     */
    public generateFacebookAdPreviewByBranchCampaignId(branchId: string, campaignId: string, adFormat: 'AUDIENCE_NETWORK_INSTREAM_VIDEO' | 'AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE' | 'AUDIENCE_NETWORK_OUTSTREAM_VIDEO' | 'AUDIENCE_NETWORK_REWARDED_VIDEO' | 'BIZ_DISCO_FEED_MOBILE' | 'DESKTOP_FEED_STANDARD' | 'FACEBOOK_REELS_BANNER' | 'FACEBOOK_REELS_MOBILE' | 'FACEBOOK_REELS_STICKER' | 'FACEBOOK_STORY_MOBILE' | 'FACEBOOK_STORY_STICKER_MOBILE' | 'INSTAGRAM_EXPLORE_CONTEXTUAL' | 'INSTAGRAM_EXPLORE_IMMERSIVE' | 'INSTAGRAM_FEED_WEB' | 'INSTAGRAM_FEED_WEB_M_SITE' | 'INSTAGRAM_REELS' | 'INSTAGRAM_REELS_OVERLAY' | 'INSTAGRAM_SHOP' | 'INSTAGRAM_STANDARD' | 'INSTAGRAM_STORY' | 'INSTANT_ARTICLE_RECIRCULATION_AD' | 'INSTANT_ARTICLE_STANDARD' | 'INSTREAM_BANNER_DESKTOP' | 'INSTREAM_BANNER_MOBILE' | 'INSTREAM_VIDEO_DESKTOP' | 'INSTREAM_VIDEO_IMAGE' | 'INSTREAM_VIDEO_MOBILE' | 'JOB_BROWSER_DESKTOP' | 'JOB_BROWSER_MOBILE' | 'MARKETPLACE_MOBILE' | 'MESSENGER_MOBILE_INBOX_MEDIA' | 'MESSENGER_MOBILE_STORY_MEDIA' | 'MOBILE_BANNER' | 'MOBILE_FEED_BASIC' | 'MOBILE_FEED_STANDARD' | 'MOBILE_FULLWIDTH' | 'MOBILE_INTERSTITIAL' | 'MOBILE_MEDIUM_RECTANGLE' | 'MOBILE_NATIVE' | 'RIGHT_COLUMN_STANDARD' | 'SUGGESTED_VIDEO_DESKTOP' | 'SUGGESTED_VIDEO_MOBILE' | 'WATCH_FEED_HOME' | 'WATCH_FEED_MOBILE', options?: any) {
        return CampaignsBranchApiFp(this.configuration).generateFacebookAdPreviewByBranchCampaignId(branchId, campaignId, adFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return campaigns of branch
     * @summary Return campaigns of branch
     * @param {string} branchId The id of the branch
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApi
     */
    public listBranchCampaigns(branchId: string, page?: number, perPage?: number, query?: object, options?: any) {
        return CampaignsBranchApiFp(this.configuration).listBranchCampaigns(branchId, page, perPage, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return leads of branch
     * @summary Return leads of branch
     * @param {string} campaignId The id of the campaign
     * @param {string} branchId The id of the branch
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApi
     */
    public listFacebookBranchCampaignLeadsOfBranchCampaign(campaignId: string, branchId: string, page?: number, perPage?: number, query?: object, options?: any) {
        return CampaignsBranchApiFp(this.configuration).listFacebookBranchCampaignLeadsOfBranchCampaign(campaignId, branchId, page, perPage, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Pause campaign and sync on facebook by branch campaign id if campaign is running
     * @summary Pause campaign and sync on facebook by branch campaign id
     * @param {string} branchId The id of the branch
     * @param {string} campaignId The id of the campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApi
     */
    public pauseFacebookCampaignByBranchCampaignId(branchId: string, campaignId: string, options?: any) {
        return CampaignsBranchApiFp(this.configuration).pauseFacebookCampaignByBranchCampaignId(branchId, campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * pause campaign and sync on google by branch campaign id if campaign is running
     * @summary Pause campaign and sync on google by branch campaign id
     * @param {string} branchId The id of the branch
     * @param {string} campaignId The id of the campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApi
     */
    public pauseGoogleCampaignByBranchCampaignId(branchId: string, campaignId: string, options?: any) {
        return CampaignsBranchApiFp(this.configuration).pauseGoogleCampaignByBranchCampaignId(branchId, campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Run campaign and sync on facebook by branch campaign id if campaign is paused or canceled 
     * @summary Run campaign and sync on facebook by branch campaign id
     * @param {string} branchId The id of the branch
     * @param {string} campaignId The id of the campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApi
     */
    public runFacebookCampaignByBranchCampaignId(branchId: string, campaignId: string, options?: any) {
        return CampaignsBranchApiFp(this.configuration).runFacebookCampaignByBranchCampaignId(branchId, campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Run campaign and sync on facebook by branch campaign id if campaign is paused
     * @summary Run campaign and sync on google by branch campaign id
     * @param {string} branchId The id of the branch
     * @param {string} campaignId The id of the campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApi
     */
    public runGoogleCampaignByBranchCampaignId(branchId: string, campaignId: string, options?: any) {
        return CampaignsBranchApiFp(this.configuration).runGoogleCampaignByBranchCampaignId(branchId, campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return campaign insights with by id
     * @summary Return campaign insights with by id
     * @param {string} branchId The id of the branch
     * @param {string} campaignId The id of the campaign
     * @param {string} startDate Start date of the date range for the insights
     * @param {string} endDate End date of the date range for the insights
     * @param {'day' | 'month' | 'week'} [granularity] Granularity of insights (default&#x3D;day if empty)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApi
     */
    public showBranchCampaignInsights(branchId: string, campaignId: string, startDate: string, endDate: string, granularity?: 'day' | 'month' | 'week', options?: any) {
        return CampaignsBranchApiFp(this.configuration).showBranchCampaignInsights(branchId, campaignId, startDate, endDate, granularity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return search term campaign insights with by id
     * @summary Return search term campaign insights with by id
     * @param {string} branchId The id of the branch
     * @param {string} campaignId The id of the campaign
     * @param {string} startDate Start date of the date range for the insights
     * @param {string} endDate End date of the date range for the insights
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApi
     */
    public showBranchCampaignSearchTermInsights(branchId: string, campaignId: string, startDate: string, endDate: string, options?: any) {
        return CampaignsBranchApiFp(this.configuration).showBranchCampaignSearchTermInsights(branchId, campaignId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return campaigns of branch
     * @summary Return campaigns of branch
     * @param {string} branchId The id of the branch
     * @param {string} campaignId The id of the campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApi
     */
    public showBranchCampaigns(branchId: string, campaignId: string, options?: any) {
        return CampaignsBranchApiFp(this.configuration).showBranchCampaigns(branchId, campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update campaigns of branch
     * @summary Update campaigns of branch
     * @param {string} branchId The id of the branch
     * @param {string} campaignId The id of the campaign
     * @param {BranchCampaignUpdateInput} branchCampaignUpdateInput Update a campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApi
     */
    public updateBranchCampaigns(branchId: string, campaignId: string, branchCampaignUpdateInput: BranchCampaignUpdateInput, options?: any) {
        return CampaignsBranchApiFp(this.configuration).updateBranchCampaigns(branchId, campaignId, branchCampaignUpdateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update campaigns of branch
     * @summary Update campaigns of branch
     * @param {string} branchId The id of the branch
     * @param {string} campaignId The id of the campaign
     * @param {GoogleBranchCampaignUpdateInput} googleBranchCampaignUpdateInput Update a campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsBranchApi
     */
    public updateGoogleBranchCampaigns(branchId: string, campaignId: string, googleBranchCampaignUpdateInput: GoogleBranchCampaignUpdateInput, options?: any) {
        return CampaignsBranchApiFp(this.configuration).updateGoogleBranchCampaigns(branchId, campaignId, googleBranchCampaignUpdateInput, options).then((request) => request(this.axios, this.basePath));
    }
}

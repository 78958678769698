import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18.534" height="18.531" viewBox="0 0 18.534 18.531" {...props}>
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_1564"
          data-name="Rectangle 1564"
          width="18.534"
          height="18.531"
          transform="translate(0 0)"
          fill="currentColor"
        />
      </clipPath>
    </defs>
    <g id="Group_4028" data-name="Group 4028" transform="translate(0 0)">
      <g id="Group_2934" data-name="Group 2934" clipPath="url(#clip-path)">
        <path
          id="Path_1515"
          data-name="Path 1515"
          d="M16.354,10.305H12.485a2.18,2.18,0,0,0-2.18,2.182v3.862a2.185,2.185,0,0,0,2.182,2.182h3.865a2.183,2.183,0,0,0,2.182-2.182V12.487a2.184,2.184,0,0,0-2.18-2.182m.391,2.182v3.868a.4.4,0,0,1-.4.4H12.487a.4.4,0,0,1-.394-.4V12.487a.4.4,0,0,1,.394-.4H16.35a.4.4,0,0,1,.4.4"
          fill="currentColor"
        />
        <path
          id="Path_1516"
          data-name="Path 1516"
          d="M6.691,9.661H2.182A2.186,2.186,0,0,0,0,11.843V16.35a2.185,2.185,0,0,0,2.182,2.181H6.691A2.184,2.184,0,0,0,8.873,16.35V11.843A2.185,2.185,0,0,0,6.691,9.661m.394,2.182V16.35a.393.393,0,0,1-.394.393H2.18a.393.393,0,0,1-.392-.393V11.843a.4.4,0,0,1,.394-.4H6.691a.394.394,0,0,1,.394.4"
          fill="currentColor"
        />
        <path
          id="Path_1517"
          data-name="Path 1517"
          d="M2.182,8.229H6.05A2.185,2.185,0,0,0,8.228,6.05V2.182A2.184,2.184,0,0,0,6.05,0H2.182A2.185,2.185,0,0,0,0,2.182V6.051A2.186,2.186,0,0,0,2.182,8.229M1.788,6.05V2.179a.4.4,0,0,1,.394-.395H6.05a.4.4,0,0,1,.395.395V6.05a.4.4,0,0,1-.395.394H2.18a.394.394,0,0,1-.392-.394"
          fill="currentColor"
        />
        <path
          id="Path_1518"
          data-name="Path 1518"
          d="M16.35,8.873h0a2.184,2.184,0,0,0,2.181-2.182V2.182A2.186,2.186,0,0,0,16.35,0H11.841a2.182,2.182,0,0,0-2.18,2.182V6.691a2.183,2.183,0,0,0,2.182,2.182Zm.4-6.691V6.691a.4.4,0,0,1-.4.395H11.844a.4.4,0,0,1-.4-.395V2.182a.4.4,0,0,1,.4-.394h4.51a.387.387,0,0,1,.276.114.393.393,0,0,1,.116.28"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default SvgComponent;

const LOCALES = Object.freeze([
  { name: 'English (US)', key: 6 },
  { name: 'Catalan', key: 1 },
  { name: 'Czech', key: 2 },
  { name: 'Cebuano', key: 56 },
  { name: 'Welsh', key: 3 },
  { name: 'Danish', key: 4 },
  { name: 'German', key: 5 },
  { name: 'Basque', key: 59 },
  { name: 'Spanish', key: 23 },
  { name: 'Spanish (Spain)', key: 7 },
  { name: 'Guarani', key: 66 },
  { name: 'Finnish', key: 8 },
  { name: 'French (France)', key: 9 },
  { name: 'Galician', key: 65 },
  { name: 'Hungarian', key: 30 },
  { name: 'Italian', key: 10 },
  { name: 'Japanese', key: 11 },
  { name: 'Korean', key: 12 },
  { name: 'Norwegian (bokmal)', key: 13 },
  { name: 'Norwegian (nynorsk)', key: 84 },
  { name: 'Dutch', key: 14 },
  { name: 'Frisian', key: 63 },
  { name: 'Polish', key: 15 },
  { name: 'Portuguese (Brazil)', key: 16 },
  { name: 'Portuguese (Portugal)', key: 31 },
  { name: 'Romanian', key: 32 },
  { name: 'Russian', key: 17 },
  { name: 'Slovak', key: 33 },
  { name: 'Slovenian', key: 34 },
  { name: 'Swedish', key: 18 },
  { name: 'Thai', key: 35 },
  { name: 'Turkish', key: 19 },
  { name: 'Kurdish (Kurmanji)', key: 76 },
  { name: 'Simplified Chinese (China)', key: 20 },
  { name: 'Traditional Chinese (Hong Kong)', key: 21 },
  { name: 'Traditional Chinese (Taiwan)', key: 22 },
  { name: 'Afrikaans', key: 36 },
  { name: 'Albanian', key: 87 },
  { name: 'Armenian', key: 68 },
  { name: 'Azerbaijani', key: 53 },
  { name: 'Belarusian', key: 54 },
  { name: 'Bengali', key: 45 },
  { name: 'Bosnian', key: 55 },
  { name: 'Bulgarian', key: 37 },
  { name: 'Croatian', key: 38 },
  { name: 'Flemish', key: 83 },
  { name: 'English (UK)', key: 24 },
  { name: 'Esperanto', key: 57 },
  { name: 'Estonian', key: 58 },
  { name: 'Faroese', key: 62 },
  { name: 'French (Canada)', key: 44 },
  { name: 'Georgian', key: 72 },
  { name: 'Greek', key: 39 },
  { name: 'Gujarati', key: 67 },
  { name: 'Hindi', key: 46 },
  { name: 'Icelandic', key: 69 },
  { name: 'Indonesian', key: 25 },
  { name: 'Irish', key: 64 },
  { name: 'Javanese', key: 71 },
  { name: 'Kannada', key: 75 },
  { name: 'Kazakh', key: 73 },
  { name: 'Latvian', key: 78 },
  { name: 'Lithuanian', key: 40 },
  { name: 'Macedonian', key: 79 },
  { name: 'Malay', key: 41 },
  { name: 'Marathi', key: 81 },
  { name: 'Mongolian', key: 80 },
  { name: 'Nepali', key: 82 },
  { name: 'Punjabi', key: 47 },
  { name: 'Serbian', key: 42 },
  { name: 'Swahili', key: 88 },
  { name: 'Filipino', key: 26 },
  { name: 'Tamil', key: 48 },
  { name: 'Telugu', key: 49 },
  { name: 'Malayalam', key: 50 },
  { name: 'Ukrainian', key: 52 },
  { name: 'Uzbek', key: 91 },
  { name: 'Vietnamese', key: 27 },
  { name: 'Khmer', key: 74 },
  { name: 'Tajik', key: 89 },
  { name: 'Arabic', key: 28 },
  { name: 'Hebrew', key: 29 },
  { name: 'Urdu', key: 90 },
  { name: 'Persian', key: 60 },
  { name: 'Pashto', key: 85 },
  { name: 'Sinhala', key: 86 },
  { name: 'Japanese (Kansai)', key: 70 },
  { name: 'English (All)', key: 1001 },
  { name: 'Spanish (All)', key: 1002 },
  { name: 'French (All)', key: 1003 },
  { name: 'Chinese (All)', key: 1004 },
  { name: 'Portuguese (All)', key: 1005 },
]);

export default LOCALES;

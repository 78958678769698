import type { Branch as BranchType } from '@app/api';
import type { BranchCompany, BranchCompanyFeatureFlags, CurrentFacebookPageResponse } from '@app/api';
import { PUBLISHER_PLATFORMS } from '@app/constants';

function currency(branch: BranchType) {
  const DEFAULT_CURRENCY = 'TRY';

  if (!branch) {
    return DEFAULT_CURRENCY;
  }

  if (!branch?.facebook_integration) {
    return DEFAULT_CURRENCY;
  }

  if (!branch?.facebook_integration?.ad_account_currency) {
    return DEFAULT_CURRENCY;
  }

  return branch.facebook_integration.ad_account_currency;
}

function integration(branch: BranchType) {
  if (!branch) {
    return null;
  }

  if (!branch?.facebook_integration) {
    return null;
  }

  return branch.facebook_integration;
}

function googleIntegration(branch: any) {
  if (!branch) {
    return null;
  }

  if (!branch?.google_integration) {
    return null;
  }

  return branch.google_integration;
}

function facebookPage(branch: any) {
  if (!branch) {
    return null;
  }

  if (!branch?.facebook_integration?.page_id) {
    return null;
  }
  return branch.facebook_integration.page_id;
}

function instagramPage(branch: any) {
  if (!branch) {
    return null;
  }

  if (!branch?.facebook_integration?.instagram_actor_id) {
    return null;
  }

  return branch.facebook_integration.instagram_actor_id;
}

function logo(company: BranchCompany) {
  if (!company?.logo) {
    return {
      url: null,
      thumbnailUrl: null,
    };
  }

  return {
    url: company?.logo?.url ?? null,
    thumbnailUrl: company.logo?.thumb?.url ?? null,
  };
}

function isLegalInformationsComplete(branch: BranchType) {
  if (shouldValidateLegalForm(branch?.company?.feature_flags)) {
    if (!branch?.legal_information?.legal_address) {
      return false;
    }

    if (!branch?.legal_information?.legal_phone_number) {
      return false;
    }

    if (!branch?.legal_information?.legal_name) {
      return false;
    }

    if (!branch?.legal_information?.legal_crs_number) {
      return false;
    }
  }

  return true;
}

function shouldPublishPost(featureInfo: BranchCompanyFeatureFlags | undefined) {
  return featureInfo?.has_social_media_feature ?? false;
}

function shouldValidateLegalForm(featureInfo: BranchCompanyFeatureFlags | undefined) {
  return featureInfo?.has_custom_lead_privacy_policy_feature ?? false;
}

function handleSocialMediaAccount(accountList: Array<CurrentFacebookPageResponse>) {
  const instagramAccounts = [] as any;

  const facebookAccounts = accountList?.map((item) => ({
    value: item.id,
    name: item.name,
    image: item.picture.url,
    platform: PUBLISHER_PLATFORMS.FACEBOOK,
    instagram_business_account_id: null,
  }));

  accountList?.map(
    (item: any) =>
      item.instagram_business_account_id &&
      item?.instagram_accounts?.map((account: { id: string; username: string; profile_pic: string }) =>
        instagramAccounts.push({
          value: account.id,
          name: account.username,
          image: account.profile_pic,
          platform: PUBLISHER_PLATFORMS.INSTAGRAM,
          instagram_business_account_id: item.instagram_business_account_id,
        })
      )
  );

  return facebookAccounts?.concat(instagramAccounts);
}

function isAcceptedAgreement(branch: BranchType, agreement: string): boolean {
  return !!branch.owner?.accepted_agreements?.find((e) => e.name == agreement);
}

function shouldFileManager(branch: BranchType) {
  return branch?.company?.feature_flags?.has_file_manager_feature ?? false;
}

function shouldCampaignFeature(branch: BranchType) {
  return branch?.company?.feature_flags?.has_campaign_feature ?? false;
}

function minimumBudgetBranch(branch: BranchType | null) {
  return Number(branch?.company?.minimum_budget);
}

function minCampaignDateRange(branch: BranchType) {
  return branch.company?.min_campaign_date_range_in_days || 1;
}

function regionLanguageBranch(branch: BranchType) {
  return branch?.company?.region_language || 'tr';
}

function regionCpm(branch: BranchType) {
  return regionLanguageBranch(branch) === 'tr' ? 12 : 1.5;
}

export default {
  currency,
  integration,
  googleIntegration,
  logo,
  facebookPage,
  instagramPage,
  isLegalInformationsComplete,
  shouldPublishPost,
  shouldValidateLegalForm,
  handleSocialMediaAccount,
  isAcceptedAgreement,
  shouldFileManager,
  shouldCampaignFeature,
  minimumBudgetBranch,
  minCampaignDateRange,
  regionLanguageBranch,
  regionCpm,
};

/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GoogleLocationsResponse } from '../model';
/**
 * GoogleBusinessBranchApi - axios parameter creator
 * @export
 */
export const GoogleBusinessBranchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Return locations of google
         * @summary Return locations of google
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGoogleBusinessLocations: async (branchId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('listGoogleBusinessLocations', 'branchId', branchId)
            const localVarPath = `/branch_account/branches/{branch_id}/google/business/locations`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GoogleBusinessBranchApi - functional programming interface
 * @export
 */
export const GoogleBusinessBranchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GoogleBusinessBranchApiAxiosParamCreator(configuration)
    return {
        /**
         * Return locations of google
         * @summary Return locations of google
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGoogleBusinessLocations(branchId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoogleLocationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGoogleBusinessLocations(branchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GoogleBusinessBranchApi - factory interface
 * @export
 */
export const GoogleBusinessBranchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GoogleBusinessBranchApiFp(configuration)
    return {
        /**
         * Return locations of google
         * @summary Return locations of google
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGoogleBusinessLocations(branchId: string, options?: any): AxiosPromise<GoogleLocationsResponse> {
            return localVarFp.listGoogleBusinessLocations(branchId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GoogleBusinessBranchApi - interface
 * @export
 * @interface GoogleBusinessBranchApi
 */
export interface GoogleBusinessBranchApiInterface {
    /**
     * Return locations of google
     * @summary Return locations of google
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleBusinessBranchApiInterface
     */
    listGoogleBusinessLocations(branchId: string, options?: any): AxiosPromise<GoogleLocationsResponse>;

}

/**
 * GoogleBusinessBranchApi - object-oriented interface
 * @export
 * @class GoogleBusinessBranchApi
 * @extends {BaseAPI}
 */
export class GoogleBusinessBranchApi extends BaseAPI implements GoogleBusinessBranchApiInterface {
    /**
     * Return locations of google
     * @summary Return locations of google
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleBusinessBranchApi
     */
    public listGoogleBusinessLocations(branchId: string, options?: any) {
        return GoogleBusinessBranchApiFp(this.configuration).listGoogleBusinessLocations(branchId, options).then((request) => request(this.axios, this.basePath));
    }
}

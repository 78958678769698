import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.01 18.555" {...props}>
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_1499" data-name="Rectangle 1499" transform="translate(0 0)" fill="currentColor" />
      </clipPath>
    </defs>
    <g id="Group_4253" data-name="Group 4253" transform="translate(0 0)">
      <g id="Group_218" data-name="Group 218">
        <path
          id="Path_1065"
          data-name="Path 1065"
          d="M15.532,1.545H13.393V.8a.805.805,0,0,0-1.61,0v.742H6.159V.8a.805.805,0,0,0-1.61,0v.742H2.477A2.48,2.48,0,0,0,0,4.022V16.077a2.48,2.48,0,0,0,2.478,2.477H15.532a2.475,2.475,0,0,0,2.477-2.477V4.022a2.48,2.48,0,0,0-2.477-2.477M1.609,7.148H16.4v8.929a.87.87,0,0,1-.869.868H2.477a.869.869,0,0,1-.868-.868Zm3.744-2.62a.81.81,0,0,0,.806-.8v-.57h5.624v.57a.806.806,0,0,0,1.377.567.8.8,0,0,0,.233-.567v-.57h2.139a.87.87,0,0,1,.869.868V5.541H1.609V4.022a.869.869,0,0,1,.868-.868H4.549v.57a.8.8,0,0,0,.8.8"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default SvgComponent;

/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UploadGoogleImageAttachmentInput
 */
export interface UploadGoogleImageAttachmentInput {
    /**
     * 
     * @type {object}
     * @memberof UploadGoogleImageAttachmentInput
     */
    file: object;
    /**
     * 
     * @type {object}
     * @memberof UploadGoogleImageAttachmentInput
     */
    extra?: object;
    /**
     * 
     * @type {string}
     * @memberof UploadGoogleImageAttachmentInput
     */
    placement_type?: UploadGoogleImageAttachmentInputPlacementTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UploadGoogleImageAttachmentInputPlacementTypeEnum {
    MarketingImage = 'MARKETING_IMAGE',
    SquareMarketingImage = 'SQUARE_MARKETING_IMAGE',
    PortraitMarketingImage = 'PORTRAIT_MARKETING_IMAGE',
    Logo = 'LOGO'
}




const ICON_TYPE = Object.freeze({
  AGE_RANGE: 'AgeRange',
  CALL: 'CALL',
  CAMPAIGN_LIST: 'CampaignList',
  DATE: 'Date',
  GENDER: 'Gender',
  INTEREST: 'Interest',
  LANGUAGE: 'Language',
  LEAD_GENERATION: 'LEAD_GENERATION',
  LOCATION: 'Location',
  ALL_MESSAGE: 'ALL_MESSAGE',
  MESSENGER: 'MESSENGER',
  PERSON: 'Person',
  PLATFORM: 'Platform',
  WHATSAPP_MESSAGE: 'WHATSAPP_MESSAGE',
  FINAL_URL: 'FINAL_URL',
  INSTAGRAM_VISIT: 'INSTAGRAM_VISIT',
  SEARCH: 'Search',
  IMAGE: 'Image',
  VIDEO: 'Video',
  PMAX: 'Pmax',
  SMART: 'Smart',
  KEYWORDS: 'Keywords',
});

export default ICON_TYPE;

import cn from 'classnames';

import { Button } from '@app/components';

type NoContentPropTypes = {
  title: string;
  description: string;
  Icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  button?: {
    label: string;
    Icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
    href: string;
  };
  className?: string;
};

const NoContent: React.FC<NoContentPropTypes> = ({ title, description, Icon, button: buttonProps, className }) => {
  return (
    <div className={cn('flex flex-col items-center text-center bg-white py-32 px-5 rounded-3 overflow-hidden drop-shadow-box', className)}>
      {Icon && <Icon className="text-9xl text-gray-500" />}
      <h4 className="font-semibold text-gray-500 my-4">{title}</h4>
      <p className="text-sm text-gray-500 md:w-4/6 mb-6">{description}</p>
      {buttonProps && (
        <Button theme="blue" label={buttonProps.label} IconLeft={buttonProps.Icon} block={false} size="md" href={buttonProps.href} />
      )}
    </div>
  );
};

export default NoContent;

import React from 'react';

import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import { Icons } from '@app/components';
import { useLocale } from '@app/hooks';
import type { TemplateAttachments } from '@app/api';

type DownloadImagesPropTypes = {
  attachments: Array<TemplateAttachments>;
};

const DownloadImages: React.FC<DownloadImagesPropTypes> = ({ attachments }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.PreviewGroup.DownloadImages',
  };
  const { t } = useLocale();

  async function handleDownloadWithZip() {
    const zip = new JSZip();

    for (const attachment of attachments) {
      if (!attachment?.file?.url) {
        continue;
      }

      const blobImage = await fetch(`${attachment.file.url}?not-from-cache-please`);

      zip.file(`image-${attachment.id}.jpeg`, blobImage.blob());
    }

    zip.generateAsync({ type: 'blob' }).then(function (content: any) {
      saveAs(content, 'images.zip');
    });
  }
  return (
    <button
      className="flex items-center space-x-2 border border-black-800 hover:border-gray-600 hover:text-gray-600 px-3 py-2 w-64 rounded-2 mt-3"
      onClick={handleDownloadWithZip}
    >
      <Icons.Export className="w-5 h-5" />
      <span className="text-3.5"> {t('actions.downloadImages', SCOPE_OPTIONS)}</span>
    </button>
  );
};

export default DownloadImages;

const DESTINATION_TYPES = Object.freeze({
  WEBSITE: 'WEBSITE',
  APP: 'APP',
  MESSENGER: 'MESSENGER',
  INSTAGRAM_DIRECT: 'INSTAGRAM_DIRECT',
  WHATSAPP: 'WHATSAPP',
  PHONE_CALL: 'PHONE_CALL',
  LEAD_GENERATION: 'LEAD_GENERATION',
});

export default DESTINATION_TYPES;

import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 29.069 18.554" {...props}>
    <defs>
      <clipPath>
        <rect data-name="Rectangle 1620" width="29.069" height="18.554" fill="currentColor" />
      </clipPath>
    </defs>
    <g data-name="Group 3105" transform="translate(0 0)">
      <g data-name="Group 3104" transform="translate(0 0)">
        <path
          data-name="Path 1713"
          d="M25.576,0a.927.927,0,1,0,.1,1.852H25.9L24.254,3.5a7.679,7.679,0,0,0-9.332-.135A7.706,7.706,0,0,0,3.544,13.706L2.2,15.046l-.579-.578a.924.924,0,1,0-1.312,1.3l.579.588-.579.579a.926.926,0,1,0,1.312,1.3l.579-.578.578.578a.926.926,0,1,0,1.313-1.3l-.579-.578,1.332-1.341a7.711,7.711,0,0,0,9.34.145A7.713,7.713,0,0,0,25.566,4.8l1.65-1.649V3.4a.927.927,0,1,0,1.853,0V.927A.927.927,0,0,0,28.142,0h-2.47Q25.624,0,25.576,0ZM9.615,3.087A5.838,5.838,0,0,1,13.571,4.63a7.69,7.69,0,0,0-.569,9.1A5.863,5.863,0,1,1,9.615,3.087ZM19.5,3.7A5.864,5.864,0,1,1,15.54,13.9a7.694,7.694,0,0,0,.569-9.1A5.831,5.831,0,0,1,19.5,3.7ZM14.758,6.116a5.892,5.892,0,0,1-.4,6.3,5.892,5.892,0,0,1,.4-6.3Z"
          transform="translate(0 0)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default SvgComponent;

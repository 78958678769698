import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { BranchesBranchUserApi } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function markFacebookModalAsFalse(branchId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new BranchesBranchUserApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.markFacebookModalAsFalse(branchId).then((res) => res.data);
}

export default function useMarkFacebookModalAsFalse(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useMutation(() => markFacebookModalAsFalse(branchId, axiosInstance), props);
}

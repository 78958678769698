import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { CampaignsBranchApi } from '@app/api';
import type { BranchCampaignUpdateInput } from '@app/api';

import { useAxios } from '@app/hooks';

export function updateBranchCampaign(
  branchId: string,
  campaignId: string,
  branchCampaignUpdateInput: BranchCampaignUpdateInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new CampaignsBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.updateBranchCampaigns(branchId, campaignId, branchCampaignUpdateInput).then((res) => res.data);
}

export default function useUpdateBranchCampaign(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { branchId: string; campaignId: string; branchCampaignUpdateInput: BranchCampaignUpdateInput }) =>
      updateBranchCampaign(values.branchId, values.campaignId, values.branchCampaignUpdateInput, axiosInstance),
    props
  );
}

import React, { useEffect } from 'react';

import { useRouter } from 'next/router';

import { Box, Facebook } from '@app/components';
import { api, useAuth, useLocale, useToast } from '@app/hooks';
import { branch as branchLib, currency, format } from '@app/lib';

type BalanceFormPropTypes = {
  className?: string;
};

const BalanceForm: React.FC<BalanceFormPropTypes> = ({ className }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Settings.BalanceCard',
  };
  const { t } = useLocale();
  const router = useRouter();
  const { branch } = useAuth();
  const toast = useToast();
  const branchCurrency = branchLib.currency(branch);
  const branchintegration = branchLib.integration(branch);
  const { data: facebookAccount, isLoading } = api.useGetBranchFacebookAccount({
    enabled: !!branchintegration,
  });
  const balance = currency.handleCents(facebookAccount?.balance);
  const isBiggerThanThousand = (facebookAccount?.balance ?? 0) > 100000;
  const hasFundingSource = facebookAccount?.funding_source ? true : false;

  useEffect(() => {
    toast.dismissAll();

    if (!isLoading && hasFundingSource === false && branchintegration) {
      toast.error(t('messages.fundingSource', SCOPE_OPTIONS), {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      });
    }
  }, [isLoading, hasFundingSource, branchintegration]);

  function handleModalClose() {
    setTimeout(() => {
      router.reload();
    }, 100);
  }

  return (
    <Box className={className}>
      <div className="flex flex-col">
        <span className="font-semibold text-4 text-gray-900">{t('labels.title', SCOPE_OPTIONS)}</span>
        <p className="font-regular text-3.5 text-gray-400">{t('labels.description', SCOPE_OPTIONS)}</p>
      </div>
      <div className="flex flex-row justify-between items-center mt-7.5">
        <span className="font-semibold text-10 text-blue-300">
          {format.money(balance, branchCurrency, { isCompact: isBiggerThanThousand })}
        </span>

        {!facebookAccount?.funding_source && (
          <Facebook.AdsPayment onModalClose={handleModalClose} label={t('actions.add', SCOPE_OPTIONS)} />
        )}
      </div>
    </Box>
  );
};

export default BalanceForm;

import { Button, Icons } from '@app/components';
import { useAuth, useLocale, useToast } from '@app/hooks';
import { array, date, format, branch as branchLib } from '@app/lib';
import { PUBLISHER_PLATFORMS, CAMPAIGN_STATUS } from '@app/constants';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import CardImage from './CardImage';
import { Wallet } from 'api/model';

type CardPropTypes = {
  id: string;
  name: string;
  image: string | undefined;
  platforms?: string[];
  deliveryStatus?: string;
  endDate?: any;
  wallet: Wallet | undefined;
};

const Card: React.FC<CardPropTypes> = ({ id, name, endDate, image, platforms, wallet }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.Card.index',
  };
  const { t, locale } = useLocale();
  const [hasDifferenceDate, setHasDifferenceDate] = useState('');
  const [disabled, setDisabled] = useState(false);
  const toast = useToast();
  const { branch } = useAuth();
  const router = useRouter();
  const branchFacebookintegration = branchLib.integration(branch);
  const branchGoogleintegration = branchLib.googleIntegration(branch);
  const start = date.handleISODate(new Date().toISOString());
  const end = date.handleISODate(endDate);
  const differenceDate = end.diff(start, ['months', 'days', 'hours']).toObject();
  const isGooglePlatform = (platforms as string[])?.includes(PUBLISHER_PLATFORMS.GOOGLE);

  useEffect(() => {
    if (end < start) {
      setHasDifferenceDate(CAMPAIGN_STATUS.EXPIRED);
    }
    if (!endDate) {
      setHasDifferenceDate(CAMPAIGN_STATUS.INDEFINITE);
    }

    if (hasDifferenceDate === CAMPAIGN_STATUS.EXPIRED) {
      setDisabled(true);
    }
  }, [hasDifferenceDate]);

  function getDifferenceDate() {
    if (differenceDate.months) {
      return (
        <div className="text-green-500 space-x-1 text-sm">
          <span>{t('labels.end', SCOPE_OPTIONS)}</span>
          <span>{differenceDate.months}</span>
          <span>{t('labels.month', SCOPE_OPTIONS)}</span>
        </div>
      );
    }
    if (!differenceDate.months && differenceDate.days) {
      return (
        <div className="text-green-500 space-x-1 text-sm">
          <span>{t('labels.end', SCOPE_OPTIONS)}</span>
          <span>{differenceDate.days}</span>
          <span>{t('labels.day', SCOPE_OPTIONS)}</span>
        </div>
      );
    }
    if (!differenceDate.months && !differenceDate.days && differenceDate.hours) {
      return (
        <div className="text-red-700 space-x-1 text-sm">
          <span>{t('labels.end', SCOPE_OPTIONS)}</span>
          <span>{format.number(differenceDate.hours, { isCompact: true, precision: 0, locale: locale })}</span>
          <span>{t('labels.hour', SCOPE_OPTIONS)}</span>
        </div>
      );
    }

    if (!hasDifferenceDate) {
      return null;
    }

    return (
      <span className={cn('text-green-500 text-sm', { 'text-red-700 text-sm': hasDifferenceDate === CAMPAIGN_STATUS.EXPIRED })}>
        {t(`labels.${hasDifferenceDate}`, SCOPE_OPTIONS)}
      </span>
    );
  }

  function campaignJoin() {
    if (typeof wallet?.balance === 'number' && wallet?.balance <= Number(branch.company?.minimum_budget || 0)) {
      toast.error(t('message.insufficientBalance', SCOPE_OPTIONS));
      router.push('/wallet');
      return;
    }

    if (!branchFacebookintegration && !isGooglePlatform) {
      return toast.error(t('messages.noJoin', SCOPE_OPTIONS));
    }

    if (!branchGoogleintegration && isGooglePlatform) {
      return toast.error(t('messages.noGoogleJoin', SCOPE_OPTIONS));
    }

    return isGooglePlatform ? router.push(`/campaigns/create/google/${id}`) : router.push(`/campaigns/create/${id}`);
  }

  return (
    <div className="flex flex-col bg-white py-3 px-4 rounded-2xl shadow-sm border-0.5 border-transparent hover:border-blue-500 hover:ease-in hover:duration-150">
      <div className="flex items-center justify-between w-full">
        <h5 className="text-sm text-black-800 leading-4 cursor-default line-clamp-2" title={name}>
          {name}
        </h5>

        <Button
          label={t('actions.join', SCOPE_OPTIONS)}
          theme="green"
          size="xs"
          block={false}
          className="px-3 py-2 rounded-xl ml-2 font-normal"
          disabled={disabled}
          onClick={() => campaignJoin()}
        />
      </div>
      <CardImage image={image} platforms={platforms} name={name} />
      <span className="mt-auto">
        <div className="flex items-start justify-between">
          <div className="flex flex-col text-sm">
            <span className="pt-px">{t('labels.endDate', SCOPE_OPTIONS)}</span>
            <span className="text-sm mt-1 text-gray-500">{endDate ? date.format(endDate, 'dd.LL.yyyy - HH:mm') : '-'}</span>
          </div>
          {!array.isEmpty(platforms) && (
            <div className="flex items-center gap-x-2 ml-auto">
              {platforms?.includes(PUBLISHER_PLATFORMS.FACEBOOK) && <Icons.Facebook className="w-5 h-5 text-black-800" />}
              {platforms?.includes(PUBLISHER_PLATFORMS.INSTAGRAM) && <Icons.Instagram className="w-5 h-5 text-black-800" />}
              {platforms?.includes(PUBLISHER_PLATFORMS.GOOGLE) && <Icons.GoogleIcon className="w-5 h-5 text-black-800" />}
            </div>
          )}
        </div>
        <div className="flex items-center space-x-1 mt-4">
          {!!getDifferenceDate() && (
            <span className="font-semibold text-green-500">
              <Icons.ClockCircle className="w-5 h-5" />
            </span>
          )}
          {getDifferenceDate()}
        </div>
      </span>
    </div>
  );
};

export default Card;

import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="Group_3009"
    data-name="Group 3009"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="14.977"
    height="14.956"
    viewBox="0 0 14.977 14.956"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_1583" data-name="Rectangle 1583" width="14.977" height="14.956" fill="currentColor" />
      </clipPath>
    </defs>
    <g id="Group_3009-2" data-name="Group 3009" transform="translate(0 0)">
      <path
        id="Path_1542"
        data-name="Path 1542"
        d="M14.977,3.324a.731.731,0,0,0-.21-.51L12.157.2a.743.743,0,0,0-1.021,0L1.106,10.229a.722.722,0,0,0-.188.332L.006,14.082a.628.628,0,0,0,0,.155.753.753,0,0,0,.036.214l.019.085a.727.727,0,0,0,.653.42h11.12a.724.724,0,1,0,0-1.448H5.092l9.675-9.675a.719.719,0,0,0,.21-.51m-4.051-.874.708-.72,1.592,1.592-.716.711Zm.567,2.594L3.849,12.688l-2.123.531.531-2.123L9.9,3.45Z"
        transform="translate(0 0)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default SvgComponent;

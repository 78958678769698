/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { InlineObject } from '../model';
// @ts-ignore
import { InlineResponse200 } from '../model';
// @ts-ignore
import { V2GetCardsResponse } from '../model';
// @ts-ignore
import { V2WalletBalanceInput } from '../model';
// @ts-ignore
import { V2WalletBalanceSuccessResponse } from '../model';
// @ts-ignore
import { V2WalletTransactionsSuccessResponse } from '../model';
// @ts-ignore
import { Wallet } from '../model';
/**
 * WalletBranchV2Api - axios parameter creator
 * @export
 */
export const WalletBranchV2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add wallet balance
         * @summary Add wallet balance
         * @param {string} branchId The id of the branch
         * @param {V2WalletBalanceInput} v2WalletBalanceInput Wallet balance request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWalletBalance: async (branchId: string, v2WalletBalanceInput: V2WalletBalanceInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('addWalletBalance', 'branchId', branchId)
            // verify required parameter 'v2WalletBalanceInput' is not null or undefined
            assertParamExists('addWalletBalance', 'v2WalletBalanceInput', v2WalletBalanceInput)
            const localVarPath = `/v2/branch_account/branches/{branch_id}/wallet/balance`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v2WalletBalanceInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a saved card
         * @summary Delete a saved card
         * @param {string} branchId The id of the branch
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSavedCard: async (branchId: string, inlineObject: InlineObject, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('deleteSavedCard', 'branchId', branchId)
            // verify required parameter 'inlineObject' is not null or undefined
            assertParamExists('deleteSavedCard', 'inlineObject', inlineObject)
            const localVarPath = `/v2/branch_account/branches/{branch_id}/wallet/cards/delete`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get branch wallet
         * @summary Get branch wallet
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranchWallet: async (branchId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('getBranchWallet', 'branchId', branchId)
            const localVarPath = `/v2/branch_account/branches/{branch_id}/wallet`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get saved cards
         * @summary Get saved cards
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSavedCards: async (branchId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('getSavedCards', 'branchId', branchId)
            const localVarPath = `/v2/branch_account/branches/{branch_id}/wallet/cards`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get wallet transactions
         * @summary Get wallet transactions
         * @param {string} branchId The id of the branch
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletTransactions: async (branchId: string, page?: number, perPage?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('getWalletTransactions', 'branchId', branchId)
            const localVarPath = `/v2/branch_account/branches/{branch_id}/wallet/transactions`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WalletBranchV2Api - functional programming interface
 * @export
 */
export const WalletBranchV2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WalletBranchV2ApiAxiosParamCreator(configuration)
    return {
        /**
         * Add wallet balance
         * @summary Add wallet balance
         * @param {string} branchId The id of the branch
         * @param {V2WalletBalanceInput} v2WalletBalanceInput Wallet balance request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addWalletBalance(branchId: string, v2WalletBalanceInput: V2WalletBalanceInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2WalletBalanceSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addWalletBalance(branchId, v2WalletBalanceInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a saved card
         * @summary Delete a saved card
         * @param {string} branchId The id of the branch
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSavedCard(branchId: string, inlineObject: InlineObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSavedCard(branchId, inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get branch wallet
         * @summary Get branch wallet
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBranchWallet(branchId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Wallet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBranchWallet(branchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get saved cards
         * @summary Get saved cards
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSavedCards(branchId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2GetCardsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSavedCards(branchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get wallet transactions
         * @summary Get wallet transactions
         * @param {string} branchId The id of the branch
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWalletTransactions(branchId: string, page?: number, perPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2WalletTransactionsSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWalletTransactions(branchId, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WalletBranchV2Api - factory interface
 * @export
 */
export const WalletBranchV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WalletBranchV2ApiFp(configuration)
    return {
        /**
         * Add wallet balance
         * @summary Add wallet balance
         * @param {string} branchId The id of the branch
         * @param {V2WalletBalanceInput} v2WalletBalanceInput Wallet balance request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWalletBalance(branchId: string, v2WalletBalanceInput: V2WalletBalanceInput, options?: any): AxiosPromise<V2WalletBalanceSuccessResponse> {
            return localVarFp.addWalletBalance(branchId, v2WalletBalanceInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a saved card
         * @summary Delete a saved card
         * @param {string} branchId The id of the branch
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSavedCard(branchId: string, inlineObject: InlineObject, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.deleteSavedCard(branchId, inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         * Get branch wallet
         * @summary Get branch wallet
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranchWallet(branchId: string, options?: any): AxiosPromise<Wallet> {
            return localVarFp.getBranchWallet(branchId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get saved cards
         * @summary Get saved cards
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSavedCards(branchId: string, options?: any): AxiosPromise<V2GetCardsResponse> {
            return localVarFp.getSavedCards(branchId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get wallet transactions
         * @summary Get wallet transactions
         * @param {string} branchId The id of the branch
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletTransactions(branchId: string, page?: number, perPage?: number, options?: any): AxiosPromise<V2WalletTransactionsSuccessResponse> {
            return localVarFp.getWalletTransactions(branchId, page, perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WalletBranchV2Api - interface
 * @export
 * @interface WalletBranchV2Api
 */
export interface WalletBranchV2ApiInterface {
    /**
     * Add wallet balance
     * @summary Add wallet balance
     * @param {string} branchId The id of the branch
     * @param {V2WalletBalanceInput} v2WalletBalanceInput Wallet balance request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletBranchV2ApiInterface
     */
    addWalletBalance(branchId: string, v2WalletBalanceInput: V2WalletBalanceInput, options?: any): AxiosPromise<V2WalletBalanceSuccessResponse>;

    /**
     * Delete a saved card
     * @summary Delete a saved card
     * @param {string} branchId The id of the branch
     * @param {InlineObject} inlineObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletBranchV2ApiInterface
     */
    deleteSavedCard(branchId: string, inlineObject: InlineObject, options?: any): AxiosPromise<InlineResponse200>;

    /**
     * Get branch wallet
     * @summary Get branch wallet
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletBranchV2ApiInterface
     */
    getBranchWallet(branchId: string, options?: any): AxiosPromise<Wallet>;

    /**
     * Get saved cards
     * @summary Get saved cards
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletBranchV2ApiInterface
     */
    getSavedCards(branchId: string, options?: any): AxiosPromise<V2GetCardsResponse>;

    /**
     * Get wallet transactions
     * @summary Get wallet transactions
     * @param {string} branchId The id of the branch
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletBranchV2ApiInterface
     */
    getWalletTransactions(branchId: string, page?: number, perPage?: number, options?: any): AxiosPromise<V2WalletTransactionsSuccessResponse>;

}

/**
 * WalletBranchV2Api - object-oriented interface
 * @export
 * @class WalletBranchV2Api
 * @extends {BaseAPI}
 */
export class WalletBranchV2Api extends BaseAPI implements WalletBranchV2ApiInterface {
    /**
     * Add wallet balance
     * @summary Add wallet balance
     * @param {string} branchId The id of the branch
     * @param {V2WalletBalanceInput} v2WalletBalanceInput Wallet balance request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletBranchV2Api
     */
    public addWalletBalance(branchId: string, v2WalletBalanceInput: V2WalletBalanceInput, options?: any) {
        return WalletBranchV2ApiFp(this.configuration).addWalletBalance(branchId, v2WalletBalanceInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a saved card
     * @summary Delete a saved card
     * @param {string} branchId The id of the branch
     * @param {InlineObject} inlineObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletBranchV2Api
     */
    public deleteSavedCard(branchId: string, inlineObject: InlineObject, options?: any) {
        return WalletBranchV2ApiFp(this.configuration).deleteSavedCard(branchId, inlineObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get branch wallet
     * @summary Get branch wallet
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletBranchV2Api
     */
    public getBranchWallet(branchId: string, options?: any) {
        return WalletBranchV2ApiFp(this.configuration).getBranchWallet(branchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get saved cards
     * @summary Get saved cards
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletBranchV2Api
     */
    public getSavedCards(branchId: string, options?: any) {
        return WalletBranchV2ApiFp(this.configuration).getSavedCards(branchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get wallet transactions
     * @summary Get wallet transactions
     * @param {string} branchId The id of the branch
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletBranchV2Api
     */
    public getWalletTransactions(branchId: string, page?: number, perPage?: number, options?: any) {
        return WalletBranchV2ApiFp(this.configuration).getWalletTransactions(branchId, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }
}

const CALL_TO_ACTION = Object.freeze({
  BOOK_TRAVEL: 'BOOK_TRAVEL',
  CONTACT_US: 'CONTACT_US',
  DONATE: 'DONATE',
  DONATE_NOW: 'DONATE_NOW',
  DOWNLOAD: 'DOWNLOAD',
  GET_DIRECTIONS: 'GET_DIRECTIONS',
  GO_LIVE: 'GO_LIVE',
  INTERESTED: 'INTERESTED',
  LEARN_MORE: 'LEARN_MORE',
  LIKE_PAGE: 'LIKE_PAGE',
  MESSAGE_PAGE: 'MESSAGE_PAGE',
  SAVE: 'SAVE',
  SEND_TIP: 'SEND_TIP',
  SHOP_NOW: 'SHOP_NOW',
  SIGN_UP: 'SIGN_UP',
  VIEW_INSTAGRAM_PROFILE: 'VIEW_INSTAGRAM_PROFILE',
  INSTAGRAM_MESSAGE: 'INSTAGRAM_MESSAGE',
  LOYALTY_LEARN_MORE: 'LOYALTY_LEARN_MORE',
  PURCHASE_GIFT_CARDS: 'PURCHASE_GIFT_CARDS',
  PAY_TO_ACCESS: 'PAY_TO_ACCESS',
  SEE_MORE: 'SEE_MORE',
  TRY_IN_CAMERA: 'TRY_IN_CAMERA',
  WHATSAPP_LINK: 'WHATSAPP_LINK',
  GET_MOBILE_APP: 'GET_MOBILE_APP',
  INSTALL_MOBILE_APP: 'INSTALL_MOBILE_APP',
  USE_MOBILE_APP: 'USE_MOBILE_APP',
  INSTALL_APP: 'INSTALL_APP',
  USE_APP: 'USE_APP',
  PLAY_GAME: 'PLAY_GAME',
  WATCH_VIDEO: 'WATCH_VIDEO',
  WATCH_MORE: 'WATCH_MORE',
  OPEN_LINK: 'OPEN_LINK',
  NO_BUTTON: 'NO_BUTTON',
  LISTEN_MUSIC: 'LISTEN_MUSIC',
  MOBILE_DOWNLOAD: 'MOBILE_DOWNLOAD',
  GET_OFFER: 'GET_OFFER',
  GET_OFFER_VIEW: 'GET_OFFER_VIEW',
  BUY_NOW: 'BUY_NOW',
  BUY_TICKETS: 'BUY_TICKETS',
  UPDATE_APP: 'UPDATE_APP',
  BET_NOW: 'BET_NOW',
  ADD_TO_CART: 'ADD_TO_CART',
  ORDER_NOW: 'ORDER_NOW',
  SELL_NOW: 'SELL_NOW',
  GET_SHOWTIMES: 'GET_SHOWTIMES',
  LISTEN_NOW: 'LISTEN_NOW',
  GET_EVENT_TICKETS: 'GET_EVENT_TICKETS',
  SEARCH_MORE: 'SEARCH_MORE',
  PRE_REGISTER: 'PRE_REGISTER',
  SWIPE_UP_PRODUCT: 'SWIPE_UP_PRODUCT',
  SWIPE_UP_SHOP: 'SWIPE_UP_SHOP',
  CALL: 'CALL',
  MISSED_CALL: 'MISSED_CALL',
  CALL_NOW: 'CALL_NOW',
  CALL_ME: 'CALL_ME',
  APPLY_NOW: 'APPLY_NOW',
  BUY: 'BUY',
  GET_QUOTE: 'GET_QUOTE',
  SUBSCRIBE: 'SUBSCRIBE',
  RECORD_NOW: 'RECORD_NOW',
  VOTE_NOW: 'VOTE_NOW',
  GIVE_FREE_RIDES: 'GIVE_FREE_RIDES',
  REGISTER_NOW: 'REGISTER_NOW',
  OPEN_MESSENGER_EXT: 'OPEN_MESSENGER_EXT',
  EVENT_RSVP: 'EVENT_RSVP',
  CIVIC_ACTION: 'CIVIC_ACTION',
  SEND_INVITES: 'SEND_INVITES',
  REFER_FRIENDS: 'REFER_FRIENDS',
  REQUEST_TIME: 'REQUEST_TIME',
  SEE_MENU: 'SEE_MENU',
  WHATSAPP_MESSAGE: 'WHATSAPP_MESSAGE',
  SEARCH: 'SEARCH',
  TRY_IT: 'TRY_IT',
  TRY_ON: 'TRY_ON',
  LINK_CARD: 'LINK_CARD',
  DIAL_CODE: 'DIAL_CODE',
  FIND_YOUR_GROUPS: 'FIND_YOUR_GROUPS',
  START_ORDER: 'START_ORDER',
  FINAL_URL: 'FINAL_URL',
  MESSENGER: 'MESSENGER',
  INSTAGRAM_VISIT: 'INSTAGRAM_VISIT',
  LEAD_GENERATION: 'LEAD_GENERATION',
});

export default CALL_TO_ACTION;

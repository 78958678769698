import React, { Fragment, useState, useImperativeHandle, forwardRef } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import cn from 'classnames';

import { Button } from '@app/components';

type ButtonType = {
  label: string;
  theme: 'blue' | 'green' | 'yellow' | 'gray';
};

type AlertPropTypes = {
  onConfirmClick?: () => void;
  onCancelClick?: () => void;
  onModalClose?: () => void;
  button: {
    confirm?: ButtonType;
    cancel?: ButtonType;
  };
  title: string;
  description?: string;
};

const Alert: React.ForwardRefRenderFunction<AlertRefTypes, React.PropsWithChildren<AlertPropTypes>> = (
  { onModalClose, onConfirmClick, onCancelClick, button, title, description, children },
  ref
) => {
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
  }));

  function closeModal() {
    setIsOpen(false);
    onModalClose && onModalClose();
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-999 overflow-y-auto" onClose={closeModal}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-blue-900 bg-opacity-40" />
          </Transition.Child>
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-4 my-8 overflow-hidden text-left align-middle transition-all transform rounded-3 bg-white">
              <Dialog.Title as="h3" className="text-5 font-medium text-gray-900">
                {title}
              </Dialog.Title>
              {description && <p className="mt-2 text-sm text-gray-600">{description}</p>}
              {children && children}
              <div
                className={cn('mt-4 grid', {
                  'grid-cols-2 gap-5': button.confirm && button.cancel,
                  'grid-cols-1': !button.confirm || !button.cancel,
                })}
              >
                {button.cancel && <Button size="sm" {...button.cancel} onClick={onCancelClick} />}
                {button.confirm && <Button size="sm" {...button.confirm} onClick={onConfirmClick} />}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default forwardRef(Alert);

function SortIcon({ upColor, downColor, ...props }: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8.464" height="12.763" viewBox="0 0 8.464 12.763" {...props}>
      <g fill="#181a27" data-name="Group 4087" transform="translate(-1290.55 -1086.417)">
        <path
          d="M1.459 4.231l3.2-3.2A.6.6 0 003.8.177L.176 3.8a.6.6 0 00-.018.834L3.8 8.288a.6.6 0 00.857-.854z"
          data-name="Icon ionic-ios-arrow-back"
          transform="rotate(-90 1194.865 -95.685)"
          fill={upColor}
        ></path>
        <path
          d="M1.459 4.233l3.2 3.2a.6.6 0 11-.857.854L.176 4.662a.6.6 0 01-.018-.834L3.8.176a.6.6 0 01.857.854z"
          data-name="Icon ionic-ios-arrow-back"
          transform="rotate(90 106.298 1192.716)"
          fill={downColor}
        ></path>
      </g>
    </svg>
  );
}

export default SortIcon;

import Script from 'next/script';
import React from 'react';

type SalesiqPropTypes = {
  widgetCode: string;
  domain: string;
  language: string;
  branchData: {
    id: string;
    name: string;
    owner: {
      id: string;
      email: string;
      name: string;
    };
  };
};

const Salesiq: React.FC<SalesiqPropTypes> = ({ widgetCode, domain, language, branchData }) => {
  if (widgetCode && domain && language) {
    return (
      <>
        <Script
          crossOrigin="anonymous"
          id="zoho-salesiq"
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
            window.$zoho=window.$zoho || {};
            $zoho.salesiq=$zoho.salesiq ||{ready:function(){
                $zoho.salesiq.language("${language}");
                $zoho.salesiq.visitor.info({"branch_id" : "${branchData.id}", "branch_name" : "${branchData.name}", "user_email" : "${branchData.owner.email}", "user_id" : "${branchData.owner.id}", "user_name" : "${branchData.owner.name}"});
              }
            };
          `,
          }}
        ></Script>
        <Script id="zsiqscript" src={`https://salesiq.zohopublic.eu/widget?wc=${widgetCode}`} crossOrigin="anonymous" defer></Script>
      </>
    );
  } else return null;
};

export default Salesiq;

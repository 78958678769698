import React from 'react';

import { format, branch as branchLib } from '@app/lib';
import { useAuth, useLocale } from '@app/hooks';
import { Box, Icons, Tooltip } from '@app/components';
import { ANALYTICS_TOTAL_CONTENT } from '@app/constants';

type CardPropTypes = {
  id: string;
  title: string;
  questionLabel: string;
  count: string | number;
  color: string;
  Icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
};

type LegendCardsPropTypes = {
  totalInsights: {
    total_impressions: string;
    total_spend: string;
    total_click_to_call_call_confirm: string;
    total_lead: string;
    total_messaging_conversation_started_7d: string;
    total_get_directions: string;
    total_contact: string;
  };
};

const Card: React.FC<CardPropTypes> = ({ id, title, questionLabel, count, color, Icon }) => {
  const { branch } = useAuth();
  const { locale } = useLocale();

  const currency = branchLib.currency(branch);
  const content =
    id === ANALYTICS_TOTAL_CONTENT.UP.IMPRESSION.KEY || id === ANALYTICS_TOTAL_CONTENT.UP.SPEND.KEY
      ? format.percent(Number(count), id, locale)
      : format.data(count ?? 0, currency, id, locale);

  return (
    <Box className="flex bg-white items-center px-5 h-full min-w-[306.66px] min-h-[100px] !mb-0 flex-1">
      <div className="relative flex justify-center items-center rounded-full p-3 mr-3 text-white" style={{ backgroundColor: color }}>
        <Icon className="w-10 h-10" />
      </div>
      <div className="flex justify-between items-center w-full">
        <span className="text-4 font-medium block text-left py-2 mr-auto">{title}</span>
        <span className="text-gray-900 font-semibold lg:text-6 xl:text-6 md:text-4.5 leading-10 block text-left overflow-ellipsis overflow-hidden">
          <span data-tip="" data-for={`content-text-${id}`}>
            {content}
          </span>
          <Tooltip id={`content-text-${id}`} content={content} />
        </span>
        <Icons.CircleQuestion className="w-5 h-5 ml-3" data-tip="" data-for={`question-icon-${id}`} />
        <Tooltip id={`question-icon-${id}`} content={questionLabel} />
      </div>
    </Box>
  );
};

const LegendCards: React.FC<LegendCardsPropTypes> = ({ totalInsights }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Analytics.Chart.LegendCards',
  };
  const { t } = useLocale();
  const { branch } = useAuth();

  function handleValue(key: string) {
    if (key === ANALYTICS_TOTAL_CONTENT.UP.IMPRESSION.KEY) {
      return totalInsights?.total_impressions ?? 0;
    }
    if (key === ANALYTICS_TOTAL_CONTENT.UP.SPEND.KEY) {
      return totalInsights?.total_spend ?? 0;
    }
    if (key === ANALYTICS_TOTAL_CONTENT.DOWN.GOOGLE_CALL.KEY) {
      return totalInsights?.total_contact ?? 0;
    }
    if (key === ANALYTICS_TOTAL_CONTENT.DOWN.META_CALL.KEY) {
      return totalInsights?.total_click_to_call_call_confirm ?? 0;
    }
    if (key === ANALYTICS_TOTAL_CONTENT.DOWN.FORM.KEY) {
      return totalInsights?.total_lead ?? 0;
    }
    if (key === ANALYTICS_TOTAL_CONTENT.DOWN.MESSAGE.KEY) {
      return totalInsights?.total_messaging_conversation_started_7d ?? 0;
    }
    if (key === ANALYTICS_TOTAL_CONTENT.DOWN.DIRECTION.KEY) {
      return totalInsights?.total_get_directions ?? 0;
    }

    return 0;
  }

  function getIcon(key: string) {
    if (key === ANALYTICS_TOTAL_CONTENT.UP.IMPRESSION.KEY) {
      return <Icons.Eye />;
    }

    if (key === ANALYTICS_TOTAL_CONTENT.UP.SPEND.KEY) {
      return <Icons.Banknote />;
    }

    if (key === ANALYTICS_TOTAL_CONTENT.DOWN.GOOGLE_CALL.KEY) {
      return (
        <>
          <Icons.CallPhone />
          <div className="p-0.5 rounded-full bg-white absolute -bottom-1 right-0 h-5 w-5 flex items-center justify-center">
            <Icons.Google height={20} />
          </div>
        </>
      );
    }

    if (key === ANALYTICS_TOTAL_CONTENT.DOWN.META_CALL.KEY) {
      return (
        <>
          <Icons.CallPhone />
          <div className="p-0.5 rounded-full bg-white absolute -bottom-1 -right-3 h-5 flex items-center justify-center">
            <Icons.Facebook color="#3F51B5" height={18} />
            <Icons.InstagramColor height={18} className="-ml-1.5 mt-[0.5px]" />
          </div>
        </>
      );
    }

    if (key === ANALYTICS_TOTAL_CONTENT.DOWN.FORM.KEY) {
      return <Icons.Book />;
    }

    if (key === ANALYTICS_TOTAL_CONTENT.DOWN.MESSAGE.KEY) {
      return <Icons.Letter />;
    }

    if (key === ANALYTICS_TOTAL_CONTENT.DOWN.DIRECTION.KEY) {
      return <Icons.MapPoint />;
    }

    return <Icons.Eye />;
  }

  return (
    <>
      <div className="grid grid-rows-1 grid-flow-col gap-5 h-32 mb-3">
        {Object.values(ANALYTICS_TOTAL_CONTENT.UP).map((content) => (
          <Card
            key={content.KEY}
            id={content.KEY}
            title={t(`labels.${content.KEY}`, SCOPE_OPTIONS)}
            questionLabel={t(`questionLabels.${content.KEY}`, SCOPE_OPTIONS)}
            count={handleValue(content.KEY)}
            color={content.COLOR}
            Icon={() => getIcon(content.KEY)}
          />
        ))}
      </div>
      <div className="flex flex-wrap gap-5 items-center">
        {Object.values(ANALYTICS_TOTAL_CONTENT.DOWN).map(
          (content) =>
            handleValue(content.KEY) !== '0.0' &&
            handleValue(content.KEY) !== 0 &&
            ((content.KEY === ANALYTICS_TOTAL_CONTENT.DOWN.DIRECTION.KEY && !branch?.google_integration) ||
            (content.KEY === ANALYTICS_TOTAL_CONTENT.DOWN.GOOGLE_CALL.KEY && !branch?.google_integration) ||
            (content.KEY === ANALYTICS_TOTAL_CONTENT.DOWN.FORM.KEY && branch?.google_integration && !branch?.facebook_integration) ? (
              ''
            ) : (
              <Card
                key={content.KEY}
                id={content.KEY}
                title={t(`labels.${content.KEY}`, SCOPE_OPTIONS)}
                questionLabel={t(`questionLabels.${content.KEY}`, SCOPE_OPTIONS)}
                count={handleValue(content.KEY)}
                color={content.COLOR}
                Icon={() => getIcon(content.KEY)}
              />
            ))
        )}
      </div>
    </>
  );
};

export default LegendCards;

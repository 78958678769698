import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.114 20.113" {...props}>
    <path
      id="Icon_awesome-facebook-messenger"
      data-name="Icon awesome-facebook-messenger"
      d="M10.642.563A9.717,9.717,0,0,0,.563,10.318a9.534,9.534,0,0,0,3.166,7.216c.339.3.269.481.326,2.361a.808.808,0,0,0,1.131.712c2.146-.945,2.173-1.019,2.537-.921,6.216,1.711,12.954-2.267,12.954-9.369A9.679,9.679,0,0,0,10.642.563ZM16.694,8.07l-2.96,4.687a1.515,1.515,0,0,1-2.186.4L9.192,11.4a.608.608,0,0,0-.73,0l-3.178,2.41a.478.478,0,0,1-.694-.635L7.55,8.485a1.515,1.515,0,0,1,2.186-.4l2.354,1.762a.608.608,0,0,0,.73,0L16,7.436a.477.477,0,0,1,.693.633Z"
      transform="translate(-0.563 -0.563)"
      fill="currentColor"
    />
  </svg>
);

export default SvgComponent;

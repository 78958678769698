import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { CampaignsBranchApi } from '@app/api';

import { useAuth, useAxios } from '@app/hooks';

export function generateFacebookAdPreviewByBranchCampaignId(
  branchId: string,
  campaignId: string,
  adFormat: any,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new CampaignsBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.generateFacebookAdPreviewByBranchCampaignId(branchId, campaignId, adFormat).then((res) => res.data);
}

export default function useGenerateFacebookAdPreviewByBranchCampaignId({
  params = {},
  ...props
}: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useQuery(
    ['useGenerateFacebookAdPreviewByBranchCampaignId', branchId, params.campaignId, params.adFormat],
    () => generateFacebookAdPreviewByBranchCampaignId(branchId, params.campaignId, params.adFormat, axiosInstance),
    props
  );
}

import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { MessagingApplicationBranchAccountApi } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export async function showMessagingApplication(
  branchId: string,
  messagingApplicationId: string,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new MessagingApplicationBranchAccountApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);
  const response = await api.showMessagingApplicationForBranch(branchId, messagingApplicationId).then((res) => res.data);
  localStorage.setItem('messagingApplication', JSON.stringify(response));
  return response;
}

export default function useShowMessagingApplication({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();
  return useQuery(
    ['useShowMessagingApplication', branchId, params.messagingApplicationId],
    () => showMessagingApplication(branchId, params.messagingApplicationId, axiosInstance),
    props
  );
}

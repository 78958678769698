import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { IntegrationsBranchApi } from '@app/api';
import type { UpdateFacebookIntegrationInput } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function updateBranchFacebookIntegration(
  branchId: string,
  updateFacebookIntegrationInput: UpdateFacebookIntegrationInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new IntegrationsBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.updateBranchFacebookIntegration(branchId, updateFacebookIntegrationInput).then((res) => res.data);
}

export default function useUpdateBranchFacebookIntegration(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useMutation((values: UpdateFacebookIntegrationInput) => updateBranchFacebookIntegration(branchId, values, axiosInstance), props);
}

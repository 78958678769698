import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.067 24.064" {...props}>
    <g id="Group_4286" data-name="Group 4286" transform="translate(-699.631 -525.264)">
      <path
        id="Path_2245"
        data-name="Path 2245"
        d="M702.776,530.482h14.038a1,1,0,0,0,0-2.005H702.776a1,1,0,0,0,0,2.005Z"
        transform="translate(1.869 2.802)"
        fill="currentColor"
      />
      <path
        id="Path_2246"
        data-name="Path 2246"
        d="M702.777,533.16H711.8a1,1,0,1,0,0-2.005h-9.024a1,1,0,1,0,0,2.005Z"
        transform="translate(1.868 5.138)"
        fill="currentColor"
      />
      <path
        id="Path_2247"
        data-name="Path 2247"
        d="M723.7,526.266a1,1,0,0,0-1-1H700.635a1,1,0,0,0-1,1v22.058a1.006,1.006,0,0,0,1.537.852l7.775-4.862h13.749a1,1,0,0,0,1.005-1Zm-2.007,16.045H708.657a1.04,1.04,0,0,0-.534.15l-6.485,4.057v-19.25h20.051Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default SvgComponent;

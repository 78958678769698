import React from 'react';

import { useLocale } from '@app/hooks';

type ElementPropTypes = {
  onClick?: (e?: Event) => void;
  className?: string;
};

type PropTypes = {
  reactKey?: string;
  i18nKey: string;
  i18nValues: { [key: string]: string | number | Function }; // eslint-disable-line @typescript-eslint/ban-types
  elements: {
    component: React.FC | string;
    props?: ElementPropTypes;
  }[];
};

const TranslateWithElement: React.FC<PropTypes> = ({ reactKey, i18nKey, i18nValues, elements }) => {
  const { t } = useLocale();
  const message = t(i18nKey, i18nValues);

  const row = React.useMemo(() => {
    const nodes = [];
    let messageToParse = message;

    elements.forEach(({ component: Component, props }, index) => {
      const componentRegExp = new RegExp(`<${index}>(.*)</${index}>`, 'gi');
      const [beforeText, componentText, restText] = messageToParse.split(componentRegExp);
      messageToParse = restText;

      nodes.push(
        <React.Fragment key={`before_${index}`}>{beforeText}</React.Fragment>,
        <Component key={`element_${index}`} {...props}>
          {componentText}
        </Component>
      );
    });

    nodes.push(<React.Fragment key={reactKey}>{messageToParse}</React.Fragment>);

    return nodes;
  }, [message, elements]);

  return <>{row}</>;
};

export default TranslateWithElement;

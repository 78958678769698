import React from 'react';

import { api, useAuth, useLocale, useToast } from '@app/hooks';
import { Box, Form, Icons } from '@app/components';
import type { Campaign as CampaignType, GoogleFeedItemSetsResponse } from '@app/api';
import { useFormContext } from 'react-hook-form';
import array from 'lib/array';

type GoogleObjectiveFormPropTypes = {
  campaign: CampaignType | any;
};

const GoogleObjectiveForm: React.FC<GoogleObjectiveFormPropTypes> = () => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.GoogleObjectiveForm',
  };
  const { t } = useLocale();
  const {
    register,
    setValue,
    formState: { errors },
    control,
  } = useFormContext();
  const { branch } = useAuth();
  const toast = useToast();
  const { data } = api.useListGoogleFeedItemSetsByBranch({
    refetchOnWindowFocus: true,
    onSuccess: (data: GoogleFeedItemSetsResponse) => {
      if (data.feed_item_sets?.length === 0) {
        toast.error(
          t('messages.emptyFeedItemSets', {
            href: 'https://ads.google.com/aw/locationgroups',
            ...SCOPE_OPTIONS,
          }),
          { isHaveElement: true }
        );
      }

      if (data.feed_item_sets?.length === 1) {
        setValue('feed_item_sets', {
          label: array.first(data.feed_item_sets).name,
          value: String(array.first(data.feed_item_sets).feed_item_set_id),
          feed: array.first(data.feed_item_sets).feed,
        });
      }
    },
  });

  return (
    <>
      <Box className="!mb-0 flex">
        <div className="flex items-center rounded-3 bg-white shadow-sm w-full">
          <div className="bg-gray-200 px-5 py-4 rounded-r-full flex items-center">
            <Icons.Google className="w-8 h-8 mr-2" />
            <Icons.Youtube className="w-9 h-9" />
          </div>
          <div className="bg-white px-5 py-4 text-3.5 font-semibold text-gray-900">Google & Youtube</div>
        </div>
      </Box>
      <Box className="!mb-0">
        <div className="bg-white shadow-sm px-5 py-4 rounded-3">
          <Form.Input
            type="text"
            id="business_name"
            label={t('form.business_name.label', SCOPE_OPTIONS)}
            {...register('business_name', {
              required: { value: false, message: t('form.business_name.errors.required', SCOPE_OPTIONS) },
            })}
            requiredSign={true}
            disabled={true}
            defaultValue={branch?.name}
            error={errors?.business_name}
          />
        </div>
      </Box>
      <Box className="!mb-0">
        <div className="bg-white shadow-sm px-5 py-4 rounded-3">
          <Form.Input
            type="text"
            id="final_url"
            label={t('form.final_url.label', SCOPE_OPTIONS)}
            {...register('final_url', {
              required: { value: false, message: t('form.final_url.errors.required', SCOPE_OPTIONS) },
            })}
            requiredSign={true}
            disabled={true}
            defaultValue={branch?.website}
            error={errors?.final_url}
          />
        </div>
      </Box>
      <Box className="!mb-0">
        <div className="bg-white shadow-sm px-5 py-4 rounded-3">
          <Form.Input
            type="text"
            id="phone"
            label={t('form.phone.label', SCOPE_OPTIONS)}
            {...register('phone', {
              required: { value: false, message: t('form.phone.errors.required', SCOPE_OPTIONS) },
            })}
            requiredSign={true}
            disabled={true}
            defaultValue={branch?.phone_number}
            error={errors?.phone}
          />
        </div>
      </Box>
      <Box className="!mb-0">
        <div className="bg-white shadow-sm px-5 py-4 rounded-3">
          <Form.Select
            name="feed_item_sets"
            label={t('form.feed_item_sets.label', SCOPE_OPTIONS)}
            rules={{
              required: { value: true, message: t('form.feed_item_sets.errors.required', SCOPE_OPTIONS) },
            }}
            placeholder={t('form.feed_item_sets.placeholder', SCOPE_OPTIONS)}
            control={control}
            options={data?.feed_item_sets?.map((item: { name: string; feed_item_set_id: string; feed: string }) => ({
              label: item.name,
              value: String(item.feed_item_set_id),
              feed: item.feed,
            }))}
            automaticHandleValue={false}
            error={errors?.feed_item_sets}
          />
        </div>
      </Box>
    </>
  );
};

export default GoogleObjectiveForm;

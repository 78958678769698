import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 18A9 9 0 109 0a9 9 0 000 18zm1.432-12.884c0 .34-.117.629-.35.867-.234.239-.516.358-.845.358-.33 0-.612-.12-.848-.358a1.186 1.186 0 01-.354-.867c0-.338.118-.628.354-.869.236-.24.518-.36.848-.36.33 0 .61.12.844.36.234.241.35.531.35.87zm-.959 8.456c.21-.088.49-.217.842-.386 0 0-.041-.508-.176-.463a1.156 1.156 0 01-.362.068c-.226 0-.386-.045-.478-.136-.092-.09-.138-.261-.138-.512a5.976 5.976 0 01.139-.96l.437-1.883c.043-.173.073-.363.089-.57.015-.207.023-.352.023-.434 0-.398-.115-.72-.343-.969-.23-.248-.556-.372-.978-.372a2.08 2.08 0 00-.747.152 9.864 9.864 0 00-.826.366s.07.503.19.46c.118-.043.235-.065.349-.065.232 0 .388.048.47.143.082.095.123.264.123.505 0 .134-.013.282-.04.444-.027.162-.06.334-.1.515l-.439 1.891a6.732 6.732 0 00-.085.535 4.124 4.124 0 00-.027.463c0 .388.119.71.355.962s.567.378.993.378c.277 0 .52-.043.73-.132z"
      fill="#000"
    />
  </svg>
);

export default SvgComponent;

import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="#fff"
      strokeWidth="1.5"
      d="M5 8.515C5 4.917 8.134 2 12 2s7 2.917 7 6.515c0 3.57-2.234 7.735-5.72 9.225a3.277 3.277 0 01-2.56 0C7.234 16.25 5 12.084 5 8.515z"
    ></path>
    <path stroke="#fff" strokeWidth="1.5" d="M14 9a2 2 0 11-4 0 2 2 0 014 0z"></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth="1.5"
      d="M20.96 15.5c.666.602 1.04 1.282 1.04 2 0 2.485-4.477 4.5-10 4.5S2 19.985 2 17.5c0-.718.374-1.398 1.04-2"
    ></path>
  </svg>
);

export default SvgComponent;

import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.265 19.304" {...props}>
    <g id="Group_4287" data-name="Group 4287" transform="translate(-637.03 -584.082)">
      <path
        id="Path_2254"
        data-name="Path 2254"
        d="M653.256,595.738a9.881,9.881,0,0,1-2.164-.54,2.564,2.564,0,0,0-2.7.578l-.582.581a12.616,12.616,0,0,1-3.793-3.791l.584-.583a2.556,2.556,0,0,0,.575-2.691,9.908,9.908,0,0,1-.541-2.173,2.564,2.564,0,0,0-2.544-2.2H639.58c-.073,0-.145,0-.229.011a2.56,2.56,0,0,0-2.31,2.784,17.532,17.532,0,0,0,2.7,7.648,17.3,17.3,0,0,0,5.294,5.295,17.554,17.554,0,0,0,7.633,2.713c.08.008.16.011.242.011h0a2.552,2.552,0,0,0,2.542-2.559v-2.493A2.555,2.555,0,0,0,653.256,595.738Zm-9.891-4.99-1.066,1.064a.876.876,0,0,0-.142,1.052,14.29,14.29,0,0,0,5.36,5.357.873.873,0,0,0,1.051-.143l1.06-1.061a.818.818,0,0,1,.85-.18,11.589,11.589,0,0,0,2.541.635.806.806,0,0,1,.688.839v2.52a.8.8,0,0,1-.232.567.843.843,0,0,1-.625.236h0a15.816,15.816,0,0,1-6.864-2.444,15.6,15.6,0,0,1-4.767-4.768,15.79,15.79,0,0,1-2.435-6.874.767.767,0,0,1,.176-.567.907.907,0,0,1,.621-.307h2.533a.8.8,0,0,1,.792.681,11.692,11.692,0,0,0,.636,2.551A.8.8,0,0,1,643.365,590.748Z"
        fill="currentColor"
      />
      <path
        id="Path_2255"
        data-name="Path 2255"
        d="M651.208,591.795a.876.876,0,0,0,.858.708.973.973,0,0,0,.168-.016.877.877,0,0,0,.692-1.027,5.048,5.048,0,0,0-4.005-4,.88.88,0,0,0-1.026.692.877.877,0,0,0,.692,1.027A3.3,3.3,0,0,1,651.208,591.795Z"
        fill="currentColor"
      />
      <path
        id="Path_2256"
        data-name="Path 2256"
        d="M648.855,584.091a.822.822,0,0,0-.632.168.891.891,0,0,0-.339.6.876.876,0,0,0,.773.966,6.642,6.642,0,0,1,5.9,5.888.873.873,0,0,0,.868.777.812.812,0,0,0,.1-.006.874.874,0,0,0,.772-.967A8.374,8.374,0,0,0,648.855,584.091Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default SvgComponent;

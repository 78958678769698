import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 23.311 18.478" {...props}>
    <defs>
      <clipPath>
        <rect data-name="Rectangle 1619" width="23.311" height="18.478" transform="translate(0 0)" fill="currentColor" />
      </clipPath>
    </defs>
    <g data-name="Group 3103" transform="translate(0 0)">
      <g data-name="Group 3102">
        <path
          data-name="Path 1711"
          d="M10.78,7.284a4.27,4.27,0,1,0-5.994,0A6.557,6.557,0,0,0,0,13.613V16.76a1.719,1.719,0,0,0,1.717,1.718H13.848a1.72,1.72,0,0,0,1.718-1.718V13.613A6.558,6.558,0,0,0,10.78,7.284m-3-.257A2.757,2.757,0,1,1,10.54,4.269,2.761,2.761,0,0,1,7.783,7.027M6.586,8.54H8.98a5.08,5.08,0,0,1,5.074,5.073V16.76a.211.211,0,0,1-.206.2H1.717a.211.211,0,0,1-.205-.2V13.613A5.08,5.08,0,0,1,6.586,8.54"
          fill="currentColor"
        />
        <path
          data-name="Path 1712"
          d="M21.461,7.727a6.267,6.267,0,0,0-2-1.346,3.528,3.528,0,1,0-4.888.008,6.026,6.026,0,0,0-1.12.6.757.757,0,1,0,.86,1.246A4.786,4.786,0,0,1,21.8,12.178v1.38a.159.159,0,0,1-.158.16H16.734a.756.756,0,0,0,0,1.513H21.64a1.673,1.673,0,0,0,1.67-1.673v-1.38a6.262,6.262,0,0,0-1.849-4.451M19.027,3.864a2.015,2.015,0,1,1-2.015-2.015,2.018,2.018,0,0,1,2.015,2.015"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default SvgComponent;

import type { BranchCampaign as BranchCampaignType } from '@app/api';
import { array } from '@app/lib';
import { GOOGLE_PLACEMENT_TYPE, IMAGE_PLACEMENT_TYPE } from '@app/constants';

function previewImage(data: BranchCampaignType) {
  if (array.isEmpty(data?.branch_campaign_attachments)) {
    return '';
  }

  const attachments = data.branch_campaign_attachments;
  const feedImage = attachments?.find((attachment) => attachment.placement_type === IMAGE_PLACEMENT_TYPE.FEED);

  if (feedImage?.file) {
    return feedImage?.file?.url;
  }

  const firstImage = array.first(attachments);

  return firstImage?.file.url;
}

function handleGoogleCampaignCoverImage(attachments: Record<string, any>) {
  if (array.isEmpty(attachments)) {
    return '';
  }

  return attachments?.find(
    (attachment: { placement_type: string }) => attachment.placement_type === GOOGLE_PLACEMENT_TYPE.SQUARE_MARKETING_IMAGE
  )?.file?.url;
}

export default { previewImage, handleGoogleCampaignCoverImage };

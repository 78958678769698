const CAMPAIGN_STATUS = Object.freeze({
  CANCELLED: 'cancelled',
  DRAFT: 'draft',
  FINISHED: 'finished',
  PAUSED: 'paused',
  PENDING: 'pending',
  REJECTED: 'rejected',
  RUNNING: 'running',
  EXPIRED: 'expired',
  INDEFINITE: 'indefinite',
});

export default CAMPAIGN_STATUS;

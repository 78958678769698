import { useState } from 'react';
import type { FC } from 'react';

import cn from 'classnames';

import { Icons } from '@app/components';
import { PUBLISHER_PLATFORMS, SCREEN_MODES } from '@app/constants';

import Header from './Header';
import Facebook from './Facebook';
import Instagram from './Instagram';

type PostPreviewProps = {
  previewData: any;
};

const PostPreview: FC<PostPreviewProps> = ({ previewData }) => {
  const [selectedPlatform, setSelectedPlatform] = useState<string>(PUBLISHER_PLATFORMS.FACEBOOK);
  const [selectedPlacementType, setSelectedPlacementType] = useState<string>(SCREEN_MODES.MOBILE);
  const isFacebookMobile = selectedPlatform === PUBLISHER_PLATFORMS.FACEBOOK;
  const isInstagramMobile = selectedPlatform === PUBLISHER_PLATFORMS.INSTAGRAM;

  return (
    <div className="flex flex-col items-center" style={{ width: selectedPlacementType === SCREEN_MODES.MOBILE ? 320 : 420, height: 450 }}>
      <div className="flex flex-col p-4 bg-white rounded-2 shadow-sm w-full mb-5">
        <Header platform={selectedPlatform} onPlatformChange={setSelectedPlatform} />
      </div>

      <div className="flex flex-col py-2 px-2 bg-white rounded-2 shadow-sm w-full items-center">
        {isFacebookMobile && (
          <Facebook
            accountName="Facebook"
            image={previewData.image}
            message={previewData.campaignName}
            description={previewData.description}
            screen={selectedPlacementType}
            date={previewData.postDate}
          />
        )}
        {isInstagramMobile && (
          <Instagram
            accountName="Instagram"
            image={previewData.image}
            description={previewData.description}
            screen={selectedPlacementType}
          />
        )}
        <div className="flex flex-col items-center ">
          <div className="flex items-center text-gray-500 my-2 justify-center w-auto">
            <button
              className={cn('p-2 border-none w-auto', {
                'bg-black-800 text-white rounded-lg': selectedPlacementType === SCREEN_MODES.DESKTOP,
              })}
              onClick={() => setSelectedPlacementType(SCREEN_MODES.DESKTOP)}
            >
              <Icons.Desktop className="w-5 h-5 fill-current" color="#bebebe" />
            </button>
            <button
              className={cn('p-2 border-none w-auto', {
                'bg-black-800 text-white rounded-lg ': selectedPlacementType === SCREEN_MODES.MOBILE,
              })}
              onClick={() => setSelectedPlacementType(SCREEN_MODES.MOBILE)}
            >
              <Icons.Mobile className="w-5 h-5 fill-current" color="#bebebe" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostPreview;

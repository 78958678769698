import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { GoogleBranchApi } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function listGoogleFeedItemSets(branchId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new GoogleBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.listGoogleFeedItemSets(branchId).then((res) => res.data);
}

export default function useListGoogleFeedItemSetsByBranch(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useQuery(['useListGoogleFeedItemSetsByBranch'], () => listGoogleFeedItemSets(branchId, axiosInstance), props);
}

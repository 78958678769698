import React from 'react';

import cn from 'classnames';

import { Icons } from '@app/components';
import { useAuth, api, useLocale } from '@app/hooks';
import { LANGUAGE } from '@app/constants';
import type { UpdateLanguageProfileInput } from '@app/api';

const Preferences = ({ miniSidebar }: any) => {
  const { signOut } = useAuth();
  const { locale, changeLocale } = useLocale();

  const { mutate } = api.useUpdateLanguageProfile({
    onSuccess: (data: UpdateLanguageProfileInput) => {
      changeLocale(data.language);
    },
  });
  function handleChangeLanguage(language: string) {
    if (language === locale) {
      return;
    }

    mutate({ language });
  }
  return (
    <ul className=" flex flex-col justify-center gap-y-2 mb-5">
      <li className="group flex items-center justify-center font-semibold text-black-800 w-full">
        <button className="flex items-center justify-center w-8 h-8 overflow-hidden bg-gray-300 rounded-full group-hover:bg-blue-600 transation group-hover:ease-in group-hover:text-white ">
          <Icons.GlobeWeb className="w-6 h-6" />
        </button>
        <div className="absolute left-0 invisible group-hover:visible w-full flex items-center p-4 cursor-pointer">
          <div
            className={cn('absolute left-19.5 z-40 space-x-1', {
              '-right-12 !left-15': miniSidebar,
            })}
          >
            <button
              className={cn(
                'w-8 h-8 overflow-hidden bg-gray-300 rounded-full hover:bg-black-800 hover:text-white  transation hover:ease-in',
                {
                  '!bg-black-800 !text-white': locale === LANGUAGE.TR,
                }
              )}
              onClick={() => handleChangeLanguage(LANGUAGE.TR as string)}
            >
              <span className="uppercase">{LANGUAGE.TR}</span>
            </button>
            <button
              className={cn(
                'w-8 h-8 overflow-hidden bg-gray-300 rounded-full hover:bg-black-800 hover:text-white transation hover:ease-in',
                {
                  '!bg-black-800 !text-white': locale === LANGUAGE.EN,
                }
              )}
              onClick={() => handleChangeLanguage(LANGUAGE.EN as string)}
            >
              <span className="uppercase">{LANGUAGE.EN}</span>
            </button>
            <button
              className={cn(
                'w-8 h-8 overflow-hidden bg-gray-300 rounded-full hover:bg-black-800 hover:text-white transation hover:ease-in',
                {
                  '!bg-black-800 !text-white': locale === LANGUAGE.RO,
                }
              )}
              onClick={() => handleChangeLanguage(LANGUAGE.RO as string)}
            >
              <span className="uppercase">{LANGUAGE.RO}</span>
            </button>
            <button
              className={cn(
                'w-8 h-8 overflow-hidden bg-gray-300 rounded-full hover:bg-black-800 hover:text-white transation hover:ease-in',
                {
                  '!bg-black-800 !text-white': locale === LANGUAGE.PL,
                }
              )}
              onClick={() => handleChangeLanguage(LANGUAGE.PL as string)}
            >
              <span className="uppercase">{LANGUAGE.PL}</span>
            </button>
          </div>
        </div>
      </li>
      {/* <li className="flex items-center justify-center font-semibold text-black-800">
        <button className="w-8 bg-gray-300 rounded-full p-2 hover:bg-blue-400 transation hover:ease-in hover:text-white">
          <Icons.QuestionMark />
        </button>
      </li> */}
      <li className="flex items-center justify-center font-semibold text-black-800 group-hover:none">
        <button className="w-8 bg-gray-300 rounded-full p-2 transation hover:ease-in hover:bg-black-800 hover:text-white" onClick={signOut}>
          <Icons.Logout />
        </button>
      </li>
    </ul>
  );
};

export default Preferences;

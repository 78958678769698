import Link from 'next/link';
import React from 'react';

type BannerPropsTypes = {
  variant?: 'default' | 'password' | 'register';
  title?: string;
  hostname?: string;
};

const LoginBanner: React.FC<BannerPropsTypes> = ({ variant = 'default', hostname }) => {
  return (
    <>
      <div className="absolute -left-[50%] -bottom-[10%] w-[175%]">
        <img alt="" className="block w-full" src="/bg-map.png"></img>
      </div>
      <div className="flex items-center justify-between w-full">
        <Link href="/">
          <a className="flex gap-2 items-center">
            <div className="max-w-10">
              <img alt="" className="block w-full" src="/logo.png"></img>
            </div>
            <span className="text-white text-2xl font-medium">
              {hostname?.includes('dijitalbayim.com') ? 'Dijital Bayim' : 'Digital Dealer'}
            </span>
          </a>
        </Link>
        {/* <div className="flex gap-2 items-center pr-2">
          <Link href="https://www.instagram.com/dijitalbayim" target="_blank">
            <a>
              <div className="w-8">
                <img alt="" className="block w-full" src="/instagram-icon.png"></img>
              </div>
            </a>
          </Link>
          <Link href="https://www.facebook.com/dijitalbayim" target="_blank">
            <a>
              <div className="w-8">
                <img alt="" className="block w-full" src="/facebook-icon.png"></img>
              </div>
            </a>
          </Link>
          <Link href="https://x.com/dijitalbayim" target="_blank">
            <a>
              <div className="w-8">
                <img alt="" className="block w-full" src="/x-icon.png"></img>
              </div>
            </a>
          </Link>
        </div> */}
      </div>
      <div>
        {variant === 'password' ? (
          <img alt="" className="block w-full h-full" src="/password-banner.png"></img>
        ) : variant === 'register' ? (
          <img alt="" className="block w-full h-full" src="/register-banner.png"></img>
        ) : (
          <img alt="" className="block w-full h-full" src="/login-banner.png"></img>
        )}
      </div>
      <div className="text-gray-300 font-thin">
        Powered by <span className="font-bold">Branchsight</span>
      </div>
    </>
  );
};

export default LoginBanner;

import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" fill="currentColor" viewBox="0 0 150 150" {...props}>
    <path
      fill="currentColor"
      d="M106.25 50h12.5a12.5 12.5 0 0112.5 12.5v56.25a12.501 12.501 0 01-12.5 12.5h-87.5a12.5 12.5 0 01-12.5-12.5V62.5A12.5 12.5 0 0131.25 50h12.5v12.5h-12.5v56.25h87.5V62.5h-12.5V50zM40.625 34.375l8.837 8.837L68.75 23.925V87.5h12.5V23.925l19.288 19.287 8.837-8.837L75 0 40.625 34.375z"
    ></path>
  </svg>
);

export default SvgComponent;

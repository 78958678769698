import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="none" viewBox="0 0 100 100" {...props}>
    <path
      fill="currentColor"
      d="M91.25 37.042l-4.375-18.209c-.917-3.75-4.167-6.333-7.958-6.333H21.042c-3.75 0-7.042 2.625-7.917 6.333L8.75 37.042c-1 4.25-.083 8.583 2.583 12 .334.458.792.791 1.167 1.208v28.917c0 4.583 3.75 8.333 8.333 8.333h58.334c4.583 0 8.333-3.75 8.333-8.333V50.25c.375-.375.833-.75 1.167-1.167a14.015 14.015 0 002.583-12.041zm-12.458-16.25L83.167 39c.416 1.75.041 3.5-1.042 4.875-.583.75-1.833 1.958-3.917 1.958-2.541 0-4.75-2.041-5.041-4.75l-2.417-20.25 8.042-.041zm-24.625.041h8.166l2.25 18.834a5.6 5.6 0 01-1.375 4.458c-.916 1.083-2.25 1.708-3.958 1.708-2.792 0-5.083-2.458-5.083-5.458V20.833zM35.375 39.667l2.292-18.834h8.166v19.542c0 3-2.291 5.458-5.375 5.458-1.416 0-2.708-.625-3.708-1.708a5.917 5.917 0 01-1.375-4.458zM16.833 39l4.209-18.167h8.208l-2.417 20.25c-.333 2.709-2.5 4.75-5.041 4.75-2.042 0-3.334-1.208-3.875-1.958-1.125-1.333-1.5-3.125-1.084-4.875zm4 40.167V54.042c.334.041.625.125.959.125 3.625 0 6.916-1.5 9.333-3.959 2.5 2.5 5.833 3.959 9.625 3.959 3.625 0 6.875-1.5 9.292-3.875 2.458 2.375 5.791 3.875 9.541 3.875 3.5 0 6.834-1.459 9.334-3.959 2.416 2.459 5.708 3.959 9.333 3.959.333 0 .625-.084.958-.125v25.125H20.833z"
    ></path>
  </svg>
);

export default SvgComponent;

const ACTIVITY_LOG_TYPES = Object.freeze({
  BRANCH_CREATED: 'branch_created',
  BRANCH_UPDATED: 'branch_updated',
  BRANCH_DESTROYED: 'branch_destroyed',
  BRANCH_ADDRESS_UPDATED: 'branch_address_updated',
  BRANCH_CAMPAIGN_CREATED: 'branch_campaign_created',
  BRANCH_CAMPAIGN_UPDATED: 'branch_campaign_updated',
  BRANCH_CAMPAIGN_DELETED: 'branch_campaign_deleted',
  BRANCH_FACEBOOK_INTEGRATION_CREATED: 'branch_facebook_integration_created',
  BRANCH_FACEBOOK_INTEGRATION_UPDATED: 'branch_facebook_integration_updated',
  BRANCH_FACEBOOK_INTEGRATION_DESTROYED: 'branch_facebook_integration_destroyed',
  COMPANY_FACEBOOK_INTEGRATION_CREATED: 'company_facebook_integration_created',
  COMPANY_FACEBOOK_INTEGRATION_UPDATED: 'company_facebook_integration_updated',
  COMPANY_FACEBOOK_INTEGRATION_DESTROYED: 'company_facebook_integration_destroyed',
  TEMPLATE_ARCHIVED: 'template_archived',
  TEMPLATE_CREATED: 'template_created',
  TEMPLATE_UPDATED: 'template_updated',
  TEMPLATE_DUPLICATED: 'template_duplicated',
  TEMPLATE_DESTROYED: 'template_destroyed',
  USER_ROLE_CREATED: 'user_role_created',
  USER_ROLE_UPDATED: 'user_role_updated',
});

export default ACTIVITY_LOG_TYPES;

import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Slider from 'react-rangeslider';
import cn from 'classnames';

import { campaign as campaignLib } from '@app/lib';
import { useLocale } from '@app/hooks';

type ScopeRangePropTypes = {
  control: any;
  cpm: number;
};

const ScopeRange: React.FC<ScopeRangePropTypes> = ({ control, cpm }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.ScopeRange',
  };
  const { watch } = useFormContext();
  const { t } = useLocale();
  const budgetWatch = watch('budget');
  const minEstimateWatch = watch('minEstimate');
  const maxEstimateWatch = watch('maxEstimate');
  const campaignScope = budgetWatch && campaignLib.calculateCampaignScope(budgetWatch, cpm, maxEstimateWatch);
  const scopePercent = campaignLib.calculateScopePercent(campaignScope, minEstimateWatch);

  return (
    <>
      <label className="block text-3.5 font-semibold text-gray-900">{t('label', SCOPE_OPTIONS)}</label>
      <section className="flex flex-row w-full">
        <div className="flex-col w-[5%] text-center text-sm text-[#DB908D]">{t('scopeLabel.poor', SCOPE_OPTIONS)}</div>
        <div className="flex-col w-[80%] text-center text-sm text-[#FFD634]">{t('scopeLabel.medium', SCOPE_OPTIONS)}</div>
        <div className="flex-col w-[15%] text-center text-sm text-[#BFD5B0]">{t('scopeLabel.good', SCOPE_OPTIONS)}</div>
      </section>

      <section className="flex flex-row w-full rangeslider-horizontal absolute left-0 z-1 bottom-8.5 px-4">
        <div className="flex-col w-[5%] bg-[#DB908D]"></div>
        <div className="flex-col w-[80%] bg-[#F8ECC1]"></div>
        <div className="flex-col w-[15%] bg-[#BFD5B0]"></div>
      </section>

      <Controller
        name={'scopeRange'}
        control={control}
        render={({ field: { onChange } }) => (
          <div className={cn('slider custom-slider')}>
            <Slider min={0} max={100} value={Number(scopePercent)} onChange={onChange} tooltip={true} />
          </div>
        )}
        defaultValue={0}
      />
    </>
  );
};

export default ScopeRange;

import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="16pt"
    height="16pt"
    viewBox="0 0 100.000000 100.000000"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
      <path
        d="M484 967 c-11 -29 0 -102 16 -102 19 0 22 106 3 112 -7 3 -16 -2 -19
    -10z"
      />
      <path
        d="M192 847 c-17 -19 62 -97 80 -79 10 10 4 21 -28 53 -33 33 -43 38
    -52 26z"
      />
      <path
        d="M755 820 c-31 -31 -36 -43 -27 -52 10 -10 21 -4 53 29 23 22 38 46
    35 52 -11 17 -19 14 -61 -29z"
      />
      <path
        d="M406 804 c-72 -23 -149 -103 -172 -179 -32 -111 -6 -202 90 -318 55
    -68 56 -69 56 -133 0 -36 4 -73 10 -83 5 -10 32 -26 60 -35 46 -15 54 -15 100
    0 28 9 55 25 60 35 6 10 10 47 10 83 0 64 1 65 56 133 67 81 94 135 101 208
    17 161 -118 307 -281 304 -23 0 -63 -7 -90 -15z m168 -36 c59 -18 138 -99 155
    -161 27 -95 2 -176 -88 -282 -28 -32 -51 -65 -51 -72 0 -10 -23 -13 -90 -13
    -67 0 -90 3 -90 13 0 7 -23 40 -51 72 -64 75 -89 123 -96 185 -9 78 12 137 71
    195 71 71 146 90 240 63z m6 -588 c0 -16 -8 -19 -57 -22 -44 -2 -58 -7 -58
    -18 0 -11 14 -16 58 -18 80 -4 62 -22 -22 -22 -73 0 -87 5 -69 27 8 10 8 17 0
    25 -7 7 -12 20 -12 30 0 16 10 18 80 18 73 0 80 -2 80 -20z"
      />
      <path
        d="M60 540 c0 -19 5 -21 58 -18 43 2 57 7 57 18 0 11 -14 16 -57 18 -53
    3 -58 1 -58 -18z"
      />
      <path
        d="M820 540 c0 -19 5 -21 58 -18 43 2 57 7 57 18 0 11 -14 16 -57 18
    -53 3 -58 1 -58 -18z"
      />
      <path
        d="M215 280 c-31 -31 -36 -43 -27 -52 10 -10 21 -4 53 29 23 22 38 46
    35 52 -11 17 -19 14 -61 -29z"
      />
      <path
        d="M724 309 c-3 -6 12 -30 35 -52 32 -33 43 -39 53 -29 9 9 4 21 -27 52
    -42 43 -50 46 -61 29z"
      />
    </g>
  </svg>
);

export default SvgComponent;

import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19.255" height="18.559" viewBox="0 0 19.255 18.559" {...props}>
    <defs>
      <clipPath>
        <rect width="19.255" height="18.559" transform="translate(0 0)" fill="currentColor" />
      </clipPath>
    </defs>
    <g data-name="Group 4254" transform="translate(0 0)">
      <g data-name="Group 219">
        <path
          data-name="Path 1066"
          d="M18.2,5.689a3.576,3.576,0,0,0-2.548-1.056H14.621V3.6a3.6,3.6,0,0,0-3.6-3.6H3.6A3.6,3.6,0,0,0,0,3.6v6.72a3.6,3.6,0,0,0,3.6,3.6H4.634v1.032a3.6,3.6,0,0,0,3.6,3.6h7.415a3.6,3.6,0,0,0,3.6-3.6V8.236A3.578,3.578,0,0,0,18.2,5.689M15.652,16.915H8.237a1.945,1.945,0,0,1-1.384-.575,1.969,1.969,0,0,1-.574-1.384V13.924h4.739a3.6,3.6,0,0,0,3.6-3.6V6.278h1.031A1.958,1.958,0,0,1,17.61,8.236v6.72a1.969,1.969,0,0,1-.574,1.384,1.945,1.945,0,0,1-1.384.575M1.645,3.6a1.969,1.969,0,0,1,.574-1.385A1.942,1.942,0,0,1,3.6,1.643h7.415a1.944,1.944,0,0,1,1.384.574A1.969,1.969,0,0,1,12.976,3.6v6.72a1.96,1.96,0,0,1-1.958,1.958H3.6a1.96,1.96,0,0,1-1.958-1.958Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default SvgComponent;

import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { FacebookAccountBranchApi } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function getBranchFacebookAccount(branchId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new FacebookAccountBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.getBranchFacebookAccount(branchId).then((res) => res.data);
}

export default function useGetBranchFacebookAccount({
  id = [],
  automaticToast = true,
  params = {},
  ...props
}: ApiQueryHookPropTypes | undefined): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios({ automaticToast });
  const { branchId } = useAuth();

  return useQuery(
    ['useGetBranchFacebookAccount', branchId ?? params.branchId, ...id],
    () => getBranchFacebookAccount(branchId ?? params.branchId, axiosInstance),
    props
  );
}

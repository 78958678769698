import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { CampaignsBranchApi } from '@app/api';
import type { GoogleBranchCampaignUpdateInput } from '@app/api';

import { useAxios } from '@app/hooks';

export function updateGoogleBranchCampaigns(
  branchId: string,
  campaignId: string,
  googleBranchCampaignUpdateInput: GoogleBranchCampaignUpdateInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new CampaignsBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.updateGoogleBranchCampaigns(branchId, campaignId, googleBranchCampaignUpdateInput).then((res) => res.data);
}

export default function useUpdateBranchGoogleCampaign(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { branchId: string; campaignId: string; googleBranchCampaignUpdateInput: GoogleBranchCampaignUpdateInput }) =>
      updateGoogleBranchCampaigns(values.branchId, values.campaignId, values.googleBranchCampaignUpdateInput, axiosInstance),
    props
  );
}

import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17.518" height="18.559" viewBox="0 0 17.518 18.559" {...props}>
    <path
      id="Path_2755"
      data-name="Path 2755"
      d="M13.245,6.759H10.1V3.277A3.279,3.279,0,0,0,6.911,0H6.9A3.282,3.282,0,0,0,4.588.925,3.435,3.435,0,0,0,3.548,3.373V7.646a3.174,3.174,0,0,0-2.619.9,3.16,3.16,0,0,0,0,4.475l2.912,2.911a4.165,4.165,0,0,0,3.855,2.627H12.7a4.823,4.823,0,0,0,4.817-4.817V11.031a4.277,4.277,0,0,0-4.273-4.272M12.7,16.69H7.694a2.27,2.27,0,0,1-2.276-2.276V3.374a1.563,1.563,0,0,1,.473-1.11,1.364,1.364,0,0,1,.977-.4A1.408,1.408,0,0,1,8.234,3.277v5.6a.935.935,0,0,0,1.869,0V8.627h3.141a2.407,2.407,0,0,1,2.4,2.4v2.711A2.949,2.949,0,0,1,12.7,16.69M3.548,13l-1.3-1.3a1.292,1.292,0,0,1,0-1.829,1.282,1.282,0,0,1,1.3-.321Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgComponent;

import React, { useEffect, useState } from 'react';

import useGetUserTransactions from '@app/hooks/api/useGetUserTransactions';
import useLocale from '@app/hooks/useLocale';
import { date } from '@app/lib';
import { V2WalletTransactionsSuccessResponseTransactions } from 'api/model';
import Table from 'components/Table';
import { useInterval } from '@app/hooks/useInterval';
import { useCountdown } from '@app/hooks/useCountdown';

type TransactionTableProps = {
  currency: string;
};

export type Transaction = {
  id: string;
  amount: number;
  campaign: any;
  created_at: string;
  ip_address: string;
  transaction_detail: string;
  transaction_type: string;
  updated_at: string;
  user: {
    id: string;
    name: string;
    surname: string;
  };
  order: {
    status: string;
  };
};
const DATA_PER_PAGE = 5;
const isIncome = (type: string) => type === 'income';

export default function TransactionTable({ currency }: TransactionTableProps) {
  const SCOPE_OPTIONS = {
    scope: 'pages.wallet.transactionsTable',
  };
  const { t } = useLocale();

  function statusRenderer(status: string) {
    // pending, success, failed
    switch (status) {
      case 'pending':
        return <span className="text-yellow-500">{t('pending', SCOPE_OPTIONS)}</span>;
      case 'success':
        return <span className="text-green-500">{t('success', SCOPE_OPTIONS)}</span>;
      case 'failed':
        return <span className="text-red-500">{t('failed', SCOPE_OPTIONS)}</span>;
      case 'cancelled':
        return <span className="text-red-500">{t('cancelled', SCOPE_OPTIONS)}</span>;
      default:
        return <span className="pl-6">{status || '-'}</span>;
    }
  }
  const columns = [
    {
      Header: t('amount', SCOPE_OPTIONS),
      accessor: (row: Transaction) => (
        <span className={`pl-6 ${isIncome(row.transaction_type) ? 'text-green-500' : 'text-red-500'}`}>
          {row.amount / 100} {currency}
        </span>
      ),
    },
    {
      Header: t('transactionType', SCOPE_OPTIONS),
      accessor: (row: Transaction) => (
        <span className="pl-6">{row.transaction_type === 'income' ? t('income', SCOPE_OPTIONS) : t('expense', SCOPE_OPTIONS)}</span>
      ),
    },
    {
      Header: t('campaign', SCOPE_OPTIONS),
      accessor: (row: Transaction) => <span className="pl-10">{row.campaign.name || '-'}</span>,
    },
    {
      Header: t('date', SCOPE_OPTIONS),
      accessor: (row: Transaction) => <span className="pl-6">{date.format(row.created_at, 'dd.MM.yyyy')}</span>,
    },
    {
      Header: 'Status',
      accessor: (row: Transaction) => statusRenderer(row.order.status),
    },
  ];

  const [page, setPage] = useState(1);

  const { data, isFetching, refetch } = useGetUserTransactions(page, DATA_PER_PAGE);

  const [transactions, setTransactions] = useState<V2WalletTransactionsSuccessResponseTransactions[]>([]);

  useEffect(() => {
    if (!isFetching && data) {
      setTransactions(data?.data.transactions || []);
    }
  }, [data]);

  const INTERVAL_VALUE = 2500;
  const [count, { startCountdown, stopCountdown }] = useCountdown({
    countStart: 60,
    intervalMs: INTERVAL_VALUE,
  });
  useEffect(() => {
    startCountdown();
    return () => {
      stopCountdown();
    };
  }, []);
  useInterval(() => {
    if (count <= 1) return;
    if (count !== 0) {
      refetch();
    }
  }, INTERVAL_VALUE);

  return (
    <Table
      columns={columns}
      data={transactions}
      header
      disableSortBy
      totalPageCount={data?.data.meta.pagination.total_pages}
      currentPage={data?.data.meta.pagination.current}
      pageSize={DATA_PER_PAGE}
      onPageChange={setPage}
      defaultSortBy={[{ id: 'created_at', desc: true }]}
    />
  );
}

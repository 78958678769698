import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { InvitationsBranchUserApi } from '@app/api';
import type { BranchUserRegistrationInput, BranchUserRegistrationInputUser } from '@app/api';
import { useAxios } from '@app/hooks';

export function branchUserAcceptInvitation(
  token: string,
  clientId: string,
  branchUserRegistrationInput: BranchUserRegistrationInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new InvitationsBranchUserApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.branchUserAcceptInvitation(token, clientId, branchUserRegistrationInput).then((res) => res.data);
}

export default function useBranchUserAcceptInvitation(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { token: string; clientId: string; user: BranchUserRegistrationInputUser }) =>
      branchUserAcceptInvitation(values.token, values.clientId, { user: values.user }, axiosInstance),
    props
  );
}

import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { CampaignsBranchApi, OTPBranchV2Api } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

type ParamsType = {
  phoneNumber: string;
};

export function sendOtpToBranchUserV2(params: ParamsType, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new OTPBranchV2Api(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.sendOtpToBranchUserV2(params).then((res) => res.data);
}

export default function useSendOtpToBranchUserV2(props: ApiQueryHookPropTypes) {
  const { api: axiosInstance } = useAxios();

  return useMutation((values: ParamsType) => sendOtpToBranchUserV2(values, axiosInstance), props);
}

import { useMutation } from 'react-query';
import { AxiosInstance, AxiosPromise } from 'axios';

import type { ImageAttachment } from '@app/api';

import { useAuth, useAxios } from '@app/hooks';

export function branchFacebookImageAttachments(
  branchId: string,
  payload: Record<string, unknown>,
  axiosInstance: AxiosInstance
): AxiosPromise<ImageAttachment> {
  return axiosInstance
    .post(`/branch_account/branches/${branchId}/attachments/social_media_images/post_image`, payload, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    .then((res) => res.data);
}

export default function useBranchFacebookImageAttachments(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useMutation(
    (values: { payload: Record<string, unknown> }) => branchFacebookImageAttachments(branchId, values.payload, axiosInstance),
    props
  );
}

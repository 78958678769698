const DAYS = Object.freeze({
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday',
  SUNDAY: 'sunday',
  NUMBERMONDAY: '0',
  NUMBERTUESDAY: '1',
  NUMBERWEDNESDAY: '2',
  NUMBERTHURSDAY: '3',
  NUMBERFRIDAY: '4',
  NUMBERSATURDAY: '5',
  NUMBERSUNDAY: '6',
});

export default DAYS;

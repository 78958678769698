import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.063 22.063" {...props}>
    <path
      id="Icon_awesome-whatsapp"
      data-name="Icon awesome-whatsapp"
      d="M18.759,5.456A10.937,10.937,0,0,0,1.551,18.65L0,24.313l5.8-1.522a10.9,10.9,0,0,0,5.225,1.33h0A11.038,11.038,0,0,0,22.063,13.188a10.976,10.976,0,0,0-3.3-7.732ZM11.027,22.279A9.071,9.071,0,0,1,6.4,21.014l-.33-.2-3.438.9.916-3.354-.217-.345a9.1,9.1,0,1,1,16.887-4.831A9.189,9.189,0,0,1,11.027,22.279Zm4.984-6.806c-.271-.138-1.615-.8-1.867-.886s-.433-.138-.616.138-.7.886-.867,1.074-.32.207-.591.069a7.436,7.436,0,0,1-3.718-3.25c-.281-.483.281-.448.8-1.492a.506.506,0,0,0-.025-.478c-.069-.138-.616-1.482-.842-2.029S7.84,8.16,7.673,8.15s-.34-.01-.522-.01a1.012,1.012,0,0,0-.729.34,3.07,3.07,0,0,0-.955,2.28A5.352,5.352,0,0,0,6.58,13.587a12.208,12.208,0,0,0,4.669,4.127,5.346,5.346,0,0,0,3.28.685,2.8,2.8,0,0,0,1.842-1.3,2.285,2.285,0,0,0,.158-1.3C16.464,15.675,16.282,15.606,16.011,15.473Z"
      transform="translate(0 -2.25)"
      fill="currentColor"
    />
  </svg>
);

export default SvgComponent;

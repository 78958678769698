import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.407 20.406" {...props}>
    <path
      id="Path_2701"
      data-name="Path 2701"
      d="M603.126,586.473a.537.537,0,0,0-.694.694l5.4,14.047a.537.537,0,0,0,.881.187l2.8-2.8a1.074,1.074,0,0,1,1.519,0l3.623,3.628h0a1.078,1.078,0,0,0,1.526-1.524h0l-3.629-3.624a1.074,1.074,0,0,1,0-1.519l2.8-2.8a.537.537,0,0,0-.187-.881Zm-2.7,1.465a2.685,2.685,0,0,1,3.471-3.47l14.047,5.4a2.684,2.684,0,0,1,.934,4.4l-2.043,2.044,2.869,2.864a3.226,3.226,0,0,1-4.561,4.564l-2.866-2.87-2.043,2.043a2.684,2.684,0,0,1-4.4-.934Z"
      transform="translate(-600.242 -584.283)"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgComponent;

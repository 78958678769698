import React from 'react';

import { useLocale } from '@app/hooks';

type FallbackPropTypes = {
  description: string;
  instructions: string;
};

const Fallback: React.FC<FallbackPropTypes> = ({ description, instructions }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Onboarding.Fallback',
  };
  const { t } = useLocale();

  return (
    <React.Fragment>
      <div className="bg-blue-200 bg-opacity-10 p-6 rounded-2">
        <h4 className="text-blue-300 text-4.5 font-medium mb-3">{t('labels.description', SCOPE_OPTIONS)}</h4>
        <div className="text-gray-700 text-3.5" dangerouslySetInnerHTML={{ __html: description }} />
      </div>
      <div className="bg-white bg-opacity-50 p-6 rounded-2">
        <h4 className="text-gray-800 text-4.5 font-medium mb-3">{t('labels.instructions', SCOPE_OPTIONS)}</h4>
        <div className="text-gray-700 text-3.5" dangerouslySetInnerHTML={{ __html: instructions }} />
      </div>
    </React.Fragment>
  );
};

export default Fallback;

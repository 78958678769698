import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14" {...props}>
    <path
      fill="currentColor"
      d="M13.183 14c-1.672 0-3.302-.373-4.89-1.118a14.85 14.85 0 01-7.175-7.175C.373 4.118.001 2.488 0 .817 0 .583.078.389.233.233A.791.791 0 01.817 0h3.15c.181 0 .343.058.486.175a.684.684 0 01.253.447l.505 2.722c.026.182.023.347-.01.497a.797.797 0 01-.223.398L3.11 6.144A12.916 12.916 0 005.153 8.77a14.228 14.228 0 002.703 2.12L9.683 9.06c.117-.117.27-.204.458-.262.188-.058.373-.074.553-.049l2.684.544a.79.79 0 01.447.263.71.71 0 01.175.476v3.15a.792.792 0 01-.233.584.792.792 0 01-.584.233z"
    ></path>
  </svg>
);

export default SvgComponent;

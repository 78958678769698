import React from 'react';

import { useAuth, useLocale } from '@app/hooks';
import { Icons, Location } from '@app/components';

type MapsProps = {
  latlng: { latitude: number; longitude: number };
  fullAddress?: string;
};

const Maps: React.FC<MapsProps> = ({ latlng, fullAddress }) => {
  const { t } = useLocale();
  const { branch } = useAuth();
  const SCOPE_OPTIONS = {
    scope: 'components.SmartCampaignPreview.Content',
  };
  const mapOptions = {
    disableDefaultUI: true, // Disable default controls
    zoomControl: false, // Enable zoom control
    draggable: false, // Enable map draggable
  };

  function getLocation() {
    if (!branch.location.latitude || !branch.location.longitude) {
      return;
    }

    if (!latlng?.latitude || !latlng?.longitude) {
      return;
    }

    return {
      lat: Number(latlng.latitude),
      lng: Number(latlng.longitude),
    };
  }

  return (
    <div className="w-full border-0.2 border-gray-400 bg-white rounded-3">
      <div className="w-full p-2 pl-3 shadow-md rounded-t-3 flex items-center">
        <img src="/images/google.png" alt="profile" className="object-cover w-20" />
        <div className="ml-8 h-9 w-full rounded-10 border-0.1 border-gray-300 justify-between flex items-center">
          <div className="flex items-center pl-3 text-sm text-gray-700">{t('labels.keyword', SCOPE_OPTIONS)}</div>
          <span className="flex items-center space-x-4 pr-4">
            <div className="h-6 flex items-center justify-center pr-2 border-r-[0.1rem] border-gray-300">
              <Icons.XMark className="w-6 h-6 text-gray-500" />
            </div>
            <div className="w-6 h-full flex items-center justify-center">
              <img src="/images/keyboard.png" alt="profile" className="object-cover" />
            </div>
            <div className="w-6 h-full flex items-center justify-center">
              <img src="/images/google-voice.png" alt="profile" className="object-cover" />
            </div>
            <div className="w-6 h-full flex items-center justify-center">
              <Icons.GoogleLens className="w-6 h-6" />
            </div>
            <div className="w-6 h-full rounded-r-10 flex items-center justify-center">
              <Icons.Search className="w-6 h-6 text-blue-500" />
            </div>
          </span>
        </div>
      </div>

      <div className="w-full p-2 pt-3 pl-3 flex items-center justify-between">
        <span className="flex items-center">
          <span className="mr-4 text-gray-700 text-md">{t('labels.businesses', SCOPE_OPTIONS)}</span>
          <Icons.TreeDot className="w-3 h-3 text-gray-700 rotate-90" />
        </span>
        <Icons.CircleInfo className="w-3 h-3 text-gray-700" />
      </div>
      <Location.LoadScript>
        <div className="bg-white p-2 rounded-3.5">
          <div className="h-45 rounded-2.5 overflow-hidden">
            <Location.Picker
              autoLocation={false}
              value={getLocation()}
              mapOptions={mapOptions}
              markerDraggable={false}
              onChange={() => null}
            />
          </div>
        </div>
      </Location.LoadScript>

      <div className="w-full px-2 pl-3 flex items-center ">
        <div className="text-gray-600">
          <span className="text-gray-700 mr-1 text-xs font-semibold">{t('labels.sponsoredAd', SCOPE_OPTIONS)}</span>
          <span className="w-full text-sm flex items-center">
            {branch.name} <Icons.Down className="ml-2 w-2 h-2 text-gray-600" />
          </span>
        </div>
      </div>

      <div className="flex justify-between w-full px-2 pl-3 text-gray-500">
        <span className="text-3 mt-1">
          {branch.phone_number} {fullAddress && ` - ${fullAddress} `}
        </span>
        <span className="flex items-center justify-center">
          <span>
            <div className="rounded-full border-0.1 border-gray-300 w-10 h-10 flex justify-center items-center mx-4 mb-1">
              <Icons.Website className="w-6 h-6 text-blue-500" />
            </div>
            <p className="text-blue-500 text-2.5 w-full text-center">{t('labels.webSite', SCOPE_OPTIONS)}</p>
          </span>
          <span>
            <div className="rounded-full border-0.1 border-gray-300 w-10 h-10 flex justify-center items-center mx-4 mb-1">
              <Icons.Directions className="w-6 h-6 text-blue-500" />
            </div>
            <p className="text-blue-500 text-2.5 w-full text-center">{t('labels.direction', SCOPE_OPTIONS)}</p>
          </span>
        </span>
      </div>
    </div>
  );
};

export default Maps;

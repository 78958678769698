import React from 'react';
import { useRouter } from 'next/router';

import { api, useAuth, useAgreementModal } from '@app/hooks';
import { Button, Modal } from '@app/components';
import { AGREEMENTS } from '@app/constants';
import { branch as branchLib } from '@app/lib';

import type { Branch } from '@app/api';

const AgreementModal: React.FC = () => {
  const { pathname } = useRouter();
  const { branchId, branch, storeBranch } = useAuth();
  const { open, setOpen, data } = useAgreementModal();
  const globalOpen =
    branch?.company?.feature_flags?.has_user_aggrement_feature && !branchLib.isAcceptedAgreement(branch, AGREEMENTS.USER_AGREEMENT);
  const isGateway = pathname.includes('/gateway');

  const { refetch } = api.useShowBranchUserBranches({
    enabled: false,
    params: {
      branchId: branchId,
    },
    onSuccess: (data: Branch) => {
      storeBranch(data);
    },
  });

  const { mutate } = api.useAcceptAgreements({
    onSuccess: () => {
      refetch();
    },
  });

  const handleAccept = () => mutate({ agreement: AGREEMENTS.USER_AGREEMENT });

  return (
    <Modal isOpen={!isGateway && (globalOpen || open)} onClose={() => setOpen(false)}>
      <div className="flex flex-col p-6">
        <div className="max-h-112 overflow-auto text-sm">
          <h1 className="text-center mb-3 font-bold">Bayi Üyelik Kullanım Taahhüdü</h1>
          <p className="my-4">
            Bayisi olduğum {branch?.company?.legal_name || data?.company?.legal_name || branch?.company?.name || data?.company?.name}{' '}
            (“Genel Müdürlük”) ile BRANCHSIGHT BİLGİ TEKNOLOJİLERİ ANONİM ŞİRKETİ (“Branchsight”) arasında akdedilmiş sözleşme sonucunda
            tarafıma dijitalbayim.com üzerinden ilgili hizmetler sunulmaktadır. dijitalbayim.com üzerinden Branchsight tarafından
            kullanılacak platformda (“Platform”) açılan hesap ve alınan hizmetleri kullanımı kapsamında Bayi Üyelik Kullanım Taahhüdü
            (“Taahhüt”) ile aşağıdaki hususlara riayet etmeyi peşinen kabul ve taahhüt ederim.
          </p>
          <p className="my-4 ml-6 font-bold">
            <b>1. P</b>latform Kapsamında Sunulan Hizmetler
          </p>
          <p className="my-4">
            <b>1.1.</b> Platform kapsamında sunulacak hizmetler kapsamında aşağıdaki şart ve koşulları kabul ederiz:
          </p>
          <ul className="list-disc flex flex-col gap-2">
            <li className="ml-6">
              Genel Müdürlük tarafından her içeriğin hem reklamlarında hem de sayfalarında paylaşım olarak otomatik olarak paylaşılacaktır.
            </li>
            <li className="ml-6">
              İçerikler belirli bayilikler adına göre özelleştirilerek paylaşım sağlanacaktır. İçeriklerin son kontrolü bayiye aittir ve
              yazı kayması, görüntünün kayması dahil fakat bunlarla sınırlı olmamakla birlikte diğer teknik sorunlar bakımından
              Branchsight’ın sorumluluğu bulunmamaktadır.
            </li>
            <li className="ml-6">
              Genel Müdürlük ve Branchsight Bayilerin reklamlarını istediği gibi başlayabilir, durdurabilir ve düzenleyebilir.
            </li>
            <li className="ml-6">
              Bayiler Facebook Reklam Hesabı, Facebook sayfası ve Instagram Sayfası olarak 3 dijital varlığını Branchsight’in hizmetlerini
              sunduğu www.dijitalbayim.com&apos;a entegre edecektir. Entegrasyonun tanımlanmasını takiben tüm bayilerin reklam
              istatistiklerini hem Genel Müdürlük hem de Branchsight kullanabilecektir. Branchsight bu bilgileri anonimleştirerek
              istatistiksel amaçlarla kullanabilecektir.
            </li>
            <li className="ml-6">
              Genel Müdürlük ya da Branchsight, bir Bayiliğin hesabında risk ve kötü niyetli kullanım gördüğü zaman tek taraflı olarak
              bağlantısını kesebilir.
            </li>
            <li className="ml-6">
              Bayilerin entegre edilen hesaplarında Branchsight uygun reklam, anlaşmalar, eğitim içeriklerimi, kampanya gibi her türlü
              paylaşımı yapabilecektir.
            </li>
            <li className="ml-6">
              Bayiye yaratılan kampanya ve içerikler kapsamında bilgilendirme amaçlı ileti e-posta veya SMS kanalı ile gönderilecektir.
            </li>
            <li className="ml-6">
              Facebook reklam hesaplarını ve sayfalarını engelleyebilmektedir. Branchsight’ın sosyal medya ve ilgili mecraların hesap
              kapatma dahil fakat bununla sınırlı olmamakla birlikte uygulayacağı cezalar kapsamında bir sorumluluğu bulunmamaktadır.
            </li>
            <li className="ml-6">Bayilere belirli dönemlerde Branchsight tarafından doğrudan anketler yapılabilecektir.</li>
            <li className="ml-6">
              Branchsight uygun gördüğü taktirde Bayinin hesaplarındaki reklam ayarlarını değiştirebilir veya optimizasyon
              gerçekleştirebilir.
            </li>
            <li className="ml-6">
              Bayi için oluşturulacak www.dijitalbayim.com kullanıcı hesabı, bayiye özeldir; Bayi kullanıcı hesabını üçüncü bir kişiye
              kullandırmayacaktır, şifrelerini paylaşmayacaktır.
            </li>
            <li className="ml-6">
              Branchsight, tamamen kendi takdirinde olmak üzere Platform’u kısmen veya tamamen, sürekli veya geçici olarak yayından
              kaldırabilir, değiştirebilir, ücretli hale getirebilir, güncelleyebilir, askıya alabilir, durdurabilir ve/veya Platform
              üzerinde ilave hizmetler açabilir.
            </li>
          </ul>
          <p className="my-4">
            <b>1.2.</b> Kendime ait kullanıcı hesap adı ve şifresi kullanılarak gerçekleştirilen iş ve işlemlerin benim tarafından
            gerçekleştirilmiş olduğunu kabul eder, bu iş ve işlemlerden kaynaklanan sorumluluğun tarafıma ait olduğunu, bu iş ve işlemleri
            tarafımın gerçekleştirmediği yolunda herhangi bir def&apos;i ve/veya itiraz ileri süremeyeceğimi ve/veya bu def&apos;i veya
            itiraza dayanarak yükümlülüklerini yerine getirmekten kaçınamayacağımı kabul, beyan ve taahhüt ederim.
          </p>
          <p className="my-4">
            <b>1.3.</b> Platform’u ve Platform’daki her türlü içerik, materyal ve unsurları, işbu Taahhüt’e, Branchsight’in herhangi bir
            suretle duyurduğu/bildirdiği Platform kurallarına, mevzuat hükümlerine ve ahlak kurallarına uygun olarak kullanacağımı, aykırı
            kullanımlarından kaynaklanabilecek her türlü hukuki ve cezai sorumluluğun bizzat tarafıma ait olacağını, Branchsight’in aykırı
            bir kullanımı tespit etmesi halinde, her türlü talep, dava ve takip hakları saklı kalmak üzere, kullanıcı statüsünü kısmen veya
            tamamen kısıtlama, engelleme, askıya alma veya sona erdirme hak ve yetkisine sahip olacağını kabul, beyan ve taahhüt ederim.
          </p>
          <p className="my-4">
            <b>1.4.</b> Platform’u, Branchsight İçerikleri’ni kısmen veya tamamen, doğrudan veya dolaylı olarak, Platform’un kullanım amacı
            dışında kullanımı, iktibası, kopyalaması, çoğaltması, değiştirmesi, tersine mühendislik yapması, kaynak koda dönüştürmesi (geri
            derlemesi), yedeklemesi, iletmesi, depolaması, işlemesi, satması, paylaşması, yayması, dağıtması, kiralanması, ödünç verilmesi,
            uyarlaması, nakletmesi veya başkaca iş, işlem ve tasarrufa konu etmesi ve/veya bunları teşvik etmesi ve/veya yapılmasını
            kolaylaştırması mutlak suretle yasak olduğunu kabul, beyan ve taahhüt ederim. Bu yasağa ilişkin olarak gerekli tüm önlemleri
            almaktan sorumlu olduğumu, aykırı kullanım sebebiyle herhangi bir zarar oluşması halinde derhal nakden ve defaten zararları
            karşılayacağımı kabul, beyan ve taahhüt ederim.
          </p>
          <p className="my-4">
            <b>1.5.</b> Branchsight, Platform’un ve Platform’daki erişime, kullanıma, indirmeye ve paylaşıma müsait içerik, materyal ve
            unsurların, virüslerden, buglardan, truva atlarından, bozuk dosyalardan, solucan programlarından, dialer programlarından, spam,
            spyware ve bunlar gibi diğer her türlü kötü ve zarar verme amaçlı yazılımlardan (casus yazılım ve reklam yazılımı gibi),
            programlardan, ürünlerden, kodlardan ve/veya materyallerden arındırılmış olmasına ilişkin herhangi bir garanti ve taahhüt
            vermemektedir. Bayi, bunların oluşmasının ve herhangi bir şekilde cihazlarına, cihaz donanım ve yazılımlarına ve/veya diğer
            ekipman ve teknolojilerine bulaşmasının önlenmesi için gerekli koruyucu yazılım, donanım ve lisanslı ürünleri kullanmak da dahil
            olmak üzere gerekli her türlü tedbiri almakla yükümlüdür. Branchsight, bu kapsamda olabilecek arızalar, hasarlar, veri
            yanlışlıkları ve kayıplar da dahil ancak bunlarla sınırlı olmamak üzere Bayi ve/veya üçüncü kişilerin uğrayabileceği zararlardan
            hiçbir şekilde sorumlu değildir.
          </p>
          <p className="my-4">
            <b>1.6.</b> Branchsight, tamamen kendi takdirine olmak üzere, dilediği zaman ve herhangi bir sebep göstermeksizin, kullanıcı
            hesabı başvurularını reddedebilir, kullanıcı statülerini sona erdirebilir veya kullanıcı hesabı başvurusunun kabul edilmesini
            yahut devam ettirilmesini ya da Platform’un kullanılmasını ek şart ve koşullara bağlayabilir. Branchsight tarafından Bayi’nin
            kullanıcı hesabı açılış işlemi sonrası getirilecek ve herhangi bir suretle duyurulacak/bildirilecek değişiklikler, ek şart ve
            koşullar, hükümler, yönergeler ve politikalar, bunların bildirilmesinin yayınlanmasının ardından bağlayıcı olacak ve Bayi
            Platform’u kullanmaya devam etmekle kabul etmiş sayılacaktır.
          </p>
          <p className="my-4">
            <b>1.7.</b> Branchsight, tamamen kendi takdirinde olmak üzere Platform’u kısmen veya tamamen, sürekli veya geçici olarak
            yayından kaldırabilir, değiştirebilir, ücretli hale getirebilir, güncelleyebilir, askıya alabilir, durdurabilir ve/veya Platform
            üzerinde ilave hizmetler açabilir. Branchsight’ın bu maddede sayılanlara ve benzer nitelikteki sair hususlara ilişkin olarak
            Bayi ve/veya üçüncü kişilere karşı hiçbir sorumluluğu bulunmamaktadır.
          </p>
          <p className="my-4 ml-6 font-bold">2. Gizlilik</p>
          <p className="my-4">
            İşbu Taahhüt kapsamında, yazılı, sözlü, manyetik ortamda ve sair şekilde açıkladığı/açıklayacağı ve/veya Branchsight’ın ve
            kendimin herhangi bir şekilde bilgi sahibi olduğu/olacağı, bağlı ve/veya stratejik iş ortaklıklarına, doğrudan ve/veya dolaylı
            hissedarlarına, iştiraklerine, tedarikçilerine ve/veya bayilerine ait her türlü mali tablo, rapor, finansal ve hukuksal
            bilgiler, marka, patent, endüstriyel tasarım konusu bilgiler dâhil, ticari sır ya da diğer yasal korumaya konu olan ya da
            olmayan her türlü bilgi, program, yazılım, kod, belge, ürün ve hizmetlere ilişkin bilgiler ve her türlü manyetik şerit, doküman,
            el kitabı, şartname, akış tablosu, program listeleri, veri dosyaları ile piyasa araştırması, iş fırsatları, projeler, iş
            modelleri, personeline ait her türlü bilgi ve belge, gizli olduğu açıkça veya zımni olarak bildirilsin veya bildirilmesin,
            “Gizli Bilgi” olarak kabul edilecektir. Ayrıca, bunlardan faydalanmak suretiyle elde ettiği yeni bilgiler ile çalışmalar
            sırasında açıklanmadığı halde, herhangi bir şekilde öğrenilen bilgiler de Gizli Bilgi kavramı dâhilinde kabul edilecektir. Gizli
            Bilgi’leri herhangi bir şekilde öğrendiğim takdirde; büyük bir gizlilik içinde korumayı, Gizli Bilgi‘yi, Branchsight’ın yazılı
            onayının alındığı durumlar saklı kalmak üzere, herhangi bir 3. kişiye hangi suretle olursa olsun açıklamamayı, doğrudan ya da
            dolaylı olarak ticari ilişkinin ve projelerin amaçları dışında kullanmamayı, Gizli Bilgi‘lerin yetkisiz kişilerce kullanım ve
            suistimalinden korumak için her türlü güvenlik önlemini almayı ve bunları sürdürmeyi kabul, beyan ve taahhüt ederim.
          </p>
          <p className="my-4">
            Aktarılan/aktarılacak her türlü Gizli Bilgi, aktaran Tarafın mülkiyetindedir ve mülkiyetinde kalmaya devam edecektir. Gizli
            Bilgilerin tamamı veya bir kısmı kesinlikle çoğaltılmayacak veya herhangi üçüncü kişi ile paylaşılmayacaktır. Platform
            kapsamındaki her türlü bilgi Branchsight’ın mülkiyeti altında kalacaktır.
          </p>
          <p className="ml-6 my-4 font-bold">3. Aykırı Kullanım</p>
          <p className="my-4">
            Aşağıda işbu madde uyarınca “aykırı kullanım” olarak nitelendirilebilecek durumlar örnekleme yoluyla sayılmıştır:
          </p>
          <ul className="flex flex-col gap-2 list-disc mb-4">
            <li className="ml-6">
              Platform’daki işlemler gerçekleştirilirken Branchsight’e, Platform’a ve/veya üçüncü kişilere herhangi bir suretle zarar
              verilmesi;
            </li>
            <li className="ml-6">
              Platform’un herhangi bir kişi adına veri tabanı, kayıt veya rehber yaratmak, kontrol etmek, güncellemek ve/veya değiştirmek
              amacıyla kullanılması;
            </li>
            <li className="ml-6">
              Platform’un bütününün veya bir bölümünün bozma, değiştirme ve/veya ters mühendislik yapma amacıyla kullanılması; yanlış
              ve/veya bir başkasının bilgileri kullanılarak işlem yapılması, yanlış ve/veya yanıltıcı kişisel veriler kullanılmak suretiyle
              gerçek olmayan kullanıcı hesapları oluşturulması ve/veya bu hesapların işbu Taahhüt’e, Branchsight’in herhangi bir suretle
              duyurduğu/bildirdiği Platform kurallarına, yürürlükteki mevzuata ve/veya ahlak kurallarına aykırı şekilde kullanılması ve/veya
              başka bir kullanıcı hesabının izinsiz kullanılması; virüs veya Platform’a, Platform’un veri tabanına, Platform’da yer alan
              herhangi bir içeriğe zarar verici herhangi başka bir teknoloji yayılması;
            </li>
            <li className="ml-6">Platform hesap sayfasına robot veya otomatik giriş yöntemleriyle girilmesi;</li>
            <li className="ml-6">
              Platform tarafından belirlenmiş olan iletişimler ve teknik sistemler üzerinde makul olmayan veya orantısız derecede büyük
              yüklemeler yaratacak ya da teknik işleyişe zarar verecek faaliyetlerde bulunulması, Platform’un üzerinde otomatik program,
              robot, web crawler, örümcek, veri madenciliği (data mining) ve veri taraması (data crawling) gibi &quot;screen scraping&quot;
              yazılımları veya sistemleri kullanılması ve/veya bu veya benzeri nitelikteki herhangi bir işlemle Platform’da yer alan
              herhangi bir içeriğin tamamının veya bir kısmının izinsiz kopyalanması, yayınlanması veya kullanılması;
            </li>
            <li className="ml-6">
              Platform’a zarar verecek her türlü program, virüs, yazılım, lisanssız ürün, truva atı ve sair tehlikelere karşı gerekli
              koruyucu yazılımların ve lisanslı ürünlerin kullanılması da dahil olmak üzere gerekli tedbirlerin alınmaması;
            </li>
            <li className="ml-6">
              Platform’un, kullanıcıların Platform deneyimlerini olumsuz yönde etkileyecek nitelikte kullanılması ve/veya diğer
              kullanıcıların haklarına yönelik saldırılarda bulunulması;
            </li>
            <li className="ml-6">Platform’da hukuka, ahlaka, kural ve şartlara aykırı içerikler yayınlaması.</li>
          </ul>
          <p>
            Yukarıda belirtilen maddeler sınırlı sayıda olmadığını, hukuk, ahlak, iyiniyet kurallarına aykırı herhangi hareketin aykırı
            sayılabileceğini ve hareketimin kullanıcı hesabının askıya alınması, üyeliğimin sonlandırılması gibi Branchsight tarafından
            uygun görülecek yaptırım ile sonuçlanabileceğini kabul, beyan ve taahhüt ederim.
          </p>
        </div>
        {globalOpen && <Button onClick={handleAccept} theme="blue" label="Kabul Ediyorum" className="self-end w-auto mt-4" />}
      </div>
    </Modal>
  );
};

export default AgreementModal;

/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { V2MobileGoogleBranchCampaignCreateInputBusinessLocation } from './v2-mobile-google-branch-campaign-create-input-business-location';

/**
 * 
 * @export
 * @interface V2MobileGoogleBranchCampaignCreateInput
 */
export interface V2MobileGoogleBranchCampaignCreateInput {
    /**
     * 
     * @type {string}
     * @memberof V2MobileGoogleBranchCampaignCreateInput
     */
    budget: string;
    /**
     * 
     * @type {string}
     * @memberof V2MobileGoogleBranchCampaignCreateInput
     */
    stop_time: string;
    /**
     * 
     * @type {string}
     * @memberof V2MobileGoogleBranchCampaignCreateInput
     */
    campaign_id: string;
    /**
     * 
     * @type {number}
     * @memberof V2MobileGoogleBranchCampaignCreateInput
     */
    location_radius: number;
    /**
     * 
     * @type {string}
     * @memberof V2MobileGoogleBranchCampaignCreateInput
     */
    targeting_type: V2MobileGoogleBranchCampaignCreateInputTargetingTypeEnum;
    /**
     * 
     * @type {V2MobileGoogleBranchCampaignCreateInputBusinessLocation}
     * @memberof V2MobileGoogleBranchCampaignCreateInput
     */
    business_location: V2MobileGoogleBranchCampaignCreateInputBusinessLocation;
}

/**
    * @export
    * @enum {string}
    */
export enum V2MobileGoogleBranchCampaignCreateInputTargetingTypeEnum {
    Pmax = 'PMAX',
    Smart = 'SMART'
}




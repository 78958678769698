import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { GoogleBranchApi } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function listKeywordsThemes(branchId: string, query: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new GoogleBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.listSuggestedGoogleKeywordThemes(branchId, query).then((res) => res.data);
}

export default function useListKeywordsThemesByBranch({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useQuery(['useListKeywordsThemesByBranch', params.query], () => listKeywordsThemes(branchId, params.query, axiosInstance), props);
}

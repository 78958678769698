import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.489 24.064" {...props}>
    <g id="Group_4289" data-name="Group 4289" transform="translate(-671.044 -583.974)">
      <path
        id="Path_2274"
        data-name="Path 2274"
        d="M680.252,595.968a6,6,0,1,0-6.031-6A6.022,6.022,0,0,0,680.252,595.968Zm0-9.726a3.728,3.728,0,1,1-3.763,3.728A3.75,3.75,0,0,1,680.252,586.242Z"
        transform="translate(0.616 0)"
        fill="currentColor"
      />
      <path
        id="Path_2275"
        data-name="Path 2275"
        d="M680.789,595.264c-3.293,0-9.745,1.815-9.745,6.012v3.439a1.135,1.135,0,0,0,2.269,0v-3.439c0-2.136,4.534-3.744,7.476-3.744s7.474,1.608,7.474,3.744v3.439a1.135,1.135,0,0,0,2.269,0v-3.439C690.533,597.079,684.08,595.264,680.789,595.264Z"
        transform="translate(0 2.188)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default SvgComponent;

import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { TokenBranchUserApi } from '@app/api';

import { useAxios } from '@app/hooks';

export function getTokenInfo(accessToken: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new TokenBranchUserApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api
    .tokenInfo({
      access_token: accessToken,
    })
    .then((res) => res);
}

export default function useGetTokenInfo({ ...props }: ApiMutationHookPropTypes): ApiMutationHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation((accessToken: string) => getTokenInfo(accessToken, axiosInstance), {
    ...props,
  });
}

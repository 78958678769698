import React from 'react';

import { useFormContext } from 'react-hook-form';

import { Form as FormComponents, Location } from '@app/components';
import { useLocale } from '@app/hooks';
import { phoneNumber, url } from '@app/lib';

type LocationObjectType = {
  lat: number;
  lng: number;
};
type FormPropTypes = {
  autoLocation?: boolean;
  hasOwnerEmail?: boolean;
  isOwnerEmailDisabled?: boolean;
};

const MAX_BRANCH_NAME_LENGTH = 25;
const MAX_ADDRESS_DIRECTION_LENGTH = 25;

const Form: React.FC<FormPropTypes> = ({ hasOwnerEmail = false, isOwnerEmailDisabled = false, autoLocation }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Branch.Form',
  };
  const { t } = useLocale();
  const {
    control,
    setValue,
    register,
    formState: { errors },
    watch,
  } = useFormContext();
  const locationWatch = watch('location');
  function handlePickerChange(data: LocationObjectType) {
    setValue('location.latitude', data.lat);
    setValue('location.longitude', data.lng);
  }

  function getLocation() {
    if (!locationWatch?.latitude || !locationWatch?.longitude) {
      return;
    }

    return {
      lat: locationWatch?.latitude,
      lng: locationWatch?.longitude,
    };
  }

  return (
    <form className="py-5 rounded-3 -mt-4.5">
      <FormComponents.Input
        type="text"
        id="name"
        label={t('form.name.label', SCOPE_OPTIONS)}
        className="mb-2.5 bg-white h-30 p-4 rounded-3.5 shadow-sm"
        {...register('name', {
          required: { value: true, message: t('form.name.errors.required', SCOPE_OPTIONS) },
          validate: (value) => {
            if (value.length > MAX_BRANCH_NAME_LENGTH) {
              return t(`form.name.errors.maxValue`, { scope: SCOPE_OPTIONS.scope, value: MAX_BRANCH_NAME_LENGTH });
            }
          },
        })}
        requiredSign={true}
        error={errors.name}
      />

      <FormComponents.PhoneInput
        className="mb-4.5 bg-white p-4 rounded-3.5 shadow-sm"
        control={control}
        name="phoneNumber"
        label={t('form.phoneNumber.label', SCOPE_OPTIONS)}
        id="phoneNumber"
        rules={{
          required: { value: true, message: t('form.phoneNumber.errors.required', SCOPE_OPTIONS) },
          validate: (phoneNumberString: string) => {
            if (phoneNumber.isValid(phoneNumberString)) {
              return true;
            }

            return t('form.phoneNumber.errors.validate', SCOPE_OPTIONS);
          },
        }}
        requiredSign={true}
        error={errors.phoneNumber}
      />
      <FormComponents.Input
        type="text"
        id="website"
        label={t('form.website.label', SCOPE_OPTIONS)}
        className="mb-2.5 bg-white h-30 p-4 rounded-3.5 shadow-sm"
        {...register('website', {
          required: { value: true, message: t('form.website.errors.required', SCOPE_OPTIONS) },
          pattern: {
            value: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g,
            message: t('form.website.errors.pattern', SCOPE_OPTIONS),
          },
          validate: (URL) => {
            if (url.isValid(URL)) {
              return true;
            }

            return t('form.website.errors.validate', SCOPE_OPTIONS);
          },
        })}
        requiredSign={true}
        error={errors.website}
      />
      {hasOwnerEmail && (
        <FormComponents.Input
          type="text"
          id="ownerEmail"
          disabled={isOwnerEmailDisabled}
          label={t('form.ownerEmail.label', SCOPE_OPTIONS)}
          className="mb-2.5 bg-white h-30 p-4"
          {...register('ownerEmail', {
            required: { value: true, message: t('form.ownerEmail.errors.required', SCOPE_OPTIONS) },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: t('form.ownerEmail.errors.pattern', SCOPE_OPTIONS),
            },
          })}
          requiredSign={true}
          error={errors.ownerEmail}
        />
      )}
      <FormComponents.Input
        type="text"
        id="address_direction"
        label={t('form.address_direction.label', SCOPE_OPTIONS)}
        description={t('form.address_direction.description', SCOPE_OPTIONS)}
        className="mb-2.5 bg-white h-34 p-4 rounded-3.5 shadow-sm"
        {...register('address_direction', {
          required: { value: true, message: t('form.address_direction.errors.required', SCOPE_OPTIONS) },
          validate: (value) => {
            if (value.length > MAX_ADDRESS_DIRECTION_LENGTH) {
              return t(`form.address_direction.errors.maxValue`, { scope: SCOPE_OPTIONS.scope, value: MAX_ADDRESS_DIRECTION_LENGTH });
            }
          },
        })}
        requiredSign={true}
        error={errors.address_direction}
      />
      <Location.LoadScript>
        <div className="bg-white p-5 rounded-3.5 shadow-sm">
          <Location.PlacesAutocomplete onSelect={(address) => setValue('address', address)} onCoordinatesChange={handlePickerChange}>
            {({ onChange, ready }) => {
              return (
                <FormComponents.Textarea
                  id="address"
                  label={t('form.address.label', SCOPE_OPTIONS)}
                  className="mb-4.5"
                  {...register('address', {
                    onChange: onChange,
                    required: { value: true, message: t('form.address.errors.required', SCOPE_OPTIONS) },
                  })}
                  requiredSign={true}
                  error={errors.address}
                  disabled={!ready}
                  rows={1}
                />
              );
            }}
          </Location.PlacesAutocomplete>
          <div className="mb-3 h-72 rounded-2.5 overflow-hidden">
            <Location.Picker autoLocation={autoLocation} value={getLocation()} onChange={handlePickerChange} />
          </div>
        </div>
      </Location.LoadScript>
    </form>
  );
};

export default Form;

import { useState } from 'react';
import Link from 'next/link';
import Router from 'next/router';

import { useForm } from 'react-hook-form';
import cn from 'classnames';

import type { BranchUserTokenSuccessResponse } from '@app/api';
import { api, useAuth, useLocale } from '@app/hooks';
import { LOGIN_FAIL_STATUS } from '@app/constants';
import { Button, Form, Icons } from '@app/components';

const GRANT_TYPE = 'password';

const LoginWithPassword: React.FC = () => {
  const SCOPE_OPTIONS = {
    scope: 'pages.login',
  };
  const [error, setError] = useState<null | string>(null);
  const { storeToken } = useAuth();
  const { t } = useLocale();
  const [emailCheckIcon, setEmailCheckIcon] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const { mutate, isLoading } = api.useBranchUserLogin({
    onSuccess: (data: BranchUserTokenSuccessResponse) => {
      storeToken(data);

      Router.push('/gateway');
    },
    onError: (data: { response: { data: { status: string; failed_attemps: number; maximum_attempts: number } } }) => {
      if (data.response.data) {
        return handleFailedSignInMessage(data.response.data);
      }
    },
  });
  const { register, handleSubmit } = useForm({ reValidateMode: 'onChange' });

  function handleSignIn(data: { [x: string]: string | number }) {
    setError(null);
    mutate({
      grant_type: GRANT_TYPE,
      client_id: process.env.NEXT_PUBLIC_API_CLIENT_ID,
      client_secret: process.env.NEXT_PUBLIC_API_CLIENT_SECRET,
      ...data,
    });
  }

  function handleFailedSignInMessage(data: { status: string; failed_attemps: number; maximum_attempts: number }) {
    if (data.status === LOGIN_FAIL_STATUS.INVALID) {
      return setError(
        t('errors.invalid', {
          ...SCOPE_OPTIONS,
          failed_attemps: data.failed_attemps,
          remaining_attemps: data.maximum_attempts - data.failed_attemps,
        })
      );
    }

    if (data.status === LOGIN_FAIL_STATUS.LAST_ATTEMPT) {
      return setError(t('errors.last_attempt', SCOPE_OPTIONS));
    }

    if (data.status === LOGIN_FAIL_STATUS.LOCKED) {
      return setError(t('errors.locked', SCOPE_OPTIONS));
    }

    return setError(t('errors.wrongEmailOrPassword', SCOPE_OPTIONS));
  }

  function emailCheck(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.validity.valid && e.target.value !== '') {
      setEmailCheckIcon(true);
    } else {
      setEmailCheckIcon(false);
    }
  }

  return (
    <form onSubmit={handleSubmit(handleSignIn)}>
      <div className="text-center mb-10">
        <h2 className="text-3xl font-semibold text-gray-900">{t('labels.title', SCOPE_OPTIONS)}</h2>
        <p className="text-sm text-gray-500 mt-1">{t('labels.description', SCOPE_OPTIONS)}</p>
      </div>
      {error && (
        <div className="flex items-center justify-between gap-4 bg-[#E53030] border border-red-400 p-2 rounded-2 mb-4 px-4 py-4">
          <div className="w-6 h-6 bg-white flex-shrink-0 rotate-45 rounded-1 flex justify-center items-center">
            <span className="text-[#E53030] -rotate-45">!</span>
          </div>
          <p className="text-3.5 text-white text-bold text-left w-full">
            <span>{error}</span>
          </p>
        </div>
      )}
      <div className="group relative mb-4">
        <Icons.EmailSolid className="absolute left-5 top-[50%] translate-y-[-50%]" width={20} />
        <Icons.CheckCircle
          className={cn('absolute right-5 top-[50%] translate-y-[-50%]', { block: emailCheckIcon, hidden: !emailCheckIcon })}
          width={20}
          color="blue"
        />
        <input
          id="email"
          type="email"
          className="emailInput invalid:border-red-500 w-full outline-none border pl-12 pr-12 py-3 rounded-5"
          placeholder="Email"
          onInput={emailCheck}
          {...register('email', {
            required: { value: true, message: t('form.email.errors.required', SCOPE_OPTIONS) },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: t('form.email.errors.pattern', SCOPE_OPTIONS),
            },
          })}
        />
      </div>
      <div className="relative mb-4">
        <Icons.Password className="absolute left-5 top-[50%] translate-y-[-50%]" width={20} />
        {passwordShow ? (
          <Icons.EyeSolid
            onClick={() => setPasswordShow((current) => !current)}
            className="cursor-pointer absolute right-3 top-[50%] translate-y-[-50%]"
            width={40}
          />
        ) : (
          <Icons.EyeClosedAlt
            onClick={() => setPasswordShow((current) => !current)}
            className="cursor-pointer absolute right-3 top-[50%] translate-y-[-50%]"
            width={40}
          />
        )}
        <input
          id="password"
          type={passwordShow ? 'text' : 'password'}
          className="invalid:border-red-500 w-full outline-none border pl-12 pr-12 py-3 rounded-5"
          placeholder={t('form.password.label', SCOPE_OPTIONS)}
          {...register('password', {
            required: { value: true, message: t('form.password.errors.required', SCOPE_OPTIONS) },
          })}
        />
      </div>
      <div className="flex justify-between mb-8">
        <Form.Checkbox id="remember" name="remember" label={t('form.rememberMe.label', SCOPE_OPTIONS)} />
        <Link href="/forgot-password">
          <a className="text-blue-300 font-medium underline text-sm">{t('actions.forgotPassword', SCOPE_OPTIONS)}</a>
        </Link>
      </div>
      <Button
        disabled={isLoading}
        type="submit"
        theme="blue"
        className="mt-8 disabled:opacity-50 disabled:cursor-not-allowed"
        label={t('form.submit', SCOPE_OPTIONS)}
      />
    </form>
  );
};

export default LoginWithPassword;

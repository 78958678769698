import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { PasswordBranchUserApi } from '@app/api';
import type { BranchUserResetPasswordInput } from '@app/api';
import { useAxios } from '@app/hooks';

export function branchUserResetPassword(
  branchUserResetPasswordInput: BranchUserResetPasswordInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new PasswordBranchUserApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.branchUserResetPassword(branchUserResetPasswordInput).then((res) => res.data);
}

export default function useBranchUserResetPassword(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation((values: BranchUserResetPasswordInput) => branchUserResetPassword(values, axiosInstance), props);
}

import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18.872" height="18.872" viewBox="0 0 18.872 18.872" {...props}>
    <defs>
      <clipPath id="clip-path">
        <path fill="#181a27" d="M0 0H18.872V18.872H0z" data-name="Rectangle 1886"></path>
      </clipPath>
    </defs>
    <g data-name="Group 4556">
      <g fill="#181a27" clipPath="url(#clip-path)" data-name="Group 4555">
        <path
          d="M12.037 1.965h4.55a.319.319 0 01.319.318v4.551a.983.983 0 101.965 0V2.283A2.3 2.3 0 0016.587 0h-4.55a.983.983 0 000 1.965"
          data-name="Path 2842"
        ></path>
        <path
          d="M.982 7.816v-.25.25a.985.985 0 00.981-.981V2.283a.318.318 0 01.318-.318h4.553a.983.983 0 100-1.965H2.283A2.285 2.285 0 000 2.283v4.552a.982.982 0 00.982.981"
          data-name="Path 2843"
        ></path>
        <path
          d="M6.834 16.906H2.281a.317.317 0 01-.316-.317v-4.554a.983.983 0 10-1.965 0v4.552a2.288 2.288 0 002.283 2.285h4.551a.983.983 0 000-1.966"
          data-name="Path 2844"
        ></path>
        <path
          d="M17.89 11.054v.25-.25a.983.983 0 00-.982.981v4.552a.318.318 0 01-.318.318h-4.554a.982.982 0 100 1.964h4.554a2.282 2.282 0 002.282-2.281v-4.553a.982.982 0 00-.982-.981"
          data-name="Path 2845"
        ></path>
        <path
          d="M14.514 13.948a1.013 1.013 0 001.373.164.984.984 0 00.177-1.374l-3.526-4.554a2.28 2.28 0 00-3.2-.4 2.294 2.294 0 00-.4.4l-3.23 4.169H3.931a.983.983 0 100 1.966h2.26a.983.983 0 00.77-.376l3.524-4.553a.319.319 0 01.446-.058.411.411 0 01.059.059l3.522 4.554z"
          data-name="Path 2846"
        ></path>
        <path
          d="M5.748 4.152a2.478 2.478 0 102.477 2.477 2.485 2.485 0 00-2.481-2.477m0 1.74a.739.739 0 01.739.739.745.745 0 01-.216.525.74.74 0 11-.524-1.264"
          data-name="Path 2847"
        ></path>
      </g>
    </g>
  </svg>
);

export default SvgComponent;

import { AGREEMENTS } from '@app/constants';

type UserType = {
  name: string | number;
  surname: string | number;
  phone_number: string | number;
  password: string | number;
  password_confirmation: string | number;
  accepted_agreements: {
    agreement: string;
  }[];
};

type DataType = {
  clientId: string | undefined;
  token: string | undefined;
  user: UserType;
};

function createBranchUserFormData(token: string, formData: { [x: string]: string | number }, isUserAggreementRequired: boolean) {
  const data: DataType = {
    clientId: process.env.NEXT_PUBLIC_API_CLIENT_ID,
    token,
    user: {
      name: formData.name,
      surname: formData.surname,
      phone_number: formData.phoneNumber,
      password: formData.password,
      password_confirmation: formData.passwordConfirmation,
      accepted_agreements: [],
    },
  };

  if (isUserAggreementRequired) {
    data.user.accepted_agreements = [
      ...data.user.accepted_agreements,
      {
        agreement: AGREEMENTS.USER_AGREEMENT,
      },
    ];
  }

  return data;
}

export default { createBranchUserFormData };

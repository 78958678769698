/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BranchSocialMediaPostCreateInput } from '../model';
// @ts-ignore
import { PunditResponse } from '../model';
// @ts-ignore
import { V2FavoriteSocialMediaPostsListResponse } from '../model';
// @ts-ignore
import { V2SocialMediaPostsListResponse } from '../model';
/**
 * SocialMediaPostsBranchApi - axios parameter creator
 * @export
 */
export const SocialMediaPostsBranchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create social media post for the branch
         * @summary Create social media post for the branch
         * @param {string} branchId The id of the branch
         * @param {BranchSocialMediaPostCreateInput} branchSocialMediaPostCreateInput Create a social media post
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBranchSocialMediaPost: async (branchId: string, branchSocialMediaPostCreateInput: BranchSocialMediaPostCreateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('createBranchSocialMediaPost', 'branchId', branchId)
            // verify required parameter 'branchSocialMediaPostCreateInput' is not null or undefined
            assertParamExists('createBranchSocialMediaPost', 'branchSocialMediaPostCreateInput', branchSocialMediaPostCreateInput)
            const localVarPath = `/branch_account/branches/{branch_id}/social_media_posts`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(branchSocialMediaPostCreateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List favorite posts with attachments
         * @summary List favorite posts with attachments
         * @param {string} branchId The id of the branch
         * @param {string} [limit] record limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFavoriteSocialMediaPosts: async (branchId: string, limit?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('listFavoriteSocialMediaPosts', 'branchId', branchId)
            const localVarPath = `/v2/branch_account/branches/{branch_id}/mobile/social_media_posts/favorites`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List posts with attachments
         * @summary List posts with attachments
         * @param {string} branchId The id of the branch
         * @param {string} startDate Start date of the date range for the insights
         * @param {string} endDate End date of the date range for the insights
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSocialMediaPosts: async (branchId: string, startDate: string, endDate: string, page?: number, perPage?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('listSocialMediaPosts', 'branchId', branchId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('listSocialMediaPosts', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('listSocialMediaPosts', 'endDate', endDate)
            const localVarPath = `/v2/branch_account/branches/{branch_id}/mobile/social_media_posts`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SocialMediaPostsBranchApi - functional programming interface
 * @export
 */
export const SocialMediaPostsBranchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SocialMediaPostsBranchApiAxiosParamCreator(configuration)
    return {
        /**
         * Create social media post for the branch
         * @summary Create social media post for the branch
         * @param {string} branchId The id of the branch
         * @param {BranchSocialMediaPostCreateInput} branchSocialMediaPostCreateInput Create a social media post
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBranchSocialMediaPost(branchId: string, branchSocialMediaPostCreateInput: BranchSocialMediaPostCreateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBranchSocialMediaPost(branchId, branchSocialMediaPostCreateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List favorite posts with attachments
         * @summary List favorite posts with attachments
         * @param {string} branchId The id of the branch
         * @param {string} [limit] record limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFavoriteSocialMediaPosts(branchId: string, limit?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2FavoriteSocialMediaPostsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFavoriteSocialMediaPosts(branchId, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List posts with attachments
         * @summary List posts with attachments
         * @param {string} branchId The id of the branch
         * @param {string} startDate Start date of the date range for the insights
         * @param {string} endDate End date of the date range for the insights
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSocialMediaPosts(branchId: string, startDate: string, endDate: string, page?: number, perPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2SocialMediaPostsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSocialMediaPosts(branchId, startDate, endDate, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SocialMediaPostsBranchApi - factory interface
 * @export
 */
export const SocialMediaPostsBranchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SocialMediaPostsBranchApiFp(configuration)
    return {
        /**
         * Create social media post for the branch
         * @summary Create social media post for the branch
         * @param {string} branchId The id of the branch
         * @param {BranchSocialMediaPostCreateInput} branchSocialMediaPostCreateInput Create a social media post
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBranchSocialMediaPost(branchId: string, branchSocialMediaPostCreateInput: BranchSocialMediaPostCreateInput, options?: any): AxiosPromise<boolean> {
            return localVarFp.createBranchSocialMediaPost(branchId, branchSocialMediaPostCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * List favorite posts with attachments
         * @summary List favorite posts with attachments
         * @param {string} branchId The id of the branch
         * @param {string} [limit] record limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFavoriteSocialMediaPosts(branchId: string, limit?: string, options?: any): AxiosPromise<V2FavoriteSocialMediaPostsListResponse> {
            return localVarFp.listFavoriteSocialMediaPosts(branchId, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * List posts with attachments
         * @summary List posts with attachments
         * @param {string} branchId The id of the branch
         * @param {string} startDate Start date of the date range for the insights
         * @param {string} endDate End date of the date range for the insights
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSocialMediaPosts(branchId: string, startDate: string, endDate: string, page?: number, perPage?: number, options?: any): AxiosPromise<V2SocialMediaPostsListResponse> {
            return localVarFp.listSocialMediaPosts(branchId, startDate, endDate, page, perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SocialMediaPostsBranchApi - interface
 * @export
 * @interface SocialMediaPostsBranchApi
 */
export interface SocialMediaPostsBranchApiInterface {
    /**
     * Create social media post for the branch
     * @summary Create social media post for the branch
     * @param {string} branchId The id of the branch
     * @param {BranchSocialMediaPostCreateInput} branchSocialMediaPostCreateInput Create a social media post
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialMediaPostsBranchApiInterface
     */
    createBranchSocialMediaPost(branchId: string, branchSocialMediaPostCreateInput: BranchSocialMediaPostCreateInput, options?: any): AxiosPromise<boolean>;

    /**
     * List favorite posts with attachments
     * @summary List favorite posts with attachments
     * @param {string} branchId The id of the branch
     * @param {string} [limit] record limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialMediaPostsBranchApiInterface
     */
    listFavoriteSocialMediaPosts(branchId: string, limit?: string, options?: any): AxiosPromise<V2FavoriteSocialMediaPostsListResponse>;

    /**
     * List posts with attachments
     * @summary List posts with attachments
     * @param {string} branchId The id of the branch
     * @param {string} startDate Start date of the date range for the insights
     * @param {string} endDate End date of the date range for the insights
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialMediaPostsBranchApiInterface
     */
    listSocialMediaPosts(branchId: string, startDate: string, endDate: string, page?: number, perPage?: number, options?: any): AxiosPromise<V2SocialMediaPostsListResponse>;

}

/**
 * SocialMediaPostsBranchApi - object-oriented interface
 * @export
 * @class SocialMediaPostsBranchApi
 * @extends {BaseAPI}
 */
export class SocialMediaPostsBranchApi extends BaseAPI implements SocialMediaPostsBranchApiInterface {
    /**
     * Create social media post for the branch
     * @summary Create social media post for the branch
     * @param {string} branchId The id of the branch
     * @param {BranchSocialMediaPostCreateInput} branchSocialMediaPostCreateInput Create a social media post
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialMediaPostsBranchApi
     */
    public createBranchSocialMediaPost(branchId: string, branchSocialMediaPostCreateInput: BranchSocialMediaPostCreateInput, options?: any) {
        return SocialMediaPostsBranchApiFp(this.configuration).createBranchSocialMediaPost(branchId, branchSocialMediaPostCreateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List favorite posts with attachments
     * @summary List favorite posts with attachments
     * @param {string} branchId The id of the branch
     * @param {string} [limit] record limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialMediaPostsBranchApi
     */
    public listFavoriteSocialMediaPosts(branchId: string, limit?: string, options?: any) {
        return SocialMediaPostsBranchApiFp(this.configuration).listFavoriteSocialMediaPosts(branchId, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List posts with attachments
     * @summary List posts with attachments
     * @param {string} branchId The id of the branch
     * @param {string} startDate Start date of the date range for the insights
     * @param {string} endDate End date of the date range for the insights
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialMediaPostsBranchApi
     */
    public listSocialMediaPosts(branchId: string, startDate: string, endDate: string, page?: number, perPage?: number, options?: any) {
        return SocialMediaPostsBranchApiFp(this.configuration).listSocialMediaPosts(branchId, startDate, endDate, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }
}

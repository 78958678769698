import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100.000000 100.000000" preserveAspectRatio="xMidYMid meet" {...props}>
    <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
      <path d="M423 930 c-51 -11 -90 -30 -126 -64 l-27 -25 31 -51 c34 -55 40 -58 60 -33 21 25 74 43 130 44 69 2 99 -26 99 -90 0 -52 -14 -78 -82 -144 -58 -59 -88 -121 -95 -197 l-6 -60 67 0 66 0 0 39 c0 59 13 85 84 160 74 78 94 113 102 175 13 94 -31 188 -106 226 -46 24 -137 33 -197 20z" />
      <path d="M429 211 c-45 -46 -34 -118 21 -141 74 -31 126 85 65 145 -32 32 -50 31 -86 -4z" />
    </g>
  </svg>
);

export default SvgComponent;

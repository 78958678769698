import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 21.6 16.2"
    xmlSpace="preserve"
    fill="currentColor"
    {...props}
  >
    <path
      d="M21.6,2.8c0-1.1-0.6-2-1.6-2.5l-0.1,0l0,0C19.6,0.1,19.2,0,18.8,0H2.8c-0.3,0-0.7,0.1-1,0.2l0,0
 	L1.6,0.3C0.6,0.7,0,1.7,0,2.8v10.7c0,1.5,1.2,2.8,2.8,2.8h16.1c1.5,0,2.8-1.2,2.8-2.8L21.6,2.8z M11.5,8.5c-0.4,0.3-0.9,0.3-1.3,0
 	L3.5,1.9h14.7L11.5,8.5z M18.9,14.3H2.8c-0.5,0-0.8-0.4-0.8-0.8V3.1l7,6.8c1.1,1.1,2.9,1.1,4,0l6.8-6.6v10.3
 	C19.7,13.9,19.3,14.3,18.9,14.3z"
    />
  </svg>
);

export default SvgComponent;

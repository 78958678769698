import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24.612" height="19.142" viewBox="0 0 24.612 19.142" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g transform="translate(-293.915 -353.043)">
      <path
        d="M303.475,367.357v-1.074a2.718,2.718,0,0,0,1.441-.713,2.354,2.354,0,0,0,.67-1.737,2.518,2.518,0,0,0-.265-1.173,2.452,2.452,0,0,0-.817-.887,5.479,5.479,0,0,0-1.4-.633,2.947,2.947,0,0,1-1.046-.5.711.711,0,0,1-.228-.569.812.812,0,0,1,.219-.627,1.01,1.01,0,0,1,.713-.217.808.808,0,0,1,.675.3,1.459,1.459,0,0,1,.274.944v.4h1.806v-.4a3.067,3.067,0,0,0-.6-1.977,2.414,2.414,0,0,0-1.443-.838v-1.238h-1.649v1.244a2.662,2.662,0,0,0-1.18.721,2.428,2.428,0,0,0-.627,1.71,2.365,2.365,0,0,0,.641,1.693,4.186,4.186,0,0,0,1.858,1.01,2.729,2.729,0,0,1,1.031.517.694.694,0,0,1,.228.536.73.73,0,0,1-.262.609,1.34,1.34,0,0,1-.867.244,1.14,1.14,0,0,1-.842-.283,1.174,1.174,0,0,1-.281-.857v-.4h-1.812v.4a2.746,2.746,0,0,0,.683,1.946,2.66,2.66,0,0,0,1.43.781v1.075Z"
        transform="translate(0.257 0.149)"
        fill="currentColor"
      />
      <path
        d="M302.907,369.378a7.344,7.344,0,1,1,7.165-8.936l1.784.725a9.02,9.02,0,1,0-2.913,7.514l-.669-1.65A7.316,7.316,0,0,1,302.907,369.378Z"
        fill="currentColor"
      />
      <path
        d="M317.72,370.44l-3.058-3.059,2.633-1.391a.835.835,0,0,0-.013-1.545L307.8,360.6a.833.833,0,0,0-1.113.43.848.848,0,0,0,0,.644l3.846,9.471a.826.826,0,0,0,.4.461.843.843,0,0,0,1.129-.351l1.423-2.7,3.057,3.055a.849.849,0,0,0,1.177.005.829.829,0,0,0,0-1.179Zm-4.841-4a.82.82,0,0,0-.348.356l-1.11,2.1-2.448-6.029L315,365.319Z"
        transform="translate(0.563 0.331)"
        fill="currentColor"
      />
    </g>
  </svg>
);

<svg xmlns="http://www.w3.org/2000/svg"></svg>;

export default SvgComponent;

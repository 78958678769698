import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 19.3 19.3"
    fill="currentColor"
    xmlSpace="preserve"
    {...props}
  >
    <g transform="translate(-637.03 -584.082)">
      <path
        d="M653.3,595.7c-0.7-0.1-1.5-0.3-2.2-0.5c-0.9-0.3-2-0.1-2.7,0.6l-0.6,0.6
       c-1.5-1-2.8-2.3-3.8-3.8l0.6-0.6c0.7-0.7,0.9-1.8,0.6-2.7c-0.3-0.7-0.4-1.4-0.5-2.2c-0.2-1.3-1.3-2.2-2.5-2.2h-2.5
       c-0.1,0-0.1,0-0.2,0c-1.4,0.1-2.4,1.4-2.3,2.8c0.3,2.7,1.2,5.3,2.7,7.6c1.4,2.1,3.2,3.9,5.3,5.3c2.3,1.5,4.9,2.4,7.6,2.7
       c0.1,0,0.2,0,0.2,0l0,0c1.4,0,2.5-1.2,2.5-2.6v-2.5C655.5,597,654.5,595.9,653.3,595.7z M643.4,590.7l-1.1,1.1
       c-0.3,0.3-0.3,0.7-0.1,1.1c1.3,2.2,3.1,4.1,5.4,5.4c0.3,0.2,0.8,0.1,1.1-0.1l1.1-1.1c0.2-0.2,0.6-0.3,0.8-0.2
       c0.8,0.3,1.7,0.5,2.5,0.6c0.4,0.1,0.7,0.4,0.7,0.8v2.5c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2l0,0
       c-2.4-0.3-4.8-1.1-6.9-2.4c-1.9-1.2-3.5-2.9-4.8-4.8c-1.3-2.1-2.2-4.4-2.4-6.9c0-0.2,0-0.4,0.2-0.6c0.2-0.2,0.4-0.3,0.6-0.3h2.5
       c0.4,0,0.7,0.3,0.8,0.7c0.1,0.9,0.3,1.7,0.6,2.6C643.7,590.2,643.6,590.5,643.4,590.7z"
      />
      <path
        d="M651.2,591.8c0.1,0.4,0.4,0.7,0.9,0.7c0.1,0,0.1,0,0.2,0c0.5-0.1,0.8-0.6,0.7-1
       c-0.4-2-2-3.6-4-4c-0.5-0.1-0.9,0.2-1,0.7c-0.1,0.5,0.2,0.9,0.7,1C649.9,589.4,651,590.5,651.2,591.8z"
      />
      <path
        d="M648.9,584.1c-0.2,0-0.5,0-0.6,0.2c-0.2,0.1-0.3,0.4-0.3,0.6c-0.1,0.5,0.3,0.9,0.8,1
       c3.1,0.3,5.6,2.8,5.9,5.9c0,0.4,0.4,0.8,0.9,0.8c0,0,0.1,0,0.1,0c0.5-0.1,0.8-0.5,0.8-1c0,0,0,0,0,0
       C655.9,587.6,652.8,584.5,648.9,584.1z"
      />
    </g>
  </svg>
);

export default SvgComponent;

import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { IntegrationsBranchApi } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function listBranchIntegrations(
  branchId: string,
  page?: number,
  perPage?: number,
  query?: object,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new IntegrationsBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.listBranchIntegrations(branchId, page, perPage, query).then((res) => res.data);
}

export default function useListBranchIntegrations({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useQuery(
    ['useListBranchIntegrations', branchId, params.page, params.perPage, params.query],
    () => listBranchIntegrations(branchId, params.page, params.perPage, params.query, axiosInstance),
    props
  );
}

import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="1.5"></circle>
    <path stroke="red" strokeLinecap="round" strokeWidth="1.5" d="M12 7v6"></path>
    <circle cx="12" cy="16" r="1" fill="currentColor"></circle>
  </svg>
);

export default SvgComponent;

import React from 'react';

import { Controller } from 'react-hook-form';
import ReactCreatableSelect from 'react-select/creatable';
import cn from 'classnames';

import { useLocale } from '@app/hooks';

type OptionType = {
  value: string | number;
  label: string;
};
type SelectPropTypes = {
  label?: string;
  id?: string;
  error?: { message: string | null };
  className?: string;
  name: string;
  options: OptionType[];
  defaultValue?: string | string[] | number | number[] | OptionType[];
  placeholder?: string;
  control: any;
  isMulti?: boolean;
  rules?: any;
  automaticHandleValue?: boolean;
  onInputChange?: (query: string) => void;
  onChange?: (values: any) => any;
  isSearchable?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  right?: React.ReactNode;
  getOptionLabels?: any;
  filterOptions?: any;
  controlShouldRenderValue?: boolean;
  prefix?: boolean;
  onCreateOption?: (value: string) => void;
};

export const CreateableSelect: React.FC<SelectPropTypes> = ({
  label,
  id,
  error,
  className,
  name,
  options,
  defaultValue,
  placeholder,
  control,
  isMulti = false,
  rules,
  automaticHandleValue = true,
  getOptionLabels,
  filterOptions,
  onInputChange,
  onChange,
  isSearchable,
  isClearable,
  isLoading,
  right,
  controlShouldRenderValue,
  prefix,
  onCreateOption,
}) => {
  const SCOPE_OPTIONS = {
    scope: 'components.CreatableSelect',
  };
  const isRequired = getRequiredStatus();
  const { t } = useLocale();
  function getRequiredStatus() {
    if (rules?.required) {
      if (!Object.prototype.hasOwnProperty.call(rules?.required, 'value')) {
        return true;
      }

      if (rules?.required.value === true) {
        return true;
      }

      return false;
    }
    return false;
  }

  function handleOnChange(newValue: OptionType | OptionType[]) {
    onChange && onChange(newValue);

    if (!automaticHandleValue) {
      return newValue;
    }

    if (Array.isArray(newValue)) {
      return newValue.map((option: OptionType) => option.value);
    }

    return newValue.value;
  }

  function handleValue(value: unknown) {
    if (typeof value === 'undefined') {
      return undefined;
    }

    if (!automaticHandleValue) {
      return value;
    }

    if (Array.isArray(value) && isMulti) {
      return options.filter((option: OptionType) => value.includes(option.value));
    }

    return options.filter((option: OptionType) => value === option.value);
  }

  return (
    <div className={cn('flex flex-col w-full', className)}>
      {!prefix && (
        <div className="flex items-center justify-between mb-1.5">
          <label htmlFor={id} className="block text-3.5 font-semibold text-black-800">
            {label}
            {isRequired && <span className="text-red-400">*</span>}
          </label>
          <div className="flex flex-row items-center gap-4">
            {error && (
              <div className="flex items-center">
                <span className="inline text-red-400 text-3 whitespace-nowrap">{error.message}</span>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="flex justify-end h-4">{right && right}</div>

      <div className="flex items-center justify-between w-full">
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field: { value, onChange, onBlur } }) => {
            return (
              <ReactCreatableSelect
                onInputChange={onInputChange}
                isSearchable={isSearchable}
                isClearable={isClearable}
                isLoading={isLoading}
                options={options ?? []}
                placeholder={placeholder ?? 'Select...'}
                isMulti={isMulti}
                onChange={(selectedValues: any) => onChange(handleOnChange(selectedValues))}
                onBlur={onBlur}
                value={handleValue(value)}
                defaultValue={handleValue(value)}
                classNamePrefix="react-select"
                className={cn({
                  error: error?.message,
                })}
                noOptionsMessage={() => t('form.interest.label', SCOPE_OPTIONS)}
                loadingMessage={() => t('form.interest.loading', SCOPE_OPTIONS)}
                getOptionLabel={getOptionLabels}
                filterOption={filterOptions}
                controlShouldRenderValue={controlShouldRenderValue}
                formatCreateLabel={(value) => t('createLabel', { ...SCOPE_OPTIONS, value })}
                onCreateOption={onCreateOption}
                createOptionPosition="first"
                allowCreateWhileLoading={true}
              />
            );
          }}
          defaultValue={defaultValue}
        />
      </div>
    </div>
  );
};

export default CreateableSelect;

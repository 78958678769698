import React from 'react';

import { FacebookProvider, Initialize } from 'react-facebook';

import { Button, Icons } from '@app/components';
import { useAuth } from '@app/hooks';
import { branch as branchLib } from '@app/lib';

declare const window: {
  FB: any;
};

type FacebookAdsPaymentPropTypes = {
  onModalClose?: (response: any) => void;
  label?: string;
  customButton?: React.ReactNode;
};

const FacebookAdsPayment: React.FC<FacebookAdsPaymentPropTypes> = ({ onModalClose, label, customButton }) => {
  const { branch } = useAuth();
  const branchIntegration = branchLib.integration(branch);

  return (
    <FacebookProvider appId={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID as string} version="v14.0">
      <Initialize>
        {({ isReady }: any) =>
          !customButton ? (
            <Button
              IconLeft={Icons.Plus}
              disabled={!isReady}
              label={label}
              size="sm"
              theme="green"
              block={false}
              onClick={() =>
                window.FB.ui(
                  {
                    account_id: branchIntegration?.ad_account_id?.replaceAll('act_', ''),
                    display: 'popup',
                    method: 'ads_payment',
                  },
                  (response: any) => {
                    onModalClose && onModalClose(response);
                  }
                )
              }
            />
          ) : (
            <button
              disabled={!isReady}
              onClick={() =>
                window.FB.ui(
                  {
                    account_id: branchIntegration?.ad_account_id?.replaceAll('act_', ''),
                    display: 'popup',
                    method: 'ads_payment',
                  },
                  (response: any) => {
                    onModalClose && onModalClose(response);
                  }
                )
              }
            >
              {customButton}
            </button>
          )
        }
      </Initialize>
    </FacebookProvider>
  );
};

export default FacebookAdsPayment;

import { Button, Icons, Modal, Table } from '@app/components';
import useDeleteSavedCard from '@app/hooks/api/useDeleteSavedCard';
import useLocale from '@app/hooks/useLocale';
import { V2GetCardsResponse } from 'api/model';
import React, { useState } from 'react';

type SavedCardsTableProps = {
  savedCards: V2GetCardsResponse | undefined;
  refetch: () => void;
};
export default function SavedCardsTable({ savedCards, refetch }: SavedCardsTableProps) {
  const SCOPE_OPTIONS = {
    scope: 'pages.wallet.savedCardsTable',
  };
  const { t } = useLocale();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedCardId, setSelectedCardId] = useState<string>('');
  const handleOpenModal = (cardId: string) => {
    setOpenModal(true);
    setSelectedCardId(cardId);
  };
  type Card = {
    ctoken: string;
    last_4: string;
    month: string;
    year: string;
    c_bank: string;
    require_cvv: string;
    c_name: string;
    c_brand: string;
    c_type: string;
    businessCard: string;
    initial: string;
    schema: string;
  };

  const columns = [
    {
      Header: t('cardName', SCOPE_OPTIONS),
      accessor: (row: Card) => <span className="pl-4">{row.c_name}</span>,
    },
    {
      Header: t('cardNumber', SCOPE_OPTIONS),
      accessor: (row: Card) => <span>**** **** **** {row.last_4}</span>,
    },
    {
      Header: t('expirationDate', SCOPE_OPTIONS),
      accessor: (row: Card) => (
        <span className="pl-8">
          {row.month}/{row.year}
        </span>
      ),
    },
    {
      Header: ' ',
      accessor: (row: Card) => (
        <Button label={t('delete', SCOPE_OPTIONS)} theme="red" className="w-35" onClick={() => handleOpenModal(row.ctoken)} />
      ),
    },
  ];

  const { mutate } = useDeleteSavedCard();

  function handleDelete(id: string) {
    mutate(id, {
      onSuccess: () => {
        refetch();
        setOpenModal(false);
      },
    });
  }

  return (
    <>
      {savedCards?.cards?.length ? (
        <Table columns={columns} data={savedCards?.cards} header disableSortBy />
      ) : (
        <div className="flex flex-col items-center gap-5">
          <Icons.CreditCard width={300} />
          <span className="font-normal text-black-800 text-xl mt-2 text-center">{t('noSavedCards', SCOPE_OPTIONS)}</span>
        </div>
      )}
      <Modal isOpen={openModal} onClose={() => setOpenModal(openModal)}>
        <div className="grid grid-cols-1 relative h-auto px-5 py-5">
          <div className="flex justify-center items-center">
            <span className="font-normal text-black-800">{t('modal.text', SCOPE_OPTIONS)}</span>
          </div>
          <div className="flex justify-center pt-5 space-x-5 h-16">
            <Button label={t('modal.cancel', SCOPE_OPTIONS)} className="w-24" theme="blue" onClick={() => setOpenModal(false)} />
            <Button label={t('modal.delete', SCOPE_OPTIONS)} theme="red" className="w-24" onClick={() => handleDelete(selectedCardId)} />
          </div>
        </div>
      </Modal>
    </>
  );
}

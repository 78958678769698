import React, { useState, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { Form, Icons } from '@app/components';
import { api, useLocale, useDebounce } from '@app/hooks';
import { array } from '@app/lib';

type DefaultValue = {
  value: string | number | undefined;
  label: string | undefined;
};

type KeywordsInputPropTypes = {
  defaultValue?: Array<DefaultValue>;
};

const DEBOUNCE_MS = 200;

const KeywordsInput: React.FC<KeywordsInputPropTypes> = ({ defaultValue }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.CustomSegmentsInput',
  };
  const { t } = useLocale();
  const [query, setQuery] = useState<string>('');
  const {
    formState: { errors },
    control,
    setValue,
    watch,
  } = useFormContext();
  const keywordsWatch = watch('keywords');
  const { debouncedValue: debouncedQuery } = useDebounce(query, DEBOUNCE_MS);
  const { data, isLoading, refetch } = api.useListKeywordsThemesByBranch({
    enabled: false,
    params: {
      query: query,
    },
  });
  useEffect(() => {
    if (query.length > 2) {
      refetch();
    }
  }, [debouncedQuery, refetch]);

  function handleDelete(keyword: any) {
    const filteredKeywords = keywordsWatch.filter((item: DefaultValue) => item.value !== keyword);
    setValue('keywords', filteredKeywords);
  }

  const handleCreate = (value: string) => {
    const newOption = {
      value,
      label: value,
      keywordType: 'free_form_keyword',
    };

    setValue('keywords', [...keywordsWatch, newOption]);
  };

  return (
    <div className="flex flex-col w-full mt-4">
      <div className="flex items-center justify-between mb-3.5">
        <label className="flex items-center text-3.5 font-semibold text-black-800 gap-x-2">
          <span>
            {t('form.customSegments.label', SCOPE_OPTIONS)}
            <span className="text-red-400">*</span>
          </span>
          <span className="text-xs font-normal">{t('form.customSegments.description', SCOPE_OPTIONS)}</span>
        </label>

        <div className="flex flex-row items-center gap-4">
          {errors && (
            <div className="flex items-center">
              <span className="inline text-red-400 text-3 whitespace-nowrap">{errors.message}</span>
            </div>
          )}
        </div>
      </div>
      {keywordsWatch && !array.isEmpty(keywordsWatch) && (
        <div className="flex items-center flex-wrap gap-1.5">
          {keywordsWatch.map((keyword: { label: string; value: string; keywordType: string }, index: number) => (
            <div className="bg-gray-200 flex justify-between p-2 space-x-2 rounded-2" key={index}>
              <span>{keyword.label}</span>
              <button onClick={() => handleDelete(keyword.value)}>
                <Icons.XMark className="w-4 h-4" />
              </button>
            </div>
          ))}
        </div>
      )}
      <Form.CreatableSelect
        isMulti
        automaticHandleValue={false}
        onInputChange={setQuery}
        onCreateOption={handleCreate}
        isSearchable
        isClearable={false}
        name="keywords"
        isLoading={isLoading}
        placeholder={t('form.customSegments.placeholder', SCOPE_OPTIONS)}
        rules={{
          required: { value: true, message: t('form.customSegments.errors.required', SCOPE_OPTIONS) },
        }}
        control={control}
        options={data?.keyword_themes?.map((keyword: { text: string; id: string }) => ({
          label: keyword.text,
          value: keyword.id,
          keywordType: 'keyword',
        }))}
        defaultValue={defaultValue?.map((item: any) => ({
          label: item.label,
          value: item.value,
          keywordType: item.keywordType,
        }))}
        error={errors.keywords}
        controlShouldRenderValue={false}
        prefix
      />
    </div>
  );
};

export default KeywordsInput;

import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { OTPBranchV2Api } from '@app/api';
import { useAxios } from '@app/hooks';

type ParamsType = {
  phoneNumber: string;
  reference: string;
  code: string;
};

export function checkOtpToBranchUserV2(params: ParamsType, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new OTPBranchV2Api(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.checkOtpToBranchUserV2(params).then((res) => res.data);
}

export default function useCheckOtpToBranchUserV2(props: ApiQueryHookPropTypes) {
  const { api: axiosInstance } = useAxios();

  return useMutation((values: ParamsType) => checkOtpToBranchUserV2(values, axiosInstance), props);
}

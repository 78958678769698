import React from 'react';

import cn from 'classnames';
import DatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import { tr, ro, pl } from 'date-fns/locale';

import { useLocale } from '@app/hooks';
import { date as dateLib } from '@app/lib';

const localeMap = {
  tr: tr,
  ro: ro,
  pl: pl,
};

type DatePropTypes = {
  label?: string;
  id?: string;
  error?: { message: string | null };
  className?: string;
  name: string;
  defaultValue?: string;
  placeholder?: string;
  control: any;
  showTimeSelect?: boolean;
  dateFormat: string;
  rules?: any;
  timeIntervals?: number;
  showTimeSelectOnly?: boolean;
  disabled?: boolean;
  customInput?: any;
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
  minTime?: Date;
  maxTime?: Date;
};

export const Date: React.FC<DatePropTypes> = ({
  error,
  name,
  defaultValue,
  control,
  timeIntervals,
  dateFormat = 'dd/MM/yyy',
  rules,
  showTimeSelect,
  showTimeSelectOnly,
  disabled,
  customInput,
  minDate,
  maxDate,
  minTime,
  maxTime,
}) => {
  const { locale } = useLocale();

  return (
    <div>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { value, onChange } }) => {
          return (
            <DatePicker
              selected={value && dateLib.ISOtoJSDate(value)}
              onChange={(date) => onChange(date && dateLib.JSDateToISO(date))}
              showTimeSelect={showTimeSelect}
              timeIntervals={timeIntervals}
              showTimeSelectOnly={showTimeSelectOnly}
              withPortal={true}
              dateFormat={dateFormat}
              className={cn(' py-2 px-2 font-normal text-sm text-black-800 border-none', {
                'bg-white border-red-400': error,
              })}
              locale={localeMap[locale as keyof typeof localeMap] || ''}
              disabled={disabled}
              customInput={customInput}
              minDate={minDate}
              minTime={minTime}
              maxTime={maxTime}
              maxDate={maxDate}
            />
          );
        }}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export default Date;

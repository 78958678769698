import React from 'react';

import { RadioGroup } from '@headlessui/react';
import { Controller } from 'react-hook-form';
import cn from 'classnames';

import { array } from '@app/lib';
import { useLocale } from '@app/hooks';
import { Box, Icons } from '@app/components';

type MediaTypePropTypes = {
  containerClassName?: string;
  options: { label: string; value: string; disabled?: boolean; date?: boolean; Icon?: string }[];
  value: string;
  onChange: (value: string) => void;
  className?: string;
  hasMainOption?: boolean;
  control?: any;
  rules?: any;
  error?: { message: string | null };
  name: string;
};

const MediaType: React.FC<MediaTypePropTypes> = ({ control, rules, name, error, options, value, onChange }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.SocialMediaManagement.MediaType',
  };
  const { t } = useLocale();
  return (
    <Box className="bg-white px-5">
      <span className="block text-3.5 font-semibold text-gray-900">{t('label', SCOPE_OPTIONS)}</span>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={() => (
          <RadioGroup value={value} onChange={onChange}>
            <div className="grid grid-cols-1 gap-y-2 mt-3">
              {!array.isEmpty(options) &&
                options.map((option) => (
                  <RadioGroup.Option value={option.value} disabled={option.disabled} key={option.value}>
                    {({ checked }) => (
                      <div
                        className={cn('flex justify-between items-center border border-black-800 px-3.5 py-2 rounded-2.5', {
                          'border border-gray-500 bg-gray-100': option.disabled,
                          'border border-red-500 bg-gray-100': error,
                        })}
                      >
                        <div className="flex items-center gap-2">
                          {option.Icon === 'Video' ? <Icons.Video className="w-5 h-5" /> : <Icons.Image className="w-5 h-5" />}
                          <span
                            className={cn('block text-3 font-semibold text-gray-900', {
                              '!text-gray-500': option.disabled,
                              '!text-red-500': error,
                            })}
                          >
                            {option.label}
                          </span>
                        </div>
                        <div
                          className={cn('flextransition-colors', {
                            'text-gray-500': !checked || option.disabled,
                            'text-green-500': checked,
                          })}
                        >
                          {checked ? (
                            <Icons.CheckRounded className="w-6 h-6" />
                          ) : option.Icon === 'Video' ? (
                            <span className="bg-yellow-200 text-yellow-400 text-xs font-medium px-2.5 py-0.5 rounded-full ">
                              {t('video', SCOPE_OPTIONS)}
                            </span>
                          ) : (
                            <Icons.OutlineCircle className="w-6 h-6" />
                          )}
                        </div>
                      </div>
                    )}
                  </RadioGroup.Option>
                ))}
            </div>
          </RadioGroup>
        )}
      />
    </Box>
  );
};

export default MediaType;

const ANALYTICS_CONTENT = Object.freeze({
  IMPRESSION: {
    KEY: 'impression',
    COLOR: '#3067f4',
  },
  CLICK: {
    KEY: 'click',
    COLOR: '#19a4aa',
  },
  COST: {
    KEY: 'cost',
    COLOR: '#442ad1',
  },
  CPC: {
    KEY: 'cpc',
    COLOR: '#ffa700',
  },
});

export default ANALYTICS_CONTENT;

import { Fragment, useEffect } from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';

import { Form, Icons } from '@app/components';
import { array as arrayLib } from 'lib';
import { useLocale } from '@app/hooks';

const NegativeKeywordsInput: React.FC = () => {
  const id = 'negative_keywords';
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.CustomSegmentsInput',
  };
  const { t } = useLocale();
  const { watch, control, clearErrors } = useFormContext();
  const { append, remove } = useFieldArray({
    control,
    name: id,
  });
  const keywordsWatch = watch(id);

  useEffect(() => {
    if (!arrayLib.isEmpty(keywordsWatch)) {
      return clearErrors(id);
    }
  }, [keywordsWatch]);

  function handleKeywords(event: any) {
    if (event.key === 'Enter' && event.target.value.trim() !== '') {
      append(event.target.value.toLowerCase());
      event.target.value = '';
    }
  }

  return (
    <Fragment>
      <div className="flex items-center justify-between mb-1.5">
        <label className="text-3.5 font-semibold text-gray-900 flex items-center gap-x-2">
          <span>{t('form.negativeKeywords.label', SCOPE_OPTIONS)}</span>
          <span className="text-xs font-normal">{t('form.negativeKeywords.description', SCOPE_OPTIONS)}</span>
        </label>
      </div>
      {keywordsWatch && !arrayLib.isEmpty(keywordsWatch) && (
        <div className="flex items-center flex-wrap gap-1.5 my-4">
          {keywordsWatch?.map((keyword: string, index: number) => (
            <div className="bg-gray-200 flex items-center justify-between p-2 space-x-2 rounded-2" key={index}>
              <span>{keyword}</span>
              <Icons.XMark onClick={() => remove(index)} className="w-4 h-4 cursor-pointer" />
            </div>
          ))}
        </div>
      )}

      <Form.Input
        id={id}
        type="text"
        name={id}
        onKeyDown={(event) => handleKeywords(event)}
        placeholder={t('form.negativeKeywords.placeholder', SCOPE_OPTIONS)}
      />
    </Fragment>
  );
};

export default NegativeKeywordsInput;

/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InlineObject2 } from '../model';
// @ts-ignore
import { UploadBannerbearImageAttachmentErrorResponse } from '../model';
// @ts-ignore
import { UploadBannerbearImageAttachmentSuccessResponse } from '../model';
/**
 * WebhooksApi - axios parameter creator
 * @export
 */
export const WebhooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Handle incoming webhook data for Bannerbear attachment images
         * @summary Handle Bannerbear Attachment Image Webhook
         * @param {InlineObject2} inlineObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleBannerbearAttachmentImageWebhook: async (inlineObject2: InlineObject2, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject2' is not null or undefined
            assertParamExists('handleBannerbearAttachmentImageWebhook', 'inlineObject2', inlineObject2)
            const localVarPath = `/webhooks/bannerbear_attachment_image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebhooksApi - functional programming interface
 * @export
 */
export const WebhooksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebhooksApiAxiosParamCreator(configuration)
    return {
        /**
         * Handle incoming webhook data for Bannerbear attachment images
         * @summary Handle Bannerbear Attachment Image Webhook
         * @param {InlineObject2} inlineObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleBannerbearAttachmentImageWebhook(inlineObject2: InlineObject2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadBannerbearImageAttachmentSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleBannerbearAttachmentImageWebhook(inlineObject2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebhooksApi - factory interface
 * @export
 */
export const WebhooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebhooksApiFp(configuration)
    return {
        /**
         * Handle incoming webhook data for Bannerbear attachment images
         * @summary Handle Bannerbear Attachment Image Webhook
         * @param {InlineObject2} inlineObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleBannerbearAttachmentImageWebhook(inlineObject2: InlineObject2, options?: any): AxiosPromise<UploadBannerbearImageAttachmentSuccessResponse> {
            return localVarFp.handleBannerbearAttachmentImageWebhook(inlineObject2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebhooksApi - interface
 * @export
 * @interface WebhooksApi
 */
export interface WebhooksApiInterface {
    /**
     * Handle incoming webhook data for Bannerbear attachment images
     * @summary Handle Bannerbear Attachment Image Webhook
     * @param {InlineObject2} inlineObject2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApiInterface
     */
    handleBannerbearAttachmentImageWebhook(inlineObject2: InlineObject2, options?: any): AxiosPromise<UploadBannerbearImageAttachmentSuccessResponse>;

}

/**
 * WebhooksApi - object-oriented interface
 * @export
 * @class WebhooksApi
 * @extends {BaseAPI}
 */
export class WebhooksApi extends BaseAPI implements WebhooksApiInterface {
    /**
     * Handle incoming webhook data for Bannerbear attachment images
     * @summary Handle Bannerbear Attachment Image Webhook
     * @param {InlineObject2} inlineObject2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public handleBannerbearAttachmentImageWebhook(inlineObject2: InlineObject2, options?: any) {
        return WebhooksApiFp(this.configuration).handleBannerbearAttachmentImageWebhook(inlineObject2, options).then((request) => request(this.axios, this.basePath));
    }
}

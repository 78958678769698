import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Group 3111"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 19.75 23.75"
    {...props}
  >
    <defs>
      <clipPath>
        <rect data-name="Rectangle 1623" width="19.75" height="23.75" fill="currentColor" />
      </clipPath>
    </defs>
    <g data-name="Group 3112">
      <path
        data-name="Path 1718"
        d="M9.875,0A9.886,9.886,0,0,0,0,9.875c0,2.846,1.736,5.752,3.192,7.689a38,38,0,0,0,5.492,5.758,1.875,1.875,0,0,0,1.193.428,1.853,1.853,0,0,0,1.182-.422,38.07,38.07,0,0,0,5.5-5.764c1.456-1.937,3.192-4.843,3.192-7.689A9.886,9.886,0,0,0,9.875,0M18,9.875c0,1.869-.982,4.165-2.841,6.639a36.593,36.593,0,0,1-5.2,5.458.128.128,0,0,1-.16,0,36.537,36.537,0,0,1-5.2-5.458C2.732,14.04,1.75,11.744,1.75,9.875a8.125,8.125,0,0,1,16.25,0"
        fill="currentColor"
      />
      <path
        data-name="Path 1719"
        d="M9.875,5A4.875,4.875,0,1,0,14.75,9.875,4.881,4.881,0,0,0,9.875,5M13,9.875A3.125,3.125,0,1,1,9.875,6.75,3.129,3.129,0,0,1,13,9.875"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default SvgComponent;

/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccountResponse } from '../model';
// @ts-ignore
import { CurrentFacebookPageResponse } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { FacebookAccountsWithInstagramResponse } from '../model';
// @ts-ignore
import { FacebookPromotePageResponse } from '../model';
// @ts-ignore
import { FacebookTokenPermissionsResponse } from '../model';
/**
 * FacebookAccountBranchApi - axios parameter creator
 * @export
 */
export const FacebookAccountBranchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get current account of branch facebook integration
         * @summary Get current account of branch facebook integration
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranchCurrentFacebookAccount: async (branchId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('getBranchCurrentFacebookAccount', 'branchId', branchId)
            const localVarPath = `/branch_account/branches/{branch_id}/facebook_account/current_account`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get branch facebook account details
         * @summary Get branch facebook account details
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranchFacebookAccount: async (branchId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('getBranchFacebookAccount', 'branchId', branchId)
            const localVarPath = `/branch_account/branches/{branch_id}/facebook_account`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get branch facebook account token permissions
         * @summary Get branch facebook account token permissions
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranchFacebookAccountTokenPermissions: async (branchId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('getBranchFacebookAccountTokenPermissions', 'branchId', branchId)
            const localVarPath = `/branch_account/branches/{branch_id}/facebook_account/token_permissions`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get branch facebook accounts with instagram accounts
         * @summary Get branch facebook accounts with instagram accounts
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranchFacebookAccounts: async (branchId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('getBranchFacebookAccounts', 'branchId', branchId)
            const localVarPath = `/branch_account/branches/{branch_id}/facebook_account/accounts`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get branch facebook promote pages with instagram accounts
         * @summary Get branch facebook promote pages with instagram accounts
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranchFacebookPromotePages: async (branchId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('getBranchFacebookPromotePages', 'branchId', branchId)
            const localVarPath = `/branch_account/branches/{branch_id}/facebook_account/promote_pages`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FacebookAccountBranchApi - functional programming interface
 * @export
 */
export const FacebookAccountBranchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FacebookAccountBranchApiAxiosParamCreator(configuration)
    return {
        /**
         * Get current account of branch facebook integration
         * @summary Get current account of branch facebook integration
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBranchCurrentFacebookAccount(branchId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentFacebookPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBranchCurrentFacebookAccount(branchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get branch facebook account details
         * @summary Get branch facebook account details
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBranchFacebookAccount(branchId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBranchFacebookAccount(branchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get branch facebook account token permissions
         * @summary Get branch facebook account token permissions
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBranchFacebookAccountTokenPermissions(branchId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookTokenPermissionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBranchFacebookAccountTokenPermissions(branchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get branch facebook accounts with instagram accounts
         * @summary Get branch facebook accounts with instagram accounts
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBranchFacebookAccounts(branchId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookAccountsWithInstagramResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBranchFacebookAccounts(branchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get branch facebook promote pages with instagram accounts
         * @summary Get branch facebook promote pages with instagram accounts
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBranchFacebookPromotePages(branchId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookPromotePageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBranchFacebookPromotePages(branchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FacebookAccountBranchApi - factory interface
 * @export
 */
export const FacebookAccountBranchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FacebookAccountBranchApiFp(configuration)
    return {
        /**
         * Get current account of branch facebook integration
         * @summary Get current account of branch facebook integration
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranchCurrentFacebookAccount(branchId: string, options?: any): AxiosPromise<CurrentFacebookPageResponse> {
            return localVarFp.getBranchCurrentFacebookAccount(branchId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get branch facebook account details
         * @summary Get branch facebook account details
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranchFacebookAccount(branchId: string, options?: any): AxiosPromise<AccountResponse> {
            return localVarFp.getBranchFacebookAccount(branchId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get branch facebook account token permissions
         * @summary Get branch facebook account token permissions
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranchFacebookAccountTokenPermissions(branchId: string, options?: any): AxiosPromise<FacebookTokenPermissionsResponse> {
            return localVarFp.getBranchFacebookAccountTokenPermissions(branchId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get branch facebook accounts with instagram accounts
         * @summary Get branch facebook accounts with instagram accounts
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranchFacebookAccounts(branchId: string, options?: any): AxiosPromise<FacebookAccountsWithInstagramResponse> {
            return localVarFp.getBranchFacebookAccounts(branchId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get branch facebook promote pages with instagram accounts
         * @summary Get branch facebook promote pages with instagram accounts
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranchFacebookPromotePages(branchId: string, options?: any): AxiosPromise<FacebookPromotePageResponse> {
            return localVarFp.getBranchFacebookPromotePages(branchId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FacebookAccountBranchApi - interface
 * @export
 * @interface FacebookAccountBranchApi
 */
export interface FacebookAccountBranchApiInterface {
    /**
     * Get current account of branch facebook integration
     * @summary Get current account of branch facebook integration
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookAccountBranchApiInterface
     */
    getBranchCurrentFacebookAccount(branchId: string, options?: any): AxiosPromise<CurrentFacebookPageResponse>;

    /**
     * Get branch facebook account details
     * @summary Get branch facebook account details
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookAccountBranchApiInterface
     */
    getBranchFacebookAccount(branchId: string, options?: any): AxiosPromise<AccountResponse>;

    /**
     * Get branch facebook account token permissions
     * @summary Get branch facebook account token permissions
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookAccountBranchApiInterface
     */
    getBranchFacebookAccountTokenPermissions(branchId: string, options?: any): AxiosPromise<FacebookTokenPermissionsResponse>;

    /**
     * Get branch facebook accounts with instagram accounts
     * @summary Get branch facebook accounts with instagram accounts
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookAccountBranchApiInterface
     */
    getBranchFacebookAccounts(branchId: string, options?: any): AxiosPromise<FacebookAccountsWithInstagramResponse>;

    /**
     * Get branch facebook promote pages with instagram accounts
     * @summary Get branch facebook promote pages with instagram accounts
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookAccountBranchApiInterface
     */
    getBranchFacebookPromotePages(branchId: string, options?: any): AxiosPromise<FacebookPromotePageResponse>;

}

/**
 * FacebookAccountBranchApi - object-oriented interface
 * @export
 * @class FacebookAccountBranchApi
 * @extends {BaseAPI}
 */
export class FacebookAccountBranchApi extends BaseAPI implements FacebookAccountBranchApiInterface {
    /**
     * Get current account of branch facebook integration
     * @summary Get current account of branch facebook integration
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookAccountBranchApi
     */
    public getBranchCurrentFacebookAccount(branchId: string, options?: any) {
        return FacebookAccountBranchApiFp(this.configuration).getBranchCurrentFacebookAccount(branchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get branch facebook account details
     * @summary Get branch facebook account details
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookAccountBranchApi
     */
    public getBranchFacebookAccount(branchId: string, options?: any) {
        return FacebookAccountBranchApiFp(this.configuration).getBranchFacebookAccount(branchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get branch facebook account token permissions
     * @summary Get branch facebook account token permissions
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookAccountBranchApi
     */
    public getBranchFacebookAccountTokenPermissions(branchId: string, options?: any) {
        return FacebookAccountBranchApiFp(this.configuration).getBranchFacebookAccountTokenPermissions(branchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get branch facebook accounts with instagram accounts
     * @summary Get branch facebook accounts with instagram accounts
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookAccountBranchApi
     */
    public getBranchFacebookAccounts(branchId: string, options?: any) {
        return FacebookAccountBranchApiFp(this.configuration).getBranchFacebookAccounts(branchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get branch facebook promote pages with instagram accounts
     * @summary Get branch facebook promote pages with instagram accounts
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookAccountBranchApi
     */
    public getBranchFacebookPromotePages(branchId: string, options?: any) {
        return FacebookAccountBranchApiFp(this.configuration).getBranchFacebookPromotePages(branchId, options).then((request) => request(this.axios, this.basePath));
    }
}

import React, { Fragment, useEffect } from 'react';
import { Transition } from '@headlessui/react';

import { useTable, usePagination, useSortBy, useExpanded } from 'react-table';
import cn from 'classnames';

import { COLORS } from '@app/constants';
import { Icons, Pagination } from '@app/components';
import { array } from '@app/lib';

import SortIcon from './SortIcon';

type TablePropTypes = {
  className?: string;
  columns: any;
  data: any;
  isLoading?: boolean;
  pageSize?: number;
  totalPageCount?: number;
  currentPage?: number;
  onPageChange?: (index: number) => void;
  onSortChange?: (query: string | null) => void;
  defaultSortBy?: { id: string; desc: boolean }[];
  manualSortBy?: boolean;
  disableSortBy?: boolean;
  EmptyComponent?: () => JSX.Element;
  campaignDetail?: boolean;
  leadGenList?: boolean;
  mediaLibraryList?: boolean;
  header?: boolean;
  renderRowSubComponent?: any;
};

const SORT_TYPE = {
  DESC: 'desc',
  ASC: 'asc',
};

const Table: React.FC<TablePropTypes> = ({
  className,
  columns,
  data,
  pageSize = 999999,
  totalPageCount = 1,
  currentPage = 1,
  onPageChange,
  onSortChange,
  defaultSortBy = [],
  manualSortBy = true,
  disableSortBy = false,
  EmptyComponent,
  campaignDetail,
  leadGenList,
  mediaLibraryList,
  header,
  renderRowSubComponent,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    rows,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    state: { pageIndex, sortBy },
  } = useTable(
    {
      columns,
      data: data ?? [],
      initialState: {
        pageIndex: currentPage - 1,
        pageSize: pageSize,
        sortBy: defaultSortBy,
      },
      manualSortBy: manualSortBy,
      disableSortBy: disableSortBy,
      manualPagination: true,
      pageCount: totalPageCount,
    },

    useSortBy,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    if (!array.isEmpty(sortBy)) {
      const item = array.first(sortBy);
      const sortType = item.desc ? SORT_TYPE.DESC : SORT_TYPE.ASC;
      onSortChange && onSortChange(`${item.id} ${sortType}`);
      return;
    }

    onSortChange && onSortChange(null);
  }, [onSortChange, JSON.stringify(sortBy)]);

  useEffect(() => {
    onPageChange && onPageChange(pageIndex + 1);
  }, [onPageChange, pageIndex]);

  function getSortIcon(isSortedDesc: boolean | undefined) {
    if (isSortedDesc === true) {
      return mediaLibraryList ? (
        <Icons.SortIcon downColor={COLORS.BLUE_300} className="fill-current text-gray-500 ml-2" />
      ) : (
        <SortIcon downColor={COLORS.BLUE_300} className="fill-current text-gray-500" />
      );
    }

    if (isSortedDesc === false) {
      return mediaLibraryList ? (
        <Icons.SortIcon downColor={COLORS.BLUE_300} className="fill-current text-gray-500 ml-2" />
      ) : (
        <SortIcon downColor={COLORS.BLUE_300} className="fill-current text-gray-500" />
      );
    }

    return mediaLibraryList ? (
      <Icons.SortIcon className="fill-current text-gray-500 ml-2" />
    ) : (
      <SortIcon className="fill-current text-gray-500" />
    );
  }

  const Header = () => {
    return (
      <thead>
        {headerGroups.map((headerGroup, headerGroupIndex) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={`headerGroups-${headerGroupIndex}`}>
            {headerGroup.headers.map((column, headerColumnIndex) => (
              <th
                scope="col"
                className={cn('px-4 py-3 text-left text-xs font-semibold text-gray-500 uppercase ', {
                  'bg-white ': campaignDetail || leadGenList || mediaLibraryList,
                  'rounded-tl-3': headerColumnIndex === 0,
                  'rounded-tr-3': headerColumnIndex === headerGroup.headers.length - 1,
                  'shadow-none first:rounded-bl-3 last:rounded-br-3': leadGenList || mediaLibraryList,
                  '!pl-0': mediaLibraryList,
                })}
                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                key={`headerColumn-${headerColumnIndex}`}
              >
                <div
                  className={cn('flex flex-row justify-start items-center select-none whitespace-nowrap', {
                    'text-blue-500': campaignDetail,
                  })}
                >
                  {column.render('Header')}
                  {column.canSort && <span className="ml-1">{getSortIcon(column.isSortedDesc)}</span>}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
    );
  };

  if (array.isEmpty(data)) {
    if (!EmptyComponent) {
      return null;
    }

    return <EmptyComponent />;
  }

  return (
    <div className={cn('flex flex-col w-full ', className, { 'rounded-lg shadow': campaignDetail })}>
      <table
        {...getTableProps({
          style: {
            borderCollapse: campaignDetail ? 'collapse' : 'separate',
            borderSpacing: campaignDetail ? '0 0' : '0 10px',
          },
        })}
      >
        {header && <Header />}
        {!array.isEmpty(data) && (
          <tbody {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <React.Fragment key={`row-${rowIndex}`}>
                  <tr className={cn({ 'shadow-sm rounded-xl': !leadGenList || !mediaLibraryList })} {...row.getRowProps()}>
                    {row.cells.map((cell, cellIndex) => {
                      const column = cell.column as any;
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={`cell-${cellIndex}`}
                          className={cn(
                            'font-medium text-sm text-gray-900',
                            {
                              'h-24': !campaignDetail || !leadGenList,
                              '!h-10 !px-0 !py-2': mediaLibraryList,
                              'px-4 py-2': campaignDetail,
                              'rounded-bl-3 ':
                                pageCount <= 1 && rows.length === rowIndex + 1 && cellIndex === 0 && (!leadGenList || !mediaLibraryList),
                              'rounded-br-3 ':
                                pageCount <= 1 &&
                                rows.length === rowIndex + 1 &&
                                cellIndex === row.cells.length - 1 &&
                                (!leadGenList || !mediaLibraryList),
                              'px-4 py-3 border-b border-gray-500': (leadGenList || mediaLibraryList) && !campaignDetail,
                              'bg-white first:rounded-tl-lg first:rounded-bl-lg last:rounded-tr-lg last:rounded-br-lg':
                                !leadGenList && !mediaLibraryList,
                            },
                            column.className
                          )}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                  {!!renderRowSubComponent && (
                    <Transition
                      show={!!row.isExpanded}
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <tr>
                        <td colSpan={visibleColumns.length}>{renderRowSubComponent({ row })}</td>
                      </tr>
                    </Transition>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        )}
      </table>

      {!array.isEmpty(data) && pageCount > 1 && (
        <div className={cn('py-3 flex items-center justify-center border-t border-gray-300', { '!border-none': mediaLibraryList })}>
          <Pagination
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            currentPage={pageIndex + 1}
            totalPageCount={pageCount}
            onPageChange={(index) => gotoPage(index - 1)}
          />
        </div>
      )}
    </div>
  );
};

export default Table;

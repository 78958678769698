import useLocale from '@app/hooks/useLocale';
import React, { useMemo } from 'react';

type CardProps = {
  holderName: string;
  cardNumber: string;
  expiryDate: string;
  cvv: string;
};
export default function Card({ holderName, cardNumber, expiryDate, cvv }: CardProps) {
  const SCOPE_OPTIONS = {
    scope: 'pages.wallet.payment.card',
  };

  const { t } = useLocale();
  const hidedCardNumber = useMemo(() => {
    if (cardNumber?.length < 14 || !cardNumber) return '';
    return `**** **** **** ${cardNumber?.slice(14)}`;
  }, [cardNumber]);
  return (
    <div className="w-96 h-58 m-auto rounded-xl relative text-white bg-blue-500">
      <div className="w-full px-8 absolute top-8">
        <div className="flex justify-between">
          <div>
            <p className="font-light">{t('name', SCOPE_OPTIONS)}</p>
            <p className="font-medium tracking-widest">{holderName || '**** ****'}</p>
          </div>
        </div>
        <div className="pt-5">
          <p className="font-light">{t('cardNumber', SCOPE_OPTIONS)}</p>
          <p className="font-medium tracking-more-wider">{hidedCardNumber || '0000 0000 0000 0000'}</p>
        </div>
        <div className="pt-6 pr-6">
          <div className="flex justify-between">
            <div>
              <p className="font-light text-xs mb-1">{t('expirationDate', SCOPE_OPTIONS)}</p>
              <p className="font-medium tracking-wider text-sm">{expiryDate || 'MM/YY'}</p>
            </div>
            <div>
              <p className="font-light text-xs mb-1">{t('cvv', SCOPE_OPTIONS)}</p>
              <p className="font-bold tracking-more-wider text-sm">{cvv || '***'}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
    <path
      stroke="#fff"
      strokeWidth="1.5"
      d="M12 25.333c-3.745 0-5.618 0-6.963-.898a5.334 5.334 0 01-1.471-1.472c-.9-1.345-.9-3.218-.9-6.963s0-5.618.9-6.963c.389-.582.889-1.082 1.471-1.471 1.345-.9 3.218-.9 6.963-.9h8c3.745 0 5.618 0 6.963.9.582.389 1.082.889 1.471 1.471.9 1.345.9 3.218.9 6.963s0 5.618-.9 6.963a5.334 5.334 0 01-1.47 1.471c-1.346.9-3.219.9-6.964.9h-8z"
    ></path>
    <path stroke="#fff" strokeWidth="1.5" d="M16 12a4 4 0 110 8 4 4 0 010-8z"></path>
    <path stroke="#fff" strokeLinecap="round" strokeWidth="1.5" d="M7.333 20v-8M24.667 20v-8"></path>
  </svg>
);

export default SvgComponent;

import React, { useState, useMemo, useRef } from 'react';

import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import cn from 'classnames';

import { array, interests as interestsLib } from '@app/lib';
import { api, useOnClickOutside } from '@app/hooks';

type ItemType = {
  key?: string;
  value: string;
  type: string;
  label: string;
  children?: any[];
};

type BrowsePropTypes = {
  isOpen: boolean;
  onClose: () => void;
  onChange: (value: ItemType[]) => void;
  value: ItemType[];
};

const Browse: React.FC<BrowsePropTypes> = ({ isOpen, onClose, onChange, value: checked }) => {
  const [expanded, setExpanded] = useState<string[]>([]);
  const divRef = useRef(null);
  const { data } = api.useListBranchFacebookAdTargetingCategories();
  const memoizedInterests = useMemo(() => interestsLib.group(data?.interests, '__ROOT__'), [data?.interests]);
  useOnClickOutside(divRef, onClose);

  function handleExpand(key: string) {
    if (expanded.includes(key)) {
      setExpanded((prev) => prev.filter((item) => item !== key));
      return;
    }

    setExpanded((prev) => [...prev, key]);
  }

  function hasItem(value: string) {
    const foundedItem = checked?.find((checkedItem) => checkedItem.value === value);

    if (foundedItem) {
      return true;
    }

    return false;
  }

  function handleCheck(item: ItemType) {
    const handledItem = { type: item.type, label: item.label, value: item.value };

    if (hasItem(item.value)) {
      onChange(checked.filter((checkedItem) => checkedItem.value !== item.value));
      return;
    }

    onChange(Array.isArray(checked) ? [...checked, handledItem] : [handledItem]);
  }

  if (isOpen === false) {
    return null;
  }

  const Closure = ({ interest, index }: { interest: ItemType; index: number }) => {
    return (
      <Disclosure defaultOpen={expanded.includes(interest.key as string)}>
        {({ open }) => (
          <>
            <button
              className="flex justify-between items-center w-full py-2 border-b border-b-gray-200 pr-4 h-10"
              style={{ paddingLeft: index * 16 + 16 }}
              onClick={() => handleExpand(interest.key as string)}
            >
              <span className="text-3.5 font-medium text-gray-800">{interest.label}</span>
              <ChevronUpIcon
                className={cn('w-5 h-5 text-gray-600 transition-all transform', {
                  'rotate-180': open,
                  'rotate-0': !open,
                })}
              />
            </button>
            <Disclosure.Panel className="text-sm flex flex-col">
              {interest?.children &&
                interest.children.map((childInterest) => {
                  if (childInterest?.children) {
                    return <Closure key={childInterest.key} interest={childInterest} index={index + 1} />;
                  }

                  if (childInterest.value === null) {
                    return;
                  }

                  return (
                    <button
                      type="button"
                      className="flex justify-between items-center w-full py-2 text-3.5 font-medium text-gray-800 text-left border-b border-b-gray-200 pr-4 h-10"
                      style={{ paddingLeft: index * 16 + 32 }}
                      onClick={() => handleCheck(childInterest)}
                      key={childInterest.key}
                    >
                      {childInterest.label}
                      <input
                        id={childInterest.value}
                        type="checkbox"
                        className="w-5 h-5 border bg-white rounded-md ring-0"
                        readOnly
                        checked={hasItem(childInterest.value)}
                      />
                    </button>
                  );
                })}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    );
  };

  return (
    <div className="mt-1.5 w-full z-999 ">
      <div ref={divRef} className="shadow-box rounded-1 w-full bg-white max-h-80 overflow-y-auto border border-gray-400">
        {!array.isEmpty(memoizedInterests) &&
          memoizedInterests.map((interest: ItemType) => <Closure key={interest.key} interest={interest} index={0} />)}
        {array.isEmpty(memoizedInterests) && (
          <div className="w-full p-3">
            <span className="text-center text-4 font-regular text-gray-500 block w-full">No options</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Browse;

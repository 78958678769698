import type { CurrentFacebookPageResponse, BranchCampaign, Campaign } from '@app/api';
import { CALL_TO_ACTION } from '@app/constants';

function campaignDetail(campaign: BranchCampaign) {
  const foundedStory = campaign.branch_campaign_attachments?.find((item) => item.placement_type === 'STORY');
  const foundedFeed = campaign.branch_campaign_attachments?.find((item) => item.placement_type === 'STORY');

  return {
    message: campaign.parent_campaign.template.creative.message,
    headline: campaign.parent_campaign.template.creative.headline,
    description: campaign.parent_campaign?.template?.creative?.description,
    storyImage: foundedStory?.file?.url,
    feedImage: foundedFeed?.file?.url,
    targetingType: campaign.targeting.targeting_type,
    callToAction:
      (campaign?.creative?.link_data?.call_to_action?.value?.app_destination as string) ??
      (campaign?.creative?.link_data?.call_to_action?.value?.link as string),
  };
}

function createCampaign(campaign: Campaign, getFormValue: (name: string) => any) {
  return {
    message: campaign.template.creative.message,
    headline: campaign.template.creative.headline,
    description: campaign.template.creative.description,
    storyImage: '',
    feedImage: '',
    targetingType: getFormValue('targetingType') as string,
    callToAction:
      (getFormValue('linkData')?.call_to_action?.value?.app_destination as string) ??
      (getFormValue('linkData')?.call_to_action?.value?.link as string),
  };
}

function accountInfo(data: CurrentFacebookPageResponse) {
  return {
    instagramUsername: data?.instagram_account?.username,
    instagramProfile: data?.instagram_account?.profile_pic,
    facebookUsername: data?.username,
    facebookName: data?.name,
    facebookProfile: data?.picture?.url as string,
  };
}

function actionByTargetingType(targetingType: string) {
  if (targetingType === CALL_TO_ACTION.CALL_NOW) {
    return 'callNow';
  }
  if (targetingType === CALL_TO_ACTION.FINAL_URL) {
    return 'finalUrl';
  }
  if (targetingType === CALL_TO_ACTION.WHATSAPP_MESSAGE) {
    return 'whatsappMessage';
  }
  if (targetingType === CALL_TO_ACTION.MESSENGER) {
    return 'messenger';
  }
  if (targetingType === CALL_TO_ACTION.INSTAGRAM_VISIT) {
    return 'instagramVisit';
  }
  if (targetingType === CALL_TO_ACTION.LEAD_GENERATION) {
    return 'leadGeneration';
  }
  return '';
}

export default { campaignDetail, createCampaign, accountInfo, actionByTargetingType };

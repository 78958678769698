import React from 'react';

import { toast } from 'react-toastify';
import { Toast } from '@app/components';

enum ToastTypes {
  SUCCESS,
  WARNING,
  ERROR,
}

type ToastHookReturnTypes = {
  success: (message: string, options?: any) => void;
  warning: (message: string, options?: any) => void;
  error: (message: string, options?: any) => void;
  dismissAll: () => void;
};

const DEFAULT_OPTIONS = {
  hideProgressBar: true,
  closeButton: false,
  closeOnClick: true,
  isHaveElement: false,
};

export default function useToast(): ToastHookReturnTypes {
  function success(message: string, options?: any) {
    const toastOptions = {
      ...DEFAULT_OPTIONS,
      ...options,
    };

    toast(
      <Toast
        message={message}
        type={ToastTypes.SUCCESS}
        disableCloseClick={!toastOptions?.closeOnClick}
        isHaveElement={toastOptions?.isHaveElement}
      />,
      toastOptions
    );
  }

  function warning(message: string, options?: any) {
    const toastOptions = {
      ...DEFAULT_OPTIONS,
      ...options,
    };

    toast(
      <Toast
        message={message}
        type={ToastTypes.WARNING}
        disableCloseClick={!toastOptions?.closeOnClick}
        isHaveElement={toastOptions?.isHaveElement}
      />,
      toastOptions
    );
  }

  function error(message: string, options?: any) {
    const toastOptions = {
      ...DEFAULT_OPTIONS,
      ...options,
    };

    toast(
      <Toast
        message={message}
        type={ToastTypes.ERROR}
        disableCloseClick={!toastOptions?.closeOnClick}
        isHaveElement={toastOptions?.isHaveElement}
      />,
      toastOptions
    );
  }

  function dismissAll() {
    return toast.dismiss();
  }

  return {
    success,
    error,
    warning,
    dismissAll,
  };
}

import React, { forwardRef, useRef, useState, useEffect } from 'react';

import type { FieldError } from 'react-hook-form';
import cn from 'classnames';

import { IconByType } from '@app/components';
import ReactInputMask from 'react-input-mask';

type InputPropTypes = {
  description?: string;
  prefix?: string;
  type: string;
  label?: string;
  id: string;
  error?: FieldError;
  className?: string;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  name: string;
  value?: string | number;
  defaultValue?: string | number;
  disabled?: boolean;
  autoComplete?: string;
  requiredSign?: boolean;
  Icon?: any;
  typeText?: boolean;
  mask?: string;
};

export const Input: React.ForwardRefRenderFunction<HTMLInputElement, React.PropsWithChildren<InputPropTypes>> = (
  {
    description,
    prefix,
    Icon,
    type = 'text',
    label,
    id,
    error,
    className,
    onChange,
    onBlur,
    onFocus,
    onKeyDown,
    name,
    defaultValue,
    disabled = false,
    requiredSign = false,
    typeText,
    mask,
    ...props
  },
  ref
) => {
  const [paddingLeft, setPaddingLeft] = useState<null | number>(null);
  const prefixRef = useRef<HTMLLabelElement>(null);
  function handleOnBlur(event: React.FocusEvent<HTMLInputElement>) {
    onBlur && onBlur(event);
  }

  useEffect(() => {
    if (prefixRef?.current) {
      setPaddingLeft(24 + prefixRef.current.offsetWidth);
    }
  }, [prefixRef?.current]);

  return (
    <div className={cn('flex flex-col', className)}>
      <div className="flex items-center justify-between mb-1.5">
        <label htmlFor={id} className="block text-3.5 font-semibold text-gray-900">
          {label}
          {requiredSign && <span className="text-red-400">*</span>}
        </label>
        {error && (
          <div className="flex items-center">
            <span className="inline text-red-400 text-3">{error.message}</span>
          </div>
        )}
      </div>
      {description && <span className="opacity-50 text-sm mb-1.5">{description}</span>}
      <div
        className={cn({
          'flex items-center py-2.5 border rounded-3 font-normal text-sm text-black-800 placeholder-gray-500 outline-none w-full':
            !typeText,
          'bg-white border-black-800': !error && !typeText,
          'bg-white border-red-400': error && !typeText,
          'px-4': !prefix && !typeText,
          'pr-4': !!prefix && !typeText,
          '!bg-gray-200': disabled,
        })}
      >
        {Icon && <IconByType type={Icon} className="w-6 h-6 mr-2" />}
        <ReactInputMask
          ref={ref as any}
          mask={mask || ''}
          alwaysShowMask={false}
          maskPlaceholder={false || ''}
          name={name}
          onChange={onChange}
          onBlur={handleOnBlur}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          type={type}
          id={id}
          defaultValue={defaultValue}
          style={paddingLeft ? { paddingLeft: paddingLeft } : {}}
          disabled={disabled}
          readOnly={disabled}
          className={cn('w-full', {
            '!bg-gray-200': disabled,
            'border-none p-0': !typeText,
            'py-3.5 border rounded-3 font-normal text-sm text-gray-900 placeholder-gray-500 outline-none': typeText,
          })}
          {...props}
        />
      </div>
    </div>
  );
};

export default forwardRef(Input);

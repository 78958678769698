/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomVariableCampaignImageInput } from '../model';
// @ts-ignore
import { CustomVariableCampaignImageResponse } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
/**
 * ImageAttachmentsBranchV2Api - axios parameter creator
 * @export
 */
export const ImageAttachmentsBranchV2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create bannerbear image with given modifications
         * @summary Create bannerbear image
         * @param {string} branchId The id of the branch
         * @param {CustomVariableCampaignImageInput} customVariableCampaignImageInput Create branch image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomVariableCampaignImage: async (branchId: string, customVariableCampaignImageInput: CustomVariableCampaignImageInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('createCustomVariableCampaignImage', 'branchId', branchId)
            // verify required parameter 'customVariableCampaignImageInput' is not null or undefined
            assertParamExists('createCustomVariableCampaignImage', 'customVariableCampaignImageInput', customVariableCampaignImageInput)
            const localVarPath = `/v2/branch_account/branches/{branch_id}/attachments/images/bannerbear`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customVariableCampaignImageInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImageAttachmentsBranchV2Api - functional programming interface
 * @export
 */
export const ImageAttachmentsBranchV2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImageAttachmentsBranchV2ApiAxiosParamCreator(configuration)
    return {
        /**
         * Create bannerbear image with given modifications
         * @summary Create bannerbear image
         * @param {string} branchId The id of the branch
         * @param {CustomVariableCampaignImageInput} customVariableCampaignImageInput Create branch image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomVariableCampaignImage(branchId: string, customVariableCampaignImageInput: CustomVariableCampaignImageInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomVariableCampaignImageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomVariableCampaignImage(branchId, customVariableCampaignImageInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImageAttachmentsBranchV2Api - factory interface
 * @export
 */
export const ImageAttachmentsBranchV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImageAttachmentsBranchV2ApiFp(configuration)
    return {
        /**
         * Create bannerbear image with given modifications
         * @summary Create bannerbear image
         * @param {string} branchId The id of the branch
         * @param {CustomVariableCampaignImageInput} customVariableCampaignImageInput Create branch image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomVariableCampaignImage(branchId: string, customVariableCampaignImageInput: CustomVariableCampaignImageInput, options?: any): AxiosPromise<CustomVariableCampaignImageResponse> {
            return localVarFp.createCustomVariableCampaignImage(branchId, customVariableCampaignImageInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImageAttachmentsBranchV2Api - interface
 * @export
 * @interface ImageAttachmentsBranchV2Api
 */
export interface ImageAttachmentsBranchV2ApiInterface {
    /**
     * Create bannerbear image with given modifications
     * @summary Create bannerbear image
     * @param {string} branchId The id of the branch
     * @param {CustomVariableCampaignImageInput} customVariableCampaignImageInput Create branch image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsBranchV2ApiInterface
     */
    createCustomVariableCampaignImage(branchId: string, customVariableCampaignImageInput: CustomVariableCampaignImageInput, options?: any): AxiosPromise<CustomVariableCampaignImageResponse>;

}

/**
 * ImageAttachmentsBranchV2Api - object-oriented interface
 * @export
 * @class ImageAttachmentsBranchV2Api
 * @extends {BaseAPI}
 */
export class ImageAttachmentsBranchV2Api extends BaseAPI implements ImageAttachmentsBranchV2ApiInterface {
    /**
     * Create bannerbear image with given modifications
     * @summary Create bannerbear image
     * @param {string} branchId The id of the branch
     * @param {CustomVariableCampaignImageInput} customVariableCampaignImageInput Create branch image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsBranchV2Api
     */
    public createCustomVariableCampaignImage(branchId: string, customVariableCampaignImageInput: CustomVariableCampaignImageInput, options?: any) {
        return ImageAttachmentsBranchV2ApiFp(this.configuration).createCustomVariableCampaignImage(branchId, customVariableCampaignImageInput, options).then((request) => request(this.axios, this.basePath));
    }
}

import React from 'react';

import { RadioGroup } from '@headlessui/react';
import cn from 'classnames';

import { array } from '@app/lib';
import { Icons, IconByType } from '@app/components';
import { Controller } from 'react-hook-form';

type RadioPickerPropTypes = {
  containerClassName?: string;
  options: { label: string; value: string; disabled?: boolean; date?: boolean; Icon?: string }[];
  value: string;
  onChange: (value: string) => void;
  className?: string;
  hasMainOption?: boolean;
  control?: any;
  rules?: any;
  error?: { message: string | null };
  name: string;
};

const ObjectiveFormPicker: React.FC<RadioPickerPropTypes> = ({
  containerClassName,
  options,
  value,
  onChange,
  className,
  hasMainOption,
  control,
  rules,
  error,
  name,
}) => {
  return (
    <>
      <div className="flex mb-3">
        {error && (
          <div className="flex items-center">
            <span className="inline text-red-400 text-3">{error.message}</span>
          </div>
        )}
      </div>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={() => (
          <RadioGroup className={containerClassName} value={value} onChange={onChange}>
            <div className={className}>
              {!array.isEmpty(options) &&
                options.map((option) => (
                  <RadioGroup.Option
                    disabled={option?.disabled}
                    value={option.value}
                    key={option.value}
                    className={({ checked }) =>
                      cn(
                        ' bg-white shadow px-5 pb-4 rounded-xl pt-3.5 flex items-center cursor-pointer focus:outline-none transition-colors',
                        {
                          'border-blue-500 border-0.5': checked && option.disabled === false && !option.date,
                          'border-red-500 border-0.5': error && !checked && option.disabled === false,
                          'border border-gray-500 bg-gray-100': option.disabled,
                          'pb-6': !hasMainOption,
                        }
                      )
                    }
                  >
                    {({ checked }) => (
                      <div className="flex flex-col items-center w-full">
                        <div className="flex flex-row w-full justify-between ">
                          <div className={cn('flex', { 'text-gray-400': option.disabled })}>
                            {hasMainOption && <IconByType type={option.Icon} />}
                          </div>
                          <div
                            className={cn('flextransition-colors', {
                              'text-gray-500': !checked,
                              'text-gray-400': option.disabled === true,
                              'text-blue-500': checked,
                              'text-red-500': error && !checked,
                            })}
                          >
                            {option.date ? (
                              <Icons.EmptyCircle className="w-6 h-6" />
                            ) : checked ? (
                              <Icons.CheckRounded className="w-6 h-6" />
                            ) : (
                              <Icons.OutlineCircle className="w-6 h-6" />
                            )}
                          </div>
                        </div>

                        <div
                          className={cn('flex space-x-3 items-center w-full pb-0.5 ', {
                            'mt-3': hasMainOption,
                            'text-gray-400': option.disabled,
                          })}
                        >
                          {!hasMainOption && <div className={cn('flex justify-start')}>{<IconByType type={option.Icon} />}</div>}

                          <RadioGroup.Label
                            as="p"
                            className={cn('flex items-center text-3.5', {
                              'text-black-800': option.disabled === false,
                              'text-gray-400': option.disabled === true,
                            })}
                          >
                            {option.label}
                          </RadioGroup.Label>
                        </div>
                      </div>
                    )}
                  </RadioGroup.Option>
                ))}
            </div>
          </RadioGroup>
        )}
      />
    </>
  );
};

export default ObjectiveFormPicker;

import { DateTime, Settings } from 'luxon';
import type { DateObjectUnits, DurationUnits, DurationObjectUnits } from 'luxon';

import { TIME_TYPE } from '@app/constants';

function JSDateToISO(date: Date) {
  return DateTime.fromJSDate(date).toISO();
}

function ISOtoJSDate(dateTimeInUtc: string) {
  return DateTime.fromISO(dateTimeInUtc).toJSDate();
}

function format(dateTimeInUtc: string, exportFormat: string, options = {}) {
  return DateTime.fromISO(dateTimeInUtc, options).toFormat(exportFormat);
}

function get(dateTimeInUtc: any, unit: keyof DateTime) {
  return DateTime.fromISO(dateTimeInUtc).get(unit);
}

function set(dateTimeInUtc: string, values: DateObjectUnits) {
  return DateTime.fromISO(dateTimeInUtc).set(values).toISO();
}

function fromFormatToFormat(date: string, dateFormat: string, exportFormat: string) {
  return DateTime.fromFormat(date, dateFormat).toFormat(exportFormat);
}

function plusFromNow(params: object) {
  return DateTime.now().plus(params).toString();
}

function plus(date: Date, params: object) {
  return DateTime.fromJSDate(date).plus(params).toJSDate();
}

function minus(date: Date, params: object) {
  return DateTime.fromJSDate(date).minus(params).toJSDate();
}

function setTime(date: Date, params: object) {
  return DateTime.fromJSDate(date).set(params).toJSDate();
}

function max(dates: Date[]) {
  return new Date(
    Math.max(
      ...(dates.map((element) => {
        return element;
      }) as any)
    )
  );
}

function minusFromNow(params: object) {
  return DateTime.now().minus(params).toString();
}

function now() {
  return DateTime.now().toString();
}

function yesterday() {
  return DateTime.now().minus({ days: 1 }).toString();
}

function setDefaultLocale(locale: string) {
  Settings.defaultLocale = locale;
}

function handleSeperateDate(date: Date, time: Date, timeType?: string) {
  if (timeType === TIME_TYPE.NOW_DATE) {
    return JSDateToISO(date);
  }

  const timeHour = get(JSDateToISO(time), 'hour');
  const timeMinute = get(JSDateToISO(time), 'minute');

  return set(JSDateToISO(date), { hour: Number(timeHour), minute: Number(timeMinute) });
}

function handleISODate(date: string) {
  return DateTime.fromISO(date);
}

function getDatesInRange(startDate: string, endDate: string, _locale: string | null = null) {
  const start = DateTime.fromISO(startDate).toJSDate();
  const end = DateTime.fromISO(endDate).plus({ days: 1 }).toJSDate();
  const dates = [];
  let date = start;

  while (date <= end) {
    dates.push(DateTime.fromISO(date.toISOString()).weekday - 1);
    date = DateTime.fromJSDate(date).plus({ days: 1 }).toJSDate();
  }

  return dates;
}

function isValid(dateTimeInUtc: string | undefined) {
  if (typeof dateTimeInUtc !== 'string') {
    return false;
  }

  return DateTime.fromISO(dateTimeInUtc).isValid;
}

function differenceDate(startDate: any, endDate: any, durationUnit = 'days') {
  const start = handleISODate(startDate.toISOString());
  const end = handleISODate(endDate.toISOString());
  const differenceDate = end.diff(start, [durationUnit] as DurationUnits).toObject();

  return differenceDate.days === 0 ? 1 : Math.round(Number(differenceDate.days));
}

function diff(startDate: string, endDate: string, durationUnit: DurationUnits): DurationObjectUnits {
  const diffObject = DateTime.fromISO(endDate).diff(DateTime.fromISO(startDate), durationUnit).toObject();

  return diffObject;
}

function findMaxDate(date: string) {
  const nowDate = ISOtoJSDate(now());
  const currentDate = ISOtoJSDate(date);

  if (currentDate < nowDate) {
    return format(JSDateToISO(nowDate), 'dd MMM yyyy');
  }
  return format(JSDateToISO(currentDate), 'dd MMM yyyy');
}

export default {
  JSDateToISO,
  format,
  plusFromNow,
  fromFormatToFormat,
  ISOtoJSDate,
  plus,
  minus,
  isValid,
  setTime,
  max,
  minusFromNow,
  now,
  yesterday,
  setDefaultLocale,
  get,
  set,
  handleSeperateDate,
  handleISODate,
  getDatesInRange,
  differenceDate,
  diff,
  findMaxDate,
};

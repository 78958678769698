import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19.021" height="18.906" viewBox="0 0 19.021 18.906" {...props}>
    <path
      id="Icon_awesome-facebook"
      data-name="Icon awesome-facebook"
      d="M19.584,10.073a9.511,9.511,0,1,0-11,9.4V12.822H6.171V10.073H8.587v-2.1a3.356,3.356,0,0,1,3.592-3.7,14.637,14.637,0,0,1,2.129.186V6.8h-1.2a1.375,1.375,0,0,0-1.55,1.485v1.785H14.2l-.422,2.749H11.559v6.646A9.514,9.514,0,0,0,19.584,10.073Z"
      transform="translate(-0.563 -0.563)"
      fill="currentColor"
    />
  </svg>
);

export default SvgComponent;

/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { TemplateAttachments } from './template-attachments';
import { TemplateCreative } from './template-creative';
import { TemplateExternalErrors } from './template-external-errors';
import { TemplateExternalIds } from './template-external-ids';
import { TemplateTargeting } from './template-targeting';

/**
 * 
 * @export
 * @interface Template
 */
export interface Template {
    /**
     * 
     * @type {TemplateExternalIds}
     * @memberof Template
     */
    external_ids?: TemplateExternalIds;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Template
     */
    child_count?: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Template
     */
    children?: Array<object>;
    /**
     * 
     * @type {Array<TemplateExternalErrors>}
     * @memberof Template
     */
    external_errors: Array<TemplateExternalErrors>;
    /**
     * 
     * @type {TemplateTargeting}
     * @memberof Template
     */
    targeting: TemplateTargeting;
    /**
     * 
     * @type {TemplateCreative}
     * @memberof Template
     */
    creative: TemplateCreative;
    /**
     * 
     * @type {Array<TemplateAttachments>}
     * @memberof Template
     */
    attachments: Array<TemplateAttachments>;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    status: TemplateStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof Template
     */
    allowed_branch_ids?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    updated_at: string;
    /**
     * 
     * @type {number}
     * @memberof Template
     */
    joined_branch_count?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum TemplateStatusEnum {
    Draft = 'draft',
    Pending = 'pending',
    Approved = 'approved',
    Rejected = 'rejected',
    Archived = 'archived',
    Running = 'running'
}




import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { LeadgenFormsBranchApi } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function listLeadgenFormDataOfBranch(
  branchId: string,
  page?: number,
  perPage?: number,
  query?: object,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new LeadgenFormsBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.listLeadgenFormDataOfBranch(branchId, page, perPage, query).then((res) => res.data);
}

export default function useListLeadgenFormDataOfBranch({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useQuery(
    ['useListLeadgenFormDataOfBranch', branchId, params.page, params.perPage, params.query],
    () => listLeadgenFormDataOfBranch(branchId, params.page, params.perPage, params.query, axiosInstance),
    props
  );
}

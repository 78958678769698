import React, { useState } from 'react';
import type { BranchCampaign as BranchCampaignType } from '@app/api';
import { branch as branchLib, format, date } from '@app/lib';
import { Icons, Table, NoContent } from '@app/components';
import { useLocale, useAuth, api } from '@app/hooks';

const DATA_PER_PAGE = 15;

const CampaignDetailTable = () => {
  const SCOPE_OPTIONS = {
    scope: 'pages.campaigns.index',
  };
  const { t } = useLocale();
  const { branch } = useAuth();
  const currency = branchLib.currency(branch);
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState<null | string>('created_at desc');
  const { data } = api.useListBranchCampaigns({
    params: {
      page: currentPage,
      perPage: DATA_PER_PAGE,
      query: {
        s: sort,
      },
    },
  });

  const columns = [
    {
      id: 'stop_time',
      Header: 'Filtrele',
      accessor: (data: BranchCampaignType) => (data.stop_time ? date.format(data.stop_time, 'dd/MM/yyyy HH:mm') : '-'),
      canSort: false,
    },
    {
      id: 'spent',
      Header: t('labels.spent', SCOPE_OPTIONS),
      accessor: (data: BranchCampaignType) => format.money(data.total_insights.spend, currency),
      canSort: true,
    },
    {
      id: 'click',
      Header: t('labels.click', SCOPE_OPTIONS),
      accessor: (data: BranchCampaignType) => format.data(data.total_insights.clicks),
      canSort: false,
    },
    {
      id: 'view',
      Header: t('labels.view', SCOPE_OPTIONS),
      accessor: (data: BranchCampaignType) => format.data(data.total_insights.impressions),
      canSort: false,
    },
    {
      id: 'ctr',
      Header: t('labels.ctr', SCOPE_OPTIONS),
      accessor: (data: BranchCampaignType) => format.data(data.total_insights.ctr),
      canSort: false,
    },
    {
      id: 'cpc',
      Header: t('labels.cpc', SCOPE_OPTIONS),
      accessor: (data: BranchCampaignType) => format.money(data.total_insights.cpc, currency),
      canSort: false,
    },

    {
      id: 'action',
      Header: () => null,
      accessor: () => {
        return (
          <button className="border rounded-full border-black-800 p-2">
            <Icons.ChevronRight className="w-3 h-3" />
          </button>
        );
      },
      disableSortBy: true,
    },
  ];
  return (
    <div className="mt-10">
      <Table
        EmptyComponent={() => (
          <NoContent
            title={t('labels.noDataTitle', SCOPE_OPTIONS)}
            description={t('labels.noDataDescription', SCOPE_OPTIONS)}
            Icon={Icons.Campaign}
          />
        )}
        columns={columns}
        data={data?.campaigns ?? []}
        totalPageCount={data?.meta.pagination.total_pages}
        currentPage={data?.meta.pagination.current}
        pageSize={DATA_PER_PAGE}
        onPageChange={setCurrentPage}
        onSortChange={setSort}
        defaultSortBy={[{ id: 'created_at', desc: true }]}
        campaignDetail
        header
        className="bg-white"
      />
    </div>
  );
};

export default CampaignDetailTable;

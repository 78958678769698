import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="Group_2890"
    data-name="Group 2890"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="17.76"
    height="18.405"
    viewBox="0 0 17.76 18.405"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_1548" data-name="Rectangle 1548" width="17.76" height="18.405" fill="currentColor" />
      </clipPath>
    </defs>
    <g id="Group_2890-2" data-name="Group 2890">
      <path
        id="Path_1497"
        data-name="Path 1497"
        d="M16.287,17.507a.9.9,0,0,0,1.22,0,.859.859,0,0,0,.253-.609V.862a.861.861,0,1,0-1.722,0V2.107l-.14.032L1.722,5.544V4.712A.861.861,0,1,0,0,4.712v8.339a.861.861,0,1,0,1.722,0v-.834l3.41.823v1.821a3.5,3.5,0,0,0,3.1,3.528,3.429,3.429,0,0,0,3.752-3.415v-.18h.842v.09l3.208.77V16.9a.867.867,0,0,0,.25.609m-6.023-2.533a1.7,1.7,0,1,1-3.409,0V13.449l3.409.819Zm5.772-1.091-.224-.054L1.722,10.447V7.315l.138-.032,14.176-3.4Z"
        transform="translate(0 0.001)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default SvgComponent;

/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { FacebookIntegrationResponse } from '../model';
// @ts-ignore
import { IntegrationsListResponse } from '../model';
// @ts-ignore
import { UpdateFacebookIntegrationInput } from '../model';
/**
 * IntegrationsBranchApi - axios parameter creator
 * @export
 */
export const IntegrationsBranchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes the integration
         * @summary Delete the integration
         * @param {string} branchId The id of the branch
         * @param {string} integrationId The id of the integration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBranchIntegration: async (branchId: string, integrationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('deleteBranchIntegration', 'branchId', branchId)
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('deleteBranchIntegration', 'integrationId', integrationId)
            const localVarPath = `/branch_account/branches/{branch_id}/integrations/{integration_id}`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)))
                .replace(`{${"integration_id"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return integrations of branch
         * @summary Return integrations of branch
         * @param {string} branchId The id of the branch
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBranchIntegrations: async (branchId: string, page?: number, perPage?: number, query?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('listBranchIntegrations', 'branchId', branchId)
            const localVarPath = `/branch_account/branches/{branch_id}/integrations`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update facebook integration
         * @summary Update facebook integration
         * @param {string} branchId The id of the branch
         * @param {UpdateFacebookIntegrationInput} updateFacebookIntegrationInput Update facebook integration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBranchFacebookIntegration: async (branchId: string, updateFacebookIntegrationInput: UpdateFacebookIntegrationInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('updateBranchFacebookIntegration', 'branchId', branchId)
            // verify required parameter 'updateFacebookIntegrationInput' is not null or undefined
            assertParamExists('updateBranchFacebookIntegration', 'updateFacebookIntegrationInput', updateFacebookIntegrationInput)
            const localVarPath = `/branch_account/branches/{branch_id}/integrations/facebook_integration_update`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFacebookIntegrationInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationsBranchApi - functional programming interface
 * @export
 */
export const IntegrationsBranchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrationsBranchApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes the integration
         * @summary Delete the integration
         * @param {string} branchId The id of the branch
         * @param {string} integrationId The id of the integration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBranchIntegration(branchId: string, integrationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBranchIntegration(branchId, integrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return integrations of branch
         * @summary Return integrations of branch
         * @param {string} branchId The id of the branch
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBranchIntegrations(branchId: string, page?: number, perPage?: number, query?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBranchIntegrations(branchId, page, perPage, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update facebook integration
         * @summary Update facebook integration
         * @param {string} branchId The id of the branch
         * @param {UpdateFacebookIntegrationInput} updateFacebookIntegrationInput Update facebook integration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBranchFacebookIntegration(branchId: string, updateFacebookIntegrationInput: UpdateFacebookIntegrationInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookIntegrationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBranchFacebookIntegration(branchId, updateFacebookIntegrationInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntegrationsBranchApi - factory interface
 * @export
 */
export const IntegrationsBranchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrationsBranchApiFp(configuration)
    return {
        /**
         * Deletes the integration
         * @summary Delete the integration
         * @param {string} branchId The id of the branch
         * @param {string} integrationId The id of the integration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBranchIntegration(branchId: string, integrationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBranchIntegration(branchId, integrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return integrations of branch
         * @summary Return integrations of branch
         * @param {string} branchId The id of the branch
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBranchIntegrations(branchId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<IntegrationsListResponse> {
            return localVarFp.listBranchIntegrations(branchId, page, perPage, query, options).then((request) => request(axios, basePath));
        },
        /**
         * Update facebook integration
         * @summary Update facebook integration
         * @param {string} branchId The id of the branch
         * @param {UpdateFacebookIntegrationInput} updateFacebookIntegrationInput Update facebook integration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBranchFacebookIntegration(branchId: string, updateFacebookIntegrationInput: UpdateFacebookIntegrationInput, options?: any): AxiosPromise<FacebookIntegrationResponse> {
            return localVarFp.updateBranchFacebookIntegration(branchId, updateFacebookIntegrationInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntegrationsBranchApi - interface
 * @export
 * @interface IntegrationsBranchApi
 */
export interface IntegrationsBranchApiInterface {
    /**
     * Deletes the integration
     * @summary Delete the integration
     * @param {string} branchId The id of the branch
     * @param {string} integrationId The id of the integration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsBranchApiInterface
     */
    deleteBranchIntegration(branchId: string, integrationId: string, options?: any): AxiosPromise<void>;

    /**
     * Return integrations of branch
     * @summary Return integrations of branch
     * @param {string} branchId The id of the branch
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsBranchApiInterface
     */
    listBranchIntegrations(branchId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<IntegrationsListResponse>;

    /**
     * Update facebook integration
     * @summary Update facebook integration
     * @param {string} branchId The id of the branch
     * @param {UpdateFacebookIntegrationInput} updateFacebookIntegrationInput Update facebook integration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsBranchApiInterface
     */
    updateBranchFacebookIntegration(branchId: string, updateFacebookIntegrationInput: UpdateFacebookIntegrationInput, options?: any): AxiosPromise<FacebookIntegrationResponse>;

}

/**
 * IntegrationsBranchApi - object-oriented interface
 * @export
 * @class IntegrationsBranchApi
 * @extends {BaseAPI}
 */
export class IntegrationsBranchApi extends BaseAPI implements IntegrationsBranchApiInterface {
    /**
     * Deletes the integration
     * @summary Delete the integration
     * @param {string} branchId The id of the branch
     * @param {string} integrationId The id of the integration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsBranchApi
     */
    public deleteBranchIntegration(branchId: string, integrationId: string, options?: any) {
        return IntegrationsBranchApiFp(this.configuration).deleteBranchIntegration(branchId, integrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return integrations of branch
     * @summary Return integrations of branch
     * @param {string} branchId The id of the branch
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsBranchApi
     */
    public listBranchIntegrations(branchId: string, page?: number, perPage?: number, query?: object, options?: any) {
        return IntegrationsBranchApiFp(this.configuration).listBranchIntegrations(branchId, page, perPage, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update facebook integration
     * @summary Update facebook integration
     * @param {string} branchId The id of the branch
     * @param {UpdateFacebookIntegrationInput} updateFacebookIntegrationInput Update facebook integration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsBranchApi
     */
    public updateBranchFacebookIntegration(branchId: string, updateFacebookIntegrationInput: UpdateFacebookIntegrationInput, options?: any) {
        return IntegrationsBranchApiFp(this.configuration).updateBranchFacebookIntegration(branchId, updateFacebookIntegrationInput, options).then((request) => request(this.axios, this.basePath));
    }
}

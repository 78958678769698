/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Branch } from '../model';
// @ts-ignore
import { BranchAccountBranchUpdateInput } from '../model';
// @ts-ignore
import { BranchCreateErrorResponse } from '../model';
// @ts-ignore
import { BranchesExternalErrorsResponse } from '../model';
// @ts-ignore
import { BranchesListResponse } from '../model';
/**
 * BranchesBranchUserApi - axios parameter creator
 * @export
 */
export const BranchesBranchUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Return external errors that prevents campaign publish
         * @summary Return external errors that prevents campaign publish
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalErrors: async (branchId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('getExternalErrors', 'branchId', branchId)
            const localVarPath = `/branch_account/branches/{branch_id}/external_errors`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return branches of branch user
         * @summary Return branches of branch user
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBranchUserBranches: async (page?: number, perPage?: number, query?: object, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/branch_account/branches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mark branch as onboarded
         * @summary Mark branch as onboarded
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markBranchAsOnboarded: async (branchId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('markBranchAsOnboarded', 'branchId', branchId)
            const localVarPath = `/branch_account/branches/{branch_id}/mark_as_onboarded`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mark facebook modal as false
         * @summary Mark facebook modal as false
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markFacebookModalAsFalse: async (branchId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('markFacebookModalAsFalse', 'branchId', branchId)
            const localVarPath = `/branch_account/branches/{branch_id}/mark_force_open_facebook_login_modal_as_false`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return branch of branch user
         * @summary Return branch of branch user
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showBranchUserBranches: async (branchId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('showBranchUserBranches', 'branchId', branchId)
            const localVarPath = `/branch_account/branches/{branch_id}`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Toggle allowing general manager updates for campaigns
         * @summary Toggle allowing general manager updates for campaigns
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleAllowGmUpdateCampaigns: async (branchId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('toggleAllowGmUpdateCampaigns', 'branchId', branchId)
            const localVarPath = `/branch_account/branches/{branch_id}/toggle_allow_gm_update_campaigns`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update branch with by id
         * @summary Update branch with by id
         * @param {string} branchId The id of the branch
         * @param {BranchAccountBranchUpdateInput} branchAccountBranchUpdateInput Update branch with by id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBranchUserBranches: async (branchId: string, branchAccountBranchUpdateInput: BranchAccountBranchUpdateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('updateBranchUserBranches', 'branchId', branchId)
            // verify required parameter 'branchAccountBranchUpdateInput' is not null or undefined
            assertParamExists('updateBranchUserBranches', 'branchAccountBranchUpdateInput', branchAccountBranchUpdateInput)
            const localVarPath = `/branch_account/branches/{branch_id}`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(branchAccountBranchUpdateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BranchesBranchUserApi - functional programming interface
 * @export
 */
export const BranchesBranchUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BranchesBranchUserApiAxiosParamCreator(configuration)
    return {
        /**
         * Return external errors that prevents campaign publish
         * @summary Return external errors that prevents campaign publish
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExternalErrors(branchId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchesExternalErrorsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExternalErrors(branchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return branches of branch user
         * @summary Return branches of branch user
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBranchUserBranches(page?: number, perPage?: number, query?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchesListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBranchUserBranches(page, perPage, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Mark branch as onboarded
         * @summary Mark branch as onboarded
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markBranchAsOnboarded(branchId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Branch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markBranchAsOnboarded(branchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Mark facebook modal as false
         * @summary Mark facebook modal as false
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markFacebookModalAsFalse(branchId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Branch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markFacebookModalAsFalse(branchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return branch of branch user
         * @summary Return branch of branch user
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showBranchUserBranches(branchId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Branch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showBranchUserBranches(branchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Toggle allowing general manager updates for campaigns
         * @summary Toggle allowing general manager updates for campaigns
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async toggleAllowGmUpdateCampaigns(branchId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Branch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.toggleAllowGmUpdateCampaigns(branchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update branch with by id
         * @summary Update branch with by id
         * @param {string} branchId The id of the branch
         * @param {BranchAccountBranchUpdateInput} branchAccountBranchUpdateInput Update branch with by id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBranchUserBranches(branchId: string, branchAccountBranchUpdateInput: BranchAccountBranchUpdateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Branch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBranchUserBranches(branchId, branchAccountBranchUpdateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BranchesBranchUserApi - factory interface
 * @export
 */
export const BranchesBranchUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BranchesBranchUserApiFp(configuration)
    return {
        /**
         * Return external errors that prevents campaign publish
         * @summary Return external errors that prevents campaign publish
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalErrors(branchId: string, options?: any): AxiosPromise<BranchesExternalErrorsResponse> {
            return localVarFp.getExternalErrors(branchId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return branches of branch user
         * @summary Return branches of branch user
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBranchUserBranches(page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<BranchesListResponse> {
            return localVarFp.listBranchUserBranches(page, perPage, query, options).then((request) => request(axios, basePath));
        },
        /**
         * Mark branch as onboarded
         * @summary Mark branch as onboarded
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markBranchAsOnboarded(branchId: string, options?: any): AxiosPromise<Branch> {
            return localVarFp.markBranchAsOnboarded(branchId, options).then((request) => request(axios, basePath));
        },
        /**
         * Mark facebook modal as false
         * @summary Mark facebook modal as false
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markFacebookModalAsFalse(branchId: string, options?: any): AxiosPromise<Branch> {
            return localVarFp.markFacebookModalAsFalse(branchId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return branch of branch user
         * @summary Return branch of branch user
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showBranchUserBranches(branchId: string, options?: any): AxiosPromise<Branch> {
            return localVarFp.showBranchUserBranches(branchId, options).then((request) => request(axios, basePath));
        },
        /**
         * Toggle allowing general manager updates for campaigns
         * @summary Toggle allowing general manager updates for campaigns
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleAllowGmUpdateCampaigns(branchId: string, options?: any): AxiosPromise<Branch> {
            return localVarFp.toggleAllowGmUpdateCampaigns(branchId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update branch with by id
         * @summary Update branch with by id
         * @param {string} branchId The id of the branch
         * @param {BranchAccountBranchUpdateInput} branchAccountBranchUpdateInput Update branch with by id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBranchUserBranches(branchId: string, branchAccountBranchUpdateInput: BranchAccountBranchUpdateInput, options?: any): AxiosPromise<Branch> {
            return localVarFp.updateBranchUserBranches(branchId, branchAccountBranchUpdateInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BranchesBranchUserApi - interface
 * @export
 * @interface BranchesBranchUserApi
 */
export interface BranchesBranchUserApiInterface {
    /**
     * Return external errors that prevents campaign publish
     * @summary Return external errors that prevents campaign publish
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchesBranchUserApiInterface
     */
    getExternalErrors(branchId: string, options?: any): AxiosPromise<BranchesExternalErrorsResponse>;

    /**
     * Return branches of branch user
     * @summary Return branches of branch user
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchesBranchUserApiInterface
     */
    listBranchUserBranches(page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<BranchesListResponse>;

    /**
     * Mark branch as onboarded
     * @summary Mark branch as onboarded
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchesBranchUserApiInterface
     */
    markBranchAsOnboarded(branchId: string, options?: any): AxiosPromise<Branch>;

    /**
     * Mark facebook modal as false
     * @summary Mark facebook modal as false
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchesBranchUserApiInterface
     */
    markFacebookModalAsFalse(branchId: string, options?: any): AxiosPromise<Branch>;

    /**
     * Return branch of branch user
     * @summary Return branch of branch user
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchesBranchUserApiInterface
     */
    showBranchUserBranches(branchId: string, options?: any): AxiosPromise<Branch>;

    /**
     * Toggle allowing general manager updates for campaigns
     * @summary Toggle allowing general manager updates for campaigns
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchesBranchUserApiInterface
     */
    toggleAllowGmUpdateCampaigns(branchId: string, options?: any): AxiosPromise<Branch>;

    /**
     * Update branch with by id
     * @summary Update branch with by id
     * @param {string} branchId The id of the branch
     * @param {BranchAccountBranchUpdateInput} branchAccountBranchUpdateInput Update branch with by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchesBranchUserApiInterface
     */
    updateBranchUserBranches(branchId: string, branchAccountBranchUpdateInput: BranchAccountBranchUpdateInput, options?: any): AxiosPromise<Branch>;

}

/**
 * BranchesBranchUserApi - object-oriented interface
 * @export
 * @class BranchesBranchUserApi
 * @extends {BaseAPI}
 */
export class BranchesBranchUserApi extends BaseAPI implements BranchesBranchUserApiInterface {
    /**
     * Return external errors that prevents campaign publish
     * @summary Return external errors that prevents campaign publish
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchesBranchUserApi
     */
    public getExternalErrors(branchId: string, options?: any) {
        return BranchesBranchUserApiFp(this.configuration).getExternalErrors(branchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return branches of branch user
     * @summary Return branches of branch user
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchesBranchUserApi
     */
    public listBranchUserBranches(page?: number, perPage?: number, query?: object, options?: any) {
        return BranchesBranchUserApiFp(this.configuration).listBranchUserBranches(page, perPage, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mark branch as onboarded
     * @summary Mark branch as onboarded
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchesBranchUserApi
     */
    public markBranchAsOnboarded(branchId: string, options?: any) {
        return BranchesBranchUserApiFp(this.configuration).markBranchAsOnboarded(branchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mark facebook modal as false
     * @summary Mark facebook modal as false
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchesBranchUserApi
     */
    public markFacebookModalAsFalse(branchId: string, options?: any) {
        return BranchesBranchUserApiFp(this.configuration).markFacebookModalAsFalse(branchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return branch of branch user
     * @summary Return branch of branch user
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchesBranchUserApi
     */
    public showBranchUserBranches(branchId: string, options?: any) {
        return BranchesBranchUserApiFp(this.configuration).showBranchUserBranches(branchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Toggle allowing general manager updates for campaigns
     * @summary Toggle allowing general manager updates for campaigns
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchesBranchUserApi
     */
    public toggleAllowGmUpdateCampaigns(branchId: string, options?: any) {
        return BranchesBranchUserApiFp(this.configuration).toggleAllowGmUpdateCampaigns(branchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update branch with by id
     * @summary Update branch with by id
     * @param {string} branchId The id of the branch
     * @param {BranchAccountBranchUpdateInput} branchAccountBranchUpdateInput Update branch with by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchesBranchUserApi
     */
    public updateBranchUserBranches(branchId: string, branchAccountBranchUpdateInput: BranchAccountBranchUpdateInput, options?: any) {
        return BranchesBranchUserApiFp(this.configuration).updateBranchUserBranches(branchId, branchAccountBranchUpdateInput, options).then((request) => request(this.axios, this.basePath));
    }
}

import React, { useEffect } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { enUS, tr } from 'date-fns/locale';

import { useLocale } from '@app/hooks';
import { TIME_TYPE } from '@app/constants';
import { date as dateLib } from '@app/lib';
import { Box, Icons, RadioPicker } from '@app/components';
import CustomInput from 'components/Campaign/DateForm/CustomInput';

type DatePropTypes = {
  error?: { message: string | null };
  name: string;
  timeType: string;
  onChangeType: (type: string) => void;
  useInstagramSchedule?: boolean;
};

const Date: React.FC<DatePropTypes> = ({ name, timeType, onChangeType, useInstagramSchedule }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.SocialMediaManagement.Date',
  };
  const { t } = useLocale();
  const { locale } = useLocale();
  const { setValue } = useFormContext();
  const nowDate = dateLib.JSDateToISO(dateLib.ISOtoJSDate(dateLib.now()));

  const OPTIONS = [
    { label: t('labels.useNow', SCOPE_OPTIONS), value: TIME_TYPE.NOW_DATE, date: true, disabled: false },
    { label: t('labels.useTime', SCOPE_OPTIONS), value: TIME_TYPE.DIFFERENT_START_DATE, date: true, disabled: false },
  ];

  useEffect(() => {
    const nowDate = dateLib.JSDateToISO(dateLib.ISOtoJSDate(dateLib.now()));
    if (timeType === TIME_TYPE.NOW_DATE) {
      setValue(name, nowDate);
    }
  }, [timeType]);

  function handleRadioSelect(value: string) {
    onChangeType(value);
  }

  function getLocale() {
    if (locale === 'en') {
      return enUS;
    }
    if (locale === 'tr') {
      return tr;
    }

    return enUS;
  }

  return (
    <Box className="bg-white px-5 min-w-60">
      <span className="block text-3.5 font-semibold text-gray-900">{t('labels.selectDate', SCOPE_OPTIONS)}</span>
      <RadioPicker className="flex items-center space-x-5 " options={OPTIONS} value={timeType} onChange={handleRadioSelect} />
      {timeType !== TIME_TYPE.NOW_DATE && (
        <Controller
          name={name}
          render={({ field: { value, onChange } }) => (
            <DatePicker
              dateFormat="d MMMM yyyy HH:mm"
              locale={getLocale()}
              selected={dateLib.ISOtoJSDate(value)}
              onChange={(date) => onChange(date && dateLib.JSDateToISO(date))}
              minDate={dateLib.ISOtoJSDate(dateLib.now())}
              withPortal={true}
              showTimeSelect={true}
              timeIntervals={1}
              customInput={<CustomInput />}
            />
          )}
          defaultValue={nowDate}
        />
      )}
      {useInstagramSchedule && (
        <span className="flex text-center px-5 text-3.5 font-semibold text-gray-900 mt-3">
          <Icons.CircleWarning className="w-8 h-8 fill-current" /> İnstagram post zamanlaması şu an kullanılamamaktadır.
        </span>
      )}
    </Box>
  );
};

export default Date;

import React from 'react';

import cn from 'classnames';

type BoxPropTypes = {
  className?: string;
};

const InfoBox: React.FC<BoxPropTypes> = ({ children, className }) => {
  return <div className={cn('bg-gray-100 py-3 px-3 rounded-3', className)}>{children}</div>;
};

export default InfoBox;

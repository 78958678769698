import { Template as TemplateType } from 'api/model';
import { PUBLISHER_PLATFORMS } from '@app/constants';

function isPlatformGoogle(template: TemplateType | undefined) {
  return (template?.targeting?.publisher_platforms as string[])?.includes(PUBLISHER_PLATFORMS.GOOGLE);
}

export default {
  isPlatformGoogle,
};

import { useState } from 'react';

import type { CurrentFacebookPageResponse } from '@app/api';

import api from './api';
import useAuth from './useAuth';

function useCheckAccountAlert() {
  const { branchId } = useAuth();
  const [showPaymentAlert, setShowPaymentAlert] = useState(false);
  const [showSpendAlert, setShowSpendAlert] = useState(false);

  const { data: pages } = api.useGetCurrentFacebookPageResponse({
    params: {
      branchId: branchId,
    },
    automaticToast: false,
    onSuccess: (data: CurrentFacebookPageResponse) => {
      if (data?.ad_account_billing_decline?.declined === true) {
        setShowPaymentAlert(true);
      }

      if (data?.ad_account_spend_limit?.has_limit === true) {
        setShowSpendAlert(true);
      }
    },
  });
  const date = pages?.ad_account_billing_decline?.time ?? null;

  return { showPaymentAlert, showSpendAlert, date };
}

export default useCheckAccountAlert;

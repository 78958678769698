import React from 'react';

import { Box } from '@app/components';

type MessageBox = {
  getAuditMessage: () => string;
};

const AuditMessageBox = ({ getAuditMessage }: MessageBox) => {
  return (
    <Box className="!mb-0 bg-white p-5">
      <span className="font-regular text-gray-700 text-3.5" dangerouslySetInnerHTML={{ __html: getAuditMessage() }} />
    </Box>
  );
};

export default AuditMessageBox;

const ANALYTICS_TOTAL_CONTENT = Object.freeze({
  UP: {
    IMPRESSION: {
      KEY: 'impression',
      COLOR: '#3E66EA',
    },
    SPEND: {
      KEY: 'spend',
      COLOR: '#FCB378',
    },
  },
  DOWN: {
    GOOGLE_CALL: {
      KEY: 'googleCall',
      COLOR: '#1DC198',
    },
    META_CALL: {
      KEY: 'metaCall',
      COLOR: '#1DC198',
    },
    FORM: {
      KEY: 'lead',
      COLOR: '#B189F4',
    },
    MESSAGE: {
      KEY: 'message',
      COLOR: '#1DA3C1',
    },
    DIRECTION: {
      KEY: 'direction',
      COLOR: '#61BBD8',
    },
  },
});

export default ANALYTICS_TOTAL_CONTENT;

import React from 'react';

import ReactJotformEmbed from 'react-jotform-embed';

type ContactPropTypes = {
  iframe_url: string;
};

const Contact: React.FC<ContactPropTypes> = ({ iframe_url }) => {
  return (
    <div className="w-full h-screen">
      <div className="h-full">
        <ReactJotformEmbed src={iframe_url} />
      </div>
    </div>
  );
};

export default Contact;

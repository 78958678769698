import React from 'react';

import { format, branch as branchLib } from '@app/lib';
import { useAuth, useLocale } from '@app/hooks';
import { Icons, Tooltip } from '@app/components';
import { ANALYTICS_CONTENT, COLORS } from '@app/constants';
import cn from 'classnames';

type CardPropTypes = {
  id: string;
  title: string;
  count: string | number;
  color: string;
  selected: boolean;
  Icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  onClick: () => void;
  index: number;
};

type LegendPropTypes = {
  selected: string[];
  onClick: (key: string) => void;
  totalInsights: any;
};

const Card: React.FC<CardPropTypes> = ({ id, index, onClick, title, count, color, selected, Icon }) => {
  const { branch } = useAuth();
  const currency = branchLib.currency(branch);

  return (
    <button
      onClick={onClick}
      className={cn('relative h-32 first:rounded-tl-xl first:rounded-bl-xl last:rounded-tr-xl last:rounded-br-xl border border-gray-400', {
        'border-l border-t border-b border-white': selected,
        'border-r-[0.5px]': !selected,
      })}
    >
      <div className="pt-4 xl:pl-5 pb-2 xl:pr-4 md:pl-1.5 md:pr-1.5 top-0 left-3 right-3 bottom-0 absolute z-1 transition-colors">
        <div className="flex flex-col" style={{ color: color }}>
          <Icon className="w-5 h-5" />
          <div className="flex justify-between items-center ">
            <span className="text-3.5 block text-left py-2 mr-auto">{title}</span>
            {selected === true && <Icons.CheckCircle className="w-6 h-6" />}
            {selected === false && <div className="absolute right-2 w-5 h-5 rounded-full border border-gray-400" />}
          </div>
        </div>

        <span className="text-gray-900 lg:text-5 xl:text-6.5 md:text-4.5 leading-10 block text-left overflow-ellipsis overflow-hidden">
          <span data-tip="" data-for={`currency-text-${id}`}>
            {format.data(count ?? 0, currency, id)}
          </span>
        </span>
        <Tooltip id={`currency-text-${id}`} content={format.data(count ?? 0, currency, id, 'tr')} />
      </div>
      <div
        className={cn('w-full h-full opacity-10 top-0 left-0 right-0 bottom-0 absolute z-0 transition-colors', {
          'rounded-tl-xl rounded-bl-xl ': index === 0,
          'rounded-tr-xl rounded-br-xl ': index === 3,
        })}
        style={{ background: selected ? `linear-gradient(180deg, ${color} 0%, ${COLORS.WHITE} 100%)` : COLORS.WHITE }}
      />
      <span
        className="h-0.5 left-3 rounded right-3 bottom-0 absolute z-1 border-b-1 transition-colors"
        style={{ borderBottomColor: selected ? color : '#f9fafa' }}
      ></span>
    </button>
  );
};

const Legend: React.FC<LegendPropTypes> = ({ totalInsights, selected, onClick }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Analytics.Chart.Legend',
  };
  const { t } = useLocale();

  function handleValue(key: string) {
    if (key === ANALYTICS_CONTENT.CPC.KEY) {
      return totalInsights?.total_cpc ?? 0;
    }
    if (key === ANALYTICS_CONTENT.IMPRESSION.KEY) {
      return totalInsights?.total_impressions ?? 0;
    }
    if (key === ANALYTICS_CONTENT.COST.KEY) {
      return totalInsights?.total_spend ?? 0;
    }

    return totalInsights?.total_clicks ?? 0;
  }

  function getIcon(key: string) {
    if (key === ANALYTICS_CONTENT.CLICK.KEY) {
      return <Icons.Click />;
    }

    if (key === ANALYTICS_CONTENT.COST.KEY) {
      return <Icons.Cost />;
    }

    if (key === ANALYTICS_CONTENT.CPC.KEY) {
      return <Icons.CPC />;
    }

    return <Icons.Eye />;
  }

  return (
    <div className="grid grid-cols-4 w-full">
      {Object.values(ANALYTICS_CONTENT).map((content, index) => (
        <Card
          key={content.KEY}
          id={content.KEY}
          title={t(`labels.${content.KEY}`, SCOPE_OPTIONS)}
          count={handleValue(content.KEY)}
          color={content.COLOR}
          selected={Array.isArray(selected) && selected.includes(content.KEY)}
          Icon={() => getIcon(content.KEY)}
          onClick={() => onClick(content.KEY)}
          index={index}
        />
      ))}
    </div>
  );
};

export default Legend;

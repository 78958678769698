import React, { Fragment, useState, useEffect, useRef } from 'react';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { Popover, Transition } from '@headlessui/react';
import { DateRange } from 'react-date-range';
import { enUS, ro, tr } from 'date-fns/locale';

import { date } from '@app/lib';
import { useLocale } from '@app/hooks';
import { Button, Icons } from '@app/components';

type DateRangePropTypes = {
  value: {
    startDate: Date;
    endDate: Date;
  };
  onChange: (date: { startDate: Date; endDate: Date }) => void;
};

const START_DATE_GAP = 13;

const DateRangePicker: React.FC<DateRangePropTypes> = ({ value, onChange }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.DateRange',
  };
  const { t, locale } = useLocale();
  const popoverRef = useRef<HTMLButtonElement>(null);
  const [state, setState] = useState({
    startDate: date.ISOtoJSDate(date.minusFromNow({ days: START_DATE_GAP })),
    endDate: date.ISOtoJSDate(date.now()),
    key: 'selection',
  });

  useEffect(() => {
    if (value) {
      setState((prev) => ({ ...prev, ...value }));
    }
  }, [value]);

  function handleUpdate() {
    onChange({
      startDate: state.startDate,
      endDate: state.endDate,
    });

    popoverRef.current?.click();
  }

  function handleClose() {
    popoverRef.current?.click();

    onChange({
      startDate: value.startDate,
      endDate: value.endDate,
    });
  }

  function getLocale() {
    if (locale === 'en') {
      return enUS;
    }
    if (locale === 'tr') {
      return tr;
    }
    if (locale === 'ro') {
      return ro;
    }

    return enUS;
  }

  return (
    <Popover className="relative">
      <Popover.Button
        ref={popoverRef}
        className="flex flex-row gap-2 items-center py-2 px-3 rounded-3 border border-black-800 transition-colors text-4 text-black-800"
      >
        <Icons.Calendar className="w-5 h-5" />
        {`${date.format(date.JSDateToISO(value.startDate), 'dd MMM yyyy', { locale })} - ${date.format(
          date.JSDateToISO(value.endDate),
          'dd MMM yyyy',
          { locale }
        )}`}
        <Icons.ChevronDown className="w-5 h-5" />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute z-10 bg-white overflow-hidden shadow-box rounded-3 right-0 mt-2">
          <DateRange
            locale={getLocale()}
            weekStartsOn={1}
            onChange={(item) => setState(item.selection as any)}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[state]}
            direction="horizontal"
            maxDate={date.ISOtoJSDate(date.now())}
          />
          <div className="flex flex-row items-center justify-end gap-5 px-3 pb-3 -mt-2">
            <Button
              label={t('actions.cancel', SCOPE_OPTIONS)}
              theme="gray"
              size="sm"
              className="w-30"
              onClick={handleClose}
              block={false}
            />
            <Button
              label={t('actions.apply', SCOPE_OPTIONS)}
              theme="blue"
              size="sm"
              className="w-30"
              onClick={handleUpdate}
              block={false}
            />
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default DateRangePicker;

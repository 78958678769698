import React from 'react';
import ReactTooltip from 'react-tooltip';

type TooltipPropType = {
  content: any;
  id: string;
  place?: 'top' | 'right' | 'bottom' | 'left';
};
const Tooltip: React.FC<TooltipPropType> = ({ content, id, place = 'top' }) => {
  return (
    <ReactTooltip
      id={id}
      effect="solid"
      place={place}
      className="!rounded-1.5 !p-2 !flex !justify-center !min-w-12 !max-w-40 !items-center !mt-0 !l-0"
      getContent={() => {
        return (
          <div className="w-full flex justify-center items-center">
            <span className="text-center">{content}</span>
          </div>
        );
      }}
    />
  );
};
export default Tooltip;

/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BranchCampaignTargetingCities } from './branch-campaign-targeting-cities';
import { BranchCampaignTargetingGeoLocations } from './branch-campaign-targeting-geo-locations';
import { BranchCampaignTargetingKeywordThemes } from './branch-campaign-targeting-keyword-themes';
import { CampaignTargetingEducationStatuses } from './campaign-targeting-education-statuses';

/**
 * 
 * @export
 * @interface BranchCampaignTargeting
 */
export interface BranchCampaignTargeting {
    /**
     * 
     * @type {string}
     * @memberof BranchCampaignTargeting
     */
    targeting_type: BranchCampaignTargetingTargetingTypeEnum;
    /**
     * 
     * @type {BranchCampaignTargetingGeoLocations}
     * @memberof BranchCampaignTargeting
     */
    geo_locations?: BranchCampaignTargetingGeoLocations;
    /**
     * 
     * @type {Array<number>}
     * @memberof BranchCampaignTargeting
     */
    locales?: Array<number>;
    /**
     * 
     * @type {Array<CampaignTargetingEducationStatuses>}
     * @memberof BranchCampaignTargeting
     */
    education_statuses?: Array<CampaignTargetingEducationStatuses>;
    /**
     * 
     * @type {Array<CampaignTargetingEducationStatuses>}
     * @memberof BranchCampaignTargeting
     */
    education_majors?: Array<CampaignTargetingEducationStatuses>;
    /**
     * 
     * @type {Array<CampaignTargetingEducationStatuses>}
     * @memberof BranchCampaignTargeting
     */
    education_schools?: Array<CampaignTargetingEducationStatuses>;
    /**
     * 
     * @type {Array<CampaignTargetingEducationStatuses>}
     * @memberof BranchCampaignTargeting
     */
    college_years?: Array<CampaignTargetingEducationStatuses>;
    /**
     * 
     * @type {Array<CampaignTargetingEducationStatuses>}
     * @memberof BranchCampaignTargeting
     */
    life_events?: Array<CampaignTargetingEducationStatuses>;
    /**
     * 
     * @type {Array<CampaignTargetingEducationStatuses>}
     * @memberof BranchCampaignTargeting
     */
    work_employers?: Array<CampaignTargetingEducationStatuses>;
    /**
     * 
     * @type {Array<CampaignTargetingEducationStatuses>}
     * @memberof BranchCampaignTargeting
     */
    industries?: Array<CampaignTargetingEducationStatuses>;
    /**
     * 
     * @type {Array<CampaignTargetingEducationStatuses>}
     * @memberof BranchCampaignTargeting
     */
    work_positions?: Array<CampaignTargetingEducationStatuses>;
    /**
     * 
     * @type {Array<CampaignTargetingEducationStatuses>}
     * @memberof BranchCampaignTargeting
     */
    interests?: Array<CampaignTargetingEducationStatuses>;
    /**
     * 
     * @type {Array<CampaignTargetingEducationStatuses>}
     * @memberof BranchCampaignTargeting
     */
    behaviors?: Array<CampaignTargetingEducationStatuses>;
    /**
     * 
     * @type {number}
     * @memberof BranchCampaignTargeting
     */
    age_min?: number;
    /**
     * 
     * @type {number}
     * @memberof BranchCampaignTargeting
     */
    age_max?: number;
    /**
     * 
     * @type {string}
     * @memberof BranchCampaignTargeting
     */
    destination_type?: BranchCampaignTargetingDestinationTypeEnum;
    /**
     * 
     * @type {Array<BranchCampaignTargetingCities>}
     * @memberof BranchCampaignTargeting
     */
    cities?: Array<BranchCampaignTargetingCities>;
    /**
     * 
     * @type {string}
     * @memberof BranchCampaignTargeting
     */
    gender?: BranchCampaignTargetingGenderEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof BranchCampaignTargeting
     */
    keywords?: Array<string>;
    /**
     * 
     * @type {Array<BranchCampaignTargetingKeywordThemes>}
     * @memberof BranchCampaignTargeting
     */
    keyword_themes?: Array<BranchCampaignTargetingKeywordThemes>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BranchCampaignTargeting
     */
    free_form_keyword_themes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BranchCampaignTargeting
     */
    negative_keywords?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum BranchCampaignTargetingTargetingTypeEnum {
    WhatsappMessage = 'WHATSAPP_MESSAGE',
    Messenger = 'MESSENGER',
    CallNow = 'CALL_NOW',
    FinalUrl = 'FINAL_URL',
    InstagramVisit = 'INSTAGRAM_VISIT',
    LeadGeneration = 'LEAD_GENERATION',
    AndroidAppInstalls = 'ANDROID_APP_INSTALLS',
    IosAppInstalls = 'IOS_APP_INSTALLS',
    OutcomeAwareness = 'OUTCOME_AWARENESS',
    Pmax = 'PMAX',
    Smart = 'SMART'
}
/**
    * @export
    * @enum {string}
    */
export enum BranchCampaignTargetingDestinationTypeEnum {
    Website = 'WEBSITE',
    App = 'APP',
    Messenger = 'MESSENGER',
    InstagramDirect = 'INSTAGRAM_DIRECT',
    Whatsapp = 'WHATSAPP',
    PhoneCall = 'PHONE_CALL'
}
/**
    * @export
    * @enum {string}
    */
export enum BranchCampaignTargetingGenderEnum {
    All = 'ALL',
    Males = 'MALES',
    Females = 'FEMALES'
}




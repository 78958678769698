import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.000000 52.000000" preserveAspectRatio="xMidYMid meet" {...props}>
    <g transform="translate(0.000000,52.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
      <path d="M77 502 c-15 -16 -17 -48 -17 -245 0 -222 0 -226 22 -241 18 -13 53 -16 178 -16 197 0 200 2 200 102 l0 71 -25 -23 c-18 -17 -25 -34 -25 -62 l0 -38 -150 0 -150 0 0 210 0 210 150 0 150 0 0 -38 c0 -28 7 -45 25 -62 l25 -23 0 70 c0 103 0 103 -202 103 -147 0 -167 -2 -181 -18z" />
      <path d="M318 358 l-3 -33 -67 -3 -68 -3 0 -59 0 -59 68 -3 67 -3 3 -32 c2 -18 9 -33 15 -33 13 0 152 119 152 130 0 11 -139 130 -152 130 -6 0 -13 -15 -15 -32z" />
    </g>
  </svg>
);

export default SvgComponent;

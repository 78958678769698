import { useMutation } from 'react-query';
import { AxiosInstance, AxiosPromise } from 'axios';

import type { ImageAttachment } from '@app/api';

import { useAuth, useAxios } from '@app/hooks';

export function uploadFacebookAdTypePhoto(branchId: string, payload: object, axiosInstance: AxiosInstance): AxiosPromise<ImageAttachment> {
  return axiosInstance
    .post(`/branch_account/branches/${branchId}/attachments/images/facebook`, payload, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    .then((res) => res.data);
}

export default function useUploadFacebookAdTypePhoto(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useMutation((values: { payload: object }) => uploadFacebookAdTypePhoto(branchId, values.payload, axiosInstance), props);
}

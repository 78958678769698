const CUSTOM_FONTS = Object.freeze([
  {
    family: 'Michelin',
    variants: ['300', 'regular', '600', '700', '900'],
    subsets: ['latin'],
    category: 'display',
  },
]);

export default CUSTOM_FONTS;

import React from 'react';
import { useRouter } from 'next/router';
import Router from 'next/router';

import { PUBLISHER_PLATFORMS, POSITIONS } from '@app/constants';
import { TemplateAttachments } from 'api/model';
import { useAuth, useLocale } from '@app/hooks';
import { branch as branchLib } from '@app/lib';
import { Box, Icons, LoaderSkeleton } from '@app/components';

type CampaignImageShowCardPropTypes = {
  platform: string;
  attachments: Array<TemplateAttachments>;
};

const CampaignImageShowCard: React.FC<CampaignImageShowCardPropTypes> = ({ platform, attachments }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.CampaignImageShowCard',
  };
  const { t } = useLocale();
  const { branch } = useAuth();
  const router = useRouter();
  const queryParams = router.query;
  const storyImage = attachments?.find((item) => item.placement_type === POSITIONS.STORY.toLocaleUpperCase())?.file?.url;
  const feedImage = attachments?.find((item) => item.placement_type === POSITIONS.FEED.toLocaleUpperCase())?.file?.url;

  function handlePublishPost() {
    return Router.push({
      pathname: '/social-media-management/[id]',
      query: { id: queryParams.id },
    });
  }

  return (
    <Box className="bg-white p-5 !mb-0 shadow-lg px-8 py-8">
      <span className="flex justify-between">
        <div className="flex justify-between">
          {platform === PUBLISHER_PLATFORMS.FACEBOOK ? (
            <Icons.FacebookIcon color="black" className="w-9 h-9" />
          ) : (
            <Icons.Instagram color="black" className="w-9 h-9" />
          )}
          <span className="self-center text-4 font-semibold text-gray-900 whitespace-nowrap capitalize ml-4">
            {t('labels.title', { ...SCOPE_OPTIONS, value: platform })}
          </span>
        </div>
        {branchLib.shouldPublishPost(branch?.company?.feature_flags) && (
          <button
            onClick={() => handlePublishPost()}
            className="border-0.5 border-black-800 py-1 px-2.5 text-black-800 rounded-lg flex items-center transition-all hover:bg-green-500 hover:text-white hover:border-green-500"
          >
            <Icons.PlatformShare className="w-5 h-5 mr-2" />
            <span>{t('actions.share', SCOPE_OPTIONS)}</span>
          </button>
        )}
      </span>
      <hr className="my-4 border-b border-gray-400" />
      <span className="justify-center grid gap-4 mt-5 grid-cols-2">
        {feedImage ? (
          <img className="rounded-2 shadow-lg" src={feedImage} alt="feed_image" width="100%" height="100%" />
        ) : (
          <LoaderSkeleton.SkeletonLoaderFeed />
        )}
        {storyImage ? (
          <img className="rounded-2 shadow-lg" src={storyImage} alt="story_image" width="100%" height="100%" />
        ) : (
          <LoaderSkeleton.SkeletonLoaderStory />
        )}
      </span>
    </Box>
  );
};

export default CampaignImageShowCard;

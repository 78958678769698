import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...props}>
    <g transform="translate(-867 -689)">
      <g transform="translate(867 689)" fill="none" stroke="currentColor" strokeWidth="1">
        <rect width="22" height="22" rx="5" stroke="none" />
        <rect x="0.5" y="0.5" width="21" height="21" rx="4.5" fill="none" />
      </g>
    </g>
  </svg>
);

export default SvgComponent;

import cn from 'classnames';

import { useLocale } from '@app/hooks';
import { PUBLISHER_PLATFORMS } from '@app/constants';
import { Icons } from '@app/components';

type HeaderPropTypes = {
  platform: string;
  onPlatformChange: (platform: string) => void;
};

const Header: React.FC<HeaderPropTypes> = ({ platform, onPlatformChange }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.SocialMediaManagement.PostPreview.Header',
  };
  const { t } = useLocale();

  return (
    <div className="flex justify-between align-center w-full">
      <span className="bg-white self-center block text-3.5 font-semibold text-gray-900">{t('label', SCOPE_OPTIONS)}:</span>

      <div className="flex text-gray-500">
        <button
          className={cn('p-3 rounded-3', {
            'bg-blue-500 text-white': platform === PUBLISHER_PLATFORMS.FACEBOOK,
          })}
          onClick={() => onPlatformChange(PUBLISHER_PLATFORMS.FACEBOOK)}
        >
          <Icons.Facebook className="w-5 h-5" />
        </button>
        <button
          className={cn('p-3 rounded-3', {
            'text-white': platform === PUBLISHER_PLATFORMS.INSTAGRAM,
          })}
          style={{
            background:
              platform === PUBLISHER_PLATFORMS.INSTAGRAM
                ? 'linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)'
                : '',
          }}
          onClick={() => onPlatformChange(PUBLISHER_PLATFORMS.INSTAGRAM)}
        >
          <Icons.Instagram className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

export default Header;

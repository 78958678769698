import Button from 'components/Button';
import React, { useMemo, useState } from 'react';
import Wallet from '..';
import useLocale from '@app/hooks/useLocale';

type AmountProps = {
  currency: string;
  balance: number;
};
export default function Amount({ currency, balance }: AmountProps) {
  const SCOPE_OPTIONS = {
    scope: 'pages.wallet.balance',
  };
  const { t } = useLocale();
  const formatedBalance = useMemo(() => (balance ? balance / 100 : 0), [balance]);
  const [openModal, setOpenModal] = useState(false);
  return (
    <div className="flex items-center gap-15">
      <div className="flex flex-col gap-3 text-center">
        <div className="text-2xl font-medium">
          {formatedBalance} {currency}
        </div>
        <div className="text-sm text-gray-500">{t('availableBalance', SCOPE_OPTIONS)}</div>
      </div>
      <Button className="w-max" label={t('deposit', SCOPE_OPTIONS)} theme="blue" onClick={() => setOpenModal(true)} />
      <Wallet.DepositModal openModal={openModal} setOpenModal={setOpenModal} currency={currency} />
    </div>
  );
}

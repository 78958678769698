import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { CampaignsBranchApi } from '@app/api';
import { date } from '@app/lib';
import { useAuth, useAxios } from '@app/hooks';

export function showBranchCampaignSearchTermInsights(
  branchId: string,
  campaignId: string,
  startDate: string,
  endDate: string,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new CampaignsBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.showBranchCampaignSearchTermInsights(branchId, campaignId, startDate, endDate).then((res) => res.data);
}

export default function useShowBranchCampaignSearchTermInsights({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();
  const formatedStartDate = date.format(date.JSDateToISO(params.startDate), 'yyyy-MM-dd');
  const formatedEndDate = date.format(date.JSDateToISO(params.endDate), 'yyyy-MM-dd');

  return useQuery(
    ['useShowBranchCampaignSearchTermInsights', branchId, params.campaignId, formatedStartDate, formatedEndDate],
    () => showBranchCampaignSearchTermInsights(branchId, params.campaignId, formatedStartDate, formatedEndDate, axiosInstance),
    props
  );
}

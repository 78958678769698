import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    version="1.1"
    id="katman_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1080 1080"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path
        d="M273.57,519.89c7.77-21.2,23.16-37.13,37.59-53.6c16.75-19.11,35.98-35.57,56.75-50.21c1.1-0.78,2.11-1.69,3.57-2.86
      c-10.22-10.12-20.33-19.86-30.12-29.91c-7.14-7.33-7.39-18.33-1.11-26.09c5.95-7.34,16.71-9.48,24.91-4.73
      c2.21,1.28,4.2,3.07,6.02,4.89c92.49,92.43,184.94,184.89,277.4,277.34c19.99,19.99,39.95,40,60,59.92
      c5.69,5.66,8.7,12.13,6.75,20.16c-3.69,15.22-21.88,20.37-33.21,9.26c-15.11-14.81-30.01-29.83-44.86-44.89
      c-2.27-2.3-4.16-2.7-7.23-1.73c-85.3,26.93-166.29,16.43-242.92-28.86c-43.22-25.54-78.36-59.88-106.5-101.36
      c-3.04-4.48-4.72-9.87-7.03-14.84C273.57,528.22,273.57,524.05,273.57,519.89z M438.27,480.29c-17.87,35.17-14.12,89.2,23.2,125.83
      c36.79,36.11,90.22,39.23,124.33,21.7c-9.89-9.88-19.62-19.68-29.52-29.32c-0.85-0.83-2.85-0.91-4.22-0.71
      c-10.25,1.51-20.38,1.18-30.41-1.5c-36.16-9.64-58.06-42.96-53.46-81.24c0.21-1.73,0.36-4.21-0.62-5.23
      C457.98,499.88,448.16,490.18,438.27,480.29z"
      />
      <path
        d="M708.42,638.74c-7.94-7.95-15.63-15.67-23.34-23.38c-13.24-13.24-26.44-26.52-39.76-39.68c-2.2-2.18-2.94-3.82-1.66-7.09
      c26.13-66.54-11.76-137.3-81.58-152.34c-21.95-4.73-43.4-2.29-64.29,5.83c-1.73,0.67-4.8,0.9-5.84-0.11
      c-15.27-14.87-30.29-29.98-46.3-45.96c8.53-2.51,16.11-5.13,23.88-6.95c99.09-23.2,186.86,0.73,264.91,64.18
      c25.18,20.47,46.45,44.64,64.75,71.42c9.57,14.01,9.67,28.69,0.18,42.68C774.95,583.32,744.81,613.61,708.42,638.74z"
      />
      <path
        d="M610.58,539.88c-28.28-28.29-56.3-56.33-84.24-84.29c14.62-7.12,46.13,1.66,63.2,17.5
      C610.97,492.98,616.05,523.62,610.58,539.88z"
      />
    </g>
  </svg>
);

export default SvgComponent;

import React from 'react';

import { useFormContext } from 'react-hook-form';

import { Form as FormComponents } from '@app/components';
import { useLocale } from '@app/hooks';
import { branch as branchLib, phoneNumber } from '@app/lib';

type LegalFormPropTypes = {
  legalInfo: {
    legal_name: string;
    legal_address: string;
    legal_phone_number: number;
    legal_crs_number: string;
  };
  featureInfo: {
    has_custom_lead_privacy_policy_feature: boolean;
  };
};

const LegalForm: React.FC<LegalFormPropTypes> = ({ legalInfo, featureInfo }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Branch.LegalForm',
  };
  const { t } = useLocale();
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();
  const isValid = branchLib.shouldValidateLegalForm(featureInfo);

  return (
    <form className="py-5 rounded-3">
      <div className="flex justify-between items-center w-full mb-5">
        <span className="block text-3.5 font-semibold text-gray-900">{t('form.labels.title', SCOPE_OPTIONS)}</span>
      </div>
      <div className="mb-5">
        <span className="block text-3.5 text-gray-500">{t('form.labels.infoText', SCOPE_OPTIONS)}</span>
      </div>
      <FormComponents.Input
        type="text"
        id="legal_name"
        label={t('form.companyName.label', SCOPE_OPTIONS)}
        className="mb-2.5 bg-white h-30 p-4 rounded-3.5 shadow-sm"
        {...register('legal_information.legal_name', {
          required: {
            value: isValid,
            message: t('form.companyName.errors.required', SCOPE_OPTIONS),
          },
        })}
        defaultValue={legalInfo?.legal_name}
        requiredSign={isValid}
        error={errors['legal_information']?.legal_name}
      />
      <FormComponents.Input
        type="text"
        id="legal_address"
        label={t('form.companyAddress.label', SCOPE_OPTIONS)}
        className="mb-2.5 bg-white h-30 p-4 rounded-3.5 shadow-sm"
        {...register('legal_information.legal_address', {
          required: {
            value: isValid,
            message: t('form.companyAddress.errors.required', SCOPE_OPTIONS),
          },
        })}
        defaultValue={legalInfo?.legal_address}
        requiredSign={isValid}
        error={errors['legal_information']?.legal_address}
      />
      <FormComponents.PhoneInput
        className="mb-2.5 bg-white h-30 p-4 rounded-3.5 shadow-sm"
        control={control}
        name="legal_information.legal_phone_number"
        label={t('form.companyPhoneNumber.label', SCOPE_OPTIONS)}
        id="legal_phone_number"
        rules={{
          required: {
            value: isValid,
            message: t('form.companyPhoneNumber.errors.required', SCOPE_OPTIONS),
          },
          validate: (phoneNumberString: string) => {
            if (phoneNumber.isValid(phoneNumberString)) {
              return true;
            }

            return t('form.companyPhoneNumber.errors.validate', SCOPE_OPTIONS);
          },
        }}
        defaultValue={legalInfo?.legal_phone_number}
        requiredSign={isValid}
        error={errors['legal_information']?.legal_phone_number}
      />
      <FormComponents.Input
        type="text"
        id="legal_crs_number"
        label={t('form.crsNumber.label', SCOPE_OPTIONS)}
        className="mb-2.5 bg-white h-30 p-4 rounded-3.5 shadow-sm"
        {...register('legal_information.legal_crs_number', {
          required: {
            value: isValid,
            message: t('form.crsNumber.errors.required', SCOPE_OPTIONS),
          },
          maxLength: { value: 16, message: t('form.crsNumber.errors.length', SCOPE_OPTIONS) },
          minLength: { value: 16, message: t('form.crsNumber.errors.length', SCOPE_OPTIONS) },
        })}
        defaultValue={legalInfo?.legal_crs_number}
        requiredSign={isValid}
        error={errors['legal_information']?.legal_crs_number}
      />
    </form>
  );
};

export default LegalForm;

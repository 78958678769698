import React from 'react';

import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import { Icons } from '@app/components';
import { useLocale } from '@app/hooks';
import type { BranchCampaign as CampaignType } from '@app/api';
import axios from 'axios';

type DownloadImagesPropTypes = {
  campaign: CampaignType;
};

const DownloadImages: React.FC<DownloadImagesPropTypes> = ({ campaign }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.PreviewGroup.DownloadImages',
  };
  const { t } = useLocale();

  async function handleDownloadWithZip() {
    const zip = new JSZip();
    const attachments = campaign.branch_campaign_attachments;
    if (!attachments) {
      return;
    }
    for (const attachment of attachments) {
      if (!attachment?.file?.url) {
        continue;
      }

      const blobImage = await axios({
        method: 'GET',
        url: `${attachment?.file?.url}?not-from-cache-please`,
        responseType: 'blob',
      });

      zip.file(`creative-${attachment.id}.png`, blobImage.data);
    }

    zip.generateAsync({ type: 'blob' }).then(function (content: any) {
      saveAs(content, `${campaign.id}-creatives.zip`);
    });
  }
  return (
    <button
      className="flex items-center space-x-2 border border-black-800 hover:border-gray-600 hover:text-gray-600 px-3 py-2 w-64 rounded-2 mt-3"
      onClick={handleDownloadWithZip}
    >
      <Icons.Export className="w-5 h-5" />
      <span className="text-3.5"> {t('actions.downloadImages', SCOPE_OPTIONS)}</span>
    </button>
  );
};

export default DownloadImages;

import React from 'react';

import cn from 'classnames';

import { useLocale } from '@app/hooks';
import { date as dateLib } from '@app/lib';
import { SCREEN_MODES } from '@app/constants';
import { Icons, LoaderSkeleton } from '@app/components';

type FacebookPropTypes = {
  accountName: string;
  message?: string;
  description: string;
  image: string;
  screen: string;
  date: string;
};

const Facebook: React.FC<FacebookPropTypes> = ({ accountName, image, message, description, screen, date }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.SocialMediaManagement.PostPreview.Facebook',
  };
  const { t } = useLocale();

  return (
    <div className="bg-white select-none p-2 w-full">
      <div className="flex flex-row h-12">
        <div className="flex w-10 rounded-full justify-center bg-gray-200 self-center aspect-square">
          <Icons.FacebookIcon className="w-8 h-8" color="#3b82f6" />
        </div>

        <span className="flex flex-row justify-between w-full">
          <div className="flex flex-col ml-2 align-center self-center">
            <span className="font-bold text-3.5 text-gray-900 capitalize">{accountName}</span>
            <span className="leading-none text-gray-500 text-2.5 capitalize">
              {screen === SCREEN_MODES.MOBILE ? t('labels.sponsor', SCOPE_OPTIONS) : dateLib.format(date, 'dd MMMM, HH:mm')}
            </span>
          </div>
          <div className="flex self-center align-center">
            {screen === SCREEN_MODES.DESKTOP && <Icons.ChevronDown className="w-3.5 h-3.5 ml-4" />}
          </div>
        </span>
      </div>

      {description && <div className="py-1 h-auto line-clamp-2 text-black-900 text-3">{description}</div>}
      <div className="flex my-1 justify-center aspect-1">
        {image ? (
          <img src={image} alt="feed_image" className={cn({ 'object-cover': screen === SCREEN_MODES.MOBILE })} height="100%" />
        ) : (
          <LoaderSkeleton.SkeletonLoaderFeed />
        )}
      </div>

      <div className="flex flex-row items-center justify-between pb-3 mt-3 px-2">
        <div className="flex flex-row justify-start ">
          <span className="leading-none text-gray-700 text-3.5 font-medium ">
            <Icons.Like className="w-4 h-4" />
          </span>
          <span className="leading-none text-gray-700 text-3.5 ml-2">{t('labels.like', SCOPE_OPTIONS)}</span>
        </div>
        <div className="flex flex-row justify-center">
          <span className="leading-none text-gray-700 text-3.5 ">
            <Icons.Comment className="w-4 h-4" />
          </span>
          <span className="leading-none text-gray-700 text-3.5 ml-2">{t('labels.comment', SCOPE_OPTIONS)}</span>
        </div>
        <div className="flex flex-row justify-end">
          <span className="leading-none text-gray-700 text-3.5">
            <Icons.Share className="w-4 h-4" />
          </span>
          <span className="leading-none text-gray-700 text-3.5 ml-2">{t('labels.share', SCOPE_OPTIONS)}</span>
        </div>
      </div>

      {screen === SCREEN_MODES.MOBILE && (
        <div className="flex flex-row items-center justify-between py-2 px-2 border-gray-300 h-auto">
          <div className="line-clamp-2 text-black-900 text-3.5">{message}</div>
        </div>
      )}
    </div>
  );
};

export default Facebook;

import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="#fff"
      d="M7.25 7A.75.75 0 018 6.25h8a.75.75 0 010 1.5H8A.75.75 0 017.25 7zM8 9.75a.75.75 0 000 1.5h5a.75.75 0 000-1.5H8z"
    ></path>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M9.945 1.25c-1.367 0-2.47 0-3.337.117-.9.12-1.658.38-2.26.981-.602.602-.86 1.36-.981 2.26-.117.867-.117 1.97-.117 3.337v8.11c0 1.367 0 2.47.117 3.337.12.9.38 1.658.981 2.26.602.602 1.36.86 2.26.982.867.116 1.97.116 3.337.116h4.11c1.367 0 2.47 0 3.337-.116.9-.122 1.658-.38 2.26-.982.602-.602.86-1.36.982-2.26.116-.867.116-1.97.116-3.337v-8.11c0-1.367 0-2.47-.116-3.337-.122-.9-.38-1.658-.982-2.26-.602-.602-1.36-.86-2.26-.981-.867-.117-1.97-.117-3.337-.117h-4.11zM5.41 3.409c.277-.277.665-.457 1.4-.556.754-.101 1.756-.103 3.191-.103h4c1.435 0 2.436.002 3.192.103.734.099 1.122.28 1.399.556.277.277.457.665.556 1.4.101.754.103 1.756.103 3.191v7.25H7.782c-.818 0-1.376 0-1.855.128a3.748 3.748 0 00-1.177.548V8c0-1.435.002-2.437.103-3.192.099-.734.28-1.122.556-1.399zm-.632 14.84c.015.354.039.665.076.943.099.734.28 1.122.556 1.399.277.277.665.457 1.4.556.754.101 1.756.103 3.191.103h4c1.435 0 2.436-.002 3.192-.103.734-.099 1.122-.28 1.399-.556.277-.277.457-.665.556-1.4.083-.615.099-1.395.102-2.441H7.898c-.978 0-1.32.006-1.583.077a2.25 2.25 0 00-1.538 1.422z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default SvgComponent;

const CAMPAIGN_TYPES = Object.freeze({
  WHATSAPP_MESSAGE: 'whatsappMessage',
  MESSENGER: 'messenger',
  CALL: 'call',
  ALL_MESSAGE: 'all_message',
  FINAL_URL: 'finalUrl',
  YEMEKSEPETI: 'yemeksepeti',
  INSTAGRAM_VISIT: 'instagramVisit',
  LEAD_GENERATION: 'leadGeneration',
  PMAX: 'pmax',
});

export default CAMPAIGN_TYPES;

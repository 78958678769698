import { Icons } from '@app/components';

type CustomDateInputType = {
  value?: string;
  onClick?: () => void;
  placeholderText?: string;
};

const CustomDateInput: React.FC<CustomDateInputType> = ({ value, onClick, placeholderText }) => {
  return (
    <div
      className="min-w-50 h-10 flex border border-black-800 rounded-2 items-center text-center space-x-4 px-2 py-1.5 justify-between"
      onClick={onClick}
      aria-hidden="true"
    >
      <span className="flex items-center space-x-1.5">
        <Icons.Date className="w-5 h-5" />
        <span className="text-3.5">{value ? value : placeholderText}</span>
      </span>
      {!value && <Icons.ChevronDown className="w-5 h-5" />}
    </div>
  );
};

export default CustomDateInput;

import React from 'react';

import { useFormContext } from 'react-hook-form';
import cn from 'classnames';

import { Box, Form, Icons } from '@app/components';
import { array } from '@app/lib';
import { PUBLISHER_PLATFORMS } from '@app/constants';
import { useLocale } from '@app/hooks';

type AccountTypes = { image?: string; name: string; value: string; platform: string; instagram_business_account_id: string | null };

type SocialMediaPropTypes = {
  containerClassName?: string;
  options: Array<AccountTypes>;
  className?: string;
  hasMainOption?: boolean;
  rules?: any;
  instagramPages: { id: string; instagram_business_account_id: string | null }[];
  setInstagramPages: (data: { id: string; instagram_business_account_id: string | null }) => void;
};

const SocialMedia: React.FC<SocialMediaPropTypes> = ({ options, instagramPages, setInstagramPages }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.SocialMediaManagement.SocialMedia',
  };
  const { t } = useLocale();
  const {
    register,
    formState: { errors },
    clearErrors,
    watch,
  } = useFormContext();
  const facebookIdsWatch = watch('targeting_platforms.facebook_page_ids');

  function handleInstagramPages(pageId: string, isChecked: boolean, platform: string) {
    if (platform === PUBLISHER_PLATFORMS.FACEBOOK) {
      return;
    }
    const selectedPage = options.find((item) => item.value === pageId);

    if (isChecked) {
      setInstagramPages([
        ...instagramPages,
        { id: selectedPage?.value, instagram_business_account_id: selectedPage?.instagram_business_account_id },
      ] as any);
      return;
    }

    const filteredData = instagramPages.filter((item) => item.id !== pageId);
    setInstagramPages(filteredData as any);
  }

  return (
    <Box className="bg-white px-5">
      <div className="flex-none items-center justify-between">
        <span className="block text-3.5 font-semibold text-gray-900 mb-2">{t('label', SCOPE_OPTIONS)}</span>
        {errors['targeting_platforms'] && (
          <div className="flex items-center">
            <span className="inline text-red-400 text-3">{t('errors.required', SCOPE_OPTIONS)}</span>
          </div>
        )}
      </div>
      <div className="grid grid-cols-3 mt-3">
        {!array.isEmpty(options) &&
          options.map((option, index) => (
            <div
              key={index}
              className={cn('flex justify-between items-center px-2', { 'border-r border-gray-300': !array.isLastItem(options, option) })}
            >
              <div className="flex items-center gap-2 relative">
                <img src={option.image} alt="profile" className="object-cover rounded-full w-10 h-10 bg-gray-400" />
                <div className="rounded-full w-5 h-5 flex items-center justify-center bg-white bottom-0 left-7 absolute shadow-md ">
                  {option.platform === PUBLISHER_PLATFORMS.FACEBOOK ? (
                    <Icons.Facebook className="w-3 h-3" color="#3b82f6" />
                  ) : (
                    <Icons.Instagram
                      className="w-3 h-3"
                      color="linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)"
                    />
                  )}
                </div>
                <span className="block text-3 font-semibold text-gray-900">{option.name}</span>
              </div>
              <div className="account-checkbox">
                <Form.Checkbox
                  id={option.value}
                  {...register(`targeting_platforms.${option.platform}_page_ids`, {
                    required: array.isEmpty(instagramPages) && array.isEmpty(facebookIdsWatch),
                    setValueAs: () => {
                      if (array.isEmpty(instagramPages) && array.isEmpty(facebookIdsWatch)) {
                        return [];
                      }
                    },
                    onChange: (e) => {
                      if (e.target.value) {
                        clearErrors('targeting_platforms');
                        handleInstagramPages(e.target.value, e.target.checked, option.platform);
                      }
                    },
                  })}
                  value={Number(option.value)}
                  error={errors['targeting_platforms']}
                />
              </div>
            </div>
          ))}
      </div>
    </Box>
  );
};

export default SocialMedia;

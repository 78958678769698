/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FacebookAdTargetingCategoryErrorResponse } from '../model';
// @ts-ignore
import { FacebookAdTargetingCategorySuccessResponse } from '../model';
// @ts-ignore
import { FacebookCitiesListResponse } from '../model';
// @ts-ignore
import { FacebookCountriesListResponse } from '../model';
// @ts-ignore
import { FacebookGeneratePreviewInput } from '../model';
// @ts-ignore
import { FacebookGeneratePreviewSuccessResponse } from '../model';
// @ts-ignore
import { FacebookInterestsListResponse } from '../model';
// @ts-ignore
import { FacebookLocalesListResponse } from '../model';
// @ts-ignore
import { FacebookReachEstimateErrorResponse } from '../model';
// @ts-ignore
import { FacebookReachEstimateInput } from '../model';
// @ts-ignore
import { FacebookReachEstimateSuccessResponse } from '../model';
/**
 * FacebookBranchApi - axios parameter creator
 * @export
 */
export const FacebookBranchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Calculate reach estimate of facebook with given params
         * @summary Calculate reach estimate of facebook with given params
         * @param {string} branchId The id of the branch
         * @param {FacebookReachEstimateInput} facebookReachEstimateInput Targeting params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateBranchFacebookReachEstimate: async (branchId: string, facebookReachEstimateInput: FacebookReachEstimateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('calculateBranchFacebookReachEstimate', 'branchId', branchId)
            // verify required parameter 'facebookReachEstimateInput' is not null or undefined
            assertParamExists('calculateBranchFacebookReachEstimate', 'facebookReachEstimateInput', facebookReachEstimateInput)
            const localVarPath = `/branch_account/branches/{branch_id}/facebook/reach_estimate`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(facebookReachEstimateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate facebook ad preview
         * @summary Generate facebook ad preview
         * @param {string} branchId The id of the branch
         * @param {FacebookGeneratePreviewInput} facebookGeneratePreviewInput Preview params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBranchFacebookPreview: async (branchId: string, facebookGeneratePreviewInput: FacebookGeneratePreviewInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('generateBranchFacebookPreview', 'branchId', branchId)
            // verify required parameter 'facebookGeneratePreviewInput' is not null or undefined
            assertParamExists('generateBranchFacebookPreview', 'facebookGeneratePreviewInput', facebookGeneratePreviewInput)
            const localVarPath = `/branch_account/branches/{branch_id}/facebook/generate_preview`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(facebookGeneratePreviewInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return ad targeting categories of facebook
         * @summary Return ad targeting categories of facebook
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBranchFacebookAdTargetingCategories: async (branchId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('listBranchFacebookAdTargetingCategories', 'branchId', branchId)
            const localVarPath = `/branch_account/branches/{branch_id}/facebook/ad_targeting_categories`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return cities of facebook
         * @summary Return cities of facebook
         * @param {string} branchId The id of the branch
         * @param {string} query City name
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBranchFacebookCities: async (branchId: string, query: string, perPage?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('listBranchFacebookCities', 'branchId', branchId)
            // verify required parameter 'query' is not null or undefined
            assertParamExists('listBranchFacebookCities', 'query', query)
            const localVarPath = `/branch_account/branches/{branch_id}/facebook/cities`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return countries of facebook
         * @summary Return countries of facebook
         * @param {string} branchId The id of the branch
         * @param {string} query Country name
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBranchFacebookCountries: async (branchId: string, query: string, perPage?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('listBranchFacebookCountries', 'branchId', branchId)
            // verify required parameter 'query' is not null or undefined
            assertParamExists('listBranchFacebookCountries', 'query', query)
            const localVarPath = `/branch_account/branches/{branch_id}/facebook/countries`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return interests of facebook
         * @summary Return interests of facebook
         * @param {string} branchId The id of the branch
         * @param {string} query Interest name
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBranchFacebookInterests: async (branchId: string, query: string, perPage?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('listBranchFacebookInterests', 'branchId', branchId)
            // verify required parameter 'query' is not null or undefined
            assertParamExists('listBranchFacebookInterests', 'query', query)
            const localVarPath = `/branch_account/branches/{branch_id}/facebook/interests`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return locales of facebook
         * @summary Return locales of facebook
         * @param {string} branchId The id of the branch
         * @param {number} [perPage] Per page limit
         * @param {string} [query] Locale name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBranchFacebookLocales: async (branchId: string, perPage?: number, query?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('listBranchFacebookLocales', 'branchId', branchId)
            const localVarPath = `/branch_account/branches/{branch_id}/facebook/locales`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FacebookBranchApi - functional programming interface
 * @export
 */
export const FacebookBranchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FacebookBranchApiAxiosParamCreator(configuration)
    return {
        /**
         * Calculate reach estimate of facebook with given params
         * @summary Calculate reach estimate of facebook with given params
         * @param {string} branchId The id of the branch
         * @param {FacebookReachEstimateInput} facebookReachEstimateInput Targeting params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateBranchFacebookReachEstimate(branchId: string, facebookReachEstimateInput: FacebookReachEstimateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookReachEstimateSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateBranchFacebookReachEstimate(branchId, facebookReachEstimateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generate facebook ad preview
         * @summary Generate facebook ad preview
         * @param {string} branchId The id of the branch
         * @param {FacebookGeneratePreviewInput} facebookGeneratePreviewInput Preview params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateBranchFacebookPreview(branchId: string, facebookGeneratePreviewInput: FacebookGeneratePreviewInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookGeneratePreviewSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateBranchFacebookPreview(branchId, facebookGeneratePreviewInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return ad targeting categories of facebook
         * @summary Return ad targeting categories of facebook
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBranchFacebookAdTargetingCategories(branchId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookAdTargetingCategorySuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBranchFacebookAdTargetingCategories(branchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return cities of facebook
         * @summary Return cities of facebook
         * @param {string} branchId The id of the branch
         * @param {string} query City name
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBranchFacebookCities(branchId: string, query: string, perPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookCitiesListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBranchFacebookCities(branchId, query, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return countries of facebook
         * @summary Return countries of facebook
         * @param {string} branchId The id of the branch
         * @param {string} query Country name
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBranchFacebookCountries(branchId: string, query: string, perPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookCountriesListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBranchFacebookCountries(branchId, query, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return interests of facebook
         * @summary Return interests of facebook
         * @param {string} branchId The id of the branch
         * @param {string} query Interest name
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBranchFacebookInterests(branchId: string, query: string, perPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookInterestsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBranchFacebookInterests(branchId, query, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return locales of facebook
         * @summary Return locales of facebook
         * @param {string} branchId The id of the branch
         * @param {number} [perPage] Per page limit
         * @param {string} [query] Locale name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBranchFacebookLocales(branchId: string, perPage?: number, query?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookLocalesListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBranchFacebookLocales(branchId, perPage, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FacebookBranchApi - factory interface
 * @export
 */
export const FacebookBranchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FacebookBranchApiFp(configuration)
    return {
        /**
         * Calculate reach estimate of facebook with given params
         * @summary Calculate reach estimate of facebook with given params
         * @param {string} branchId The id of the branch
         * @param {FacebookReachEstimateInput} facebookReachEstimateInput Targeting params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateBranchFacebookReachEstimate(branchId: string, facebookReachEstimateInput: FacebookReachEstimateInput, options?: any): AxiosPromise<FacebookReachEstimateSuccessResponse> {
            return localVarFp.calculateBranchFacebookReachEstimate(branchId, facebookReachEstimateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate facebook ad preview
         * @summary Generate facebook ad preview
         * @param {string} branchId The id of the branch
         * @param {FacebookGeneratePreviewInput} facebookGeneratePreviewInput Preview params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBranchFacebookPreview(branchId: string, facebookGeneratePreviewInput: FacebookGeneratePreviewInput, options?: any): AxiosPromise<FacebookGeneratePreviewSuccessResponse> {
            return localVarFp.generateBranchFacebookPreview(branchId, facebookGeneratePreviewInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Return ad targeting categories of facebook
         * @summary Return ad targeting categories of facebook
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBranchFacebookAdTargetingCategories(branchId: string, options?: any): AxiosPromise<FacebookAdTargetingCategorySuccessResponse> {
            return localVarFp.listBranchFacebookAdTargetingCategories(branchId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return cities of facebook
         * @summary Return cities of facebook
         * @param {string} branchId The id of the branch
         * @param {string} query City name
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBranchFacebookCities(branchId: string, query: string, perPage?: number, options?: any): AxiosPromise<FacebookCitiesListResponse> {
            return localVarFp.listBranchFacebookCities(branchId, query, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Return countries of facebook
         * @summary Return countries of facebook
         * @param {string} branchId The id of the branch
         * @param {string} query Country name
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBranchFacebookCountries(branchId: string, query: string, perPage?: number, options?: any): AxiosPromise<FacebookCountriesListResponse> {
            return localVarFp.listBranchFacebookCountries(branchId, query, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Return interests of facebook
         * @summary Return interests of facebook
         * @param {string} branchId The id of the branch
         * @param {string} query Interest name
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBranchFacebookInterests(branchId: string, query: string, perPage?: number, options?: any): AxiosPromise<FacebookInterestsListResponse> {
            return localVarFp.listBranchFacebookInterests(branchId, query, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Return locales of facebook
         * @summary Return locales of facebook
         * @param {string} branchId The id of the branch
         * @param {number} [perPage] Per page limit
         * @param {string} [query] Locale name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBranchFacebookLocales(branchId: string, perPage?: number, query?: string, options?: any): AxiosPromise<FacebookLocalesListResponse> {
            return localVarFp.listBranchFacebookLocales(branchId, perPage, query, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FacebookBranchApi - interface
 * @export
 * @interface FacebookBranchApi
 */
export interface FacebookBranchApiInterface {
    /**
     * Calculate reach estimate of facebook with given params
     * @summary Calculate reach estimate of facebook with given params
     * @param {string} branchId The id of the branch
     * @param {FacebookReachEstimateInput} facebookReachEstimateInput Targeting params
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookBranchApiInterface
     */
    calculateBranchFacebookReachEstimate(branchId: string, facebookReachEstimateInput: FacebookReachEstimateInput, options?: any): AxiosPromise<FacebookReachEstimateSuccessResponse>;

    /**
     * Generate facebook ad preview
     * @summary Generate facebook ad preview
     * @param {string} branchId The id of the branch
     * @param {FacebookGeneratePreviewInput} facebookGeneratePreviewInput Preview params
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookBranchApiInterface
     */
    generateBranchFacebookPreview(branchId: string, facebookGeneratePreviewInput: FacebookGeneratePreviewInput, options?: any): AxiosPromise<FacebookGeneratePreviewSuccessResponse>;

    /**
     * Return ad targeting categories of facebook
     * @summary Return ad targeting categories of facebook
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookBranchApiInterface
     */
    listBranchFacebookAdTargetingCategories(branchId: string, options?: any): AxiosPromise<FacebookAdTargetingCategorySuccessResponse>;

    /**
     * Return cities of facebook
     * @summary Return cities of facebook
     * @param {string} branchId The id of the branch
     * @param {string} query City name
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookBranchApiInterface
     */
    listBranchFacebookCities(branchId: string, query: string, perPage?: number, options?: any): AxiosPromise<FacebookCitiesListResponse>;

    /**
     * Return countries of facebook
     * @summary Return countries of facebook
     * @param {string} branchId The id of the branch
     * @param {string} query Country name
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookBranchApiInterface
     */
    listBranchFacebookCountries(branchId: string, query: string, perPage?: number, options?: any): AxiosPromise<FacebookCountriesListResponse>;

    /**
     * Return interests of facebook
     * @summary Return interests of facebook
     * @param {string} branchId The id of the branch
     * @param {string} query Interest name
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookBranchApiInterface
     */
    listBranchFacebookInterests(branchId: string, query: string, perPage?: number, options?: any): AxiosPromise<FacebookInterestsListResponse>;

    /**
     * Return locales of facebook
     * @summary Return locales of facebook
     * @param {string} branchId The id of the branch
     * @param {number} [perPage] Per page limit
     * @param {string} [query] Locale name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookBranchApiInterface
     */
    listBranchFacebookLocales(branchId: string, perPage?: number, query?: string, options?: any): AxiosPromise<FacebookLocalesListResponse>;

}

/**
 * FacebookBranchApi - object-oriented interface
 * @export
 * @class FacebookBranchApi
 * @extends {BaseAPI}
 */
export class FacebookBranchApi extends BaseAPI implements FacebookBranchApiInterface {
    /**
     * Calculate reach estimate of facebook with given params
     * @summary Calculate reach estimate of facebook with given params
     * @param {string} branchId The id of the branch
     * @param {FacebookReachEstimateInput} facebookReachEstimateInput Targeting params
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookBranchApi
     */
    public calculateBranchFacebookReachEstimate(branchId: string, facebookReachEstimateInput: FacebookReachEstimateInput, options?: any) {
        return FacebookBranchApiFp(this.configuration).calculateBranchFacebookReachEstimate(branchId, facebookReachEstimateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate facebook ad preview
     * @summary Generate facebook ad preview
     * @param {string} branchId The id of the branch
     * @param {FacebookGeneratePreviewInput} facebookGeneratePreviewInput Preview params
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookBranchApi
     */
    public generateBranchFacebookPreview(branchId: string, facebookGeneratePreviewInput: FacebookGeneratePreviewInput, options?: any) {
        return FacebookBranchApiFp(this.configuration).generateBranchFacebookPreview(branchId, facebookGeneratePreviewInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return ad targeting categories of facebook
     * @summary Return ad targeting categories of facebook
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookBranchApi
     */
    public listBranchFacebookAdTargetingCategories(branchId: string, options?: any) {
        return FacebookBranchApiFp(this.configuration).listBranchFacebookAdTargetingCategories(branchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return cities of facebook
     * @summary Return cities of facebook
     * @param {string} branchId The id of the branch
     * @param {string} query City name
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookBranchApi
     */
    public listBranchFacebookCities(branchId: string, query: string, perPage?: number, options?: any) {
        return FacebookBranchApiFp(this.configuration).listBranchFacebookCities(branchId, query, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return countries of facebook
     * @summary Return countries of facebook
     * @param {string} branchId The id of the branch
     * @param {string} query Country name
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookBranchApi
     */
    public listBranchFacebookCountries(branchId: string, query: string, perPage?: number, options?: any) {
        return FacebookBranchApiFp(this.configuration).listBranchFacebookCountries(branchId, query, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return interests of facebook
     * @summary Return interests of facebook
     * @param {string} branchId The id of the branch
     * @param {string} query Interest name
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookBranchApi
     */
    public listBranchFacebookInterests(branchId: string, query: string, perPage?: number, options?: any) {
        return FacebookBranchApiFp(this.configuration).listBranchFacebookInterests(branchId, query, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return locales of facebook
     * @summary Return locales of facebook
     * @param {string} branchId The id of the branch
     * @param {number} [perPage] Per page limit
     * @param {string} [query] Locale name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookBranchApi
     */
    public listBranchFacebookLocales(branchId: string, perPage?: number, query?: string, options?: any) {
        return FacebookBranchApiFp(this.configuration).listBranchFacebookLocales(branchId, perPage, query, options).then((request) => request(this.axios, this.basePath));
    }
}

import useLocale from '@app/hooks/useLocale';
import React from 'react';

type CardPropTypes = {
  name?: string;
  company: string;
  onDeleteClick?: () => void;
  isLoading?: boolean;
};

const Card: React.FC<CardPropTypes> = ({ name, company, onDeleteClick, isLoading }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Integration.Card',
  };
  const { t } = useLocale();
  return (
    <div className="flex flex-row items-center justify-between gap-4 bg-white py-4 px-6 rounded-3 max-w-110 w-full">
      <div className="flex flex-col">
        <h2 className="font-semibold text-5.5 text-blue-300 truncate max-w-65">{name ?? '-'}</h2>
        <p className="font-semibold text-3.5 text-gray-500">{company}</p>
      </div>
      {onDeleteClick && (
        <div>
          <button disabled={isLoading} className="font-semibold text-3.5 text-gray-500 underline" onClick={onDeleteClick}>
            {t('labels.button.disconnect', SCOPE_OPTIONS)}
          </button>
        </div>
      )}
    </div>
  );
};

export default Card;

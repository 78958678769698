import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { FacebookBranchApi } from '@app/api';
import type { FacebookReachEstimateInput } from '@app/api';

import { useAuth, useAxios } from '@app/hooks';

export function calculateBranchFacebookReachEstimate(
  branchId: string,
  facebookReachEstimateInput: FacebookReachEstimateInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new FacebookBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.calculateBranchFacebookReachEstimate(branchId, facebookReachEstimateInput).then((res) => res.data);
}

export default function useCalculateBranchFacebookReachEstimate({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useQuery(
    ['useCalculateBranchFacebookReachEstimate', branchId, params],
    () => calculateBranchFacebookReachEstimate(branchId, params, axiosInstance),
    props
  );
}

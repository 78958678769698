import React, { Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import cn from 'classnames';

type ModalPropTypes = {
  isOpen: boolean;
  onClose: () => void;
  isSuccessModal?: boolean;
  wfull?: boolean;
};

const Modal: React.FC<ModalPropTypes> = ({ children, isOpen, onClose, isSuccessModal, wfull }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={cn('fixed inset-0 z-999 overflow-y-auto', {
          'w-122 m-auto': isSuccessModal,
          '!z-999': isSuccessModal,
        })}
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-blue-900 bg-opacity-40" />
          </Transition.Child>
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={cn(
                'inline-block w-full max-w-180 my-8 overflow-hidden text-left align-middle transition-all transform rounded-3 bg-white',
                { '!rounded-8': isSuccessModal, 'w-[450px]': !wfull }
              )}
            >
              {children && children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;

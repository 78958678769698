/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GeoTargetsResponse } from '../model';
// @ts-ignore
import { GoogleFeedItemSetsResponse } from '../model';
// @ts-ignore
import { KeywordThemesResponse } from '../model';
// @ts-ignore
import { KeywordsResponse } from '../model';
/**
 * GoogleBranchApi - axios parameter creator
 * @export
 */
export const GoogleBranchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Return feed item sets of google
         * @summary Return feed item sets of google
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGoogleFeedItemSets: async (branchId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('listGoogleFeedItemSets', 'branchId', branchId)
            const localVarPath = `/branch_account/branches/{branch_id}/google/feed_item_sets`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return geo target locations of google
         * @summary Return geo target locations of google
         * @param {string} branchId The id of the branch
         * @param {string} query City name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGoogleGeoTargets: async (branchId: string, query: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('listGoogleGeoTargets', 'branchId', branchId)
            // verify required parameter 'query' is not null or undefined
            assertParamExists('listGoogleGeoTargets', 'query', query)
            const localVarPath = `/branch_account/branches/{branch_id}/google/geo_targets`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return keywords of google
         * @summary Return keywords of google
         * @param {string} branchId The id of the branch
         * @param {string} [query] Keyword
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGoogleKeywords: async (branchId: string, query?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('listGoogleKeywords', 'branchId', branchId)
            const localVarPath = `/branch_account/branches/{branch_id}/google/keywords`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return suggested keyword themes of google
         * @summary Return suggested keyword themes of google
         * @param {string} branchId The id of the branch
         * @param {string} query Keyword name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSuggestedGoogleKeywordThemes: async (branchId: string, query: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('listSuggestedGoogleKeywordThemes', 'branchId', branchId)
            // verify required parameter 'query' is not null or undefined
            assertParamExists('listSuggestedGoogleKeywordThemes', 'query', query)
            const localVarPath = `/branch_account/branches/{branch_id}/google/keyword_themes`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GoogleBranchApi - functional programming interface
 * @export
 */
export const GoogleBranchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GoogleBranchApiAxiosParamCreator(configuration)
    return {
        /**
         * Return feed item sets of google
         * @summary Return feed item sets of google
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGoogleFeedItemSets(branchId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoogleFeedItemSetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGoogleFeedItemSets(branchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return geo target locations of google
         * @summary Return geo target locations of google
         * @param {string} branchId The id of the branch
         * @param {string} query City name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGoogleGeoTargets(branchId: string, query: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeoTargetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGoogleGeoTargets(branchId, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return keywords of google
         * @summary Return keywords of google
         * @param {string} branchId The id of the branch
         * @param {string} [query] Keyword
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGoogleKeywords(branchId: string, query?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KeywordsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGoogleKeywords(branchId, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return suggested keyword themes of google
         * @summary Return suggested keyword themes of google
         * @param {string} branchId The id of the branch
         * @param {string} query Keyword name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSuggestedGoogleKeywordThemes(branchId: string, query: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KeywordThemesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSuggestedGoogleKeywordThemes(branchId, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GoogleBranchApi - factory interface
 * @export
 */
export const GoogleBranchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GoogleBranchApiFp(configuration)
    return {
        /**
         * Return feed item sets of google
         * @summary Return feed item sets of google
         * @param {string} branchId The id of the branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGoogleFeedItemSets(branchId: string, options?: any): AxiosPromise<GoogleFeedItemSetsResponse> {
            return localVarFp.listGoogleFeedItemSets(branchId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return geo target locations of google
         * @summary Return geo target locations of google
         * @param {string} branchId The id of the branch
         * @param {string} query City name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGoogleGeoTargets(branchId: string, query: string, options?: any): AxiosPromise<GeoTargetsResponse> {
            return localVarFp.listGoogleGeoTargets(branchId, query, options).then((request) => request(axios, basePath));
        },
        /**
         * Return keywords of google
         * @summary Return keywords of google
         * @param {string} branchId The id of the branch
         * @param {string} [query] Keyword
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGoogleKeywords(branchId: string, query?: string, options?: any): AxiosPromise<KeywordsResponse> {
            return localVarFp.listGoogleKeywords(branchId, query, options).then((request) => request(axios, basePath));
        },
        /**
         * Return suggested keyword themes of google
         * @summary Return suggested keyword themes of google
         * @param {string} branchId The id of the branch
         * @param {string} query Keyword name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSuggestedGoogleKeywordThemes(branchId: string, query: string, options?: any): AxiosPromise<KeywordThemesResponse> {
            return localVarFp.listSuggestedGoogleKeywordThemes(branchId, query, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GoogleBranchApi - interface
 * @export
 * @interface GoogleBranchApi
 */
export interface GoogleBranchApiInterface {
    /**
     * Return feed item sets of google
     * @summary Return feed item sets of google
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleBranchApiInterface
     */
    listGoogleFeedItemSets(branchId: string, options?: any): AxiosPromise<GoogleFeedItemSetsResponse>;

    /**
     * Return geo target locations of google
     * @summary Return geo target locations of google
     * @param {string} branchId The id of the branch
     * @param {string} query City name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleBranchApiInterface
     */
    listGoogleGeoTargets(branchId: string, query: string, options?: any): AxiosPromise<GeoTargetsResponse>;

    /**
     * Return keywords of google
     * @summary Return keywords of google
     * @param {string} branchId The id of the branch
     * @param {string} [query] Keyword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleBranchApiInterface
     */
    listGoogleKeywords(branchId: string, query?: string, options?: any): AxiosPromise<KeywordsResponse>;

    /**
     * Return suggested keyword themes of google
     * @summary Return suggested keyword themes of google
     * @param {string} branchId The id of the branch
     * @param {string} query Keyword name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleBranchApiInterface
     */
    listSuggestedGoogleKeywordThemes(branchId: string, query: string, options?: any): AxiosPromise<KeywordThemesResponse>;

}

/**
 * GoogleBranchApi - object-oriented interface
 * @export
 * @class GoogleBranchApi
 * @extends {BaseAPI}
 */
export class GoogleBranchApi extends BaseAPI implements GoogleBranchApiInterface {
    /**
     * Return feed item sets of google
     * @summary Return feed item sets of google
     * @param {string} branchId The id of the branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleBranchApi
     */
    public listGoogleFeedItemSets(branchId: string, options?: any) {
        return GoogleBranchApiFp(this.configuration).listGoogleFeedItemSets(branchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return geo target locations of google
     * @summary Return geo target locations of google
     * @param {string} branchId The id of the branch
     * @param {string} query City name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleBranchApi
     */
    public listGoogleGeoTargets(branchId: string, query: string, options?: any) {
        return GoogleBranchApiFp(this.configuration).listGoogleGeoTargets(branchId, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return keywords of google
     * @summary Return keywords of google
     * @param {string} branchId The id of the branch
     * @param {string} [query] Keyword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleBranchApi
     */
    public listGoogleKeywords(branchId: string, query?: string, options?: any) {
        return GoogleBranchApiFp(this.configuration).listGoogleKeywords(branchId, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return suggested keyword themes of google
     * @summary Return suggested keyword themes of google
     * @param {string} branchId The id of the branch
     * @param {string} query Keyword name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleBranchApi
     */
    public listSuggestedGoogleKeywordThemes(branchId: string, query: string, options?: any) {
        return GoogleBranchApiFp(this.configuration).listSuggestedGoogleKeywordThemes(branchId, query, options).then((request) => request(this.axios, this.basePath));
    }
}

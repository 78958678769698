import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { CompanyCampaignsBranchApi } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function listPendingCampaignsOfCompany(
  branchId: string,
  page?: number,
  perPage?: number,
  query?: object,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new CompanyCampaignsBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.listPendingCampaignsOfCompany(branchId, page, perPage, query).then((res) => res.data);
}

export default function useListPendingCampaignsOfCompany({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useQuery(
    ['useListPendingCampaignsOfCompany', branchId, params.page, params.perPage, params.query],
    () => listPendingCampaignsOfCompany(branchId, params.page, params.perPage, params.query, axiosInstance),
    props
  );
}

import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="18.551"
    height="18.871"
    viewBox="0 0 18.551 18.871"
    fill="currentColor"
    {...props}
  >
    <defs>
      <clipPath id="clipPath">
        <rect
          id="Rectangle_1568"
          data-name="Rectangle 1568"
          width="18.551"
          height="18.871"
          transform="translate(0 0)"
          fill="currentColor"
        />
      </clipPath>
    </defs>
    <g id="Group_4031" data-name="Group 4031" transform="translate(0 0)">
      <g id="Group_2942" data-name="Group 2942" clipPath="url(#clip-path)">
        <path
          id="Path_1528"
          data-name="Path 1528"
          d="M18.523,7.151a1.356,1.356,0,0,0-.162-.966l-1.791-3a1.3,1.3,0,0,0-1.748-.53l-1.525.7a8.158,8.158,0,0,0-.763-.44l-.152-1.695a.938.938,0,0,0-.012-.1A1.377,1.377,0,0,0,11.042,0h-3.5A1.341,1.341,0,0,0,6.2,1.238L6.05,2.918a7.426,7.426,0,0,0-.766.438L3.7,2.631a1.37,1.37,0,0,0-1.693.576L.275,6.188A1.366,1.366,0,0,0,.64,7.982l1.4,1c-.014.153-.021.305-.021.451a3.437,3.437,0,0,0,.018.452L.555,10.892a1.362,1.362,0,0,0-.368,1.785L1.945,15.7a1.4,1.4,0,0,0,.739.612,1.273,1.273,0,0,0,1-.091l1.534-.7a7.9,7.9,0,0,0,.76.441l.157,1.692a.7.7,0,0,0,.013.1,1.38,1.38,0,0,0,1.326,1.124h3.5a1.346,1.346,0,0,0,1.337-1.24l.154-1.672a7.553,7.553,0,0,0,.767-.442l1.584.729a1.371,1.371,0,0,0,1.69-.575l-.216-.127,0,0,.217.126,1.735-2.985a1.366,1.366,0,0,0,.166-.966,1.346,1.346,0,0,0-.521-.823L16.6,9.914c.015-.16.022-.319.022-.479s-.008-.309-.021-.459l1.384-.988a1.373,1.373,0,0,0,.542-.837m-3.464.618a.935.935,0,0,0-.382.9,5.125,5.125,0,0,1,.072.752,5.261,5.261,0,0,1-.073.778.935.935,0,0,0,.36.872l1.367,1.04L15.12,14.328l-1.61-.742a.953.953,0,0,0-.505-.075.918.918,0,0,0-.469.2,5.485,5.485,0,0,1-1.332.768.937.937,0,0,0-.559.771L10.486,17H7.943l-.159-1.755a.937.937,0,0,0-.564-.772,6.473,6.473,0,0,1-1.347-.784.913.913,0,0,0-.463-.182.982.982,0,0,0-.488.078l-1.609.739L2.038,12.137,3.559,11.1a.931.931,0,0,0,.4-.915,5.29,5.29,0,0,1-.077-.744,5.263,5.263,0,0,1,.074-.776.928.928,0,0,0-.384-.89L2.112,6.736,3.392,4.53,5,5.269a.96.96,0,0,0,.505.076.913.913,0,0,0,.47-.2,5.423,5.423,0,0,1,1.331-.768.921.921,0,0,0,.386-.309A.956.956,0,0,0,7.867,3.6l.159-1.753h2.543l.159,1.75a.953.953,0,0,0,.173.474.981.981,0,0,0,.388.311,6.287,6.287,0,0,1,1.349.784.947.947,0,0,0,.461.181.974.974,0,0,0,.489-.081L15.2,4.53l1.318,2.207Z"
          fill="currentColor"
        />
        <path
          id="Path_1529"
          data-name="Path 1529"
          d="M9.277,5.8h0a3.638,3.638,0,1,0-.005,7.275h.011a3.638,3.638,0,0,0,0-7.275m1.252,4.893a1.775,1.775,0,1,1,0-2.512,1.774,1.774,0,0,1,0,2.512"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default SvgComponent;

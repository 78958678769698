import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="33.333" height="26.667" version="1" viewBox="0 0 25 20" fill="currentColor" {...props}>
    <path
      d="M135 168c-33-15-75-28-92-28-27 0-34-5-39-27-5-20-2-30 12-42 11-9 23-24 27-34 8-20 27-14 27 9 0 19 2 18 60-11 63-32 80-32 80 0 0 14 7 28 15 31 22 9 19 61-4 74-11 5-21 21-23 33l-3 24-60-29zm52-135c-3-2-25 6-51 19-38 18-46 27-46 50s8 32 48 52l47 24 3-70c2-39 1-72-1-75zM80 106c0-14-5-28-10-31-17-11-50 5-50 24 0 24 9 31 37 31 18 0 23-6 23-24zm147-10c-4-9-11-16-17-16-11 0-14 33-3 44 11 10 26-11 20-28z"
      transform="matrix(.1 0 0 -.1 0 20)"
    ></path>
  </svg>
);

export default SvgComponent;

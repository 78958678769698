import { Fragment, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { Tab } from '@headlessui/react';
import cn from 'classnames';
import { useForm, FormProvider } from 'react-hook-form';

import { Form, Icons } from '@app/components';
import { useDebounce, useLocale, useToast, useAuth, api } from '@app/hooks';
import { array as arrayLib } from '@app/lib';
import type { BranchCampaign as BranchCampaignType } from '@app/api';

const DEBOUNCE_MS = 200;

type DefaultValueType = {
  value: string | number | undefined;
  label: string | undefined;
};

type KeywordWizardPropTypes = {
  campaign: Partial<BranchCampaignType>;
};

const KeywordWizard: React.FC<KeywordWizardPropTypes> = ({ campaign }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.CustomSegmentsInput',
  };
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { setValue, control } = methods;
  const { t } = useLocale();
  const toast = useToast();
  const { branchId } = useAuth();
  const router = useRouter();
  const [query, setQuery] = useState<string>('');
  const { debouncedValue: debouncedQuery } = useDebounce(query, DEBOUNCE_MS);
  const { data, isLoading, refetch } = api.useListKeywordsThemesByBranch({
    enabled: false,
    params: {
      query: query,
    },
  });
  const { mutate, isLoading: isMutateLoading } = api.useUpdateBranchGoogleCampaign({
    onSuccess: () => {
      toast.success(t('message.updated', SCOPE_OPTIONS));
    },
  });
  const keywordsWatch = methods.watch('keywords');
  const negativeKeywordsWatch = methods.watch('negativeKeywords');
  const tabs = [
    { name: t('form.customSegments.label', SCOPE_OPTIONS), value: 'keywords', key: 0 },
    { name: t('form.negativeKeywords.label', SCOPE_OPTIONS), value: 'negativeKeywords', key: 1 },
  ];
  const keywordThemesMap = data?.keyword_themes?.map((keyword: { text: string; id: string }) => ({
    label: keyword.text,
    value: keyword.id,
    keywordType: 'keywordTheme',
  }));

  useEffect(() => {
    if (query.length <= 2) return;

    refetch();
  }, [debouncedQuery, refetch]);

  useEffect(() => {
    if (!campaign) return;

    const keywordThemes =
      campaign?.targeting?.keyword_themes?.map((e) => ({ label: e.text, value: e.id, keywordType: 'keywordTheme' })) || [];
    const freeFormKeywordThemes =
      campaign?.targeting?.free_form_keyword_themes?.map((e) => ({
        label: e,
        value: e,
        keywordType: 'freeForm',
      })) || [];
    const negativeKeywords =
      campaign?.targeting?.negative_keywords?.map((e) => ({
        label: e,
        value: e,
        keywordType: 'negative',
      })) || [];

    setValue('keywords', [...(keywordThemes as []), ...(freeFormKeywordThemes as [])]);
    setValue('negativeKeywords', negativeKeywords);
  }, [campaign]);

  function handleDelete(keyword: any) {
    const filteredKeywords = keywordsWatch.filter((item: DefaultValueType) => item.value !== keyword);
    setValue('keywords', filteredKeywords);
    handleSubmitKeyword(filteredKeywords);
  }

  function handleDeleteNegative(keyword: any) {
    const filteredNegativeKeywords = negativeKeywordsWatch.filter((item: DefaultValueType) => item.value !== keyword);
    setValue('negativeKeywords', filteredNegativeKeywords);
    handleSubmitNegativeKeyword(filteredNegativeKeywords);
  }

  function handleSubmitKeyword(keywords: any) {
    const data = {
      campaign: {
        targeting: {
          ...campaign.targeting,
          keyword_themes: keywords.filter((e: any) => e.keywordType === 'keywordTheme').map((e: any) => ({ id: e.value, text: e.label })),
          free_form_keyword_themes: keywords.filter((e: any) => e.keywordType === 'freeForm').map((e: any) => e.value),
          negative_keywords: negativeKeywordsWatch.map((e: any) => e.value),
        },
      },
    };

    mutate({ branchId: branchId, campaignId: router.query.id, googleBranchCampaignUpdateInput: data });
  }

  function handleSubmitNegativeKeyword(negativeKeywords: any) {
    const filteredKeywords = keywordsWatch.filter((item: DefaultValueType) => !negativeKeywords.some((e: any) => e.value === item.value));
    setValue('keywords', filteredKeywords);

    const data = {
      campaign: {
        targeting: {
          ...campaign.targeting,
          keyword_themes: filteredKeywords
            .filter((e: any) => e.keywordType === 'keywordTheme')
            .map((e: any) => ({ id: e.value, text: e.label })),
          free_form_keyword_themes: filteredKeywords.filter((e: any) => e.keywordType === 'freeForm').map((e: any) => e.value),
          negative_keywords: negativeKeywords.map((e: any) => (e.keywordType === 'keywordTheme' ? e.label : e.value)),
        },
      },
    };

    mutate({ branchId: branchId, campaignId: router.query.id, googleBranchCampaignUpdateInput: data });
  }

  function createOption(value: string) {
    const newOption = {
      value,
      label: value,
      keywordType: 'freeForm',
    };
    const keywords = [...keywordsWatch, newOption];

    setValue('keywords', keywords);
    handleSubmitKeyword(keywords);
  }

  return (
    <div className="relative mt-5">
      {isMutateLoading && <div className="absolute top-0 left-0 h-full w-full bg-white/60 z-999"></div>}
      <Tab.Group>
        <Tab.List className="flex items-center gap-x-2">
          {tabs.map((tab) => (
            <Tab as={Fragment} key={tab.value}>
              {({ selected }) => (
                <button
                  className={cn('px-3 py-2 bg-gray-400 text-white font-semibold rounded', {
                    'bg-blue-200': selected,
                  })}
                >
                  {tab.name}
                </button>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          <FormProvider {...methods}>
            <Tab.Panel className="mt-5 bg-white p-4 rounded-lg shadow">
              <span className="text-sm text-green-500">{t('form.customSegments.description', SCOPE_OPTIONS)}</span>
              <Form.CreatableSelect
                isMulti
                automaticHandleValue={false}
                onInputChange={setQuery}
                isSearchable
                isClearable={false}
                name="keywords"
                isLoading={isLoading}
                placeholder={t('form.customSegments.placeholder', SCOPE_OPTIONS)}
                control={control}
                options={keywordThemesMap}
                controlShouldRenderValue={false}
                onChange={handleSubmitKeyword}
                onCreateOption={createOption}
              />
              {keywordsWatch && !arrayLib.isEmpty(keywordsWatch) && (
                <div className="flex items-center flex-wrap gap-1.5 mt-6">
                  {keywordsWatch.map((keyword: { label: string; value: string; keywordType: string }, index: number) => (
                    <div className="bg-gray-200 flex justify-between p-2 space-x-2 rounded-2" key={index}>
                      <span>{keyword.label}</span>
                      <button onClick={() => handleDelete(keyword.value)}>
                        <Icons.XMark className="w-4 h-4" />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </Tab.Panel>
          </FormProvider>
          <Tab.Panel className="mt-5 bg-white p-4 rounded-lg shadow">
            <span className="text-sm text-red-500">{t('form.negativeKeywords.description', SCOPE_OPTIONS)}</span>
            <Form.CreatableSelect
              isMulti
              automaticHandleValue={false}
              isSearchable
              isClearable={false}
              name="negativeKeywords"
              placeholder={t('form.negativeKeywords.placeholder', SCOPE_OPTIONS)}
              control={control}
              options={keywordsWatch}
              controlShouldRenderValue={false}
              onChange={handleSubmitNegativeKeyword}
            />
            {negativeKeywordsWatch && !arrayLib.isEmpty(negativeKeywordsWatch) && (
              <div className="flex items-center flex-wrap gap-1.5 mt-6">
                {negativeKeywordsWatch.map((keyword: { label: string; value: string; keywordType: string }, index: number) => (
                  <div className="bg-gray-200 flex justify-between p-2 space-x-2 rounded-2" key={index}>
                    <span>{keyword.label}</span>
                    <button onClick={() => handleDeleteNegative(keyword.value)}>
                      <Icons.XMark className="w-4 h-4" />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default KeywordWizard;

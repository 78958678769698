import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { GoogleBusinessBranchApi } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function getBusinessLocationsByBranch(branchId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new GoogleBusinessBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.listGoogleBusinessLocations(branchId).then((res) => res.data);
}

export default function useGetBusinessLocationsByBranchByBranch(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useQuery(['useGetBusinessLocationsByBranchByBranch'], () => getBusinessLocationsByBranch(branchId, axiosInstance), props);
}

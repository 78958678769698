import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { CompanyCampaignsBranchApi } from '@app/api';

import { useAuth, useAxios } from '@app/hooks';

export function showCampaignsOfCompany(branchId: string, campaignId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new CompanyCampaignsBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.showCampaignsOfCompany(branchId, campaignId).then((res) => res.data);
}

export default function useShowCampaignsOfCompany({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useQuery(
    ['useShowCampaignsOfCompany', branchId, params.campaignId],
    () => showCampaignsOfCompany(branchId, params.campaignId, axiosInstance),
    props
  );
}

import dynamic from 'next/dynamic';

import CampaignInfo from './CampaignInfo';
import CampaignDetailTable from './CampaignDetailTable';
import Card from './Card';
const GenerateImage = dynamic(() => import('./GenerateImage'), { ssr: false });
import GoogleObjectiveForm from './GoogleObjectiveForm';
import SmartObjectiveForm from './SmartObjectiveForm';
import GoogleSettingsForm from './GoogleSettingsForm';
import Leads from './Leads';
import ObjectiveForm from './ObjectiveForm';
import ObjectiveFormPicker from './ObjectiveFormPicker';
import SettingsForm from './SettingsForm';
import SummaryForm from './SummaryForm';
import ScopeRange from './ScopeRange';
import MediaLibraryListTable from './MediaLibraryListTable';
import CampaignImageShowCard from './CampaignImageShowCard';

export default {
  CampaignInfo,
  CampaignDetailTable,
  Card,
  GenerateImage,
  GoogleObjectiveForm,
  SmartObjectiveForm,
  GoogleSettingsForm,
  Leads,
  ObjectiveForm,
  ObjectiveFormPicker,
  SettingsForm,
  SummaryForm,
  ScopeRange,
  MediaLibraryListTable,
  CampaignImageShowCard,
};

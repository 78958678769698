import React from 'react';

import { api, useAuth, useLocale } from '@app/hooks';
import { Box, Form, Icons } from '@app/components';
import type { Campaign as CampaignType, GoogleLocationsResponse, GoogleLocationsResponseLocations } from '@app/api';
import { useFormContext } from 'react-hook-form';
import array from 'lib/array';

type SmartObjectiveFormPropTypes = {
  campaign: CampaignType | any;
};

const SmartObjectiveForm: React.FC<SmartObjectiveFormPropTypes> = () => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.SmartObjectiveForm',
  };
  const { t } = useLocale();
  const {
    control,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const { branch } = useAuth();
  const businessLocationWatch = watch('creative.business_location');
  const { data } = api.useGetBusinessLocationsByBranch({
    refetchOnWindowFocus: true,
    onSuccess: (data: GoogleLocationsResponse) => {
      const firstLocation: GoogleLocationsResponseLocations = array.first(data?.locations);
      if (firstLocation && !businessLocationWatch) {
        setValue('creative.business_location', {
          label: firstLocation.title,
          value: String(firstLocation.name),
          latitude: firstLocation.latlng?.latitude,
          longitude: firstLocation.latlng?.longitude,
          storefrontAddress: firstLocation.storefrontAddress,
        });
      }
    },
  });

  return (
    <>
      <Box className="!mb-0 flex">
        <div className="flex items-center rounded-3 bg-white shadow-sm w-full">
          <div className="bg-gray-200 px-5 py-4 rounded-r-full flex items-center">
            <Icons.Google className="w-8 h-8 mr-2" />
          </div>
          <div className="bg-white px-5 py-4 text-3.5 font-semibold text-gray-900">Google</div>
        </div>
      </Box>
      <Box className="!mb-0">
        <div className="bg-white shadow-sm px-5 py-4 rounded-3">
          <Form.Input
            type="text"
            id="phone"
            label={t('form.phone.label', SCOPE_OPTIONS)}
            {...register('phone', {
              required: { value: false, message: t('form.phone.errors.required', SCOPE_OPTIONS) },
            })}
            requiredSign={true}
            disabled={true}
            defaultValue={branch?.phone_number}
            error={errors?.phone}
          />
        </div>
      </Box>
      <Box className="!mb-0">
        <div className="bg-white shadow-sm px-5 py-4 rounded-3">
          <Form.Select
            name="creative.business_location"
            label={t('form.businessLocation.label', SCOPE_OPTIONS)}
            rules={{
              required: { value: true, message: t('form.businessLocation.errors.required', SCOPE_OPTIONS) },
            }}
            placeholder={t('form.businessLocation.placeholder', SCOPE_OPTIONS)}
            control={control}
            options={data?.locations
              .filter((location: GoogleLocationsResponseLocations) => location.latlng)
              ?.map((item: GoogleLocationsResponseLocations) => ({
                label: item.title,
                value: String(item.name),
                latitude: item.latlng?.latitude,
                longitude: item.latlng?.longitude,
                storefrontAddress: item.storefrontAddress,
              }))}
            automaticHandleValue={false}
            error={errors?.business_location}
          />
        </div>
      </Box>
      <Box className="!mb-0">
        <div className="bg-white shadow-sm px-5 py-4 rounded-3">
          <Form.Input
            type="text"
            id="business_name"
            label={t('form.business_name.label', SCOPE_OPTIONS)}
            {...register('business_name', {
              required: { value: false, message: t('form.business_name.errors.required', SCOPE_OPTIONS) },
            })}
            requiredSign={true}
            disabled={true}
            defaultValue={branch?.name}
            error={errors?.business_name}
          />
        </div>
      </Box>
    </>
  );
};

export default SmartObjectiveForm;

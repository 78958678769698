import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Box from 'components/Box';
import Button from 'components/Button';
import Wallet from '..';
import MaskInput from '../MaskInput';
import { PayTR } from 'constants/paymentTypes';
import useLocale from '@app/hooks/useLocale';
import Form from 'components/Form';
import { V2GetCardsResponse } from 'api/model';
import useGetSavedCards from '@app/hooks/api/useGetSavedCards';
import api from '@app/hooks/api';
import Loader from 'components/Loader';

const MASKS = {
  cardNumber: '9999 9999 9999 9999',
  expiryDate: '99/99',
  cvv: '999',
};

type Inputs = {
  cardNumber: string;
  holderName: string;
  expiryDate: string;
  cvv: string;
  selectedCard: any;
  isCardSaved: boolean;
};

type AddCardProps = {
  payTRInstance: PayTR;
};

const SUCCESS_URL = process.env.NEXT_PUBLIC_PAYMENT_SUCCESS_URL as string;
const FAIL_URL = process.env.NEXT_PUBLIC_PAYMENT_FAIL_URL as string;

export default function AddCard({ payTRInstance }: AddCardProps) {
  const SCOPE_OPTIONS = {
    scope: 'pages.wallet.payment.form',
  };

  const { t } = useLocale();

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm<Inputs>();

  const { data: profile } = api.useGetProfile();
  const [html, setHtml] = React.useState('');

  const { data, isLoading } = useGetSavedCards();
  const [savedCards, setSavedCards] = useState<V2GetCardsResponse>({} as V2GetCardsResponse);
  const [selectedCard, setSelectedCard] = useState({} as { label: string; value: string });
  const isSelectCard = useMemo(() => (selectedCard.value ? true : false), [selectedCard]);
  useEffect(() => {
    if (data) {
      setSavedCards(data.data);
    }
  }, [data]);

  const hasCard = useMemo(() => savedCards.cards?.length > 0, [savedCards]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (isSelectCard) {
      payWithSavedCard();
      return;
    }
    const res = await fetch('/api/pay', {
      method: 'POST',
      body: JSON.stringify({
        payTRInstance,
        data,
        selectedCard,
        utoken: profile?.utoken,
        successURL: window.location.origin + SUCCESS_URL,
        failURL: window.location.origin + FAIL_URL,
        userHasCard: hasCard,
        isCardSaved: data.isCardSaved,
      }),
    });
    const { result } = await res.json();
    setHtml(result);
  };

  async function payWithSavedCard() {
    const res = await fetch('/api/pay-with-saved-card', {
      method: 'POST',
      body: JSON.stringify({
        payTRInstance,
        ctoken: selectedCard.value,
        cvv: watch('cvv'),
        utoken: profile?.utoken,
        successURL: window.location.origin + SUCCESS_URL,
        failURL: window.location.origin + FAIL_URL,
      }),
    });
    const { result } = await res.json();
    setHtml(result);
  }

  useEffect(() => {
    if (html) {
      localStorage.removeItem('deposit');
    }
  }, [html]);

  const watchCardNumber = watch('cardNumber');
  const watchHolderName = watch('holderName');
  const watchExpiryDate = watch('expiryDate');
  const watchCvv = watch('cvv');

  useEffect(() => {
    setValue('cvv', '');
  }, [selectedCard]);

  return (
    <div className="flex flex-col w-full">
      {isLoading && <Loader />}
      {html && (
        <div className="bg-white absolute top-0 bottom-0 left-0 right-0 z-50">
          <iframe title="3d secure" srcDoc={html} className="w-full h-full" />
        </div>
      )}
      {!selectedCard.value && (
        <div className="mb-5">
          <Wallet.Card cardNumber={watchCardNumber} holderName={watchHolderName} expiryDate={watchExpiryDate} cvv={watchCvv} />
        </div>
      )}
      <form className="flex flex-col gap-4 w-full" onSubmit={handleSubmit(onSubmit)}>
        <Box className="bg-white shadow-sm px-4 gap-5 flex flex-col">
          {savedCards.cards?.length ? (
            <div className="flex flex-col gap-5">
              <span className="text-sm text-gray-500">{t('savedCardHint', SCOPE_OPTIONS)}</span>
              <Form.Select
                name="selectedCard"
                label={t('savedCards', SCOPE_OPTIONS)}
                control={control}
                options={[
                  { label: t('selectCard', SCOPE_OPTIONS), value: '' },
                  ...savedCards.cards.map((card: any) => ({
                    label: `${t('lastDigits', SCOPE_OPTIONS)}: ${card.last_4}`,
                    value: card.ctoken,
                  })),
                ]}
                onChange={(e) => setSelectedCard(e)}
              />
              {selectedCard.value && (
                <MaskInput
                  type="text"
                  id="cvv"
                  className="w-52"
                  label={t('cvv', SCOPE_OPTIONS)}
                  {...register('cvv', {
                    required: { value: true, message: t('rules.cvv.required', SCOPE_OPTIONS) },
                    minLength: { value: 3, message: t('rules.cvv.invalid', SCOPE_OPTIONS) },
                  })}
                  requiredSign={true}
                  error={errors.cvv}
                  mask={MASKS.cvv}
                />
              )}
            </div>
          ) : null}

          {!isSelectCard && (
            <>
              <div className="grid grid-cols-2 gap-5">
                <MaskInput
                  type="text"
                  id="cardNumber"
                  label={t('cardNumber', SCOPE_OPTIONS)}
                  {...register('cardNumber', {
                    required: { value: true, message: t('rules.cardNumber.required', SCOPE_OPTIONS) },
                    minLength: { value: 19, message: t('rules.cardNumber.invalid', SCOPE_OPTIONS) },
                  })}
                  requiredSign={true}
                  error={errors.cardNumber}
                  mask={MASKS.cardNumber}
                />
                <MaskInput
                  type="text"
                  id="holderName"
                  label={t('holderName', SCOPE_OPTIONS)}
                  {...register('holderName', {
                    required: { value: true, message: t('rules.holderName.required', SCOPE_OPTIONS) },
                  })}
                  requiredSign={true}
                  error={errors.holderName}
                />
              </div>
              <div className="grid grid-cols-2 gap-5">
                <MaskInput
                  type="text"
                  id="expiryDate"
                  label={t('expirationDate', SCOPE_OPTIONS)}
                  {...register('expiryDate', {
                    required: { value: true, message: t('rules.expirationDate.required', SCOPE_OPTIONS) },
                    minLength: { value: 5, message: t('rules.expirationDate.invalid', SCOPE_OPTIONS) },
                  })}
                  requiredSign={true}
                  error={errors.expiryDate}
                  mask={MASKS.expiryDate}
                />
                <MaskInput
                  type="text"
                  id="cvv"
                  label={t('cvv', SCOPE_OPTIONS)}
                  {...register('cvv', {
                    required: { value: true, message: t('rules.cvv.required', SCOPE_OPTIONS) },
                    minLength: { value: 3, message: t('rules.cvv.invalid', SCOPE_OPTIONS) },
                  })}
                  requiredSign={true}
                  error={errors.cvv}
                  mask={MASKS.cvv}
                />
              </div>
            </>
          )}
          {!isSelectCard && (
            <Form.Checkbox id="isCardSaved" className="mt-4" {...register('isCardSaved')} label={t('saveCard', SCOPE_OPTIONS)} />
          )}
          <Button className="w-40 mx-auto" theme="blue" label={t('pay', SCOPE_OPTIONS)} type="submit" />
        </Box>
      </form>
    </div>
  );
}

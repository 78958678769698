import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { BranchesBranchUserApi } from '@app/api';

import { useAxios } from '@app/hooks';

export function showBranchUserBranches(branchId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new BranchesBranchUserApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.showBranchUserBranches(branchId).then((res) => res.data);
}

export default function useShowBranchUserBranches({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useQuery(['useShowBranchUserBranches', params.branchId], () => showBranchUserBranches(params.branchId, axiosInstance), props);
}

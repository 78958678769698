import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { WalletBranchV2Api } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function deleteSavedCard(branchId: string, ctoken: string, axiosInstance: AxiosInstance) {
  const api = new WalletBranchV2Api(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);
  return api.deleteSavedCard(branchId, { ctoken });
}

export default function useDeleteSavedCard() {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();
  return useMutation((ctoken: string) => deleteSavedCard(branchId, ctoken, axiosInstance));
}

import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { WalletBranchV2Api } from '@app/api';
import useAxios from '../useAxios';

export function deposit(branchId: string, balance: number, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new WalletBranchV2Api(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);
  return api.addWalletBalance(branchId, {
    wallet: {
      balance,
    },
  });
}

export default function useDeposit() {
  const { api: axiosInstance } = useAxios();
  return useMutation((data: { branchId: string; balance: number }) => deposit(data.branchId, data.balance, axiosInstance));
}

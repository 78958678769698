import { useRouter } from 'next/router';

import { api, useAuth, useLocale } from '@app/hooks';
import { SearchTermInsightResponseSearchTermInsights } from '@app/api';

import { branch as branchLib } from '@app/lib';

type KeywordReportPropTypes = {
  dateRange: {
    startDate: Date;
    endDate: Date;
  };
};

const KeywordReport: React.FC<KeywordReportPropTypes> = ({ dateRange: { startDate, endDate } }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.KeywordReport',
  };
  const router = useRouter();
  const { t } = useLocale();
  const { branch } = useAuth();
  const branchCurrency = branchLib.currency(branch);
  const { data: insights } = api.useShowBranchCampaignSearchTermInsights({
    params: {
      campaignId: router.query.id,
      startDate,
      endDate,
    },
  });

  return (
    <div className="mt-5">
      <span className="font-semibold text-lg">{t('labels.searchTermReport', SCOPE_OPTIONS)}</span>
      <div className="mt-5 bg-white rounded-lg shadow max-h-[400px] overflow-auto">
        <table className="relative w-full h-full text-sm text-left">
          <thead className="sticky top-0 bg-white before:content-[''] before:absolute before:bottom-0 before:w-full before:h-[1px] before:bg-blue-500/50">
            <tr>
              <th scope="col" className="px-4 py-3 border-r border-blue-500/50">
                {t('labels.searchTerm', SCOPE_OPTIONS)}
              </th>
              <th scope="col" className="px-4 py-3 border-r border-blue-500/50">
                {t('labels.spend', SCOPE_OPTIONS)}
              </th>
              <th scope="col" className="px-4 py-3 border-r border-blue-500/50">
                {t('labels.clicks', SCOPE_OPTIONS)}
              </th>
              <th scope="col" className="px-4 py-3">
                {t('labels.cpc', SCOPE_OPTIONS)}
              </th>
            </tr>
          </thead>
          <tbody>
            {insights?.search_term_insights?.map((e: SearchTermInsightResponseSearchTermInsights, i: number) => (
              <tr key={i}>
                <td className="px-4 py-2 border-r border-blue-500/50">
                  <span className="py-2 pl-2 bg-gray-200 flex items-center justify-start rounded">{e.search_term}</span>
                </td>
                <td className="px-4 py-3 border-r border-blue-500/50">
                  {e.spend} {branchCurrency}
                </td>
                <td className="px-4 py-3 border-r border-blue-500/50">
                  {e.clicks} {branchCurrency}
                </td>
                <td className="px-4 py-3 border-r">
                  {e.cpc} {branchCurrency}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot className="sticky bottom-0 bg-white ">
            <tr>
              <td className="px-4 py-2 border-r border-blue-500/50 font-semibold">{t('labels.total', SCOPE_OPTIONS)}</td>
              <td className="px-4 py-3 border-r border-blue-500/50 font-semibold">
                {insights?.total_insights?.total_spend} {branchCurrency}
              </td>
              <td className="px-4 py-3 border-r border-blue-500/50 font-semibold">{insights?.total_insights?.total_clicks}</td>
              <td className="px-4 py-3 border-r font-semibold">
                {insights?.total_insights?.total_cpc} {branchCurrency}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default KeywordReport;

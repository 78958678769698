import React from 'react';

import * as XLSX from 'xlsx';

import { Box, Button } from '@app/components';
import { api } from '@app/hooks';
import type { Campaign as CampaignTypes, LeadgenData as LeadgenDataTypes } from '@app/api';
import { array } from '@app/lib';

type LeadsPropTypes = {
  campaign?: CampaignTypes;
  hasInformation: (data: boolean) => void;
};

const Leads: React.FC<LeadsPropTypes> = ({ campaign, hasInformation }) => {
  const { data, isLoading } = api.useReturnLeadsByBranchCampaignId({
    enabled: !!campaign?.id,
    params: {
      campaignId: campaign?.id,
    },
  });

  function handleDownloadClick() {
    const dataJson = data.leads.map((lead: LeadgenDataTypes) => ({
      Id: lead.id,
      Date: lead.created_time,
      ...lead.field_data.reduce((previousValue, currentValue) => {
        const values = currentValue?.values ?? [];
        const name = currentValue?.name ?? '';

        return {
          ...previousValue,
          [name]: values.join(', '),
        };
      }, {}),
    }));

    if (array.isEmpty(dataJson) || dataJson === 0) {
      hasInformation(true);
    }

    const worksheet = XLSX.utils.json_to_sheet(dataJson);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Leads');
    XLSX.writeFile(workbook, `campaign-${campaign?.id}.xlsx`);
  }

  return (
    <Box className="flex items-center justify-between">
      <span className="font-semibold text-4 text-gray-900">{campaign?.name}</span>
      <Button disabled={isLoading} block={false} theme="green" label="Download Leads" onClick={handleDownloadClick} />
    </Box>
  );
};

export default Leads;

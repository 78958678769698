import React, { useState } from 'react';

import cn from 'classnames';

import { useLocale } from '@app/hooks';
import { ANALYTICS_CONTENT, ANALYTICS_FILTERS, CHART_TYPES } from '@app/constants';

import LineChart from './LineChart';
import BarChart from './BarChart';
import Legend from './Legend';
import LegendCards from './LegendCards';

const DEFAULT_SELECTED_TYPES = [ANALYTICS_CONTENT.IMPRESSION, ANALYTICS_CONTENT.CLICK].map((content) => content.KEY);

type AnalyticsPropTypes = {
  data: {
    labels: string[];
    data: {
      impression: number[];
      click: number[];
      cost: string[];
      cpc: string[];
      reach: number[];
    };
  };
  onFilterChange: (key: string) => void;
  selectedFilter: string;
  totalInsights: {
    total_impressions: string;
    total_spend: string;
    total_click_to_call_call_confirm: string;
    total_lead: string;
    total_messaging_conversation_started_7d: string;
    total_get_directions: string;
    total_contact: string;
  };
  chartType: string;
};

const Analytics: React.FC<AnalyticsPropTypes> = ({ data, totalInsights, selectedFilter, onFilterChange, chartType }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Analytics.Chart.index',
  };
  const { t } = useLocale();
  const [selectedTypes, setSelectedTypes] = useState<Array<string>>(DEFAULT_SELECTED_TYPES);

  function handleSelected(type: string) {
    if (selectedTypes.includes(type) && selectedTypes.length === 1) {
      return;
    }

    if (selectedTypes.includes(type)) {
      setSelectedTypes((prev) => prev.filter((item) => item !== type));
      return;
    }

    setSelectedTypes((prev) => [...prev, type]);
  }

  if (chartType === CHART_TYPES.BAR) {
    return (
      <>
        <LegendCards totalInsights={totalInsights} />
        <div className="mt-5 bg-white p-6 rounded-lg shadow">
          <BarChart data={data.data} labels={data.labels} />
          <div className="w-full mt-5 flex items-center justify-center">
            <div className="inline-flex flex-row  rounded-7.5 border border-[#3E66EA] overflow-hidden">
              {Object.values(ANALYTICS_FILTERS).map((filter, index) => (
                <button
                  key={filter}
                  onClick={() => onFilterChange(filter)}
                  className={cn(' w-22 h-9.5 text-3 text-[#3E66EA] border-[#3E66EA] font-semibold text-center transition-colors', {
                    'border-l': index !== 0,
                    'bg-white': filter !== selectedFilter,
                    'bg-[#3E66EA] !text-white': filter === selectedFilter,
                  })}
                >
                  {t(`labels.${filter}`, SCOPE_OPTIONS)}
                </button>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }

  if (chartType === CHART_TYPES.LINE) {
    return (
      <>
        <Legend selected={selectedTypes} totalInsights={totalInsights} onClick={handleSelected} />
        <div className="mt-5 bg-white p-6 rounded-lg shadow">
          <LineChart selected={selectedTypes} data={data.data} labels={data.labels} />
          <div className="w-full mt-5 flex items-center justify-center">
            <div className="inline-flex flex-row  rounded-2 border border-black-800 overflow-hidden">
              {Object.values(ANALYTICS_FILTERS).map((filter, index) => (
                <button
                  key={filter}
                  onClick={() => onFilterChange(filter)}
                  className={cn(' w-22 h-9.5 text-3 border-black-800 font-semibold text-center transition-colors', {
                    'border-l': index !== 0,
                    'bg-white': filter !== selectedFilter,
                    'bg-black-800 text-white': filter === selectedFilter,
                  })}
                >
                  {t(`labels.${filter}`, SCOPE_OPTIONS)}
                </button>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }

  return null;
};

export default Analytics;

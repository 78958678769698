import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { BranchesBranchUserApi } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function markBranchAsOnboarded(branchId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new BranchesBranchUserApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.markBranchAsOnboarded(branchId).then((res) => res.data);
}

export default function useMarkBranchAsOnboarded(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useMutation(() => markBranchAsOnboarded(branchId, axiosInstance), props);
}

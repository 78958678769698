import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27.54" height="16.739" viewBox="0 0 27.54 16.739" {...props}>
    <g id="Group_4240" data-name="Group 4240" transform="translate(-509.254 -460.199)">
      <path
        id="Path_1502"
        data-name="Path 1502"
        d="M12.774,12.611A4.793,4.793,0,1,0,8.028,7.817a4.775,4.775,0,0,0,4.746,4.794m0-7.679A2.887,2.887,0,1,1,9.935,7.819a2.866,2.866,0,0,1,2.838-2.886"
        transform="translate(510.18 460.548)"
        fill="currentColor"
      />
      <path
        id="Path_1503"
        data-name="Path 1503"
        d="M27.484,7.958a1.918,1.918,0,0,0-.471-.9,20.549,20.549,0,0,0-2.9-2.794A18.569,18.569,0,0,0,16.456.288,12.765,12.765,0,0,0,11.425.215,15.226,15.226,0,0,0,7.088,1.781,23.1,23.1,0,0,0,1.4,6.094c-.366.371-.647.668-.92,1a1.935,1.935,0,0,0,0,2.548A20.229,20.229,0,0,0,3.4,12.472a18.561,18.561,0,0,0,7.659,3.977,12.572,12.572,0,0,0,2.685.29,13.11,13.11,0,0,0,2.349-.216,15.3,15.3,0,0,0,4.337-1.565,23.187,23.187,0,0,0,5.692-4.314c.292-.3.6-.619.9-.977a1.886,1.886,0,0,0,.371-.594l.087-.222.057-.636ZM13.781,1.9a10.7,10.7,0,0,1,2.272.245,16.71,16.71,0,0,1,6.86,3.593,18.377,18.377,0,0,1,2.66,2.565l.045.05-.048.076c-.252.309-.544.608-.8.872a21.289,21.289,0,0,1-5.216,3.963,13.509,13.509,0,0,1-3.8,1.38,10.848,10.848,0,0,1-4.285-.062,16.707,16.707,0,0,1-6.861-3.593A18.485,18.485,0,0,1,1.953,8.435c-.04-.048-.042-.065-.042-.065s0-.018.043-.067c.231-.283.487-.553.8-.872A21.122,21.122,0,0,1,7.971,3.47a13.361,13.361,0,0,1,3.8-1.377A11.2,11.2,0,0,1,13.781,1.9"
        transform="translate(509.254 460.199)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default SvgComponent;

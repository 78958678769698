import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { FacebookBranchApi } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function listBranchFacebookInterests(branchId: string, query: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new FacebookBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.listBranchFacebookInterests(branchId, query).then((res) => res.data);
}

export default function useListBranchFacebookInterests({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useQuery(
    ['useListBranchFacebookInterests', params.query],
    () => listBranchFacebookInterests(branchId, params.query, axiosInstance),
    props
  );
}

import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { SocialMediaPostsBranchApi } from '@app/api';
import type { BranchSocialMediaPostCreateInput } from '@app/api';

import { useAuth, useAxios } from '@app/hooks';

export function createSocialMediaPost(
  branchId: string,
  branchSocialMediaPostCreateInput: BranchSocialMediaPostCreateInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new SocialMediaPostsBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.createBranchSocialMediaPost(branchId, branchSocialMediaPostCreateInput).then((res) => res.data);
}

export default function useCreateSocialMediaPost(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useMutation(
    (values: { branchId: string; branchSocialMediaPostCreateInput: BranchSocialMediaPostCreateInput }) =>
      createSocialMediaPost(branchId, values.branchSocialMediaPostCreateInput, axiosInstance),
    props
  );
}

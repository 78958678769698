import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23.568" height="18.329" viewBox="0 0 23.568 18.329" {...props}>
    <g id="Group_4452" data-name="Group 4452" transform="translate(-293.915 -353.043)">
      <path
        id="Path_2688"
        data-name="Path 2688"
        d="M303.315,366.893v-1.028a2.6,2.6,0,0,0,1.38-.683,2.254,2.254,0,0,0,.642-1.663,2.411,2.411,0,0,0-.254-1.123,2.348,2.348,0,0,0-.782-.849,5.247,5.247,0,0,0-1.341-.606,2.822,2.822,0,0,1-1-.483.681.681,0,0,1-.218-.545.778.778,0,0,1,.21-.6.967.967,0,0,1,.683-.208.773.773,0,0,1,.646.285,1.4,1.4,0,0,1,.262.9v.386h1.729v-.386a2.937,2.937,0,0,0-.573-1.893,2.311,2.311,0,0,0-1.382-.8v-1.185h-1.579v1.191a2.549,2.549,0,0,0-1.13.69,2.325,2.325,0,0,0-.6,1.637,2.265,2.265,0,0,0,.614,1.621,4.008,4.008,0,0,0,1.779.967,2.613,2.613,0,0,1,.987.5.665.665,0,0,1,.218.513.7.7,0,0,1-.251.583,1.283,1.283,0,0,1-.83.234,1.092,1.092,0,0,1-.806-.271,1.124,1.124,0,0,1-.269-.821v-.385h-1.735v.385a2.629,2.629,0,0,0,.654,1.863,2.547,2.547,0,0,0,1.369.748v1.029Z"
        fill="currentColor"
      />
      <path
        id="Path_2689"
        data-name="Path 2689"
        d="M302.525,368.685a7.032,7.032,0,1,1,6.861-8.557l1.708.694a8.638,8.638,0,1,0-2.789,7.2l-.641-1.58A7.005,7.005,0,0,1,302.525,368.685Z"
        fill="currentColor"
      />
      <path
        id="Path_2690"
        data-name="Path 2690"
        d="M317.249,370.019l-2.928-2.929,2.521-1.332a.8.8,0,0,0-.012-1.479l-9.075-3.682a.8.8,0,0,0-1.066.412.812.812,0,0,0,0,.617l3.683,9.069a.791.791,0,0,0,.379.441.807.807,0,0,0,1.081-.336l1.363-2.582,2.927,2.925a.813.813,0,0,0,1.127.005.794.794,0,0,0,0-1.129Zm-4.636-3.831a.785.785,0,0,0-.333.341l-1.063,2.015-2.344-5.773,5.772,2.345Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default SvgComponent;

import React from 'react';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import cn from 'classnames';

import { useLocale } from '@app/hooks';
import { format } from '@app/lib';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Filler);

type AnalyticsPropTypes = {
  labels: string[];
  data: {
    impression: number[];
    click: number[];
    cost: string[];
    cpc: string[];
    reach: number[];
  };
};

const Analytics: React.FC<AnalyticsPropTypes> = ({ labels, data }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Analytics.Chart.BarChart',
  };
  const { t } = useLocale();

  const options = {
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    scales: {
      y: {
        grid: {
          borderColor: '#F0F0F0',
          tickColor: '#ffffff',
          color: '#F0F0F0',
          borderDash: [3, 3],
        },
        ticks: {
          color: '#9FA9B3',
          callback: (val: string | number) => format.data(val),
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: { color: '#9FA9B3' },
      },
    },
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
        },
        display: false,
        maxWidth: 2,
      },
      title: {
        display: true,
        callback: (val: string | number) => format.data(val),
      },
      tooltip: {
        titleColor: 'white',
        backgroundColor: '#072DAA',
        displayColors: false,
      },
    },
    elements: {
      bar: {
        borderRadius: 70,
        backgroundColor: '#072DAA',
      },
    },
  };

  return (
    <div className="w-full">
      <div className={cn('flex items-center justify-between text-gray-600 text-3 font-light pb-1.5')}>
        <span className="text-6">{t(`title`, SCOPE_OPTIONS)}</span>
      </div>
      {data && (
        <Bar
          options={options as any}
          data={{
            labels: labels ?? [],
            datasets: [
              {
                data: data?.reach ?? [],
                backgroundColor: '#3E66EA',
                maxBarThickness: 8,
              },
            ],
          }}
          width="100%"
          height="40"
        />
      )}
    </div>
  );
};

export default Analytics;

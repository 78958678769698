import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    id="Layer_1"
    x="0"
    y="0"
    enableBackground="new 0 0 20.3 21"
    version="1.1"
    viewBox="0 0 20.3 21"
    xmlSpace="preserve"
    {...props}
  >
    <style></style>
    <g id="Group_4564">
      <defs>
        <path id="SVGID_1_" d="M0 0H20.3V21H0z"></path>
      </defs>
      <clipPath id="SVGID_00000110433321416231812630000010653488018099881146_">
        <use overflow="visible" xlinkHref="#SVGID_1_"></use>
      </clipPath>
      <g id="Group_4562" clipPath="url(#SVGID_00000110433321416231812630000010653488018099881146_)">
        <path
          id="Path_2854"
          d="M17 0H3.3C1.5 0 0 1.5 0 3.3v10.6c0 1.8 1.5 3.3 3.3 3.3h6.1v2.3H6.9c-.4 0-.8.4-.8.8s.4.8.8.8h6.6c.4 0 .8-.4.8-.8s-.4-.8-.8-.8H11v-2.3h6c1.8 0 3.3-1.5 3.3-3.3V3.3C20.3 1.5 18.8 0 17 0m1.7 13.9c0 .9-.8 1.7-1.7 1.7H3.3c-.9 0-1.7-.8-1.7-1.7V3.3c0-.9.8-1.7 1.7-1.7H17c.9 0 1.7.8 1.7 1.7v10.6z"
          className="st1"
        ></path>
      </g>
    </g>
  </svg>
);

export default SvgComponent;

import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Group 235" xmlns="http://www.w3.org/2000/svg" width="24.135" height="17.207" viewBox="0 0 24.135 17.207" {...props}>
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_1503" data-name="Rectangle 1503" width="24.135" height="17.207" fill="currentColor" />
      </clipPath>
    </defs>
    <g id="Group_224" data-name="Group 224">
      <path
        id="Path_1076"
        data-name="Path 1076"
        d="M23.894.234A.829.829,0,0,0,23.32,0H.809A.814.814,0,0,0,0,.809V16.4a.814.814,0,0,0,.809.809H23.326a.811.811,0,0,0,.809-.81V.808a.807.807,0,0,0-.241-.574M22.509,5.083v10.5H1.619V1.625h20.89V5.083Z"
        transform="translate(0 0)"
        fill="currentColor"
      />
      <path
        id="Path_1077"
        data-name="Path 1077"
        d="M14.646,8.152h6.078a.818.818,0,0,0,.576-.241.813.813,0,0,0-.576-1.384H14.646a.812.812,0,0,0,0,1.625"
        transform="translate(-0.863 -0.407)"
        fill="currentColor"
      />
      <path
        id="Path_1078"
        data-name="Path 1078"
        d="M14.644,11.718h6.08a.813.813,0,0,0,0-1.626h-6.08a.813.813,0,0,0,0,1.626"
        transform="translate(-0.863 -0.629)"
        fill="currentColor"
      />
      <path
        id="Path_1079"
        data-name="Path 1079"
        d="M4.5,12.82h6.1a.814.814,0,0,0,.81-.81V5.832a.813.813,0,0,0-.81-.819H4.5a.813.813,0,0,0-.808.817V12.01a.814.814,0,0,0,.809.809m.81-6.18H9.8V11.2H5.313Z"
        transform="translate(-0.23 -0.313)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default SvgComponent;

import { Icons } from '@app/components';
import { ICON_TYPE } from '@app/constants';

function IconByType({ type, className }: { type: string | undefined; className?: string }): JSX.Element {
  const defaultStyle = 'w-6 h-6 mb-1';

  if (type === ICON_TYPE.ALL_MESSAGE) {
    return <Icons.Message className={className ?? defaultStyle} />;
  }

  if (type === ICON_TYPE.INSTAGRAM_VISIT) {
    return <Icons.Person className={className ?? defaultStyle} />;
  }

  if (type === ICON_TYPE.FINAL_URL) {
    return <Icons.Web className={className ?? defaultStyle} />;
  }

  if (type === ICON_TYPE.CALL) {
    return <Icons.Call className={className ?? defaultStyle} />;
  }

  if (type === ICON_TYPE.LEAD_GENERATION) {
    return <Icons.LeadGen className={className ?? defaultStyle} />;
  }

  if (type === ICON_TYPE.WHATSAPP_MESSAGE) {
    return <Icons.Whatsapp className={className ?? defaultStyle} />;
  }

  if (type === ICON_TYPE.MESSENGER) {
    return <Icons.Messenger className={className ?? defaultStyle} />;
  }

  if (type === ICON_TYPE.CAMPAIGN_LIST) {
    return <Icons.CampaignList className={className ?? defaultStyle} />;
  }

  if (type === ICON_TYPE.DATE) {
    return <Icons.Date className={className ?? defaultStyle} />;
  }

  if (type === ICON_TYPE.AGE_RANGE) {
    return <Icons.AgeRange className={className ?? defaultStyle} />;
  }

  if (type === ICON_TYPE.INTEREST) {
    return <Icons.Interest className={className ?? defaultStyle} />;
  }

  if (type === ICON_TYPE.LANGUAGE) {
    return <Icons.Language className={className ?? defaultStyle} />;
  }

  if (type === ICON_TYPE.GENDER) {
    return <Icons.Gender className={className ?? defaultStyle} />;
  }

  if (type === ICON_TYPE.LOCATION) {
    return <Icons.Location className={className ?? defaultStyle} />;
  }

  if (type === ICON_TYPE.PLATFORM) {
    return <Icons.Platform className={className ?? defaultStyle} />;
  }

  if (type === ICON_TYPE.PERSON) {
    return <Icons.Person className={className ?? defaultStyle} />;
  }

  if (type === ICON_TYPE.SEARCH) {
    return <Icons.Search className={className ?? defaultStyle} />;
  }

  if (type === ICON_TYPE.PMAX) {
    return <Icons.Store className={className ?? defaultStyle} />;
  }

  if (type === ICON_TYPE.SMART) {
    return <Icons.Store className={className ?? defaultStyle} />;
  }

  if (type === ICON_TYPE.KEYWORDS) {
    return <Icons.Search className={className ?? defaultStyle} />;
  }

  return <Icons.Web className={className ?? defaultStyle} />;
}

export default IconByType;

import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.369 24.064" {...props}>
    <g id="Group_4288" data-name="Group 4288" transform="translate(-786.246 -112.056)">
      <path
        id="Path_2752"
        data-name="st0"
        d="M790.9,133.1c0,0.5,0.3,0.9,0.8,0.9c0.5,0,0.9-0.3,0.9-0.8c0,0,0-0.1,0-0.1c0-2.2,1.8-4,4-4
		c2.2,0,4,1.8,4,4c0,0.5,0.3,0.9,0.8,0.9c0.5,0,0.9-0.3,0.9-0.8c0,0,0-0.1,0-0.1c0-3.2-2.6-5.7-5.7-5.7
		C793.4,127.4,790.9,130,790.9,133.1L790.9,133.1z"
        fill="currentColor"
      />
      <path
        id="Path_2753"
        data-name="st0"
        d="M796.6,119.5c-2,0-3.6,1.6-3.6,3.6s1.6,3.6,3.6,3.6c2,0,3.6-1.6,3.6-3.6
		C800.2,121.1,798.6,119.5,796.6,119.5z M798.5,123.1c0,1-0.8,1.9-1.9,1.9c-1,0-1.9-0.8-1.9-1.9c0-1,0.8-1.9,1.9-1.9c0,0,0,0,0,0
		C797.6,121.2,798.5,122.1,798.5,123.1z"
        fill="currentColor"
      />
      <path
        id="Path_2754"
        data-name="st0"
        d="M806.9,118.6c0-0.1,0-0.2,0-0.2c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.2-0.3l-5.7-5.7
		c-0.1-0.1-0.2-0.1-0.3-0.2c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.2,0c0,0,0,0-0.1,0h-11.7c-1.3,0-2.4,1-2.4,2.3v20.5c0,1.3,1.1,2.3,2.4,2.3
		h15.9c1.3,0,2.4-1,2.4-2.3L806.9,118.6C806.9,118.7,806.9,118.6,806.9,118.6z M801.8,117.8c-0.3,0-0.6-0.2-0.6-0.5v-2.2l2.7,2.7
		H801.8z M801.8,119.6h3.3v15.3c0,0.3-0.3,0.5-0.6,0.5h-15.9c-0.3,0-0.6-0.2-0.6-0.5v-20.5c0-0.3,0.3-0.5,0.6-0.5h10.8v3.5
		C799.4,118.6,800.5,119.6,801.8,119.6L801.8,119.6z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default SvgComponent;

import React from 'react';

import cn from 'classnames';

type BoxPropTypes = {
  className?: string;
};

const Box: React.FC<BoxPropTypes> = ({ children, className }) => {
  return <div className={cn('py-5 rounded-3 mb-4', className)}>{children}</div>;
};

export default Box;

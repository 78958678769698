const DELIVERY_STATUS = Object.freeze({
  APP_INSTALLS: 'APP_INSTALLS',
  BRAND_AWARENESS: 'BRAND_AWARENESS',
  CONVERSIONS: 'CONVERSIONS',
  EVENT_RESPONSES: 'EVENT_RESPONSES',
  LEAD_GENERATION: 'LEAD_GENERATION',
  LINK_CLICKS: 'LINK_CLICKS',
  LOCAL_AWARENESS: 'LOCAL_AWARENESS',
  MESSAGES: 'MESSAGES',
  OFFER_CLAIMS: 'OFFER_CLAIMS',
  PAGE_LIKES: 'PAGE_LIKES',
  POST_ENGAGEMENT: 'POST_ENGAGEMENT',
  PRODUCT_CATALOG_SALES: 'PRODUCT_CATALOG_SALES',
  REACH: 'REACH',
  STORE_VISITS: 'STORE_VISITS',
  VIDEO_VIEWS: 'VIDEO_VIEWS',
});

export default DELIVERY_STATUS;

import Modal from 'components/Modal';
import React, { useEffect } from 'react';
import MaskInput from '../MaskInput';
import { useForm } from 'react-hook-form';
import Button from 'components/Button';
import { useRouter } from 'next/router';
import useDeposit from '@app/hooks/api/useDeposit';
import useAuth from '@app/hooks/useAuth';
import useLocale from '@app/hooks/useLocale';

type DepositModalProps = {
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
  currency: string;
};

export default function DepositModal({ openModal, setOpenModal, currency }: DepositModalProps) {
  const SCOPE_OPTIONS = {
    scope: 'pages.wallet.balance.depositModal',
  };
  const { t } = useLocale();

  const router = useRouter();
  const { branchId } = useAuth();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{
    amount: string;
  }>();
  useEffect(() => {
    if (openModal) {
      setValue('amount', '');
    }
  }, [openModal, setValue]);
  const mutation = useDeposit();
  function handleDeposit(data: { amount: string }) {
    const balance = parseInt(data.amount) * 100;
    mutation.mutate(
      {
        branchId,
        balance,
      },
      {
        onSuccess: ({ data }) => {
          localStorage.setItem('deposit', JSON.stringify(data));
          router.push('/wallet/pay');
        },
      }
    );
    setOpenModal(false);
  }

  return (
    <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
      <form className="grid grid-cols-1 relative  px-5 py-5" onSubmit={handleSubmit(handleDeposit)}>
        <div className="items-center justify-center py-8 px-4 space-y-6 rounded-3">
          <div className="flex flex-col justify-center items-center gap-5">
            <span className="text-5.5 font-semibold text-black-800">{t('deposit', SCOPE_OPTIONS)}</span>
            <div className="flex justify-center items-center">
              <span className="text-3.5 text-slate-500 text-center">{t('description', SCOPE_OPTIONS)}</span>
            </div>
            <MaskInput
              type="text"
              id=""
              className="w-96"
              label={t('amount', SCOPE_OPTIONS)}
              {...register('amount', {
                required: { value: true, message: t('required', SCOPE_OPTIONS) },
                min: {
                  value: 1,
                  message: t('min', {
                    currency,
                    ...SCOPE_OPTIONS,
                  }),
                },
              })}
              requiredSign={true}
              error={errors.amount}
              mask="999"
              description={currency}
            />
          </div>
          <span className="text-3.5 text-slate-500 text-center">
            {t('min', {
              currency,
              ...SCOPE_OPTIONS,
            })}
          </span>
          <Button label={t('deposit', SCOPE_OPTIONS)} theme="blue" className="w-96" />
        </div>
      </form>
    </Modal>
  );
}

import React, { memo, useEffect, useState } from 'react';

import cn from 'classnames';
import { useRouter } from 'next/router';

import { AD_FORMAT, PUBLISHER_PLATFORMS, POSITIONS } from '@app/constants';
import { Icons } from '@app/components';
import { facebookPreview } from '@app/lib';
import { useLocale, api } from '@app/hooks';

import PreviewItem from './PreviewItem';
import GenerateItem from './GenerateItem';
import ResetButton from './ResetButton';
import DownloadImages from './DownloadImages';

type PreviewsPropTypes = {
  autoReload?: boolean;
  refresh?: boolean;
  downloadable?: boolean;
  campaignId?: string;
  creative?: any;
};

const PreviewGroup: React.FC<PreviewsPropTypes> = memo(
  ({ refresh = true, autoReload = false, downloadable = true, creative, campaignId }) => {
    const SCOPE_OPTIONS = {
      scope: 'components.PreviewGroup.index',
    };
    const { t } = useLocale();
    const [isActive, setIsActive] = useState(PUBLISHER_PLATFORMS.FACEBOOK);
    const [findButtonSelected, setFindButtonSelected] = useState(POSITIONS.FEED);
    const isPreview = !!campaignId;
    const [selected, setSelected] = useState(AD_FORMAT.MOBILE_FEED_STANDARD);
    const { platform, place } = facebookPreview.getTitle(selected);
    const Icon = platform === 'Facebook' ? Icons.Facebook : Icons.Instagram;
    const [refreshCount, setRefreshCount] = useState<number>(1);
    const router = useRouter();
    const { id } = router.query;
    const { data: campaign, isLoading } = api.useShowBranchCampaigns({
      enabled: !!id,
      params: {
        campaignId: id,
      },
    });

    useEffect(() => {
      setFindButtonSelected(POSITIONS.FEED);
    }, [platform]);

    useEffect(() => {
      if (creative?.storyImage || creative?.feedImage) {
        setRefreshCount((prev) => prev + 1);
      }
    }, [creative?.storyImage, creative?.feedImage]);

    const handleFacebook = () => {
      if (isActive === PUBLISHER_PLATFORMS.INSTAGRAM) {
        setIsActive(PUBLISHER_PLATFORMS.FACEBOOK);
      }
      setSelected(AD_FORMAT.MOBILE_FEED_STANDARD);
      return PUBLISHER_PLATFORMS.FACEBOOK;
    };
    const handleInstagram = () => {
      if (isActive === PUBLISHER_PLATFORMS.FACEBOOK) {
        setIsActive(PUBLISHER_PLATFORMS.INSTAGRAM);
      }
      setSelected(AD_FORMAT.INSTAGRAM_STANDARD);

      return PUBLISHER_PLATFORMS.INSTAGRAM;
    };

    function handleFeed() {
      if (isActive === PUBLISHER_PLATFORMS.FACEBOOK) {
        setSelected(AD_FORMAT.MOBILE_FEED_STANDARD);
      }

      if (isActive === PUBLISHER_PLATFORMS.INSTAGRAM) {
        setSelected(AD_FORMAT.INSTAGRAM_STANDARD);
      }
      setFindButtonSelected(POSITIONS.FEED);
    }

    function handleStory() {
      if (isActive === PUBLISHER_PLATFORMS.FACEBOOK) {
        setSelected(AD_FORMAT.FACEBOOK_STORY_MOBILE);
      }

      if (isActive === PUBLISHER_PLATFORMS.INSTAGRAM) {
        setSelected(AD_FORMAT.INSTAGRAM_STORY);
      }
      setFindButtonSelected(POSITIONS.STORY);
    }

    return (
      <div className="flex flex-col items-center w-full">
        <div className="flex items-center bg-white p-4.5 shadow-sm rounded-2 w-full justify-between mt-4">
          <div className="text-md text-black-800">{t('labels.platformTemplate', SCOPE_OPTIONS)}:</div>
          <div className="flex text-gray-500">
            <button
              className={cn('p-3 rounded-3', {
                'bg-blue-500 text-white': isActive === PUBLISHER_PLATFORMS.FACEBOOK,
              })}
              onClick={handleFacebook}
            >
              <Icons.Facebook className="w-5 h-5" />
            </button>
            <button
              className={cn('p-3 rounded-3', {
                'text-white': isActive === 'instagram',
              })}
              style={{
                background:
                  isActive === 'instagram' ? 'linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)' : '',
              }}
              onClick={handleInstagram}
            >
              <Icons.Instagram className="w-5 h-5" />
            </button>
          </div>
        </div>

        <div className="flex flex-col w-full bg-white mt-5 p-4.5 rounded-2 shadow-sm">
          <div className="flex items-start justify-between w-full mb-4">
            <div className="flex items-center space-x-2">
              <span className="bg-white text-blue-500">
                <Icon className="w-8 h-8" />
              </span>
              <div className="flex flex-col">
                <span>{platform}</span> <span className="text-gray-500">{place}</span>
              </div>
            </div>

            {refresh && (
              <div className="flex items-start">
                <button className="flex items-center">
                  <span className="pr-1">{t('labels.refresh', SCOPE_OPTIONS)} </span>
                  <span>
                    <Icons.Refresh className="w-5 h-5" />
                  </span>
                </button>
              </div>
            )}
          </div>
          {autoReload && <ResetButton key={refreshCount} onClick={() => setRefreshCount((prev) => prev + 1)} />}

          <div className="flex flex-col items-center ">
            {isPreview && <PreviewItem adFormat={selected} campaignId={campaignId as string} />}
            {!isPreview && (
              <GenerateItem
                autoReload={autoReload}
                creative={{ ...creative, attachment_id: facebookPreview.getImage(creative, selected) }}
                adFormat={selected}
                refreshCount={refreshCount}
                small={false}
              />
            )}
            <div className="flex items-center text-gray-500 mt-4">
              <button
                className={cn('px-4 py-1.5 border-y border-l border-gray-500 rounded-tl-md rounded-bl-md w-32', {
                  'bg-black-800 text-white': findButtonSelected === 'feed',
                })}
                onClick={handleFeed}
              >
                {t('actions.feed', SCOPE_OPTIONS)}
              </button>
              <button
                className={cn('px-4 py-1.5 border-y border-r border-gray-500 rounded-tr-md rounded-br-md w-32', {
                  'bg-black-800 text-white': findButtonSelected === 'story',
                })}
                onClick={handleStory}
              >
                {t('actions.story', SCOPE_OPTIONS)}
              </button>
            </div>
            {!isLoading && downloadable && <DownloadImages campaign={campaign} />}
          </div>
        </div>
      </div>
    );
  }
);
export default PreviewGroup;

import React, { useCallback } from 'react';

import { useDropzone } from 'react-dropzone';
import { Controller, useFormContext } from 'react-hook-form';

import { api, useLocale } from '@app/hooks';
import { Icons } from '@app/components';
import { array, image as imageLib } from '@app/lib';

type InputPropTypes = {
  size: { width: number; height: number };
  name: string;
  onUpload: (file: File) => void;
};

const Input: React.FC<InputPropTypes> = ({ onUpload, size, name }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.SocialMediaManagement.UploadInput',
  };
  const { t } = useLocale();
  const {
    control,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const { mutateAsync: mutateFacebookPhoto } = api.useBranchFacebookImageAttachments({
    onSuccess: (data: any) => {
      data && setValue('attachments', data);
    },
  });

  const onDrop = useCallback(async (file) => {
    await previewFile(array.first(file));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop,
  });

  async function previewFile(e: File) {
    const file = e;
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        getErrorMessage(file).then((res) => {
          if (res) {
            return;
          }
          onUpload(reader.result as any);
          createAttachment(file);
        });
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file as any);
    }
  }

  function createAttachment(image: File) {
    const formData = new FormData();

    if (image) {
      formData.append('file', image);
    }

    mutateFacebookPhoto({ payload: formData });
  }

  async function getErrorMessage(value: File) {
    const file = (await imageLib.fileToUrl(value)) as any;
    const fileRatio = Number(file?.width / file?.height).toFixed(2);
    const sizeRatio = Number(size.width / size.height).toFixed(2);

    if ((file?.width !== size.width && file?.height !== size.height) || fileRatio !== sizeRatio) {
      setError(name, { type: 'validate', message: t('messages.validate', SCOPE_OPTIONS) });
      return true;
    }

    return clearErrors(name);
  }

  return (
    <div className="flex flex-col items-center justify-center mt-3 ">
      <div
        className="w-full h-55 bg-white border-0.5 border-dashed border-[#bebebe] rounded-3 flex items-center justify-center"
        {...getRootProps()}
      >
        <Controller
          control={control}
          name={name}
          rules={{
            required: { value: true, message: t('messages.required', SCOPE_OPTIONS) },
          }}
          render={() => <input {...getInputProps()} />}
        />
        <div className="flex flex-col items-center justify-center">
          <Icons.ImageUpload className="h-25" />
          <div className="w-60 !text-center">
            <span className="text-3 text-gray-900 w-full">{t('label', SCOPE_OPTIONS)}</span>
          </div>
          {errors[name] && (
            <div className="flex items-center px-2 mt-2">
              <span className="inline text-red-400 text-3 text-center">{errors[name].message}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Input;

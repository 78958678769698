import Card from './Card';
import Balance from './Balance';
import TransactionsTable from './TransactionsTable';
import SavedCardsTable from './SavedCardsTable';
import PaymentForm from './PaymentForm';
import MaskInput from './MaskInput';
import DepositModal from './DepositModal';

export default {
  Card,
  Balance,
  TransactionsTable,
  SavedCardsTable,
  PaymentForm,
  MaskInput,
  DepositModal,
};

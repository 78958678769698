import React from 'react';

import cn from 'classnames';
import { Icons } from '@app/components';

type CollapsePropTypes = {
  miniSidebar: boolean;
  onCollapseClick: (miniSidebar: boolean) => void;
};

const Collapse: React.FC<CollapsePropTypes> = ({ miniSidebar, onCollapseClick }) => {
  return (
    <div
      className={cn('bg-gray-100 cursor-pointer', {
        relative: miniSidebar,
      })}
      onClick={() => onCollapseClick(!miniSidebar)}
      aria-hidden
    >
      <div
        className={cn('absolute', {
          'bottom-6 right-0': !miniSidebar,
          'bottom-6 left-0': miniSidebar,
        })}
      >
        <div
          className={cn('shadow-boxLarge w-5 h-10 flex items-center justify-center ', {
            'rounded-tl-lg rounded-bl-lg': !miniSidebar,
            'rounded-tr-lg rounded-br-lg': miniSidebar,
          })}
          aria-hidden
        >
          <button>{miniSidebar ? <Icons.ChevronRight className="w-3 h-3" /> : <Icons.Collapse className="w-3 h-3" />}</button>
        </div>
      </div>
    </div>
  );
};

export default Collapse;

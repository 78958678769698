import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18.906" height="18.906" viewBox="0 0 18.906 18.906" {...props}>
    <g id="Group_4251" data-name="Group 4251" transform="translate(0)">
      <g id="Group_88" data-name="Group 88" transform="translate(0 0)">
        <g id="Group_87" data-name="Group 87">
          <path
            id="Path_285"
            data-name="Path 285"
            d="M1147.812,75.922c2.524,0,2.823.009,3.819.055a5.224,5.224,0,0,1,1.756.325,3.134,3.134,0,0,1,1.795,1.795,5.221,5.221,0,0,1,.325,1.755c.046,1,.055,1.3.055,3.82s-.01,2.823-.055,3.82a5.221,5.221,0,0,1-.325,1.755,3.134,3.134,0,0,1-1.795,1.795,5.224,5.224,0,0,1-1.756.325c-1,.046-1.3.055-3.819.055s-2.824-.01-3.82-.055a5.223,5.223,0,0,1-1.754-.325,3.134,3.134,0,0,1-1.795-1.795,5.23,5.23,0,0,1-.325-1.755c-.046-1-.055-1.3-.055-3.82s.01-2.823.055-3.82a5.23,5.23,0,0,1,.325-1.755,3.134,3.134,0,0,1,1.795-1.795,5.223,5.223,0,0,1,1.754-.325c1-.046,1.3-.055,3.82-.055m0-1.7c-2.568,0-2.889.01-3.9.057a6.927,6.927,0,0,0-2.3.439,4.828,4.828,0,0,0-2.764,2.765,6.938,6.938,0,0,0-.44,2.295c-.047,1.008-.057,1.33-.057,3.9s.01,2.889.057,3.9a6.938,6.938,0,0,0,.44,2.295,4.828,4.828,0,0,0,2.764,2.765,6.927,6.927,0,0,0,2.3.439c1.008.046,1.329.057,3.9.057s2.889-.011,3.9-.057a6.926,6.926,0,0,0,2.295-.439,4.836,4.836,0,0,0,2.765-2.765,6.927,6.927,0,0,0,.439-2.295c.046-1.008.057-1.33.057-3.9s-.011-2.889-.057-3.9a6.926,6.926,0,0,0-.439-2.295A4.836,4.836,0,0,0,1154,74.715a6.927,6.927,0,0,0-2.295-.439c-1.008-.047-1.33-.057-3.9-.057"
            transform="translate(-1138.359 -74.219)"
            fill="currentColor"
          />
        </g>
      </g>
      <g id="Group_90" data-name="Group 90" transform="translate(4.599 4.599)">
        <g id="Group_89" data-name="Group 89">
          <path
            id="Path_286"
            data-name="Path 286"
            d="M1148.049,79.055a4.854,4.854,0,1,0,4.854,4.854,4.854,4.854,0,0,0-4.854-4.854m0,8a3.151,3.151,0,1,1,3.151-3.151,3.15,3.15,0,0,1-3.151,3.151"
            transform="translate(-1143.195 -79.055)"
            fill="currentColor"
          />
        </g>
      </g>
      <g id="Group_92" data-name="Group 92" transform="translate(13.365 3.272)">
        <g id="Group_91" data-name="Group 91">
          <path
            id="Path_287"
            data-name="Path 287"
            d="M1154.681,78.795a1.135,1.135,0,1,1-1.134-1.135,1.134,1.134,0,0,1,1.134,1.135"
            transform="translate(-1152.412 -77.66)"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgComponent;

import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useAuth } from '@app/hooks';
import { useRouter } from 'next/router';

import { ENVIRONMENT_TYPES } from '@app/constants';

function useGTM() {
  const [initialized, setInitialized] = useState<boolean>(false);
  const router = useRouter();
  const { branch, token } = useAuth();

  useEffect(() => {
    if (process.env.NODE_ENV === ENVIRONMENT_TYPES.PRODUCTION) {
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID as string,
        dataLayer: {
          event: 'virtualPageView',
        },
      });
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized && token) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'userIdentification',
          userId: token.user.id,
          userEmail: token.user.email,
        },
      });
    }
  }, [initialized, token]);

  useEffect(() => {
    if (initialized && branch) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'branchUpdated',
          branch: {
            id: branch.id,
            name: branch.name,
            isOnboarded: branch.is_onboarded,
            companyName: branch?.company?.name,
            allowGmUpdateCampaigns: branch.allow_gm_update_campaigns,
            hasActiveCampaigns: branch.has_active_campaigns,
          },
        },
      });
    }
  }, [initialized, branch]);

  useEffect(() => {
    const virtualPageView = (url: string) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'virtualPageView',
          virtualUrl: url,
          ...(token && { userId: token.user.id, userEmail: token.user.email }),
          ...(branch && {
            branch: {
              id: branch.id,
              name: branch.name,
              isOnboarded: branch.is_onboarded,
              companyName: branch?.company?.name,
              allowGmUpdateCampaigns: branch.allow_gm_update_campaigns,
              hasActiveCampaigns: branch.has_active_campaigns,
            },
          }),
        },
      });
    };

    const handleRouterChange = (url: string) => {
      if (initialized) {
        virtualPageView(url);
      }
    };

    router.events.on('routeChangeComplete', handleRouterChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouterChange);
    };
  }, [router, initialized, token, branch]);

  return useGTM;
}

export default useGTM;

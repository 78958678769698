import React, { forwardRef, useState } from 'react';

import type { FieldError } from 'react-hook-form';
import cn from 'classnames';

type CheckboxPropTypes = {
  label?: JSX.Element | string;
  id: string;
  error?: FieldError;
  className?: string;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  name: string;
  checked?: boolean;
  disabled?: boolean;
  day?: boolean;
  value?: number;
  required?: boolean;
  dangerouslySetInnerHTML?: any;
  labelClassname?: string;
};

export const Checkbox: React.ForwardRefRenderFunction<HTMLInputElement, React.PropsWithChildren<CheckboxPropTypes>> = (
  {
    label,
    id,
    error,
    day,
    value,
    checked,
    disabled,
    className,
    onChange,
    onBlur,
    name,
    required,
    dangerouslySetInnerHTML,
    labelClassname,
    ...props
  },
  ref
) => {
  const [isFocused, setIsFocused] = useState(false);

  function onFocus() {
    setIsFocused(true);
  }

  function handleOnBlur(event: React.FocusEvent<HTMLInputElement>) {
    setIsFocused(false);
    onBlur && onBlur(event);
  }

  return (
    <div className={cn('items-center select-none', { flex: !day, 'pr-6': day }, className)}>
      <div className="flex items-center">
        <input
          required={required}
          checked={checked}
          ref={ref}
          onFocus={onFocus}
          name={name}
          disabled={disabled}
          onChange={onChange}
          onBlur={handleOnBlur}
          type="checkbox"
          id={id}
          value={value}
          className={cn('w-6 h-6 border bg-white border-black-800 rounded-md cursor-pointer', {
            'bg-white border-gray-400': !error,
            'bg-white border-red-400': error,
            'border-gray-600': !error && isFocused,
          })}
          {...props}
        />
      </div>
      <div className="ml-3">
        <label
          htmlFor={id}
          className={cn('block text-sm font-semibold text-gray-900', labelClassname)}
          dangerouslySetInnerHTML={dangerouslySetInnerHTML}
        >
          {label}
        </label>
      </div>
    </div>
  );
};

export default forwardRef(Checkbox);

import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { GoogleBranchApi } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function listGoogleKeywords(branchId: string, query: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new GoogleBranchApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.listGoogleKeywords(branchId, query).then((res) => res.data);
}

export default function useListGoogleKeywordsByBranch({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();

  return useQuery(['useListGoogleKeywordsByBranch', params.query], () => listGoogleKeywords(branchId, params.query, axiosInstance), props);
}

import React, { useState } from 'react';

import ReactCountryFlag from 'react-country-flag';

import { useLocale } from '@app/hooks';
import Icons from 'components/Icons';

const AuthLanguageDropdown: React.FC = () => {
  const { locale, changeLocale, locales } = useLocale();
  const [listOpen, setListOpen] = useState(false);

  function handleChangeLanguage(locale: any) {
    changeLocale(locale.key);
    setListOpen(false);
  }

  const selectedLocale = locales.find((l) => l.key === locale);

  return (
    <>
      <button onClick={() => setListOpen((current) => !current)} className="flex gap-2 items-center w-30 cursor-pointer">
        <div className="w-max">
          <ReactCountryFlag svg countryCode={selectedLocale?.flag as string} style={{ display: 'block' }} />
        </div>
        <div>{selectedLocale?.text}</div>
        <div>
          <Icons.ChevronDown width={15} />
        </div>
      </button>
      {listOpen &&
        locales
          .filter((l) => l.key !== locale)
          .map((l) => (
            <button
              key={l.key}
              className="flex gap-2 items-center w-30 cursor-pointer mt-2 last:mb-1"
              onClick={() => handleChangeLanguage(l)}
            >
              <div className="w-max">
                <ReactCountryFlag svg countryCode={l.flag} style={{ display: 'block' }} />
              </div>
              <div>{l.text}</div>
            </button>
          ))}
    </>
  );
};

export default AuthLanguageDropdown;

import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 22 22" {...props}>
    <defs>
      <clipPath>
        <rect data-name="Rectangle 1507" width="10.865" height="8.049" fill="#fff" />
      </clipPath>
    </defs>
    <g data-name="Group 3877" transform="translate(-867 -689)">
      <g data-name="Rectangle 1505" transform="translate(867 689)" fill="currentColor" stroke="currentColor" strokeWidth="1">
        <rect width="22" height="22" rx="5" stroke="none" />
        <rect x="0.5" y="0.5" width="21" height="21" rx="4.5" fill="none" />
      </g>
      <g data-name="Group 297" transform="translate(873.135 695.975)">
        <g data-name="Group 296" transform="translate(0 0)">
          <path
            data-name="Path 1087"
            d="M3.743,8.049a.924.924,0,0,1-.655-.271L.271,4.961a.926.926,0,0,1,1.31-1.31L3.743,5.813,9.284.271a.926.926,0,1,1,1.31,1.31l-6.2,6.2a.924.924,0,0,1-.655.271"
            transform="translate(0 0)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgComponent;

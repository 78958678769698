import currency from 'currency.js';

function format(value: string | number) {
  return currency(value, { symbol: '' }).format();
}

function reverse(value: string | number) {
  return currency(value, { symbol: '' }).format().replaceAll(',', '').replaceAll('.', '');
}

function handleCents(value: string | number) {
  return currency(value, { symbol: '', fromCents: true, precision: 2 }).format().split(',').join('');
}

function decimal(value: string | number) {
  return String(currency(value, { symbol: '' }).format()).replace(new RegExp(',', 'g'), '');
}

function reverseFormat(value: string | number) {
  return currency(value, { symbol: '' }).format().split('.')[0].replaceAll(',', '');
}

export default {
  format,
  reverse,
  decimal,
  handleCents,
  reverseFormat,
};

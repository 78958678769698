import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { WalletBranchV2Api } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function getSavedCards(branchId: string, axiosInstance: AxiosInstance) {
  const api = new WalletBranchV2Api(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);
  return api.getSavedCards(branchId);
}

export default function useGetSavedCards() {
  const { api: axiosInstance } = useAxios();
  const { branchId } = useAuth();
  return useQuery(['savedCards', branchId], () => getSavedCards(branchId, axiosInstance));
}

import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { BranchesBranchUserApi } from '@app/api';
import { useAxios } from '@app/hooks';

export function listBranchUserBranches(
  page?: number,
  perPage?: number,
  query?: object,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new BranchesBranchUserApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.listBranchUserBranches(page, perPage, query).then((res) => res.data);
}

export default function useListBranchUserBranches({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useQuery(
    ['useListBranchUserBranches', params.page, params.perPage, params.query],
    () => listBranchUserBranches(params.page, params.perPage, params.query, axiosInstance),
    props
  );
}

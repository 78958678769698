import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    version="1.1"
    id="katman_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1080 1080"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path
        d="M273.01,535.82c5.55-8.11,10.53-16.69,16.75-24.25c33.58-40.82,72.81-74.89,120.24-98.9
      c32.37-16.38,66.5-26.83,102.72-30.26c70.37-6.67,134,12.01,192.29,50.88c37.84,25.24,69.39,57.06,96.89,93.07
      c6.78,8.88,6.8,18.5,0.05,27.34c-31.73,41.54-68.8,77.25-114.11,103.85c-37.47,21.99-77.6,35.96-121.04,40.07
      c-63.22,5.98-121.62-8.73-175.94-40.86c-44.92-26.57-81.61-62.19-113.02-103.51c-2.05-2.69-3.24-6.04-4.82-9.08
      C273.01,541.38,273.01,538.6,273.01,535.82z M541.08,652.13c62.86-0.94,111.72-51.59,111.07-115.15
      c-0.61-59.83-52.49-110-112.83-109.13c-63.5,0.92-112.14,51.11-111.54,115.11C428.35,603.02,480.26,653.04,541.08,652.13z"
      />
      <path
        d="M600.43,540.15c-0.23,33.26-27.74,60.37-61.11,60.23c-33.03-0.14-59.95-27.58-59.74-60.92
      c0.21-33.06,27.91-60.1,61.4-59.93C573.77,479.7,600.66,507.13,600.43,540.15z"
      />
    </g>
  </svg>
);

export default SvgComponent;

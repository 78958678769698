import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_5_5)">
      <path
        d="M9.45073 23.1401C9.45073 22.3625 9.45908 21.5848 9.44739 20.8072C9.44071 20.3802 9.56541 20.0167 9.82707 19.6783C12.015 16.8432 14.1968 14.0026 16.3797 11.1637C16.4248 11.1052 16.4894 11.0496 16.505 10.9833C16.5233 10.9048 16.5289 10.7735 16.4855 10.7423C16.422 10.6972 16.3023 10.6978 16.2194 10.7228C16.1359 10.7479 16.0679 10.8252 15.9939 10.8809C12.8829 13.2261 9.76695 15.564 6.66599 17.9226C6.10259 18.3512 5.56201 18.4269 4.91231 18.1525C3.61014 17.6019 2.29293 17.0865 0.979615 16.5627C0.429015 16.3434 0.0532264 15.9821 0.00479146 15.3681C-0.0458704 14.7324 0.271462 14.3082 0.810371 13.9993C8.83054 9.41355 16.8507 4.82783 24.8614 0.22596C25.7861 -0.305089 26.7815 0.158605 26.9653 1.00305C27.0126 1.2207 27.012 1.46897 26.9608 1.68607C25.9175 6.09255 24.8636 10.4968 23.8114 14.9016C23.1562 17.6431 22.4998 20.3847 21.8434 23.1256C21.6068 24.1137 20.8213 24.5295 19.8804 24.1549C18.61 23.6489 17.3406 23.1396 16.0757 22.6191C15.8898 22.5428 15.784 22.574 15.6476 22.7115C14.3761 23.9929 13.1006 25.2704 11.819 26.5418C11.2111 27.1452 10.3621 27.1508 9.81928 26.5719C9.56708 26.3025 9.45073 25.9802 9.45128 25.6122C9.4524 24.7884 9.45128 23.9645 9.45128 23.1407L9.45073 23.1401Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_5_5">
        <rect width="27" height="27" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;

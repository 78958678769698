import React from 'react';
import ContentLoader from 'react-content-loader';

const SkeletonLoaderFeed = (props: any) => {
  return (
    <ContentLoader viewBox="0 0 400 400" {...props}>
      <rect x="0" y="0" rx="16" ry="16" width="400" height="400" />
    </ContentLoader>
  );
};

export default SkeletonLoaderFeed;

import React, { useState, useContext, createContext, SetStateAction, Dispatch } from 'react';

type ReturnTypes = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  data: InvitationDetail | null;
  setData: Dispatch<SetStateAction<any>>;
};

const AgreementModalContext = createContext({} as ReturnTypes);

export const AgreementModalProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [data, setData] = useState<InvitationDetail | null>(null);

  return (
    <AgreementModalContext.Provider
      value={{
        open,
        setOpen,
        data,
        setData,
      }}
    >
      {children}
    </AgreementModalContext.Provider>
  );
};

export default function useAgreementModal(): ReturnTypes {
  return useContext(AgreementModalContext);
}
